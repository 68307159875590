import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import WelcomeKnowledge from "../components/WelcomeKnowledge";
import KnowledgeBaseList from "../components/KnowledgeBaseList";
import CreateKnowledgeBase from "../components/CreateKnowledgeBase";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { Toaster } from "react-hot-toast";

function Knowledge() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [loading, setLoading] = useState(false);
  const [knowledgeList, setKnowledgeList] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);

  useEffect(() => {
    const handleGetKnowledgeBase = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseUrl}/api/knowledge_base/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE4MjI5Nzg0LCJpYXQiOjE3MTgwMTM3ODQsImp0aSI6IjQyYWY5MjY2NGM0ZjQ2NzJiYTBjODQ4MjkzMDIxM2QyIiwidXNlcl9pZCI6NH0.CFG6kc2KskSny2Ve6uw4Ln95Ae_fnIWsj4VPXjKolZQ`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setKnowledgeList(responseData.data["knowledge base"]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    handleGetKnowledgeBase();
  }, [refresh, setRefresh]);

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor }}
        className="w-full h-screen"
      >
        <Navbar />
        {knowledgeList && knowledgeList.length > 0 ? (
          <div>
            <div className="flex justify-end p-5 w-full">
              <CreateKnowledgeBase refresh={refresh} setRefresh={setRefresh} />
            </div>
            <KnowledgeBaseList
              data={knowledgeList}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </div>
        ) : (
          <WelcomeKnowledge  refresh={refresh} setRefresh={setRefresh} />
        )}
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default Knowledge;
