import React, { useEffect } from "react";
import { useNotifications } from "../utils/NotificationsContext";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";

function ShowNotification() {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const { notifications, unreadCount, markAsRead, userId } = useNotifications();

  useEffect(() => {
    // Call markAsRead on component mount
    markAsRead();
  }, [markAsRead]);

  console.log(notifications);
  

  return (
    <div style={{ backgroundColor: backgroundColor, color: fontColor }}>
      <Navbar />
      <div className="mx-auto rounded-lg p-4 border max-w-2xl my-4 bg-white">
        {notifications && notifications.length > 0 ? (
          <ul>
            {notifications.map((notification) => {
              const timestamp = notification.timestamp;
              const formattedDate = timestamp
                ? timestamp.toDate().toLocaleString()
                : "Invalid Date";
              const isRead = notification.read?.[userId] || false;

              return (
                <li
                  key={notification.id}
                  className={isRead ? "bg-gray-200" : "my-4 border-b-2"}
                >
                  <strong>{notification.heading}</strong>
                  <p>{notification.message}</p>
                  <span className="text-gray-500 text-sm">{formattedDate}</span>
                </li>
              );
            })}
          </ul>
        ) : (
          <h2 className="text-center">No notifications yet!</h2>
        )}
      </div>
    </div>
  );
}

export default ShowNotification;
