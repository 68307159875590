import React, { useState } from "react";
import { MdAttachFile } from "react-icons/md";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { setFileData } from "../features/importContact/contactSlice";
import { Button } from "@material-tailwind/react";
import { setActiveStep } from "../features/importContact/activeStep";

function ContactUpload() {
  const [isDragOver, setIsDragOver] = useState(false);
  const dispatch = useDispatch();
  const { fontColor } = useSelector((state) => state.theme);
  const fileData = useSelector((state) => state.fileData.fileData);
  const activeStep = useSelector((state) => state.activeStep.activeStep);

  const handleNext = () => dispatch(setActiveStep(activeStep + 1));
  const handlePrev = () => dispatch(setActiveStep(activeStep - 1));

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    handleFiles(files);
  };

  const handleFiles = (files) => {
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        function addMissingValues(arr, defaultValue) {
          for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < arr[i].length; j++) {
              if (arr[i][j] === undefined) {
                arr[i][j] = defaultValue;
              }
            }
          }
          return arr;
        }
        const completeData = addMissingValues(parsedData, "-");
        const filteredData = completeData.filter((array) => array.length > 0);
        dispatch(setFileData(filteredData)); // Dispatch action to update file data
      };
      reader.readAsBinaryString(file);
    }
  };
  const isFileUploaded = fileData && fileData.length > 0;

  return (
    <>
      <div className="p-4">
        {fileData.length > 0 ? (
          <div className="relative overflow-x-auto h-[300px] my-5 overflow-y-hidden">
            <table className="w-full text-sm text-left rtl:text-right border text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {fileData.length > 0 &&
                    fileData[0].map((header, index) => (
                      <th
                        style={{ color: fontColor }}
                        key={index}
                        scope="col"
                        className="px-6 py-3"
                      >
                        {header}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {fileData.slice(1).map((row, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    {row.map((cell, index) => (
                      <td
                        style={{ color: fontColor }}
                        key={index}
                        className="px-6 py-4"
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}

        <div
          className={`border-2 border-dashed flex justify-center items-center p-8 ${
            fileData.length > 0 ? "h-[200px]" : "h-[500px]"
          } rounded-lg cursor-pointer relative ${
            isDragOver ? "bg-gray-200" : ""
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={handleFileSelect}
            accept=".csv, .xls, .xlsx, .xlsb"
          />
          <div className="flex items-center gap-2">
            <MdAttachFile className="text-4xl" style={{ color: fontColor }} />
            <div>
              <p
                style={{ color: fontColor }}
                className="text-md text-gray-800 font-semibold"
              >
                Click to choose a file or drag and drop here
              </p>
              <p
                style={{ color: fontColor }}
                className="text-sm text-gray-600 font-medium"
              >
                Please provide a file in any of the following formats (csv, xls,
                xlsx, xlsb)
              </p>
            </div>
          </div>
          <label htmlFor="fileInput" className="absolute inset-0"></label>
        </div>
      </div>
      <div className="px-4 flex justify-between">
        <Button onClick={handlePrev}>Prev</Button>
        {(activeStep !== 1 || isFileUploaded) && (
          <Button onClick={handleNext}>Next</Button>
        )}
      </div>
    </>
  );
}

export default ContactUpload;
