import React, { useState } from "react";

function SuperRemoveCredits({ isOpen, onClose, onConfirm }) {
  const [creditAmount, setCreditAmount] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (creditAmount) {
      onConfirm(creditAmount); // Pass the credit amount to the parent
      setCreditAmount(""); // Clear the input field
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-4 max-w-2xl rounded shadow-lg">
          <h2 className="text-xl mb-4">Remove Credits</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="creditAmount" className="block text-sm font-medium">
                Amount
              </label>
              <input
                id="creditAmount"
                type="number"
                min="1"
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
                className="border p-2 w-full mt-1"
                required
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={onClose}
                className="border p-2 bg-gray-200 rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="border p-2 bg-green-400 text-white rounded"
              >
                Remove
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default SuperRemoveCredits;
