import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CampaignList from "./CampaignList";
import CampaignHome from "./CampaignHome";
import QuickCampaignTable from "./QuickCampaignTable";

function QuickCampagnList() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const refresh = useSelector((state) => state.refresh.refresh);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;

  useEffect(() => {
    const fetchCampaign = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseUrl}/api/quick_campaign/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setResponseData(responseData.data.quick_campaign);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchCampaign();
  }, [refresh]);

  return (
    <>
      {responseData && responseData.length != 0 ? (
        <QuickCampaignTable
          responseData={responseData}
          setResponseData={setResponseData}
        />
      ) : (
        <CampaignHome data={"quickCampaign"} />
      )}
    </>
  );
}

export default QuickCampagnList;
