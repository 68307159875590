import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Dashboard from "../components/Dashboard";
import Intercom from "@intercom/messenger-js-sdk";
import { useSelector } from "react-redux";
import { GHL } from "../utils/ghlHelper";

function DashboardPage() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const [loading, setLoading] = useState(false); // Loading state
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  // useEffect(() => {
  //   const ghl = new GHL(); // Create an instance of GHL

  //   window.ghl = ghl;

  //   const fetchUserData = async () => {
  //     try {
  //       const userData = await ghl.getUserData();
  //       console.log("hiii", userData); // Use the user data as needed

  //       if (userData) {
  //         setLoading(true);
  //         const response = await fetch(`${baseUrl}/api/ghl_user_login/`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             user_data: userData,
  //           }),
  //         });
  //         const responseData = await response.json();
  //         if (!response.ok) {
  //           console.error(responseData);
  //         }
  //         const { access_token, refresh_token } = responseData.data;
  //         localStorage.setItem("accessToken", access_token);
  //         localStorage.setItem("refreshToken", refresh_token);
  //         localStorage.setItem(
  //           "userData",
  //           JSON.stringify(responseData.data.user)
  //         );
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     } finally {
  //       setLoading(false); // Set loading to false after fetch completes
  //     }
  //   };
  //   fetchUserData();
  // }, []); // Fetch user data when the component mounts

  if (baseUrl == "https://app.closerx.ai") {
    Intercom({
      app_id: "p6t03dhm",
      user_id: userData?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: userData?.first_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: userData?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
  }
  return (
    <>
      {loading ? ( // Conditionally render loading spinner
        <div>Loading...</div>
      ) : (
        <div className="bg-[#ECF0F4]">
          <Navbar />
          <Dashboard />
        </div>
      )}
    </>
  );
}

export default DashboardPage;
