import React from "react";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import UploadCloneVoice from "../components/UploadCloneVoice";
import RecordCloneVoice from "../components/RecordCloneVoice";
import { Toaster } from "react-hot-toast";
import GetCloneVoice from "../components/GetCloneVoice";

function CloneVoice() {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor, color: fontColor }}
        className="max-w-screen h-screen flex flex-col"
      >
        <Navbar />
        <div className="flex-1 p-4 flex gap-4 flex-col lg:flex-row">
          <div className="lg:basis-1/2  p-2 flex gap-3 flex-col">
            <UploadCloneVoice />
            <RecordCloneVoice />
          </div>
          <div className="lg:basis-1/2 h-full p-2">
            <h2>Your Voices</h2>
            <GetCloneVoice />
          </div>
        </div>
      </div>
    </>
  );
}

export default CloneVoice;
