import React, { useMemo, useRef, useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import PageHeader from "./PageHeader";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../features/Refresh/refresh";
import { MdOutlineCopyAll } from "react-icons/md";
import DNSExample from "../assets/dns.jpeg";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import { senderEmailSchema } from "../schemas/FormSchema";
import { MdCopyAll } from "react-icons/md";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { IoCopyOutline } from "react-icons/io5";

function AgencyCustom({ data }) {
  const dnsRecordRef = useRef(null);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const refresh = useSelector((state) => state.refresh.refresh);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const editor = useRef(null);
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [logo, setLogo] = useState("");
  const [icon, setIcon] = useState("");
  const [content, setContent] = useState("");
  const [resellPrice, setResellPrice] = useState("");
  const [legalName, setLegalName] = useState("");
  const [token, setToken] = useState("");
  const [stripeKey, setStripeKey] = useState("");
  const [records, setRecords] = useState("");
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [dnsData, setDnsData] = useState("");

  useEffect(() => {
    setResellPrice(data.reselling_price || "");
    setLegalName(data.legal_name || "");
    setStripeKey(data.stripe_key || "");
    setEmail(data.email || "");
    setContent(data.content || "");
    setColor1(data.color1 || "");
    setColor2(data.color2 || "");
    setRecords(data.dns_record || "");
    setContent(data.email_template || "");
    // setLogo()
    setDomain(data.domain.replace(/^app\./, "") || "");
  }, []);
  // console.log(data.logo);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("reselling_price", resellPrice);
      formData.append("stripe_key", stripeKey);
      formData.append("legal_name", legalName);
      // formData.append("sms_number", "");
      formData.append("color1", color1);
      formData.append("color2", color2);
      // formData.append("dns_record", records);
      formData.append("logo", logo);
      formData.append("fav_icon", icon);
      formData.append("email", email);
      formData.append("email_template", content);
      formData.append("sendgrid_token", token);

      const response = await fetch(`${baseUrl}/api/white_label_domain_user/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          Accept: "application/json",
          "Company-Name": companyName,
        },
        body: formData,
      });

      const jsonData = await response.json();
      if (response.ok) {
        // console.log("kefbhcd");
        toast.success("Agency Customization Success!");
        fetchData();
      } else if (response.ok) {
        toast.error(jsonData.message);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setLogo(file);
  };

  const handleDropIcon = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setIcon(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const { width, height } = img;
          // Define your dimension limits
          const minWidth = 100;
          const minHeight = 40;
          const maxWidth = 200;
          const maxHeight = 70;

          if (
            width < minWidth ||
            width > maxWidth ||
            height < minHeight ||
            height > maxHeight
          ) {
            toast.error(
              `Logo dimensions must be between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight}`
            );
            setLogo("");
          } else {
            setLogo(file);
          }
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    }
    // setLogo(file);
  };

  const handleFileInputIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const { width, height } = img;
          // Define your dimension limits
          const minWidth = 16;
          const minHeight = 16;

          if (width !== minWidth || height !== minHeight) {
            toast.error(
              `Fav Icon dimensions must be ${minWidth}x${minHeight} `
            );
            setIcon("");
          } else {
            setIcon(file);
          }
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    }
    // setIcon(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragOverIcon = (e) => {
    e.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      senderEmail: data?.sendgrid_email || "",
    },
    validationSchema: senderEmailSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${baseUrl}/api/authenicate_domain/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: companyName,
            email: `${values.senderEmail}@${domain}`,
            domain: domain,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          setDnsData(data.dns_records);
          toast("Copy DNS Records", {
            icon: <IoCopyOutline />,
          });
          dnsRecordRef.current?.scrollIntoView({ behavior: 'smooth' });
          localStorage.setItem("domainID", JSON.stringify(data.domain_id));
        } else if (response.status === 401) {
          const data = await response.json();
          toast.error(data.message);
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "",
    }),
    []
  );

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleCopyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied successfully");
      })
      .catch((err) => {
        toast.error("Failed to copy text");
      });
  };

  const handleVerify = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/verify_domain/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          domain_id: localStorage.getItem("domainID"),
          email: formik.values.senderEmail,
        }),
      });
      if (response.ok) {
        toast.success("DNS verified");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyUrl = () => {
    let url = `54.183.118.154`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("DNS value copied successfully");
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      });
  };

  return (
    <>
      <div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            // Define default options
            className: "",
            duration: 2000,
            style: {
              background: "#363636",
              color: "#fff",
            },

            // Default options htmlFor specific types
            success: {
              duration: 2000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
      </div>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full min-h-screen"
      >
        {/* <Navbar />
        <PageHeader buttons={pageHeaderData} /> */}
        <div className="flex-1 flex justify-center w-full items-center p-4 ">
          <div className="w-full  bg-white custom-shadow p-5 rounded-lg max-w-3xl">
            <form className="" onSubmit={handleSubmit}>
              <div className="mb-5">
                <label
                  htmlFor="resellPrice"
                  style={{ color: fontColor }}
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Reselling Price
                </label>
                <input
                  type="text"
                  id="resellPrice"
                  value={resellPrice}
                  onChange={(e) => setResellPrice(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Reselling Price"
                />
              </div>
              {/* <div className="mb-5">
              <label
                htmlFor="records"
                style={{ color: fontColor }}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                DNS Records/Cname Records
              </label>
              <input
                type="text"
                id="records"
                name="records"
                value={records}
                onChange={(e) => setRecords(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="DNS Records/Cname Records"
              />
            </div> */}
              <div className="mb-5">
                <label
                  htmlFor="stripeKey"
                  style={{ color: fontColor }}
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Billing Information
                </label>
                <input
                  type="text"
                  id="stripeKey"
                  name="stripeKey"
                  value={stripeKey}
                  onChange={(e) => setStripeKey(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Stripe Api Key"
                />
              </div>
              <div className="mb-5">
                <div className="w-full">
                  <label
                    htmlFor="legalName"
                    style={{ color: fontColor }}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Display Name
                  </label>
                  <input
                    type="text"
                    id="legalName"
                    value={legalName}
                    onChange={(e) => setLegalName(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Display Name"
                  />
                </div>
              </div>
              {/* <div className="mb-5">
                <div className="w-full">
                  <label
                    htmlFor="legalName"
                    style={{ color: fontColor }}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    SMS Number
                  </label>
                  <input
                    type="number"
                    id="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="SMS Number"
                  />
                </div>
              </div> */}
              <div className="mb-5 flex gap-3">
                <div className="basis-1/2">
                  <label
                    htmlFor="color1"
                    style={{ color: fontColor }}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Font Colour
                  </label>
                  <input
                    type="color"
                    id="color1"
                    value={color1}
                    onChange={(e) => setColor1(e.target.value)}
                    className="w-full h-[42px] rounded-lg border-gray-600"
                  />
                </div>
                <div className="basis-1/2">
                  <label
                    htmlFor="color2"
                    style={{ color: fontColor }}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Background Color
                  </label>
                  <input
                    type="color"
                    id="color2"
                    value={color2}
                    onChange={(e) => setColor2(e.target.value)}
                    className="w-full h-[42px] rounded-lg border-gray-600"
                  />
                </div>
              </div>
              <div className="mb-5">
                <label
                  htmlFor="logo"
                  style={{ color: fontColor }}
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Upload Logo
                </label>
                <div
                  className="w-full h-40 border border-gray-300 rounded-lg flex items-center justify-center cursor-pointer"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={() => document.getElementById("logoInput").click()}
                >
                  {logo ? (
                    <img
                      src={URL.createObjectURL(logo)}
                      alt="Logo"
                      className="max-h-full max-w-full"
                    />
                  ) : data?.logo ? (
                    <img
                      src={data.logo}
                      alt="Logo"
                      className="max-h-full max-w-full"
                    />
                  ) : (
                    <span className="text-gray-400">
                      Drag & Drop or Click to Upload
                    </span>
                  )}
                </div>
                <input
                  id="logoInput"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileInputChange}
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="logo"
                  style={{ color: fontColor }}
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Upload Fav Icon
                </label>
                <div
                  className="w-full h-40 border border-gray-300 rounded-lg flex items-center justify-center cursor-pointer"
                  onDrop={handleDropIcon}
                  onDragOver={handleDragOverIcon}
                  onClick={() => document.getElementById("iconinput").click()}
                >
                  {icon ? (
                    <img
                      src={URL.createObjectURL(icon)}
                      alt="icon"
                      className="max-h-full max-w-full"
                    />
                  ) : data?.fav_icon ? (
                    <img
                      src={data.fav_icon}
                      alt="icon"
                      className="max-h-full max-w-full"
                    />
                  ) : (
                    <span className="text-gray-400">
                      Drag & Drop or Click to Upload
                    </span>
                  )}
                </div>
                <input
                  id="iconinput"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileInputIconChange}
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="email"
                  style={{ color: fontColor }}
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Payment Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="example@gmail.com"
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="emailTemplate"
                  style={{ color: fontColor }}
                  className="block mb-2 text-sm font-medium text-black dark:text-white"
                >
                  Email Template
                </label>
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  tabIndex={1}
                  onChange={(newContent) => setContent(newContent)}
                  style={{ height: "500px" }}
                />
              </div>
              <button
                type="submit"
                className="text-white bg-gray-800 hover:bg-gray-700 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Submit
              </button>
            </form>
            <div className="mt-7 flex flex-col gap-3">
              <h2 className="text-2xl">
                <strong>Note:</strong> Add DNS records
              </h2>
              <div className="flex justify-start items-center gap-3 text-xl">
                <h2>Value:</h2>
                <h3 className="border p-1 rounded-md flex items-center gap-4">
                  54.183.118.154
                  <span>
                    <MdOutlineCopyAll
                      onClick={handleCopyUrl}
                      className="cursor-pointer"
                    />
                  </span>
                </h3>
              </div>
              <div>
                <h3>Example:</h3>
                <img src={DNSExample} alt="DNS Example" className="mt-3" />
              </div>
            </div>
            <div>
              <h2 className="mt-7 text-2xl font-semibold">Add Sender Email</h2>
              <form onSubmit={formik.handleSubmit} className="mt-3">
                <div className="mb-5">
                  <label
                    htmlFor="email"
                    style={{ color: fontColor }}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Sender Domain
                  </label>
                  <input
                    type="email"
                    id="senderEmail"
                    name="senderEmail"
                    value={domain}
                    disabled
                    className={`bg-gray-50 border ${
                      domain ? "cursor-not-allowed" : ""
                    } border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="example"
                  />
                </div>
                <div className="mb-5">
                  <label
                    htmlFor="email"
                    style={{ color: fontColor }}
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Sender Email
                  </label>
                  <div className="flex border border-gray-300">
                    <input
                      type="text"
                      id="senderEmail"
                      name="senderEmail"
                      value={`${formik.values.senderEmail}`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={data?.sendgrid_email}
                      className={`bg-gray-50 border-0 ${
                        data?.sendgrid_email ? "cursor-not-allowed" : ""
                      } ${
                        data?.domain_verified
                          ? "text-green-500"
                          : "text-red-500"
                      } border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      placeholder="example"
                    />
                    <input
                      value={`@${domain}`}
                      className={`bg-gray-50 border-0  ${
                        data?.sendgrid_email ? "cursor-not-allowed" : ""
                      } ${
                        data?.domain_verified
                          ? "text-green-500"
                          : "text-red-500"
                      } border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      disabled
                    />
                  </div>
                  {formik.errors.senderEmail && formik.touched.senderEmail ? (
                    <p className="w-full text-red-600 m-[7px] font-medium">
                      {formik.errors.senderEmail}
                    </p>
                  ) : null}
                </div>
                <button
                  type="submit"
                  disabled={data?.domain_verified}
                  className={`text-white ${
                    data?.domain_verified
                      ? "bg-gray-600 cursor-not-allowed"
                      : "bg-gray-800 hover:bg-gray-700"
                  } focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                >
                  Show Records
                </button>
              </form>
              {dnsData && (
                <div id="dnsRecord" className="mt-9" ref={dnsRecordRef}>
                  <ol className="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
                    <li className="mb-10 ms-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                        <svg
                          className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 16"
                        >
                          <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
                        </svg>
                      </span>
                      <h3 className="font-medium leading-tight">
                        Add all of these records to DNS Section.
                      </h3>

                      <div className="relative overflow-x-auto mt-3">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <thead className="text-xs w-full text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr className="w-full">
                              <th scope="col" className="px-6 py-3">
                                Type
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Name
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {dnsData &&
                              Object.keys(dnsData).map((key, index) => (
                                <React.Fragment key={index}>
                                  <tr className="bg-white w-full border-b dark:bg-gray-800 dark:border-gray-700">
                                    <td className="px-6 py-4 w-[10%]">
                                      {dnsData[key].type}
                                    </td>
                                    <td className="px-6 py-4 w-[45%]">
                                      <div className="flex justify-between items-center">
                                        <h2>{dnsData[key].host}</h2>{" "}
                                        {/* Replace with actual data from dnsData */}
                                        <div
                                          onClick={() =>
                                            handleCopyText(dnsData[key].host)
                                          }
                                          className="text-xl p-2"
                                        >
                                          <MdCopyAll />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 w-[45%]">
                                      <div className="flex justify-between items-center">
                                        <h2>{dnsData[key].data}</h2>{" "}
                                        {/* Replace with actual data from dnsData */}
                                        <div
                                          onClick={() =>
                                            handleCopyText(dnsData[key].data)
                                          }
                                          className="text-xl p-2"
                                        >
                                          <MdCopyAll />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li className="ms-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                        <IoShieldCheckmarkSharp />
                      </span>
                      <h3 className="font-medium leading-tight">
                        Confirm you've added these once confirmed
                      </h3>
                      <input
                        type="checkbox"
                        id="termsCheckbox"
                        checked={isCheckboxChecked}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label htmlFor="termsCheckbox">
                        {" "}
                        I've added these records
                      </label>
                    </li>
                  </ol>
                  <button
                    type="button"
                    onClick={handleVerify}
                    disabled={!isCheckboxChecked}
                    className={`text-white mt-4 ${
                      isCheckboxChecked ? "" : "opacity-80 cursor-not-allowed"
                    } bg-gray-800 hover:bg-gray-700 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                  >
                    Verify
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgencyCustom;
