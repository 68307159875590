import React from "react";
import { Button } from "@material-tailwind/react";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "../features/importContact/activeStep";

function ImportFormatStepOne() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const activeStep = useSelector((state) => state.activeStep.activeStep);
  const dispatch = useDispatch();
  const { fontColor } = useSelector((state) => state.theme);

  const handleNext = () => dispatch(setActiveStep(activeStep + 1));

  const handleDownload = () => {
    // Path to the file relative to the public directory
    const fileName = "example-file.xlsx";
    const fileUrl = `https://newrecon.s3.us-west-1.amazonaws.com/excel+files/example.xlsx`;

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <>
      <div className="p-4">
        <div className="flex flex-col justify-between items-start lg:flex-row gap-5 py-6 px-0">
          <div className="flex flex-col gap-2">
            <h1
              style={{ color: fontColor }}
              className="text-gray-800 text-xl font-medium"
            >
              Get ready to import your data.
            </h1>
            <p style={{ color: fontColor }} className="text-[#89898a]">
              The following columns are supported. Some may be required, the
              rest are optional.
            </p>
          </div>
          <button
            onClick={handleDownload}
            style={{ color: fontColor }}
            className="border border-gray-800 px-4 py-2 rounded-lg flex items-center gap-2"
          >
            <MdOutlineDownloadForOffline className="text-lg mb-[1px]" />
            Download Example
          </button>
        </div>
        <div className="px-2">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm border text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-6 text-[14px] py-3"
                  >
                    COLUMN NAME
                  </th>
                  <th
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-6 text-[14px] py-3"
                  >
                    FORMAT
                  </th>
                  <th
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-6 text-[14px] py-3"
                  >
                    EXAMPLE
                  </th>
                  <th
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-6 text-[14px] py-3"
                  >
                    DESCRIPTION
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{ color: fontColor }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4">Phone</td>
                  <td className="px-6 py-4">regex</td>
                  <td className="px-6 py-4">1234567890</td>
                  <td className="px-6 py-4">Lead Phone Number</td>
                </tr>
                <tr
                  style={{ color: fontColor }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4">Email</td>
                  <td className="px-6 py-4">email</td>
                  <td className="px-6 py-4">sampleCorp@sampleCorp.ai</td>
                  <td className="px-6 py-4">Lead Email</td>
                </tr>
                <tr
                  style={{ color: fontColor }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4">First Name</td>
                  <td className="px-6 py-4">string</td>
                  <td className="px-6 py-4">John</td>
                  <td className="px-6 py-4">Lead First Name</td>
                </tr>
                <tr
                  style={{ color: fontColor }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4">Last Name</td>
                  <td className="px-6 py-4">string</td>
                  <td className="px-6 py-4">Doe</td>
                  <td className="px-6 py-4">Lead Last Name</td>
                </tr>
                <tr
                  style={{ color: fontColor }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4">Company Name</td>
                  <td className="px-6 py-4">string</td>
                  <td className="px-6 py-4">SampleCorp</td>
                  <td className="px-6 py-4">Lead Company Name</td>
                </tr>
                <tr
                  style={{ color: fontColor }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4">Industry</td>
                  <td className="px-6 py-4">string</td>
                  <td className="px-6 py-4">Technology</td>
                  <td className="px-6 py-4">Lead Technology Name</td>
                </tr>
                <tr
                  style={{ color: fontColor }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4">Custom One</td>
                  <td className="px-6 py-4">string</td>
                  <td className="px-6 py-4">Position</td>
                  <td className="px-6 py-4">Lead Custom Name</td>
                </tr>
                <tr
                  style={{ color: fontColor }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4">Custom Two</td>
                  <td className="px-6 py-4">string</td>
                  <td className="px-6 py-4">Address</td>
                  <td className="px-6 py-4">Lead Custom Name</td>
                </tr>
                <tr
                  style={{ color: fontColor }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4">Custom Three</td>
                  <td className="px-6 py-4">string</td>
                  <td className="px-6 py-4">City</td>
                  <td className="px-6 py-4">Lead Custom Name</td>
                </tr>
                <tr
                  style={{ color: fontColor }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-6 py-4">Custom Four</td>
                  <td className="px-6 py-4">string</td>
                  <td className="px-6 py-4">State</td>
                  <td className="px-6 py-4">Lead Custom Name</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="px-4 flex justify-end">
        {(activeStep !== 1 || isFileUploaded) && (
          <Button onClick={handleNext}>Next</Button>
        )}
      </div>
    </>
  );
}

export default ImportFormatStepOne;
