import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setContactData } from "../features/getContact/contactData";
import toast from "react-hot-toast";


function ContactList({refresh,setRefresh}) {
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem('userData'))?.companies[0]?.company_name;
  const contactData = useSelector((state) => state.contactData.contactData);
  const { fontColor } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const [refreshData, setRefreshData] = useState(false);
  const [listData, setListData] = useState(null);

  const handleGetList = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/contact/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        setListData(responseData.data["contact_lists"]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetList();
  }, [refreshData, setRefreshData]);

  const handleGetContact = async (id) => {
    try {
      const response = await fetch(`${baseUrl}/api/contact/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        dispatch(setContactData(responseData.data.contacts));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${baseUrl}/api/contact/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        setRefreshData(!refreshData);
        setRefresh(!refresh);
        toast.success("Contact Delete Successfully")
      }
      else{
        toast.error("Something went wrong")
      }
    } catch (error) {
      console.error(error);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }

  return (
    <>
      <div className="relative overflow-x-auto mt-5 mx-5 shadow- sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th style={{ color: fontColor }} scope="col" className="px-6 py-3">
                Name
              </th>
              <th style={{ color: fontColor }} scope="col" className="px-6 py-3">
                Created Date
              </th>
              <th style={{ color: fontColor }} scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {listData &&
              listData.map((list, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <th
                      onClick={() => handleGetContact(list.id)}
                      scope="row"
                      style={{ color: fontColor }}
                      className="px-6 py-4 font-medium cursor-pointer text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {list.name}
                    </th>
                    <td style={{ color: fontColor }} className="px-6 py-4">{formatDate(list.created_at)}</td>
                    <td className="px-6 py-4">
                      <button
                        onClick={() => handleDelete(list.id)}
                        className="font-medium flex gap-2"
                      >
                        <MdDelete className="text-2xl hover:text-red-500" />
                      </button>
                    </td>
                  </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ContactList;
