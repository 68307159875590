import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { setRefresh } from "../features/Refresh/refresh";

export default function WebhookDialog({ open, setOpen }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;

  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresh.refresh);

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      webhookType: "",
      webhookUrl: "",
      bearer_token: "",
    },
    validationSchema: Yup.object({
      webhookType: Yup.string().required("Required"),
      webhookUrl:
        Yup.string()
        .required("Required"),
      bearer_token: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch(`${baseUrl}/api/agency/webhook/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            webhook_type: values.webhookType,
            url: values.webhookUrl,
            bearer_token: values.bearer_token,
          }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Webhook created successfully:", data);
        dispatch(setRefresh(!refresh));
        resetForm();

        handleClose();
      } catch (error) {
        console.error("Failed to create webhook:", error);
      }
    },
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create Webhook</DialogTitle>
      <DialogContent>
        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-1">
            <label htmlFor="webhookType">Webhook Type</label>
            <select
              name="webhookType"
              id="webhookType"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.webhookType}
              className={
                formik.touched.webhookType && formik.errors.webhookType
                  ? "border-red-500"
                  : ""
              }
            >
              <option value="" label="Select type" />
              <option value="post_call_bookings">post_call_bookings</option>
              <option value="post_call_data">post_call_data</option>
            </select>
            {formik.touched.webhookType && formik.errors.webhookType ? (
              <div className="text-red-500 text-sm">
                {formik.errors.webhookType}
              </div>
            ) : null}
          </div>
          <div>
            <label htmlFor="webhookUrl">Webhook URL</label>
            <input
              type="text"
              id="webhookUrl"
              name="webhookUrl"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.webhookUrl}
              className={`mt-2 p-1 w-full border ${
                formik.touched.webhookUrl && formik.errors.webhookUrl
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
            />
            {formik.touched.webhookUrl && formik.errors.webhookUrl ? (
              <div className="text-red-500 text-sm">
                {formik.errors.webhookUrl}
              </div>
            ) : null}
          </div>
          <div>
            <label htmlFor="bearer_token">Bearer Token</label>
            <input
              type="text"
              id="bearer_token"
              name="bearer_token"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bearer_token}
              className={`mt-2 p-1 w-full border ${
                formik.touched.bearer_token && formik.errors.bearer_token
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
            />
            {formik.touched.bearer_token && formik.errors.bearer_token ? (
              <div className="text-red-500 text-sm">
                {formik.errors.bearer_token}
              </div>
            ) : null}
          </div>
          <button
            type="submit"
            className="mt-4 bg-blue-500 text-white p-2 rounded"
          >
            Create
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
