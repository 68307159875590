import React, { useState, useEffect } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useFormik } from "formik";
import {
  createCampaignStepOne,
  createCampaignStepThree,
  createCampaignStepTwo,
  createCampaignStepTwoInbound,
  quickCampaignSchema,
} from "../schemas/FormSchema";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../features/Refresh/refresh";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";

const QuickCampaign = ({ modalOpen, setModalOpen }) => {
  const { fontColor } = useSelector((state) => state.theme);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const refresh = useSelector((state) => state.refresh.refresh);
  const dispatch = useDispatch();
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [agentName, setAgentName] = useState("");
  const [callType, setCallType] = useState("");
  const [twilio, setTwilio] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [knowledgeBase, setKnowledgeBase] = useState([]);
  const [contactName, setContactName] = useState("");
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState("");
  const [twilioList, setTwilioList] = useState(null);

  useEffect(() => {
    const fetchKnowledgeBase = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/knowledge_base/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setKnowledgeBase(responseData.data["knowledge base"]);
        }
      } catch (error) {
        console.error("Error fetching knowledge base options:", error);
      }
    };

    const fetchAgents = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/agents/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          const filteredAgents = responseData.data.agents.filter(
            (agent) => agent.direction === "Outbound"
          );
          setAgents(filteredAgents);
        }
      } catch (error) {
        console.error("Error fetching agents:", error);
      }
    };

    const fetchTwilio = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/twilio/number/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setTwilioList(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching Twillio Number:", error);
      }
    };

    fetchKnowledgeBase();
    fetchTwilio();
    fetchAgents();
  }, []);

  const formmik = useFormik({
    initialValues: {
      campaignName: "",
      agentName: "",
    },
    validationSchema: createCampaignStepOne,
    onSubmit: (values) => {
      setCampaignName(values.campaignName);
      setSelectedAgent(values.agentName);
      setCurrentStep(currentStep + 1);
    },
  });

  const formikStepTwo = useFormik({
    initialValues: {
      knowledgeBase: "",
      contactList: "",
      twilio: "",
    },
    validationSchema: quickCampaignSchema,
    onSubmit: async (values) => {
      setSelectedKnowledgeBase(values.knowledgeBase);
      setContactName(values.contactList);
      setTwilio(values.twilio);
      setLoading(true);
      const formData = {
        name: campaignName,
        agent: parseInt(selectedAgent),
        knowledge_base: parseInt(values.knowledgeBase),
        calling_number: values.twilio,
      };
      setModalOpen(false);
      setCurrentStep(1);
      try {
        const response = await fetch(`${baseUrl}/api/quick_campaign/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          formmik.values.campaignName = "";
          formmik.values.agentName = "";
          formikStepTwo.values.knowledgeBase = "";
          formikStepTwo.values.twilio = "";
          dispatch(setRefresh(!refresh));
          toast.success("Campaign created successfully!");
        } else if (response.status === 400) {
          const responseData = await response.json();
          toast.error(responseData.message);
        } else {
          toast.error("Something went wrong!");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (formmik.values.agentName) {
      const agent = agents.find(
        (agent) => agent.id == formmik.values.agentName
      );
      if (agent) {
        setAgentName(agent.name);
        setCallType(agent.direction);
      }
    }
  }, [agents, formmik.values.agentName]);

  const closeModal = () => {
    setModalOpen(false);
    setCurrentStep(1);
    formmik.values.campaignName = "";
    formmik.values.agentName = "";
    setCallType("");
    formikStepTwo.values.knowledgeBase = "";
    formikStepTwo.values.twilio = "";
    formikStepTwo.values.budget = "";
    formikStepTwo.values.contactList = "";
    // formmikStepThree.values.startDate = new Date();
    // formmikStepThree.values.endTime = "";
    // formmikStepThree.values.startTime = "";
    // formmikStepThree.values.budget = "";
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    setModalOpen(true);
  };

  const handleAgentChange = (event) => {
    setSelectedAgent(event.target.value);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <form onSubmit={formmik.handleSubmit}>
            <div className="flex flex-wrap justify-start items-center">
              <div className="flex w-full justify-between items-center">
                {currentStep > 1 && (
                  <button
                    onClick={handlePrevious}
                    type="button"
                    className="text-3xl"
                  >
                    <MdOutlineArrowBackIos />
                  </button>
                )}
              </div>
              <div className="basis-full p-4 flex justify-start items-center">
                <label
                  style={{ color: fontColor }}
                  htmlFor="campaignName"
                  className="font-medium w-28 block text-left"
                >
                  Campaign Name* :
                </label>
                <div className="flex-1">
                  <input
                    type="text"
                    id="campaignName"
                    value={formmik.values.campaignName}
                    onChange={formmik.handleChange}
                    onBlur={formmik.handleBlur}
                    name="campaignName"
                    placeholder="Campaign Name*"
                    className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formmik.errors.campaignName &&
                  formmik.touched.campaignName ? (
                    <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                      {formmik.errors.campaignName}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="basis-full p-4 flex justify-start items-center">
                <label
                  style={{ color: fontColor }}
                  htmlFor="agentName"
                  className="font-medium w-28 text-left block"
                >
                  Agent*
                </label>
                <div className="flex-1">
                  <select
                    id="agentName"
                    name="agentName"
                    value={formmik.values.agentName}
                    onChange={formmik.handleChange}
                    onBlur={formmik.handleBlur}
                    className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="" disabled>
                      Select an agent
                    </option>
                    {agents.map((agent) => (
                      <option key={agent.id} value={agent.id}>
                        {agent.name}
                      </option>
                    ))}
                  </select>
                  {formmik.errors.agentName && formmik.touched.agentName ? (
                    <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                      {formmik.errors.agentName}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="basis-full p-4 flex justify-between items-center">
                <label
                  htmlFor="callType"
                  style={{ color: fontColor }}
                  className="font-medium w-28 text-left block"
                >
                  CallType
                </label>
                <input
                  id="callType"
                  name="callType"
                  value={callType}
                  onChange={handleAgentChange}
                  disabled={true}
                  className="bg-gray-100 cursor-not-allowed flex-1 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="flex justify-end items-center w-full">
                <button
                  // onClick={handleContinue}
                  type="submit"
                  className="text-white inline-flex justify-end bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
        );
      case 2:
        return (
          <form onSubmit={formikStepTwo.handleSubmit}>
            <div className="flex flex-wrap justify-start items-center">
              <div className="flex w-full justify-between items-center"></div>
              <div className="basis-full p-4 flex justify-start items-center">
                <label
                  htmlFor="knowledgeBase"
                  style={{ color: fontColor }}
                  className="font-medium w-28 block text-left"
                >
                  Knowledge Base
                </label>
                <div className="flex-1">
                  <select
                    id="knowledgeBase"
                    name="knowledgeBase"
                    value={formikStepTwo.values.knowledgeBase}
                    onChange={formikStepTwo.handleChange}
                    onBlur={formikStepTwo.handleBlur}
                    // onChange={(e) => setSelectedKnowledgeBase(e.target.value)}
                    className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Select a knowledge base</option>
                    {knowledgeBase &&
                      knowledgeBase.length > 0 &&
                      knowledgeBase.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </select>
                  {formikStepTwo.errors.knowledgeBase &&
                  formikStepTwo.touched.knowledgeBase ? (
                    <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                      {formikStepTwo.errors.knowledgeBase}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="basis-full p-4 flex justify-between items-center">
                <label
                  htmlFor="twilio"
                  style={{ color: fontColor }}
                  className="font-medium w-28 text-left block"
                >
                  Twilio Number
                </label>
                <div className="flex-1">
                  {twilioList && twilioList.length > 0 && (
                    <select
                      id="twilio"
                      name="twilio"
                      value={formikStepTwo.values.twilio}
                      onChange={formikStepTwo.handleChange}
                      onBlur={formikStepTwo.handleBlur}
                      className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="">Select a Contact Number</option>
                      {twilioList.map((option) => (
                        <option key={option.id} value={option.contact_number}>
                          {option.contact_number}
                        </option>
                      ))}
                    </select>
                  )}
                  {formikStepTwo.errors.twilio &&
                  formikStepTwo.touched.twilio ? (
                    <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                      {formikStepTwo.errors.twilio}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="flex justify-end items-center w-full">
                <button
                  // onClick={handleContinue}
                  type="submit"
                  className="text-white inline-flex justify-end bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    formmik.setValues({
      campaignName: "",
      agentName: "",
    });

    formikStepTwo.setValues({
      knowledgeBase: "",
      twilio: "",
      budget: "",
      contactList: "",
    });

    setCallType("");
  }, [modalOpen]);

  return (
    <>
      {modalOpen && (
        <div className="fixed inset-0 z-20 overflow-auto bg-black bg-opacity-40 flex justify-center items-center p-8">
          <div className="xl:w-[50%] w-full 2xl:max-h-[85%] bg-white custom-shadow flex flex-col 2xl:flex-row  p-1 rounded-xl relative">
            <div className="flex p-1 md:p-3">
              <div className="flex flex-wrap justify-start items-center">
                <div className="flex w-full justify-between items-center">
                  {currentStep > 1 && (
                    <button
                      onClick={handlePrevious}
                      type="button"
                      className="text-2xl mr-auto"
                    >
                      <MdOutlineArrowBackIos />
                    </button>
                  )}
                  <button
                    type="button"
                    className="text-3xl ml-auto"
                    onClick={closeModal}
                  >
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                  </button>
                </div>
                {renderStepContent()}
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="w-full z-[999] h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
};

export default QuickCampaign;
