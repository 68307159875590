import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ManageSubsciption({ data }) {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);  

  const handleSubscription = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/customer_portal/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          subscription_id: data,
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        // window.location.href = responseData.data;
        window.open(responseData.data,"_blank");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button
        style={{ color: fontColor }}
        onClick={handleSubscription}
        className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
        type="button"
      >
        <span
          style={{ backgroundColor: backgroundColor }}
          className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
        >
          Manage Subscription
        </span>
      </button>
    </>
  );
}

export default ManageSubsciption;
