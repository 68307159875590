import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { endCallSchema } from "../schemas/FormSchema";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { setRefresh } from "../features/Refresh/refresh";
import { setSaveScript } from "../features/Agents/saveScript";

function EndCallPopup({ data }) {
  const refresh = useSelector((state) => state.refresh.refresh);
  const { fontColor } = useSelector((state) => state.theme);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [showPopUp, setShowPopUp] = useState(false);
  const [step, setStep] = useState(2);
  const [goals, setGoals] = useState([]);
  const [outcome, setOutcome] = useState([]);
  const [editIndex, setEditIndex] = useState(null); // Track the index of the outcome being edited
  const agentID = localStorage.getItem("agentID");
  const retrievedString = localStorage.getItem("scriptData");
  const retrievedArrayOfScript = JSON.parse(retrievedString);
  const dispatch = useDispatch();

  const states =
    retrievedArrayOfScript && retrievedArrayOfScript?.map((item) => item.State);
  const statesOption = states && states.slice(1);

  useEffect(() => {
    if (data && data.end_call) {
      try {
        const parsedData = JSON.parse(data.end_call);
        setOutcome(parsedData);
      } catch (error) {
        console.error("Error parsing end_call data:", error);
      }
    }
  }, [data]);

  const handleToggle = () => {
    setShowPopUp(!showPopUp);
  };

  const formik = useFormik({
    initialValues: {
      stateName: "",
      description: "",
    },
    validationSchema: endCallSchema,
    onSubmit: async (values, { resetForm }) => {
      const newGoal = {
        stateName: values.stateName,
        description: values.description,
      };
      const updatedGoals = [...outcome];
      if (editIndex !== null) {
        updatedGoals[editIndex] = newGoal; // Update the outcome if editing
        setEditIndex(null); // Reset edit index after editing
      } else {
        updatedGoals.push(newGoal); // Add a new outcome if not editing
      }

      try {
        const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            end_call: JSON.stringify(updatedGoals),
          }),
        });

        if (response.ok) {
          const script = JSON.parse(localStorage.getItem("scriptData"));
          const stateToUpdate = script.find(
            (state) => state.State === values.stateName
          );

          const value = `If user response is "${values.description}", trigger the following function  
<function_calls> 
<invoke> 
<function_name>end_call</function_name> 
<parameters> 
<original_message>User's original message goes here</original_message> 
</parameters> 
</invoke> 
</function_calls>`;

          if (stateToUpdate) {
            stateToUpdate.important = value;
          }

          // Save the updated script back to localStorage
          localStorage.setItem("scriptData", JSON.stringify(script));

          handleSaveScript();
          handleToggle();
          setStep(2);
          resetForm();

          toast.success("End call updated successfully");
        } else {
          const responseData = await response.json();
          toast.error(responseData.message || "Something went wrong");
        }
      } catch (error) {
        console.error("Error updating end call:", error);
        toast.error("Something went wrong");
      }
    },
  });

  const handleSaveScript = async () => {
    try {
      const script = localStorage.getItem("scriptData");
      const scriptData = JSON.parse(script);
      const finalScript = scriptData.slice(1);

      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          json_script: finalScript,
        }),
      });

      if (response.ok) {
        dispatch(setSaveScript(false));
        dispatch(setRefresh(!refresh));
        // toast.success("Script saved successfully");
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditOutcome = (index) => {
    setEditIndex(index);
    setStep(1); // Move to the form step for editing
    formik.setValues({
      // Set form values to the values of the outcome being edited
      stateName: outcome[index].stateName,
      description: outcome[index].description,
    });
  };

  const handleDeleteOutcome = async (index, state) => {
    const updatedOutcome = [...outcome];
    updatedOutcome.splice(index, 1);

    const script = localStorage.getItem("scriptData");
    const scriptData = JSON.parse(script);

    scriptData.forEach((item) => {
      if (item.State === state) {
        delete item.important;
      }
    });

    localStorage.setItem("scriptData", JSON.stringify(scriptData));

    try {
      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          end_call: JSON.stringify(updatedOutcome),
        }),
      });

      if (response.ok) {
        setOutcome(updatedOutcome);
        handleToggle();
        handleSaveScript();
        toast.success("End call updated successfully");
      } else {
        const responseData = await response.json();
        toast.error(responseData.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error updating end call:", error);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <button
        style={{ color: fontColor }}
        className="select-none rounded-lg  2xl:text-lg text-center align-middle text-xs font-semibold"
        type="button"
        onClick={handleToggle}
      >
        End Call
      </button>
      <div
        className={`${
          showPopUp ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          showPopUp ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div
          data-dialog="sign-in-dialog"
          className="relative mx-4 flex w-full max-w-xl flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
        >
          {step === 1 && (
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col gap-4 p-6">
                <div className="flex justify-between items-center">
                  <h4
                    style={{ color: fontColor }}
                    className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
                  >
                    End Function States
                  </h4>
                  <div onClick={handleToggle}>
                    <MdOutlineClose className="text-2xl cursor-pointer" />
                  </div>
                </div>
                <h6
                  style={{ color: fontColor }}
                  className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                >
                  Trigger State
                </h6>
                <div className="relative h-11 w-full min-w-[200px]">
                  <select
                    id="callType"
                    name="stateName"
                    value={formik.values.stateName}
                    onChange={formik.handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option defaultValue>Select Trigger State</option>
                    {statesOption &&
                      statesOption.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                  </select>
                  {formik.errors.stateName && formik.touched.stateName ? (
                    <p className="w-full text-red-600 m-[7px] font-medium">
                      {formik.errors.stateName}
                    </p>
                  ) : null}
                </div>
                <h6
                  style={{ color: fontColor }}
                  className="block -mb-2 font-sans mt-3 text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                >
                  User Response
                </h6>
                <div
                  style={{ color: fontColor }}
                  className="relative w-full min-w-[200px]"
                >
                  <textarea
                    className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200 border-t-transparent text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder="Description "
                    id="description"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.description && formik.touched.description ? (
                    <p className="w-full text-red-600 my-[-7px] mx-[7px] font-medium">
                      {formik.errors.description}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="p-6 pt-0">
                <button
                  className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          )}
          {step === 2 && (
            <div className="flex flex-col gap-4 p-6">
              <div className="flex justify-between items-center">
                <h4
                  style={{ color: fontColor }}
                  className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
                >
                  End Call States
                </h4>
                <div onClick={handleToggle}>
                  <MdOutlineClose className="text-2xl cursor-pointer" />
                </div>
              </div>
              <div
                onClick={() => setStep(1)}
                className="w-full flex justify-center cursor-pointer border border-dashed p-4"
              >
                + setup another End State
              </div>
              <div className="max-h-96 overflow-auto">
                {outcome &&
                  outcome.map((outcome, index) => (
                    <div
                      key={index}
                      className="w-full flex mb-2 justify-between border p-4"
                    >
                      <h3>{outcome.stateName}</h3>
                      <div className="flex justify-center gap-3 text-2xl">
                        <CiEdit
                          onClick={() => handleEditOutcome(index)}
                          className="hover:text-light-blue-800 cursor-pointer"
                        />
                        <MdDelete
                          onClick={() =>
                            handleDeleteOutcome(index, outcome.stateName)
                          }
                          className="hover:text-red-600 cursor-pointer"
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EndCallPopup;
