import React, { useEffect } from "react";
import { FiPlus } from "react-icons/fi";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
  Input,
  Textarea,
} from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { IoCallOutline } from "react-icons/io5";
import { useFormik } from "formik";
import { advancedSettingSchema } from "../schemas/FormSchema";
import toast from "react-hot-toast";

let substringToReplace = `<sample_responses>
User: Where are you calling from?
<response>Oh, I'm actually calling about a property matter here in the US. Are you the sole owner of the property we're discussing?</response>
<next_state>Qualifying Question 1 State</next_state>
<wait_time>10</wait_time>
<function_calls></function_calls>
User: Are you based out of Dubai?
<response>Hmm, I'm not in Dubai - I'm calling about some local US property business. Speaking of which, are you the sole owner of the property?</response>
<next_state>Qualifying Question 1 State</next_state>
<wait_time>10</wait_time>
<function_calls></function_calls>
User: What's your favorite color?
<response>Blue's pretty nice, but let's talk about your property. Are you the sole owner?</response>
<next_state>Qualifying Question 1 State</next_state>
<wait_time>10</wait_time>
<function_calls></function_calls>
User: Can you explain quantum physics?
<response>That's a complex topic! Quantum mechanics is a fundamental theory that describes the behavior of nature at...Are you the sole owner of the said property?</response>
<next_state>Qualifying Question 1 State</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
User: What's the weather like there?
<response>You know, I haven't even looked outside today. Been busy with property stuff. Speaking of which, are you the sole owner of the property we're discussing?</response>
<next_state>Qualifying Question 1 State</next_state>
<wait_time>10</wait_time>
<function_calls></function_calls>
</sample_responses>
<condition_sample_responses>
<sample_script1>
[[Property Inquiry]]
Previous State: Company Information
Current State: Property Inquiry
Next State: Property Size Inquiry
Wait Time: 10
[Prompt]: "So are you the only owner of the property?"
[Condition]: IF user response is “sole owner”, MOVE TO “Property Size Inquiry” State; ELSE MOVE TO “Co-owner Inquiry” State
</sample_script1>
Assistant: So are you the only owner of the property?
if user_response is: Yes, I'm the only owner.
Then, assistant_response: <response>I see, thanks for letting me know. Since you're the sole owner, could you tell me the size of your property?</response>
<next_state>Water Access Inquiry</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
If user_response is: No, there are other owners too.
Then, assistant_response: <response>I appreciate you sharing that information. Since there are co-owners involved, I'd like to know a bit more about that. How many co-owners are there, and what's your relationship with them?</response>
<next_state>Primary Owner Inquiry</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
<sample_script2>
[[Property Size Inquiry]]
Previous State: Property Inquiry
Current State: Property Size Inquiry
Next State: HOA Inquiry
Wait Time: 10
[Prompt]: "And could you tell me the size of your property?"
[Condition]: If user response is “less than 10 acres”, MOVE TO “End Call” State; ELSE MOVE TO “HOA Inquiry” State
</sample_script2>
Assistant: And could you tell me the size of your property?
If user_response is: It's about 5 acres.
Then, assistant_response: <response>Thanks for providing the information! We’re currently focusing on properties that are more than 10 acres. If there's anything else you'd like to explore with us?</response>
<next_state>Conclusion State</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
If user_response is: 25 acres
Then, assistant_response: <response>Got it, 25 acres is a good size. Is your property associated with a Homeowners Association?</response>
<next_state>Water Access State</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
<sample_script3>
[[Co-owner Inquiry]]
Previous State: Size Inquiry
Current State: Water Access Inquiry
Next State: Improvements State
Wait Time: 10
[Prompt]: "I see. Does the property have direct water access?"
[Condition]: If user response is “Yes”, MOVE TO “Improvements” State; ELSE MOVE TO “Property Age” State
</sample_script3>
Assistant: I see. Does the property have direct water access?
If user_response is: Yes, property has direct water access.
Then, assistant_response: <response>Okay, noted. Now, have you made any improvements to the property, like fence?</response>
<next_state>Interested to Sell State</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
If user_response is: No, there is no direct water access.
Then, assistant_response: <response>Got it. How old is the property in question?</response>
<next_state>Property Condition State</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
</condition_sample_responses>
`;

function AdvancedSettings({ data }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const agentID = localStorage.getItem("agentID");
  const company_name = JSON.parse(localStorage.getItem("userData"))
    ?.companies[0]?.company_name;
  const [openLeft, setOpenLeft] = React.useState(false);
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );

  const openDrawerLeft = () => setOpenLeft(true);
  const closeDrawerLeft = () => setOpenLeft(false);

  const formik = useFormik({
    initialValues: {
      basicPrompt: "",
      temperature: 0.5,
      automaticDisconnect: "",
    },
    validationSchema: advancedSettingSchema,
    onSubmit: async (values) => {
      const asteriskIndex = values.basicPrompt.indexOf("*");
      const removedAsterisksText = values.basicPrompt.replace(/\*/g, "");

      // Insert text at the position of the first asterisk
      // const additionalText = "INSERTED_TEXT";
      const resultText =
        removedAsterisksText.slice(0, asteriskIndex) +
        substringToReplace +
        removedAsterisksText.slice(asteriskIndex);
      try {
        const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            automatic_call_time_end: values.automaticDisconnect,
            temperature: values.temperature,
            basic_prompt: resultText,
          }),
        });
        if (response.ok) {
          closeDrawerLeft();
          toast.success("Advanced setting updated successfully");
          // const script = JSON.parse(localStorage.getItem("scriptData"));
          // const stateToUpdate = script.find(
          //   (state) => state.State === values.stateName
          // );

          // if (stateToUpdate) {
          //   stateToUpdate.important = value;
          // }

          // // Save the updated script back to localStorage
          // console.log(JSON.stringify(script));
          // localStorage.setItem("scriptData", JSON.stringify(script));
        } else if (response.status === 400) {
          const responseData = await response.json();
          toast.error(responseData.message);
        } else {
          toast.error("Something went wrong!");
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  function replaceEachCharacter(text, substring, replacement) {
    let startIndex = text?.indexOf(substring);

    if (startIndex === -1) return text; // Return original text if substring is not found

    // Create a new string where every character of the substring is replaced
    let beforeText = text?.substring(0, startIndex);
    let afterText = text?.substring(startIndex + substring.length);

    // Generate the replacement string by repeating the replacement text
    let replacedSubstring = replacement.repeat(substring.length);

    return beforeText + replacedSubstring + afterText;
  }

  useEffect(() => {
    let replacementText = "*";

    if (data) {
      formik.setValues({
        basicPrompt: data.basic_prompt
          ? replaceEachCharacter(
              data?.basic_prompt,
              substringToReplace,
              replacementText
            )
          : "",
        temperature: data.temperature || 0,
        automaticDisconnect: data.automatic_call_time_end || "",
      });
    }
  }, [data]);

  const handleReset = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/agent/reset_prompt/`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ agent_id: data?.id }),
      });
      if (response.ok) {
        // closeDrawerLeft();
        let replacementText = "*";
        const responseData = await response.json()

        formik.setValues({
          basicPrompt: replaceEachCharacter(
            responseData.data.basic_prompt,
            substringToReplace,
            replacementText
          ),
        });
        toast.success("Advanced setting Reset successfully");
        // const script = JSON.parse(localStorage.getItem("scriptData"));
        // const stateToUpdate = script.find(
        //   (state) => state.State === values.stateName
        // );

        // if (stateToUpdate) {
        //   stateToUpdate.important = value;
        // }

        // // Save the updated script back to localStorage
        // console.log(JSON.stringify(script));
        // localStorage.setItem("scriptData", JSON.stringify(script));
      } else if (response.status === 400) {
        const responseData = await response.json();
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="absolute left-4 top-5">
        <button
          onClick={openDrawerLeft}
          className="text-white flex gap-3 hover:text-gray-800 bg-gray-900 border border-gray-800 hover:bg-white focus:ring-2 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
        >
          <FiPlus className="text-xl" /> Advanced Settings
        </button>
      </div>
      <div
        className={`${
          openLeft ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          openLeft ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div className="relative w-full md:w-3/4 lg:w-1/2 h-full bg-white  bg-clip-border text-gray-700 shadow-md">
          <form
            onSubmit={formik.handleSubmit}
            className="relative w-full h-full bg-white  bg-clip-border text-gray-700 shadow-md"
          >
            <div className="p-6 flex items-center justify-between">
              <Typography variant="h5" color="blue-gray">
                {legalName}
              </Typography>
              <IconButton
                variant="text"
                color="blue-gray"
                onClick={closeDrawerLeft}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-7 w-7"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </div>
            <div className="flex h-full flex-col gap-4 p-4">
              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Basic Prompt
              </Typography>
              <textarea
                color="gray"
                label="Basic Prompt"
                className="w-full h-1/2 resize-none rounded-xl"
                name="basicPrompt"
                value={formik.values.basicPrompt}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.basicPrompt && formik.touched.basicPrompt ? (
                <p className="w-full text-red-600 mx-[7px]  font-medium">
                  {formik.errors.basicPrompt}
                </p>
              ) : null}
              <div className="flex  w-full flex-col gap-4">
                <div className="flex justify-between items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="basis-1/2"
                  >
                    Temperature:
                  </Typography>
                  <Input
                    size="lg"
                    type="number"
                    placeholder=""
                    name="temperature"
                    min={0.0}
                    step={0.1}
                    max={1}
                    value={formik.values.temperature}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className=" !border-t-blue-gray-200 basis-1/2 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  {formik.errors.temperature && formik.touched.temperature ? (
                    <p className="w-full text-red-600 mx-[7px]  font-medium">
                      {formik.errors.temperature}
                    </p>
                  ) : null}
                </div>
                <div className="basis-full flex justify-between items-center">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="basis-1/2"
                  >
                    Automatic Disconnect (in minutes):
                  </Typography>
                  <Input
                    size="lg"
                    type="number"
                    placeholder=""
                    min="0"
                    name="automaticDisconnect"
                    value={formik.values.automaticDisconnect}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className=" !border-t-blue-gray-200 basis-1/2 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  {formik.errors.automaticDisconnect &&
                  formik.touched.automaticDisconnect ? (
                    <p className="w-full text-red-600 mx-[7px]  font-medium">
                      {formik.errors.automaticDisconnect}
                    </p>
                  ) : null}
                </div>
              </div>
              <button
                style={{ color: fontColor }}
                type="submit"
                className="text-gray-900 w-full justify-center flex gap-3 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
              >
                Save
              </button>
              <button
                style={{ color: fontColor }}
                type="button"
                onClick={() => handleReset()}
                className="text-gray-900 w-full justify-center flex gap-3 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* <Drawer
        placement="left"
        open={openLeft}
        onClose={closeDrawerLeft}
        className="p-4"
        size= "00"
      >
        <div className="mb-6 flex items-center justify-between">
          <Typography variant="h5" color="blue-gray">
            Material Tailwind
          </Typography>
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={closeDrawerLeft}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>
        <Typography color="gray" className="mb-8 pr-4 font-normal">
          Material Tailwind features multiple React and HTML components, all
          written with Tailwind CSS classes and Material Design guidelines.
        </Typography>
        <div className="flex gap-2">
          <Button size="sm" variant="outlined">
            Documentation
          </Button>
          <Button size="sm">Get Started</Button>
        </div>
      </Drawer> */}
    </>
  );
}

export default AdvancedSettings;
