import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./app/store";
import * as Sentry from "@sentry/react";
import { NotificationsProvider } from "./utils/NotificationsContext.jsx";


if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: "https://420b5f67dd4265c832f51e30c0125fb2@o4507447177707520.ingest.us.sentry.io/4507447190159360",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}



ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
   <NotificationsProvider>
    <App />
    </NotificationsProvider>
  </Provider>
);
