// ghlHelper.js
import CryptoJS from "crypto-js";
export class GHL {
  constructor() {}

  async getUserData() {

    const key = await new Promise((resolve) => {
      window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*");
      window.addEventListener("message", ({ data }) => {
        if (data.message === "REQUEST_USER_DATA_RESPONSE") {
          resolve(data.payload);
        }
      });
    });

    //   const res = await fetch("/decrypt-sso", {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ key }),
    //   });
    //   const data = await res.json();
    const secretKey = import.meta.env.VITE_GHL_SSO_KEY;

    const data = CryptoJS.AES.decrypt(
      key,
      secretKey
    ).toString(CryptoJS.enc.Utf8);

    
    return data;
  }
}
