import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  Controls,
} from "reactflow";
import { IoGitBranchOutline } from "react-icons/io5";
import CustomNode from "./CustomNode";
import "reactflow/dist/style.css";
import CustomNodeChild from "./CustomNodeChild";
import Navbar from "./Navbar";
import { MdOutlineRecordVoiceOver } from "react-icons/md";
import { RiEditBoxLine, RiVoiceprintLine } from "react-icons/ri";
import { SlCalender, SlGraph } from "react-icons/sl";
import CalenderPopUp from "./CalenderPopUp";
import { TbTransfer } from "react-icons/tb";
import LiveTransfer from "./LiveTransfer";
import NotesPopUp from "./NotesPopUp";
import GoalPopUp from "./GoalPopUp";
import { IoCallOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import VoiceMailPopUp from "./VoiceMailPopUp";
import AdvancedPopUp from "./AdvancedPopUp";
import { setSaveScript } from "../features/Agents/saveScript";
import ChatAgent from "./ChatAgent";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import AdvancedSettings from "./AdvancedSettings";
import toast, { Toaster } from "react-hot-toast";
import EndCallPopup from "./EndCallPopup";
import { MdOutlineVoicemail } from "react-icons/md";
import { LuPhoneOff } from "react-icons/lu";
import TestAgent from "./TestAgent";
import { IoPlayCircleOutline } from "react-icons/io5";
import { IoStopCircleOutline } from "react-icons/io5";
import { setRefresh } from "../features/Refresh/refresh";
import { setIsEditing } from "../features/NodeIsEditing/isEditing";
import { setDeleteNode } from "../features/deleteNode/deleteNode";
import IfCondition from "./IfCondition";

const nodeTypes = {
  customNode: CustomNode,
  customNodeChild: CustomNodeChild,
  customNodeChildSibling: CustomNodeChild,
};

const BasicFlow = () => {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const refresh = useSelector((state) => state.refresh.refresh);
  const saveScript = useSelector((state) => state.saveScript.saveScript);
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const [loading, setLoading] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();

  const [voiceDropDown, setVoiceDropDown] = useState(false);
  const [agentID, setAgentID] = useState(localStorage.getItem("agentID"));
  const [script, setScript] = useState([]);
  const company_name = JSON.parse(localStorage.getItem("userData"))
    ?.companies[0]?.company_name;
  const [voices, setVoices] = useState(null);
  const [CloneVoices, setCloneVoices] = useState(null);
  const [response, setResponse] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playingVoiceId, setPlayingVoiceId] = useState(null);
  const dispatch = useDispatch();
  const scrollableRef = useRef(null);
  const deleteNode = useSelector((state) => state.deleteNode.deleteNode);

  useEffect(() => {
    const handleGetVoices = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/get/voice_list/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setVoices(responseData);
        }
      } catch (error) {
        console.error(error);
      }
    };
    handleGetVoices();

    const handleGetCloneVoices = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/get/clone/voice/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setCloneVoices(responseData.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleGetCloneVoices();
  }, []);

  useEffect(() => {
    if (deleteNode) {
      handleSaveScript();
    }
  }, [deleteNode]);

  const handleGetScript = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem(
          "StartCallPrompt",
          responseData.data.question.start_call
        );
        setResponse(responseData.data.question);
        setScript(responseData.data.question.json_script);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetScript();
  }, [refresh]);

  useEffect(() => {
    if (script) {
      try {
        if (script.length != 0) {
          const data = script;
          // console.log(JSON.stringify(data));
          const transformedData =
            data &&
            data.map((item, index) => ({
              State: item.State,
              Prompt: item.Prompt,
              position: item.position,
              important: item.important,
              instructions: item.instructions,
              "Wait Time": item["Wait Time"],
              "Next State": item["Next State"],
              condition: item.condition,
              "Previous State": item["Previous State"], // Previous state or empty string for the first item
            }));

          transformedData &&
            transformedData.unshift({
              State: "opening",
              Prompt: `${
                response?.start_call && response?.start_call
                  ? response?.start_call
                  : "Hey, prospectName! How are you doing"
              }`,
              "Next State": "",
              "Previous State": "",
            });

          const jsonString = JSON.stringify(transformedData);

          localStorage.setItem("scriptData", jsonString);

          const retrievedString = localStorage.getItem("scriptData");
          const retrievedArrayOfScript = JSON.parse(retrievedString);

          const initialNodes = getTreeLayoutNodes(retrievedArrayOfScript);

          const initialEdges = retrievedArrayOfScript
            .map((state, index) => {
              const sourceIndex = retrievedArrayOfScript.findIndex(
                (item) => item.State === state["Previous State"]
              );
              const targetIndex = retrievedArrayOfScript.findIndex(
                (item) => item.State === state.State
              );

              if (sourceIndex !== -1 && targetIndex !== -1) {
                return {
                  id: `e${sourceIndex}-${targetIndex}`,
                  source: `${sourceIndex}`,
                  target: `${targetIndex}`,
                };
              }
              return null;
            })
            .filter((edge) => edge !== null);

          setNodes(initialNodes);
          setEdges(initialEdges);
        } else {
          script.unshift({
            State: "opening",
            Prompt: `${
              response?.start_call && response?.start_call
                ? response?.start_call
                : "Hey, prospectName! How are you doing"
            }`,
            "Next State": "",
            "Previous State": "",
          });

          const jsonString = JSON.stringify(script);
          localStorage.setItem("scriptData", jsonString);
          const retrievedString = localStorage.getItem("scriptData");
          const retrievedArrayOfScript = JSON.parse(retrievedString);

          const screenWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

          const xPosition = screenWidth / 2 - 200;

          const initialNodes = retrievedArrayOfScript.map((state, index) => ({
            id: `${index}`,
            type: "customNode",
            position: { x: xPosition, y: 50 + index * 300 },
            data: {
              heading: `${index + 1}`,
              line: "Opening",
              para: state.Prompt || state.Prompts,
            },
          }));

          const initialEdges = initialNodes.slice(1).map((node, index) => ({
            id: `e${index + 1}-${index + 2}`,
            source: `${index}`,
            target: `${index + 1}`,
          }));

          setNodes(initialNodes);
          setEdges(initialEdges);
        }
      } catch (error) {
        console.error("Failed to parse script:", error);
      }
    }

    return () => {
      // localStorage.removeItem('scriptData');
      // localStorage.removeItem('agentID');
    };
  }, [script, refresh]);

  const getTreeLayoutNodes = (scriptData) => {
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const xPosition = screenWidth / 2 - 200;

    return (
      scriptData &&
      scriptData.map((item, index) => ({
        id: `${index}`,
        type: index === 0 ? "customNode" : "customNodeChild",
        position: {
          x: item.position ? item.position.x : xPosition,
          y: item.position ? item.position.y : 50 + index * 400,
        },
        dragHandle: ".custom-drag-handle",
        data: {
          heading: `${index + 1}`,
          line: item.State,
          para: item.Prompt,
        },
      }))
    );
  };

  const onConnect = useCallback(
    (params) => {
      setEdges((els) => {
        const newEdges = addEdge(params, els);

        const retrievedString = localStorage.getItem("scriptData");
        const retrievedArrayOfScript = JSON.parse(retrievedString);

        const sourceIndex = parseInt(params.source);
        const targetIndex = parseInt(params.target);

        dispatch(setSaveScript(true));

        // console.log(targetIndex);
        // console.log(sourceIndex);

        // Update the previous and next states
        retrievedArrayOfScript[targetIndex]["Previous State"] =
          retrievedArrayOfScript[sourceIndex].State;

        retrievedArrayOfScript[sourceIndex]["Next State"] =
          retrievedArrayOfScript[targetIndex].State;

        // Find the index of the previous state of the target node

        // console.log("script fuck", retrievedArrayOfScript);

        const previousState =
          retrievedArrayOfScript[targetIndex]["Previous State"];

        // console.log(previousState);

        const previousStateIndex = retrievedArrayOfScript.findIndex(
          (node) => node.State === previousState
        );

        // Log the previous state index
        // console.log("Previous State Index:", previousStateIndex);

        // Remove the target node from its current position
        const [targetNode] = retrievedArrayOfScript.splice(targetIndex, 1);

        // Determine the new index for the target node (after the source node)
        const newTargetIndex = previousStateIndex + 1;

        // Insert the target node immediately after the source node
        retrievedArrayOfScript.splice(newTargetIndex, 0, targetNode);

        // Save the updated script data back to localStorage
        localStorage.setItem(
          "scriptData",
          JSON.stringify(retrievedArrayOfScript)
        );

        handleSaveScript();

        return newEdges;
      });
    },
    [setEdges, dispatch]
  );

  const addNode = () => {
    const newNodeIndex = nodes.length;

    const lastNode = nodes[nodes.length - 1];
    const newPosition = lastNode
      ? { x: lastNode.position.x, y: lastNode.position.y + 300 }
      : { x: 400, y: 400 };
    const newNode = {
      id: `${newNodeIndex}`,
      type: "customNodeChild",
      position: newPosition,
      data: {
        heading: `${newNodeIndex + 1}`,
        line: `New State ${newNodeIndex}`,
        para: "New Paragraph",
      },
    };

    setNodes((nds) => {
      const newNodes = [...nds, newNode];
      localStorage.setItem("nodesData", JSON.stringify(newNodes));
      return newNodes;
    });

    const retrievedString = localStorage.getItem("scriptData");
    const retrievedArrayOfScript = JSON.parse(retrievedString);

    retrievedArrayOfScript.unshift({
      State: `New State ${newNodeIndex}`,
      Prompt: "New Prompt",
      "Previous State": "",
      "Next State": "",
      "Wait Time": 10,
      position: { x: 40, y: 80 },
    });

    localStorage.setItem("scriptData", JSON.stringify(retrievedArrayOfScript));

    handleSaveScript();

    toast.success("New Node added successfully at the top");
    // dispatch()
  };

  const handleVoiceToggle = () => {
    setVoiceDropDown(!voiceDropDown);
  };

  const handleSaveScript = async (edgeDelete) => {
    try {
      const script = localStorage.getItem("scriptData");
      const scriptData = JSON.parse(script);

      const finalScript = scriptData.map((item) => {
        if (item["wait time"]) {
          // Attempt to convert 'wait time' to a numeric value
          const numericWaitTime = parseFloat(item["wait time"]);
          if (!isNaN(numericWaitTime)) {
            item["wait time"] = numericWaitTime;
          } else {
            console.error(`Invalid 'wait time' value: ${item["wait time"]}`);
          }
        }
        return item;
      });

      const filteredNodes = finalScript.filter(
        (node) => node.State !== "opening"
      );

      const orderedStates = reorderScript(filteredNodes, edgeDelete);

      console.log(orderedStates);

      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          json_script: orderedStates,
        }),
      });

      if (response.ok) {
        dispatch(setSaveScript(false));
        dispatch(setDeleteNode(false));
        handleGetScript();

        dispatch(setRefresh(!refresh));

        // localStorage.setItem("scriptData", JSON.stringify(script));
        toast.success("Script saved successfully");
        dispatch(setIsEditing(false));
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetVoice = async (id) => {
    try {
      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          voice: id,
        }),
      });

      if (response.ok) {
        setVoiceDropDown(false);
        dispatch(setRefresh(!refresh));
        toast.success("Voice selected successfully");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  function reorderScript(states, edgeDelete) {
    if (edgeDelete) {
      return states;
    }
  
    // Create a map to easily find states by their names
    const stateMap = new Map(states.map((state) => [state.State, state]));
  
    // Initialize arrays to track ordered states, visited states, and states that should keep their position
    const orderedStates = [];
    const visited = new Set();
    const preservePosition = [];
  
    // Separate out states that need to preserve their position
    states.forEach((state, index) => {
      if (state["Previous State"] === "") {
        preservePosition.push({ state, index });
        visited.add(state.State);
      }
    });
  
    // Function to traverse and order states from a given state
    function traverse(state) {
      if (!state || visited.has(state.State)) return;
      visited.add(state.State);
      orderedStates.push(state);
      if (state["Next State"]) {
        traverse(stateMap.get(state["Next State"]));
      }
    }
  
    // Start with states that have no Previous State (excluding those with empty Previous State)
    states.forEach((state) => {
      if (!state["Previous State"] && state["Previous State"] !== "" && !visited.has(state.State)) {
        traverse(state);
      }
    });
  
    // In case there are any unvisited states (disconnected chains), traverse them as well
    states.forEach((state) => {
      if (!visited.has(state.State)) {
        traverse(state);
      }
    });
  
    // Reinsert the preserved position states back into their original indices
    preservePosition.forEach(({ state, index }) => {
      orderedStates.splice(index, 0, state);
    });
  
    return orderedStates;
  }  

  const handleNodeDragStop = (node) => {
    // Update node positions in state or perform any necessary operations
    const retrievedString = localStorage.getItem("scriptData");
    const retrievedArrayOfScript = JSON.parse(retrievedString);

    const updatedScriptData = retrievedArrayOfScript.map((item) => {
      if (item.State === node.data.line) {
        // Assuming 'line' is the unique identifier for the node
        return {
          ...item,
          position: node.position, // Update the position with the new position from ReactFlow
        };
      }
      return item;
    });

    localStorage.setItem("scriptData", JSON.stringify(updatedScriptData));

    dispatch(setSaveScript(true));

    // You can access the updated node positions from 'nodes' state
    // or use 'node' parameter directly if it contains the updated positions
  };

  const handlePlayClick = (voice, event) => {
    event.stopPropagation(); // Prevent triggering the parent click handler

    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
    }

    if (playingVoiceId === voice.id) {
      return;
    }

    const newAudio = new Audio(voice.sample);
    newAudio.play();
    setCurrentAudio(newAudio);
    setPlayingVoiceId(voice.id);

    newAudio.onended = () => {
      setPlayingVoiceId(null);
    };
  };

  useEffect(() => {
    return () => {
      if (currentAudio) {
        currentAudio.pause();
      }
    };
  }, [currentAudio]);

  const handleEdgeDelete = (edgeId) => {
    setEdges((eds) => {
      // Remove the edge from the state
      const updatedEdges = eds.filter((edge) => edge.id !== edgeId);

      // Get the script data from localStorage
      const scriptString = localStorage.getItem("scriptData");
      if (scriptString) {
        const scriptData = JSON.parse(scriptString);
        // Find the edge to be deleted
        const edgeToDelete = eds.find((edge) => edge.id === edgeId);
        if (edgeToDelete) {
          const sourceId = edgeToDelete.source;
          const targetId = edgeToDelete.target;
          // Update the source state's Next State and target state's Previous State
          scriptData.map((state, index) => {
            if (index == sourceId) {
              state["Next State"] = ""; // Remove the link to the target state
            }
            if (index == targetId) {
              state["Previous State"] = ""; // Remove the link to the source state
            }
          });

          // Update the script data in localStorage
          localStorage.setItem("scriptData", JSON.stringify(scriptData));
          dispatch(setSaveScript(true));
        }
        let edgeDelete = true;
        handleSaveScript(edgeDelete);

        return updatedEdges;
      }
      return eds;
    });
  };

  const onEdgeClick = (event, edge) => {
    handleEdgeDelete(edge.id);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const scrollable = scrollableRef.current;
      if (scrollable) {
        if (event.key === "ArrowDown") {
          scrollable.scrollBy(0, 50); // Scroll down 50px
        } else if (event.key === "ArrowUp") {
          scrollable.scrollBy(0, -50); // Scroll up 50px
        }
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },

            // Default options for specific types
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
      </div>
      <div className="flex flex-col w-full h-screen">
        <Navbar />
        <div className="w-full flex-1 relative">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            zoomOnScroll={false} // Disable zooming on scroll
            panOnScroll={true}
            panOnDrag={true}
            nodeTypes={nodeTypes}
            onEdgeClick={onEdgeClick}
            onNodeDragStop={(event, node) => handleNodeDragStop(node)}
          >
            <Controls />
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>
          <div className="absolute right-4 bottom-5">
            {saveScript && saveScript ? (
              <button
                style={{ color: fontColor }}
                type="button"
                onClick={handleSaveScript}
                className="text-gray-900 flex gap-3 bg-white hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
              >
                <IoCallOutline className="text-xl" /> Save Script
              </button>
            ) : (
              <>
                <TestAgent />
                <ChatAgent />
              </>
            )}
          </div>
          <div className="absolute right-4 top-5">
            <button
              onClick={addNode}
              className="text-white flex gap-3 hover:text-gray-800 bg-gray-900 border border-gray-800 hover:bg-white focus:ring-2 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
            >
              <FiPlus className="text-xl" /> Add Node
            </button>
            {/* <AdvancedPopUp /> */}
          </div>
          <AdvancedSettings data={response && response} />
        </div>
        <div className="p-6 pb-2 relative">
          <div className="absolute w-14 top-[-20px] right-0 h-4 bg-white"></div>
          <div className="flex gap-3">
            <ul className="w-full  h-92 flex-1 flex justify-between gap-1 flex-wrap lg:flex-nowrap">
              <li className="relative basis-[45%] md:basis-[30%]">
                <button
                  onClick={handleVoiceToggle}
                  className="hover:bg-gray-200 px-2 justify-center items-center gap-2 w-full rounded-md text-left py-2 capitalize text-lg flex"
                >
                  <RiVoiceprintLine className="text-xl text-[#9CA3AF] hidden sm:block" />
                  <span
                    style={{ color: fontColor }}
                    className="text-xs font-semibold 2xl:text-lg"
                  >
                    Choose Voice
                  </span>
                </button>
                <div className="text-center">{response?.voice_name}</div>
                <ul
                  ref={scrollableRef}
                  className={`absolute ${
                    voiceDropDown ? "" : "hidden"
                  } bottom-20 left-0 right-2 h-96 z-10 min-w-[250px] overflow-auto rounded-md border border-blue-gray-50 bg-white p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none`}
                >
                  {CloneVoices && (
                    <li className="font-extrabold">Clone Voices</li>
                  )}
                  {CloneVoices &&
                    CloneVoices.map((voice, index) => (
                      <li
                        key={voice.id}
                        onClick={() => handleSetVoice(voice.id)}
                        className="flex justify-between items-center w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                      >
                        <div>{voice.voice_name}</div>
                        {/* <button
                          onClick={(event) => handlePlayClick(voice, event)}
                          className="ml-2 p-1 rounded text-2xl"
                        >
                          {playingVoiceId === voice.id ? (
                            <IoStopCircleOutline />
                          ) : (
                            <IoPlayCircleOutline />
                          )}
                        </button> */}
                      </li>
                    ))}
                  {voices && (
                    <li className="mt-7 font-extrabold">Simple Voices</li>
                  )}
                  {voices &&
                    voices.map((voice, index) => (
                      <li
                        key={voice.id}
                        onClick={() => handleSetVoice(voice.id)}
                        className="flex justify-between items-center w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                      >
                        <div>{voice.voice_name}</div>
                        <button
                          onClick={(event) => handlePlayClick(voice, event)}
                          className="ml-2 p-1 rounded text-2xl"
                        >
                          {playingVoiceId === voice.id ? (
                            <IoStopCircleOutline />
                          ) : (
                            <IoPlayCircleOutline />
                          )}
                        </button>
                      </li>
                    ))}
                </ul>
              </li>
              <li className="basis-[45%] md:basis-[30%]">
                <div className="hover:bg-gray-200 px-2 justify-center items-center gap-2 w-full rounded-md text-left py-2 capitalize text-lg flex">
                  <SlCalender className="text-xl text-[#9CA3AF] hidden sm:block" />
                  <CalenderPopUp data={response && response} />
                </div>
              </li>
              <li className="basis-[45%] md:basis-[30%]">
                <div className="hover:bg-gray-200 px-2 items-center justify-center gap-2 w-full rounded-md text-left py-2 capitalize flex">
                  <TbTransfer className="text-xl  text-[#9CA3AF] hidden sm:block" />
                  <LiveTransfer data={response && response} />
                </div>
              </li>
              <li className="basis-[45%] md:basis-[30%]">
                <div className="hover:bg-gray-200 px-2 items-center justify-center gap-2 w-full rounded-md text-left py-2 capitalize flex">
                  <RiEditBoxLine className="text-xl  text-[#9CA3AF] hidden sm:block" />
                  <NotesPopUp data={response && response} />
                </div>
              </li>
              <li className="basis-[45%] md:basis-[30%]">
                <div className="hover:bg-gray-200 px-2 items-center gap-2 justify-center w-full rounded-md text-left py-2 capitalize text-md flex">
                  <IoGitBranchOutline className="text-xl text-[#9CA3AF] hidden sm:block" />
                  <IfCondition data={response && response} />
                </div>
              </li>
              <li className="basis-[45%] md:basis-[30%]">
                <div className="hover:bg-gray-200 px-2 items-center justify-center gap-2 w-full rounded-md text-left py-2 capitalize text-md flex">
                  <SlGraph className="text-xl text-[#9CA3AF] hidden sm:block" />
                  <GoalPopUp data={response && response} />
                </div>
              </li>
              <li className="basis-[45%] md:basis-[30%]">
                <div className="hover:bg-gray-200 px-2 items-center gap-2 justify-center w-full rounded-md text-left py-2 capitalize text-md flex">
                  <MdOutlineVoicemail className="text-xl text-[#9CA3AF] hidden sm:block" />
                  <VoiceMailPopUp data={response && response} />
                </div>
              </li>
              <li className="basis-[45%] md:basis-[30%]">
                <div className="hover:bg-gray-200 px-2 items-center gap-2 w-full justify-center rounded-md text-left py-2 capitalize text-md flex">
                  <LuPhoneOff className="text-xl text-[#9CA3AF] hidden sm:block" />
                  <EndCallPopup data={response && response} />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
};

export default BasicFlow;
