import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import AnalyticsCardLayout from "../components/AnalyticsCardLayout";
import QuickAnalyticsCard from "../components/QuickAnalyticsCard";
import { useSelector } from "react-redux";

export default function AnalyticsTabs({ setLoading }) {
  const [activeTab, setActiveTab] = React.useState("Campaign");
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);

  // Updated data array with components
  const data = [
    {
      label: "Campagin",
      value: "Campaign",
      Component: AnalyticsCardLayout,
    },
    {
      label: "CRM Campaign",
      value: "quickCampaign",
      Component: QuickAnalyticsCard,
    },
  ];

  return (
    <Tabs value={activeTab}>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex justify-between items-end bg-white"
      >
        <div className="md:basis-[60%] px-3 2xl:basis-[30%] basis-full">
          <TabsHeader
            className="rounded-none bg-transparent"
            indicatorProps={{
              className:
                "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
            }}
          >
            {data.map(({ label, value }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => setActiveTab(value)}
                className={activeTab === value ? "pb-3" : "pb-3"}
                style={{ color: fontColor }}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
        </div>
        {/* <Agreement activeTab={activeTab} /> */}
      </div>
      <TabsBody>
        {data.map(({ value, Component }) => (
          <TabPanel key={value} value={value}>
            <Component setLoading={setLoading} />
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}
