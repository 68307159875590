import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scriptData: null,
};

export const scriptDataSlice = createSlice({
  name: "scriptData",
  initialState,
  reducers: {
    setScriptData(state, action) {
      state.scriptData = action.payload;
    },
  },
});

export const { setScriptData } = scriptDataSlice.actions;
export default scriptDataSlice.reducer;
