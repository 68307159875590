import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteNode: false,
};

export const deleteNodeSlice = createSlice({
  name: "deleteNode",
  initialState,
  reducers: {
    setDeleteNode(state, action) {
      state.deleteNode = action.payload;
    },
  },
});

export const { setDeleteNode } = deleteNodeSlice.actions;
export default deleteNodeSlice.reducer;
