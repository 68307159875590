import React, { useState } from "react";
import { BsLightningCharge } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { GoTriangleDown } from "react-icons/go";
import { IoCallOutline } from "react-icons/io5";
import { LuBrain } from "react-icons/lu";
import { MdOutlineRecordVoiceOver } from "react-icons/md";
import { SlGraph } from "react-icons/sl";
import { FaRegFileLines } from "react-icons/fa6";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Navbar from "./Navbar";

function Editor() {
  const [contents, setContents] = useState([
    "Yeah. It's Alexander... from Air dot aiy eye? How's your day going so far?",
    "Yeah. So it uh, It look like you opted into one of our ads lookin'for information how to scale your business using aiy eye. Is that something that you recall?",
    "Yeah. It's Alexander... from Air dot aiy eye? How's your day going so far?",
    "Yeah. So it uh, It look like you opted into one of our ads lookin'for information how to scale your business using aiy eye. Is that something that you recall?",
    "Yeah. It's Alexander... from Air dot aiy eye? How's your day going so far?",
    "Yeah. So it uh, It look like you opted into one of our ads lookin'for information how to scale your business using aiy eye. Is that something that you recall?",
    "Yeah. It's Alexander... from Air dot aiy eye? How's your day going so far?",
    "Yeah. So it uh, It look like you opted into one of our ads lookin'for information how to scale your business using aiy eye. Is that something that you recall?",
  ]);

  const handleChange = (event, index) => {
    const newContents = [...contents]; 
    newContents[index] = event.target.value;
    setContents(newContents);
  };

  const autoResize = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };
  return (
    <>
    <Navbar />
      <div className="flex justify-between items-start h-[90vh] overflow-hidden">
        <div className="basis-[78%] h-auto overflow-hidden py-12">
          <div className="max-w-[75%] h-full overflow-hidden mx-auto">
            <h1 className="text-4xl font-bold text-gray-800">swd</h1>
            <p className="capitalize text-gray-800 italic p-2 text-lg font-medium">
              standard editor
            </p>
            <div className="w-full h-[73vh] overflow-y-scroll mt-3" style={{}}>
              <div className="flex items-center gap-3 mb-6">
                <div className="bg-gray-800 w-fit text-white py-2 px-3 rounded-md">
                  Hey, {"{"}ProspectName{"}"}!
                </div>
                <button
                  data-tooltip-target="tooltip-bottom"
                  data-tooltip-placement="bottom"
                  type="button"
                  className=""
                >
                  <IoMdInformationCircleOutline className="text-2xl text-gray-800" />
                </button>

                <div
                  id="tooltip-bottom"
                  role="tooltip"
                  className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                >
                  Information
                  <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
              </div>
              {contents.map((content, index) => (
                <div className="" key={index}>
                  <p className="text-[#d8d7d3] font-semibold">
                    *Wait for the Prospect to Respond*
                  </p>
                  <textarea
                    value={content}
                    onChange={() => handleChange(event, index)}
                    onInput={autoResize}
                    className="bg-gray-50 text-gray-800 font-medium text-md rounded-lg focus:ring-0 w-full outline-0 border-0"
                    style={{ overflowY: "hidden", resize: "none" }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="basis-[22%] w-full h-[92vh] px-4 py-12 flex flex-col border-l-2">
          <ul className="w-full h-92 flex-1 flex flex-col gap-1 ">
            <li className="">
              <button className="hover:bg-gray-200 items-center gap-2 w-full rounded-md text-left p-2 capitalize text-md font-semibold flex">
                <LuBrain className="text-xl text-[#9CA3AF]" />
                Connect a knoweldge base
                <GoTriangleDown className="text-xl text-[#9CA3AF]" />
              </button>
            </li>
            <li className="">
              <button className="hover:bg-gray-200 items-center gap-2 w-full rounded-md text-left p-2 capitalize text-md font-semibold flex">
                <BsLightningCharge className="text-xl text-[#9CA3AF]" />
                Add an Action
              </button>
            </li>
            <li className="">
              <button className="hover:bg-gray-200 items-center gap-2 w-full rounded-md text-left p-2 capitalize text-md font-semibold flex">
                <FaRegFileLines className="text-xl text-[#9CA3AF]" />
                Magically generate script using AI
              </button>
            </li>
            <li className="">
              <button className="hover:bg-gray-200 items-center gap-2 w-full rounded-md text-left p-2 capitalize text-md font-semibold flex">
                <MdOutlineTipsAndUpdates className="text-xl text-[#9CA3AF]" />
                Must read instructions/vital tips
              </button>
            </li>
            <li className="">
              <button className="hover:bg-gray-200 items-center gap-2 w-full rounded-md text-left p-2 capitalize text-md font-semibold flex">
                <MdOutlineRecordVoiceOver className="text-xl text-[#9CA3AF]" />
                Male: british accent
              </button>
            </li>
            <li className="">
              <button className="hover:bg-gray-200 items-center gap-2 w-full rounded-md text-left p-2 capitalize text-md font-semibold flex">
                <FiEdit className="text-xl text-[#9CA3AF]" />
                Post call notes
              </button>
            </li>
            <li className="">
              <button className="hover:bg-gray-200 items-center gap-2 w-full rounded-md text-left p-2 capitalize text-md font-semibold flex">
                <SlGraph className="text-xl text-[#9CA3AF]" />
                Setup Stats Tracking
              </button>
            </li>
          </ul>
          <button
            type="button"
            className="text-white m-2 bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 font-medium rounded-xl text-lg px-5 py-3 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 capitalize flex items-center gap-3 justify-center"
          >
            <IoCallOutline className="text-xl" /> Talk to your agent
          </button>
        </div>
      </div>
    </>
  );
}

export default Editor;
