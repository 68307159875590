import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


function KnowledgeBaseList({ data, refresh, setRefresh }) {
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const { fontColor } = useSelector((state) => state.theme);
  const navigate = useNavigate();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${baseUrl}/api/knowledge_base/${id}/`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        setRefresh(!refresh);
        toast.success("Knowledge Base Delete Successfully")
      }
      else{
        toast.error("Something went wrong")
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigate = (id) => {
    navigate(`/knowledge-bases/${id}`);
  };
  return (
    <>
      <div className="relative overflow-x-auto p-7 pt-0">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Name
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Created At
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Updated At
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((data, index) => (
                <tr
                  
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td
                   style={{ color: fontColor }}
                    className="px-6 py-4 cursor-pointer"
                    onClick={() => handleNavigate(data.id)}
                  >
                    {data.name}
                  </td>
                  <td style={{ color: fontColor }} className="px-6 py-4">{formatDate(data.created_at)}</td>
                  <td style={{ color: fontColor }} className="px-6 py-4">{formatDate(data.updated_at)}</td>
                  <td className="px-6 py-4 text-xl">
                    <MdDelete
                      onClick={() => handleDelete(data.id)}
                      className="hover:text-red-600 cursor-pointer"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default KnowledgeBaseList;
