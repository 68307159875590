import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";

function NavbarDrawer({ openRight, setOpenRight }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const navigate = useNavigate();
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const [guestUser, setGuestUser] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isGuest = localStorage.getItem("guestUser");

    setGuestUser(isGuest === "true"); // Ensure you're storing guestUser as string 'true' or 'false'
  }, []);

  const closeDrawerRight = () => setOpenRight(false);

  const handleLogout = (e) => {
    e.preventDefault();
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      fetch(`${baseUrl}/api/auth/logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      })
        .then((response) => {
          if (response.ok) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("agentID");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("userData");
            localStorage.removeItem("guestUser");
            navigate("/login");
          } else {
            throw new Error("Logout failed");
          }
        })
        .catch((error) => {
          console.error("Logout error:", error);
        });
    }
  };

  const handleBackSuper = async () => {
    localStorage.removeItem("guestUser");
    const email = JSON.parse(localStorage.getItem("userData"))?.email;
    setGuestUser(false);
    setLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}/api/superadmin/reverse/impersonate/user/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({ email: email }), // Use the correct payload
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const { access_token, refresh_token } = data.data;
      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("refreshToken", refresh_token);
      localStorage.setItem("userData", JSON.stringify(data.data.user));
      navigate("/superAdmins/user-view");
      // setRefresh(!refresh);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PulseLoader color="#36d7b7" loading={loading} />;
  }

  return (
    <>
      <div
        className={`${
          openRight ? "" : "pointer-events-none"
        } fixed inset-0 z-[199] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          openRight ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div
          style={{ backgroundColor: backgroundColor }}
          className={`p-4 ${
            openRight ? "" : "hidden"
          } absolute z-[200] bg-white top-0 right-0 h-screen w-[20rem]`}
        >
          <div
            style={{ color: fontColor }}
            className="mb-6 flex items-center justify-between"
          >
            <Typography variant="h5" color="blue-gray">
              <NavLink
                style={{ color: fontColor }}
                className="capitalize"
                to="/create/agent"
              >
                {legalName}
              </NavLink>
            </Typography>
            <div className="cursor-pointer" onClick={closeDrawerRight}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <ul
            style={{ color: fontColor }}
            className="flex flex-col mt-5 gap-2 w-full"
          >
            <li>
              <NavLink
                to="/user"
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-lg font-medium rounded-lg inline-block bg-black text-white p-2 w-full"
                      : "text-lg font-medium rounded-lg inline-block hover:bg-gray-100 p-2 w-full"
                  }`
                }
              >
                Your Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/org"
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-lg font-medium rounded-lg inline-block bg-black text-white p-2 w-full"
                      : "text-lg font-medium rounded-lg inline-block hover:bg-gray-100 p-2 w-full"
                  }`
                }
              >
                Team Settings
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/integration"
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-lg font-medium rounded-lg inline-block bg-black text-white p-2 w-full"
                      : "text-lg font-medium rounded-lg inline-block hover:bg-gray-100 p-2 w-full"
                  }`
                }
              >
                Integrations
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/bookings"
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-lg font-medium rounded-lg inline-block bg-black text-white p-2 w-full"
                      : "text-lg font-medium rounded-lg inline-block hover:bg-gray-100 p-2 w-full"
                  }`
                }
              >
                Bookings
              </NavLink>
            </li>

            {guestUser && (
              <li>
                <span
                  onClick={handleBackSuper}
                  className="text-lg font-medium cursor-pointer rounded-lg inline-block hover:bg-gray-100 p-2 w-full"
                >
                  Back
                </span>
              </li>
            )}

            <li>
              <button
                onClick={handleLogout}
                className="text-lg font-medium text-left rounded-lg inline-block hover:bg-gray-100 p-2 w-full"
              >
                Sign Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default NavbarDrawer;
