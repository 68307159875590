import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const UpgradeSubs = ({ data }) => {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]?.company_name;
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubscription = async () => {
    setIsModalOpen(false); // Close modal
    try {
      const response = await fetch(`${baseUrl}/api/user_subscription_payment/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          subscription_id: data,
        }),
      });
      if (response.ok) {
        toast.success("Subscription Upgrade to $297!");
      } else if (response.status === 400) {
        const responseData = await response.json();
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const Modal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded shadow-lg w-1/3">
          <h2 className="text-lg font-semibold mb-4">Confirm Action</h2>
          <p>Are you sure you want to upgrade your subscription?</p>
          <div className="mt-4 flex justify-end">
            <button
              onClick={onConfirm}
              className="bg-green-500 text-white px-4 py-2 rounded mr-2"
            >
              Yes
            </button>
            <button
              onClick={onClose}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              No
            </button>
          </div>
        </div>
      </div>,
      document.body
    );
  };

  return (
    <>
      <button
        style={{ color: fontColor }}
        onClick={() => setIsModalOpen(true)}
        className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f] group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
        type="button"
      >
        <span
          style={{ backgroundColor: backgroundColor }}
          className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
        >
          Upgrade Subscription
        </span>
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleSubscription}
      />
    </>
  );
};

export default UpgradeSubs;
