import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { GrSettingsOption } from "react-icons/gr";
import { useSelector } from "react-redux";
import { Handle } from "reactflow";

const suggestions = [
  "contact_number}}",
  "email}}",
  "first_name}}",
  "last_name}}",
  "company_name}}",
  "industry}}",
  "custom1}}",
  "custom2}}",
  "custom3}}",
  "custom4}}",
];

function CustomNode({ id, data, sourcePosition, targetPosition }) {
  const [agentID, setAgentID] = useState(localStorage.getItem("agentID"));
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const company_name = JSON.parse(localStorage.getItem("userData"))
    ?.companies[0]?.company_name;
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const [isEditing, setIsEditing] = useState(false);

  const [paratext, setParaText] = useState(
    localStorage.getItem("StartCallPrompt")
  );
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef(null);
  const textareaRef = useRef(null);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    const startCallPrompt = localStorage.getItem("StartCallPrompt");

    if (startCallPrompt != "null") {
      setParaText(startCallPrompt);
    } else {
      setParaText("Hey, prospectName! How are you doing");
    }
    // setParaText(startCallPrompt || "Hey, prospectName! How are you doing");
  }, [data]);

  const handleChange = (e) => {
    const value = e.target.value;
    setParaText(value);

    const regex = /{{\s+/g;
    const matches = value.match(regex);

    const anothermatch = value.endsWith("{{");

    if ((matches && matches.length > 0) || anothermatch) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleBlur = () => {};

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleBlur();
      handleStartCall();
      setIsEditing(false);
    }
  };

  const handleStartCall = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          start_call: paratext,
        }),
      });
      if (response.ok) {
        toast.success("Start Call Update successfully");
      } else if (response.status === 401) {
        const responseData = await response.json();
        toast.error(responseData.message || "Something went wrong");
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setParaText((prevValue) => {
      if (!textareaRef.current) {
        return prevValue + suggestion;
      }

      const startPos = textareaRef.current.selectionStart;
      const endPos = textareaRef.current.selectionEnd;

      // Insert suggestion at the cursor position
      const newValue =
        prevValue.substring(0, startPos) +
        suggestion +
        prevValue.substring(endPos, prevValue.length);

      return newValue;
    });
    setShowSuggestions(false); // Hide suggestions after insertion
    // Trigger blur after insertion
  };

  return (
    <>
      <div
        onDoubleClick={handleDoubleClick}
        className="border border-gray-800 w-80 h-auto rounded-lg bg-white overflow-hidden"
      >
        <div className="px-4 py-5">
          <div className="flex justify-between items-center">
            <p style={{ color: fontColor }} className="font-bold text-md">
              {data.line}
            </p>
            {/* <GrSettingsOption /> */}
          </div>
          <div className="mt-2">
            {isEditing ? (
              <textarea
                type="text"
                ref={textareaRef}
                value={paratext}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoFocus
                className="w-full h-full resize-none overflow-hidden outline-none focus:border-black focus:ring-0"
                style={{
                  height: "auto",
                  minHeight: "100px",
                  maxHeight: "700px",
                  scrollbarWidth: "none",
                  resize: "none",
                }}
              />
            ) : (
              <div
                style={{ color: fontColor }}
                className="w-full h-16 text-sm font-medium"
              >
                {paratext}
              </div>
            )}
            {showSuggestions && (
              <div className="suggestions h-[150px]" ref={suggestionsRef}>
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion-item"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Handle type="target" position={targetPosition} id={id} />
      <Handle type="source" position={sourcePosition} id={id} />
    </>
  );
}

export default CustomNode;
