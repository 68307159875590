import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { MdOutlineClose } from "react-icons/md";
import { PiWechatLogo } from "react-icons/pi";
import { LuRefreshCcw } from "react-icons/lu";
import { useSelector } from "react-redux";

function ChatAgent() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor } = useSelector((state) => state.theme);
  const [showPopUp, setShowPopUp] = useState(false);
  const messageRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [agentID, setAgentID] = useState(localStorage.getItem("agentID"));
  const company_name = JSON.parse(localStorage.getItem("userData"))
    ?.companies[0]?.company_name;
  const [messages, setMessages] = useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageRef.current) {
        messageRef.current.scrollTop = messageRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [messages]);

  const fetchChatHistory = async () => {
    setIsLoading(true);
    // messages.unshift({ role: "assistant", content: "hsdzvxds vudjhfazgc" })
    const startMessage = {
      role: "user",
      content: [
        {
          type: "text",
          text: "Start",
        },
      ],
    };

    const messagesToSend = [startMessage];
    try {
      const response = await fetch(`${baseUrl}/api/agents/chat/${agentID}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ history: messagesToSend }),
      });
      if (response.ok) {
        const responseData = await response.json();

        const filteredMessages = responseData.data.filter(
          (msg, index) =>
            index !== 0 || msg.content[0].text.toLowerCase() !== "start"
        );
        setMessages(filteredMessages);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChatAgent = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const newMessage = {
      role: "user",
      content: [{ type: "text", text: inputValue }],
    };
    const startMessage = {
      role: "user",
      content: [
        {
          type: "text",
          text: "Start",
        },
      ],
    };
    const updatedMessages = [startMessage, ...messages, newMessage];
    const uiMessages = [...messages, newMessage];
    setMessages(uiMessages);
    setInputValue("");
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/api/agents/chat/${agentID}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ history: updatedMessages }),
      });
      if (response.ok) {
        const responseData = await response.json();
        const filteredMessages = responseData.data.filter(
          (msg, index) =>
            index !== 0 || msg.content[0].text.toLowerCase() !== "start"
        );
        setMessages(filteredMessages);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (showPopUp && messages.length == 0) {
      fetchChatHistory();
      // alert("efhjbdc")
    }
  }, [showPopUp]);

  useEffect(() => {
    setDisabled(inputValue.trim() === ""); // Disable the button if input is empty
  }, [inputValue]);

  const handleToggle = () => {
    setShowPopUp(!showPopUp);
  };
  return (
    <>
      <button
        onClick={handleToggle}
        style={{ color: fontColor }}
        type="button"
        className="text-gray-900 bg-white flex gap-3 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
      >
        <PiWechatLogo className="text-xl" /> Test via chat
      </button>
      <div
        className={`${
          showPopUp ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          showPopUp ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300 cursor-default`}
      >
        <div
          data-dialog="sign-in-dialog"
          className="relative mx-auto flex w-full max-w-[40rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
        >
          <div className="flex flex-col gap-4 p-6">
            <div className="flex justify-between items-center">
              <h4
                style={{ color: fontColor }}
                className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
              >
                ChatBot
              </h4>
              <div className="flex items-center gap-3">
                <div onClick={() => setMessages([])} className="cursor-pointer">
                  <LuRefreshCcw className="text-xl" />
                </div>
                <div onClick={handleToggle} className="cursor-pointer">
                  <MdOutlineClose className="text-2xl" />
                </div>
              </div>
            </div>
            <div className="bg-white rounded-xl custom-shadow w-full max-w-3xl">
              {/* <div className="flex items-center justify-between rounded-tl-xl rounded-tr-xl bg-[#001d3d] px-5 py-4">
                <div className="flex items-center space-x-1">
                  <p className="text-white">Connected</p>
                  <span className="relative flex h-3 w-3 mb-[1px]">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-400"></span>
                  </span>
                </div>
              </div> */}
              {/* <hr className="border-t-2 border-gray-200" /> */}
              <div
                ref={messageRef}
                className=" h-[300px] 2xl:h-[500px] overflow-auto px-6 py-4"
              >
                {messages.length > 0 &&
                  messages.map((message, index) => (
                    <div
                      key={index}
                      className={`mb-4 flex ${
                        message.role === "user"
                          ? "justify-end"
                          : "justify-start"
                      } items-center`}
                    >
                      <div
                        className={`${
                          message.role === "user" ? "hidden" : ""
                        } basis-[10%]`}
                      >
                        <img
                          src="https://cdn.dribbble.com/users/1740191/screenshots/6287147/eva.png"
                          alt=""
                          className="w-20"
                        />
                      </div>
                      <div className={`flex-1 `}>
                        <div
                          style={{ color: fontColor }}
                          className={`w-fit rounded-lg  p-3 inline-block ${
                            message.role === "user"
                              ? "bg-[#d2edd4] float-end"
                              : "bg-[#f5f5f5]"
                          }`}
                        >
                          <span>
                            {/* {message.role === "user"
                              ? message?.content.map((text, index) => (
                                  <div key={index}>{text.text}</div>
                                ))
                              : ""} */}
                            {message?.content.map((text, index) => (
                              <div key={index}>{text.text}</div>
                            ))}

                            {/* {message?.content?.map((text, index) => {
                              // Extract content between <response> tags
                              const responseMatch = text.text.match(
                                /<response>(.*?)<\/response>/
                              );
                              const responseText = responseMatch
                                ? responseMatch[1]
                                : "";

                              return <div key={index}>{responseText}</div>;
                            })} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                {isLoading && (
                  <div className="flex space-x-2 justify-start items-center dark:invert pl-4">
                    <div className="h-3 w-3 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-3 w-3 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-3 w-3 bg-gray-500 rounded-full animate-bounce"></div>
                  </div>
                )}
              </div>

              <hr className="border-t-2 border-gray-200" />
              <form
                onSubmit={handleChatAgent}
                className="flex justify-between px-6 py-4 space-x-3"
              >
                <input
                  type="text"
                  name="message"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-800 focus:border-gray-800 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <button
                  type="submit"
                  disabled={disabled}
                  className={`${
                    disabled
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-gray-800 hover:bg-gray-900 "
                  } focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 flex items-center justify-center  ease-out  rounded-lg px-4 py-2 text-white`}
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatAgent;
