import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import { TbRefresh } from "react-icons/tb";
import { Tooltip } from "@material-tailwind/react";
import IntegrationPageNav from "./IntegrationPageNav";


function Twilio() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [sid, setSid] = useState("");
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [sidToken, setSidToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRotating, setIsRotating] = useState(false);

  // const [twilioIntegrated, setTwilioIntegrated] = useState("");

  useEffect(() => {
    const handleGetTwilio = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/api/agency/twilio/register/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setSid(responseData.data.twilio_sid);
          setSidToken(responseData.data.twilio_token);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    handleGetTwilio();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsRotating(true)
    try {
      const response = await fetch(`${baseUrl}/api/agency/twilio/register/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          twilio_sid: sid,
          twilio_token: sidToken,
        }),
      });
      if (response.ok) {
        toast.success("Twilio Integrated Successfully");
      }
      else if (response.status === 400) {
        const responsedata = await response.json();
        toast.error(responsedata.message)
      } else {
        toast.error("Something went wrong!")
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsRotating(false)
    }
  };
  return (
    <>
    
      <div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },

            // Default options for specific types
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
      </div>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full h-screen "
      >
        
        <div className=" flex justify-center items-center mt-5">
          <div className="custom-shadow bg-white rounded-lg py-10 w-full h-fit mx-10 lg:basis-1/3 ">
            <div className="w-full relative">
              <h1
                style={{ color: fontColor }}
                className="mb-5 text-2xl text-center flex justify-center items-center gap-3"
              >
                TWILIO
                {sid && sidToken ? (
                  <span className="relative flex h-3 w-3 mb-[1px]">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-400"></span>
                  </span>
                ) : (
                  ""
                )}
              </h1>
              <Tooltip content="Update Numbers" placement="top-center">
                <div
                  onClick={handleSubmit}
                  className={`absolute top-[5px] cursor-pointer right-[15px] text-2xl ${isRotating ? 'rotate' : ''}`}
                >
                  <TbRefresh />
                </div>
              </Tooltip>
            </div>
            <form onSubmit={handleSubmit} className="p-5">
              <div className="relative">
                <input
                  type="text"
                  id="floating_filled"
                  className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={sid}
                  onChange={(e) => setSid(e.target.value)}
                />
                <label
                  style={{ color: fontColor }}
                  htmlFor="floating_filled"
                  className="absolute z-0 text-md cursor-pointer font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4  origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  Twilio Account SID
                </label>
              </div>
              <div className="relative mt-10">
                <input
                  type="text"
                  id="floating_filled2"
                  className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={sidToken}
                  onChange={(e) => setSidToken(e.target.value)}
                />
                <label
                  style={{ color: fontColor }}
                  htmlFor="floating_filled2"
                  className="absolute text-md cursor-pointer font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-0 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  Twilio Auth Token
                </label>
              </div>
              <button
                type="submit"
                className="text-white w-full mt-10 bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
      
    </>
  );
}

export default Twilio;
