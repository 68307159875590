import React, { useState } from "react";
import { Tabs, TabsHeader, Tab,TabsBody,TabPanel } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import WebhookDilog from "./WebhookDilog";
import Webhooks from "./Webhooks"; // Only keeping this import
import ZapierIntigration from "./ZapierIntigration";
import ClientApi from "./ClientApi";
import Twilio from "./Twilio";



export default function IntegrationPageNav() {
  const [activeTab, setActiveTab] = useState("Twilio");
  const [open, setOpen] = useState(false);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const data = [
    { label: "Twilio", value: "Twilio", Component: Twilio },
    { label: "Webhook", value: "Webhook", Component: Webhooks },
    { label: "Client API", value: "ClientApi", Component: ClientApi },
    { label: "Zapier", value: "Zapier", Component: ZapierIntigration },
    ];

  return (
    <>
      <WebhookDilog open={open} setOpen={setOpen} />
      <Tabs className="py-5 flex flex-col" value={activeTab}>
        <div style={{ backgroundColor: backgroundColor }} className="flex justify-between items-center px-[32px] bg-white">
          <div className="basis-[60%]">
            <TabsHeader
              className="rounded-none"
              indicatorProps={{
                className:
                  "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
              }}
            >
              {data.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab(value)}
                  className="pb-3"
                  style={{ color: fontColor }}
                >
                  {label}
                </Tab>
              ))}
            </TabsHeader>
          </div>
          {activeTab === "Webhook" && (
            <button
              onClick={handleClickOpen}
              className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f] group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800 "
              type="button"
            >
              <span
                style={{ backgroundColor: backgroundColor, color: fontColor }}
                className="relative px-5 py-2 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
              >
                Generate
              </span>
            </button>
          )}
        </div>
        
        <TabsBody className="" >
          {data.map(({ value, Component }) => (
            <TabPanel  key={value} value={value}>
              <Component />
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
      
    </>
  );
}


