import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function ActivateMember() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);

  const activateMemberAccount = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/invite/${uuid}/`,
        {
          method: "GET",
        }
      );
      if (response.ok) {
        navigate("/create/agent");
      }
      else{
        navigate("/create/agent");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    activateMemberAccount();
  }, []);

  return null;
}

export default ActivateMember;
