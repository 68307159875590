import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function Access() {
  const { id, email } = useParams();
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAccess = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/superadmin/impersonate/user/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify({ email: email, company_name: id }), // Use the correct payload
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        // window.open(`http://localhost:5173/access/${id}/${email}`, "_blank");

        const { access_token, refresh_token } = data.data;
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);
        localStorage.setItem("userData", JSON.stringify(data.data.user));
        localStorage.setItem("guestUser", JSON.stringify(data.guest));

        navigate("/dashboard");
      } catch (error) {
        console.error("Error:", error);
      }
    };
    handleAccess();
  }, [baseUrl,id,email]);

  return null;
}

export default Access;
