import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

function GetCloneVoice() {
  const [voices, setVoices] = useState([]);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const [refresh, setRefresh] = useState(false);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;

  useEffect(() => {
    // Function to fetch voices from the API
    const fetchVoices = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/get/clone/voice/`, {
          method: "GET",
          headers: {
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        const data = await response.json();
        setVoices(data.data); // Assuming the response is an array of voice objects
      } catch (error) {
        console.error("Error fetching voices:", error);
      }
    };

    fetchVoices();
  }, [refresh]);

  const playVoice = (voiceUrl) => {
    const audio = new Audio(voiceUrl);
    audio.play();
  };

  const deleteCloneVoice = async (id) => {
    try {
      const response = await fetch(`${baseUrl}/api/delete/clone/voice/`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          voice_id: id,
        }),
      });
      const resData = await response.json();
      if (response.ok) {
        setRefresh(!refresh);
      } else {
        toast.error(resData.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching voices:", error);
    }
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div className="p-4">
        {/* <h1 className="text-2xl font-bold mb-4">Available Voices</h1> */}
        {voices.length === 0 ? (
          <p>Loading voices...</p>
        ) : (
          <ul>
            {voices.map((voice) => (
              <li key={voice.id} className="mb-2 w-full flex justify-between">
                <span className="font-semibold">{voice.voice_name}</span>
                <button
                  className="ml-4 bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                  onClick={() => deleteCloneVoice(voice.id)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

export default GetCloneVoice;
