import React, { useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Google from "./Google";
import { useFormik } from "formik";
import { signUpSchema } from "../schemas/FormSchema";
import { useSelector } from "react-redux";
import Intercom from "@intercom/messenger-js-sdk";

const SignUp = () => {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const [showPassword, setShowPassword] = useState(false);
  const [signupErr, setSignupErr] = useState("");
  const Navigate = useNavigate();
  const formMik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: signUpSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${baseUrl}/api/auth/registration/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email.toLowerCase(),
            password: values.password,
          }),
        });
        const responseData = await response.json();
        const errorMessage = responseData.email;
        if (!response.ok) {
          setSignupErr(errorMessage);
        }
        const { access_token, refresh_token } = responseData.data;
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);
        localStorage.setItem(
          "userData",
          JSON.stringify(responseData.data.user)
        );
        Navigate("/onboarding");
      } catch (error) {
        console.error("Error signing up:", error.message);
      }
    },
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  if (baseUrl == "https://app.closerx.ai") {
    Intercom({
      app_id: "p6t03dhm",
      // user_id: userDetail?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      // name: userDetail?.first_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      // email: userDetail?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
  }

  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="w-screen h-screen p-4 flex justify-center items-center"
    >
      <div className="w-screen h-screen p-4 flex justify-center items-center">
        <div className="md:basis-3/4 lg:basis-1/2 xl:basis-1/4 w-full sm:basis-4/5 bg-white rounded-md custom-shadow flex justify-center items-center gap-10 flex-col p-10">
          <div className="flex justify-center items-center flex-col gap-4">
            <h1
              style={{ color: fontColor }}
              className="text-4xl font-medium text-center capitalize"
            >
              {legalName}
            </h1>
            <p style={{ color: fontColor }} className="text-lg text-center">
              Create An Account
            </p>
            {signupErr ? (
              <p className="text-red-600 m-[-7px] capitalize font-medium">
                {signupErr}
              </p>
            ) : null}
          </div>
          <div className="w-full flex justify-center items-center flex-col gap-4">
            <form
              className="w-full flex justify-center items-center gap-4 flex-col"
              onSubmit={formMik.handleSubmit}
            >
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Email address*"
                value={formMik.values.email}
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formMik.errors.email && formMik.touched.email ? (
                <p className="w-full text-red-600 m-[-7px] font-medium">
                  {formMik.errors.email}
                </p>
              ) : null}
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password*"
                  value={formMik.values.password}
                  onChange={formMik.handleChange}
                  onBlur={formMik.handleBlur}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />

                <div
                  className="absolute inset-y-0 right-0 flex items-center mr-3 focus:outline-none"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? (
                    <IoEyeOutline className="text-black h-6 w-6" />
                  ) : (
                    <IoEyeOffOutline className="text-black h-6 w-6" />
                  )}
                </div>
              </div>
              {formMik.errors.password && formMik.touched.password ? (
                <p className="w-full text-red-600 m-[-7px] font-medium">
                  {formMik.errors.password}
                </p>
              ) : null}
              <button
                type="submit"
                className="text-white w-full bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                Continue
              </button>
              <div className="w-full flex text-sm gap-1">
                <p style={{ color: fontColor }}>Already have an account?</p>
                <Link to="/login">
                  <button style={{ color: fontColor }} className="font-bold">
                    Login
                  </button>
                </Link>
              </div>
            </form>
            {baseUrl == "https://app.closerx.ai" && (
              <>
                <div className="relative w-full">
                  <span className="absolute bg-gray-400 w-[45%] h-[1px] top-[59%] transform -translate-y-1/2 left-0"></span>
                  <p className="text-center">or</p>
                  <span className="absolute bg-gray-400 w-[45%] h-[1px] top-[59%] transform -translate-y-1/2 right-0"></span>
                </div>
                <Google />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
