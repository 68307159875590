import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BsThreeDots } from "react-icons/bs";
import { useSelector } from "react-redux";

const Members = ({ loading, setLoading }) => {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const companies = userData.companies;
  const full_name = `${userData?.first_name ?? ""} ${
    userData?.last_name ?? ""
  }`;
  const company_name = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [members, setMembers] = useState(null);
  const [activeMemberIndex, setActiveMemberIndex] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const loggedInUserEmail = userData.email;
  const { fontColor } = useSelector((state) => state.theme);

  const handleGetMember = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${baseUrl}/api/agency/users/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        setMembers(responseData.data.agents);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = async (e, index) => {
    const selectedRole = e.target.value;
    const memberId = members[index].id;

    try {
      const response = await fetch(`${baseUrl}/api/member_role_update/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ user_type: selectedRole, pk: memberId }),
      });
      if (response.ok) {
        const updatedMembers = [...members];
        updatedMembers[index].user_type = selectedRole;
        setMembers(updatedMembers);
        toast.success("Member role updated successfully");
      } else if (response.status === 400) {
        const responseData = await response.json();
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveMember = async (memberId) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/remove_agency_user/${memberId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        setRefresh(!refresh);
        setActiveMemberIndex(null);
        toast.success("Member removed successfully");
      } else if (response.status === 400) {
        const responseData = await response.json();
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetMember();
  }, [refresh, setRefresh]);

  function convertDateToDDMMYYYY(dateStr) {
    // Parse the input date string into a Date object
    let date = new Date(dateStr);
    
    // Extract the day, month, and year from the Date object
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    let year = date.getFullYear();
    
    // Format the date into the desired format
    let formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
}

  return (
    <div style={{ color: fontColor }} className="space-y-3 w-full p-5">
      <div className="space-y-5 mb-20">
        <h1 className="text-3xl">Members</h1>
        <p className="text-xl">View and manage organization members</p>
      </div>
      <div className="w-full overflow-x-scroll">
        <table
          style={{ color: fontColor }}
          className="w-full overflow-x-auto text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
        >
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr style={{ color: fontColor }}>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3 text-gray-900"
              >
                User
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3 text-gray-900"
              >
                Joined
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3 text-gray-900"
              >
                Role
              </th>
            </tr>
          </thead>
          <tbody>
            {members &&
              members.map((member, index) => (
                <tr
                  style={{ color: fontColor }}
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    style={{ color: fontColor }}
                    scope="row"
                    className="px-6 py-4 capitalize font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {member.first_name} {member.last_name}
                  </th>
                  <td className="px-6 py-4">{convertDateToDDMMYYYY(member.created_at)}</td>
                  <td className="px-6 py-4 flex justify-between items-center">
                    <select
                      value={member.user_type}
                      onChange={(e) => handleRoleChange(e, index)}
                    >
                      <option value="admin">Admin</option>
                      <option value="member">Member</option>
                    </select>

                    {activeMemberIndex === index ? (
                      <button
                        className="text-red-500"
                        onClick={() => handleRemoveMember(member.id)}
                        disabled={member.user == loggedInUserEmail}
                      >
                        Remove Member
                      </button>
                    ) : (
                      <BsThreeDots
                        onClick={() => setActiveMemberIndex(index)}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Members;
