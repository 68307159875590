import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { HiMiniPlay } from "react-icons/hi2";
import { HiMiniPause } from "react-icons/hi2";
import Agreement from "./Agreement";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../features/Refresh/refresh";
import { FaRegStopCircle } from "react-icons/fa";
import { Tooltip } from "@material-tailwind/react";
import toast from "react-hot-toast";


const CampaignList = () => {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const { fontColor } = useSelector((state) => state.theme);
  const refresh = useSelector((state) => state.refresh.refresh);
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState([]);
  const [refreshData, setRefreshData] = useState(true);
  const [statusFilter, setStatusFilter] = useState("");

  const togglePlayPause = async (campaignId, currentStatus) => {
    const statusType = currentStatus === "paused" ? "resume" : "pause";

    try {
      const response = await fetch(`${baseUrl}/api/campaign/${campaignId}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ status_type: statusType }),
      });

      if (response.ok) {
        setRefreshData(!refreshData);
        toast.success("Campaign Updated Successfully!");
      } else if (response.status === 400) {
        const responseData = await response.json();
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/campaign/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setResponseData(responseData.data["campaign lists"]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCampaign();
  }, [refreshData, refresh]);

  const localTime = (utcTimeString) => {
    const utcDate = new Date(utcTimeString);
    const localDate = utcDate.toLocaleDateString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return localDate;
  };

  const handleDelete = async (campaignId) => {
    try {
      const response = await fetch(`${baseUrl}/api/campaign/${campaignId}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        setResponseData((prevData) =>
          prevData.filter((campaign) => campaign.id !== campaignId)
        );
        dispatch(setRefresh(!refresh));
        toast.success("Campaign Ended successfully");
      } else {
        console.error("Failed to delete campaign");
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filteredData = responseData.filter(
    (campaign) => statusFilter === "" || campaign.status === statusFilter
  );

  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg m-6">
        <div className="m-4">
          <label style={{ color: fontColor }} htmlFor="statusFilter" className="mr-2">
            Filter by Status:
          </label>
          <select
            id="statusFilter"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="active">Active</option>
            <option value="running">Running</option>
            <option value="paused">Paused</option>
            <option value="completed">Completed</option>
          </select>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-4 py-3"
              >
                Campaign Name
              </th>
              <th style={{ color: fontColor }} scope="col" className=" py-3">
                Campaign Direction
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Status
              </th>
              <th style={{ color: fontColor }} scope="col" className=" py-3">
                Call Left
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Budget left
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-3 py-3"
              >
                Created Date
              </th>
              <th style={{ color: fontColor }} scope="col" className=" py-3">
                Pause/Resume
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                End
              </th>
            </tr>
          </thead>
          <tbody>
            {responseData &&
              responseData.length > 0 &&
              filteredData.map((response) => (
                <tr key={response.id} className="bg-white">
                  <td
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-6 py-3 capitalize"
                  >
                    {response.name}
                  </td>
                  <td
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-6 py-3"
                  >
                    {response.direction}
                  </td>
                  <td
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-6 py-3 capitalize"
                  >
                    {response.status}
                  </td>
                  <td
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-6 py-3"
                  >
                    {response.calls_done}/{response.total_calls}
                  </td>
                  <td
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-7 py-3"
                  >
                    {Math.round(response.bugget_left)}/{response.total_bugget}
                  </td>
                  <td
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-6 py-3"
                  >
                    {localTime(response.created_at)}
                  </td>
                  <td
                    style={{ color: fontColor }}
                    scope="col"
                    className="px-6 py-3"
                  >
                    {response.status === "paused" ? (
                      <HiMiniPlay
                        className="text-xl hover:text-blue-600 cursor-pointer"
                        onClick={() =>
                          togglePlayPause(response.id, response.status)
                        }
                      />
                    ) : (
                      <HiMiniPause
                        className="text-xl hover:text-blue-600 cursor-pointer"
                        onClick={() =>
                          togglePlayPause(response.id, response.status)
                        }
                      />
                    )}
                  </td>
                  <td scope="col" className="px-6 py-3">
                    <Tooltip content="End Campaign" placement="left">
                      <div>
                        <FaRegStopCircle
                          className="text-xl hover:text-red-600 cursor-pointer"
                          onClick={() => handleDelete(response.id)}
                        />
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CampaignList;
