import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useSelector } from "react-redux";

const MobVerify = (props) => {
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const [value, setValue] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otpValue, setOTPValue] = useState("");
  const [otpRes, setOtpRes] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(null);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const { fontColor } = useSelector((state) => state.theme);

  const sendOTP = async () => {
    if (!value) {
      toast.error("Please enter a valid phone number.");
      return;
    }
    setIsSending(true);
    try {
      const response = await fetch(`${baseUrl}/api/send_otp/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ "contact number": value }),
      });
      if (response.ok) {
        setShowOTPInput(true);
        setOtpRes("Security Code sent successfully!");
        setOtpSuccess(true);
        startResendTimer();
      } else {
        setOtpRes("Failed to send Security Code. Please try again.");
        setOtpSuccess(false);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
    setIsSending(false);
  };

  const handleVerifyOTP = async () => {
    if (!otpValue) {
      return;
    }
    try {
      const otpNumber = parseInt(otpValue, 10);
      const response = await fetch(`${baseUrl}/api/check_otp/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ otp: otpNumber }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setOtpRes("Security Code verified successfully!");
        setOtpSuccess(true);
        localStorage.setItem(
          "userData",
          JSON.stringify(responseData.data.user)
        );
        props.onSuccess();
      } else {
        setOtpRes("Failed to verify Security Code!");
        setOtpSuccess(false);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  const startResendTimer = () => {
    setIsResendDisabled(true);
    setResendTimer(30);
    const interval = setInterval(() => {
      setResendTimer((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setIsResendDisabled(false);
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <div className="fixed z-[999] top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center">
      <div className="bg-white rounded-lg p-4 max-w-lg w-full">
        <div className="w-full space-y-3">
          <div className="flex flex-col justify-start">
            <h1 style={{ color: fontColor }} className="flex justify-start text-2xl font-semibold">
              Create Account
            </h1>
            <p style={{ color: fontColor }} className="flex justify-start text-[14px] ">
              Please verify your phone number to continue
            </p>
          </div>
          <hr className="w-[100%] mx-auto border-t-2 border-gray-200" />
          <div className="flex flex-col space-y-4 w-full">
            <label style={{ color: fontColor }} className="flex justify-between items-center text-lg font-medium text-center">
              Phone Number{" "}
              <span className={`${otpSuccess ? "text-green-500" : "text-red-500"} uppercase text-sm mt-[2px] font-semibold`}>
                {otpRes}
              </span>
            </label>
            <div>
              <PhoneInput
                defaultCountry="usa"
                value={value}
                onChange={(value) => setValue(value)}
                inputClassName="w-[400px]"
              />
            </div>
            <p style={{ color: fontColor }} className="flex justify-start text-[14px] ">
              We'll send you a verification code to your phone number
            </p>
            {showOTPInput && (
              <>
                <label style={{ color: fontColor }} className="flex justify-start">Enter Security Code</label>
                <input
                  type="text"
                  value={otpValue}
                  onChange={(e) => setOTPValue(e.target.value)}
                  placeholder="Enter OTP"
                  className="w-full h-10 rounded-xl hover:bg-gray-200 px-4 bg-gray-100"
                />
              </>
            )}
          </div>
          <div className="flex justify-between items-end w-full p-1">
            {!showOTPInput && (
              <button
                className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 flex items-center justify-center"
                onClick={sendOTP}
                disabled={isSending}
              >
                {isSending ? "Sending..." : "Verify"}
              </button>
            )}
            {showOTPInput && (
              <button
                className="text-white float-left bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700flex items-center justify-center"
                onClick={sendOTP}
                disabled={isResendDisabled}
              >
                {isResendDisabled ? `Resend (${resendTimer}s)` : "Resend"}
              </button>
            )}
            {showOTPInput && (
              <button
                className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700flex items-center justify-center"
                onClick={handleVerifyOTP}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobVerify;
