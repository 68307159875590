import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fontColor: "",
  backgroundColor: "",
  logo: "",
  legalName: "",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setFontColor(state, action) {
      state.fontColor = action.payload;
    },
    setBackgroundColor(state, action) {
      state.backgroundColor = action.payload;
    },
    setLogo(state, action) {
      state.logo = action.payload;
    },
    setLegalName(state, action) {
      state.legalName = action.payload;
    },
  },
});

export const { setFontColor, setBackgroundColor, setLogo, setLegalName } =
  themeSlice.actions;
export default themeSlice.reducer;
