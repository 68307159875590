import React, { useState, useEffect } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useFormik } from "formik";
import {
  createCampaignStepOne,
  createCampaignStepThree,
  createCampaignStepTwo,
  createCampaignStepTwoInbound,
} from "../schemas/FormSchema";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../features/Refresh/refresh";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";

const CreateCampaign = ({ modalOpen, setModalOpen }) => {
  const { fontColor } = useSelector((state) => state.theme);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const refresh = useSelector((state) => state.refresh.refresh);
  const dispatch = useDispatch();
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("18:00");
  const [budget, setBudget] = useState("");
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [agentName, setAgentName] = useState("");
  const [callType, setCallType] = useState("");
  const [twilio, setTwilio] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [knowledgeBase, setKnowledgeBase] = useState([]);
  const [contactName, setContactName] = useState("");
  const [contactList, setContactList] = useState([]);
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [twilioList, setTwilioList] = useState(null);

  // Update the current time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  // Helper function to format the current time as HH:mm
  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  // Format current time to HH:mm for the input min attribute
  const formattedCurrentTime = formatTime(currentTime);

  useEffect(() => {
    const fetchKnowledgeBase = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/knowledge_base/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setKnowledgeBase(responseData.data["knowledge base"]);
        }
      } catch (error) {
        console.error("Error fetching knowledge base options:", error);
      }
    };

    const fetchContactList = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/contact/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setContactList(responseData.data["contact_lists"]);
        }
      } catch (error) {
        console.error("Error fetching knowledge base options:", error);
      }
    };

    const fetchAgents = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/agents/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setAgents(responseData.data.agents);
        }
      } catch (error) {
        console.error("Error fetching agents:", error);
      }
    };

    const fetchTwilio = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/twilio/number/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setTwilioList(responseData.data);
        }
      } catch (error) {
        console.error("Error fetching Twillio Number:", error);
      }
    };

    fetchContactList();
    fetchKnowledgeBase();
    fetchTwilio();
    fetchAgents();
  }, []);

  const formmik = useFormik({
    initialValues: {
      campaignName: "",
      agentName: "",
    },
    validationSchema: createCampaignStepOne,
    onSubmit: (values) => {
      setCampaignName(values.campaignName);
      setSelectedAgent(values.agentName);
      setCurrentStep(currentStep + 1);
    },
  });

  const formikStepTwo = useFormik({
    initialValues: {
      knowledgeBase: "",
      contactList: "",
      twilio: "",
      budget: "",
    },
    validationSchema:
      callType && callType != "Inbound"
        ? createCampaignStepTwo
        : createCampaignStepTwoInbound,
    onSubmit: async (values) => {
      setSelectedKnowledgeBase(values.knowledgeBase);
      setContactName(values.contactList);
      setTwilio(values.twilio);
      if (callType != "Inbound") {
        setCurrentStep(currentStep + 1);
      } else {
        setLoading(true);
        const formData = {
          name: campaignName,
          agent: parseInt(selectedAgent),
          knowledge_base: parseInt(values.knowledgeBase),
          calling_number: values.twilio,
          total_bugget: parseInt(values.budget),
          bugget_left: parseInt(values.budget),
        };
        setModalOpen(false);
        setCurrentStep(1);
        try {
          const response = await fetch(`${baseUrl}/api/campaign/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Company-Name": companyName,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify(formData),
          });
          if (response.ok) {
            formmik.values.campaignName = "";
            formmik.values.agentName = "";
            formikStepTwo.values.knowledgeBase = "";
            formikStepTwo.values.twilio = "";
            formikStepTwo.values.budget = "";
            dispatch(setRefresh(!refresh));
            toast.success("Campaign created successfully!");
          } else if (response.status === 400) {
            const responseData = await response.json();
            toast.error(responseData.message);
          } else {
            toast.error("Something went wrong!");
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const formmikStepThree = useFormik({
    initialValues: {
      startDate: new Date(),
      endTime: "",
      startTime: "",
      budget: "",
    },
    validationSchema: createCampaignStepThree,
    onSubmit: async (values) => {
      setLoading(true);
  
      // Use current date and time if startDate and startTime are not provided
      const now = new Date();
      const startDate = values.startDate || now;
      const startTime = values.startTime || now.toISOString().split('T')[1].slice(0, 5);
  
      // Parse the input date and time string
      const dateObj = new Date(startDate);
      const utcDateTime = dateObj.toISOString();
      const [dateStart, timeWithMilliseconds] = utcDateTime.split("T");
      const [hour, min] = timeWithMilliseconds.split(":").slice(0, 2); // Take only the hour and minute parts
      const time = `${hour}:${min}`;
  
      // console.log("Date:", dateStart);
      // console.log("Time:", time);
  
      // Set endTime to 23 hours 58 minutes after startTime if not provided
      const endDate = new Date(dateObj);
      if (values.endTime) {
        const [endHours, endMinutes] = values.endTime.split(":");
        endDate.setHours(endHours);
        endDate.setMinutes(endMinutes);
      } else {
        endDate.setHours(endDate.getHours() + 23);
        endDate.setMinutes(endDate.getMinutes() + 58);
      }
      endDate.setSeconds(0);
      const endTime = endDate.toISOString();
      const result = endTime.slice(11, 16);
  
      const formData = {
        name: campaignName,
        agent: parseInt(selectedAgent),
        contact_list: contactName,
        knowledge_base: parseInt(selectedKnowledgeBase),
        calling_number: twilio,
        start_date: dateStart,
        start_time: time,
        end_time: result,
        total_bugget: parseInt(values.budget),
        bugget_left: parseInt(values.budget),
        saturday: saturday,
        sunday: sunday,
      };
  
      setModalOpen(false);
      setCurrentStep(1);
  
      try {
        const response = await fetch(`${baseUrl}/api/campaign/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          formmikStepThree.resetForm();
          dispatch(setRefresh(!refresh));
          toast.success("Campaign created successfully!");
        } else if (response.status === 400) {
          const responseData = await response.json();
          toast.error(responseData.message);
        } else {
          toast.error("Something went wrong!");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });
  

  useEffect(() => {
    if (formmik.values.agentName) {
      const agent = agents.find(
        (agent) => agent.id == formmik.values.agentName
      );
      if (agent) {
        setAgentName(agent.name);
        setCallType(agent.direction);
      }
    }
  }, [agents, formmik.values.agentName]);

  const closeModal = () => {
    setModalOpen(false);
    setCurrentStep(1);
    formmik.values.campaignName = "";
    formmik.values.agentName = "";
    setCallType("");
    formikStepTwo.values.knowledgeBase = "";
    formikStepTwo.values.twilio = "";
    formikStepTwo.values.budget = "";
    formikStepTwo.values.contactList = "";
    formmikStepThree.values.startDate = new Date();
    formmikStepThree.values.endTime = "";
    formmikStepThree.values.startTime = "";
    formmikStepThree.values.budget = "";
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    setModalOpen(true);
  };

  const handleAgentChange = (event) => {
    setSelectedAgent(event.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSaturday(true);
    } else {
      setSaturday(false);
      setSaturday(true);
    }
  };

  const handleCheckboxChange2 = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSunday(true);
    } else {
      setSunday(false);
      setSunday(true);
    }
  };

  function getMinimumDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <form onSubmit={formmik.handleSubmit}>
            <div className="flex flex-wrap justify-start items-center">
              <div className="flex w-full justify-between items-center">
                {currentStep > 1 && (
                  <button
                    onClick={handlePrevious}
                    type="button"
                    className="text-3xl"
                  >
                    <MdOutlineArrowBackIos />
                  </button>
                )}
              </div>
              <div className="basis-full p-4 flex justify-start items-center">
                <label
                  style={{ color: fontColor }}
                  htmlFor="campaignName"
                  className="font-medium w-28 block text-left"
                >
                  Campaign Name* :
                </label>
                <div className="flex-1">
                  <input
                    type="text"
                    id="campaignName"
                    value={formmik.values.campaignName}
                    onChange={formmik.handleChange}
                    onBlur={formmik.handleBlur}
                    name="campaignName"
                    placeholder="Campaign Name*"
                    className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formmik.errors.campaignName &&
                  formmik.touched.campaignName ? (
                    <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                      {formmik.errors.campaignName}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="basis-full p-4 flex justify-start items-center">
                <label
                  style={{ color: fontColor }}
                  htmlFor="agentName"
                  className="font-medium w-28 text-left block"
                >
                  Agent*
                </label>
                <div className="flex-1">
                  <select
                    id="agentName"
                    name="agentName"
                    value={formmik.values.agentName}
                    onChange={formmik.handleChange}
                    onBlur={formmik.handleBlur}
                    className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="" disabled>
                      Select an agent
                    </option>
                    {agents.map((agent) => (
                      <option key={agent.id} value={agent.id}>
                        {agent.name}
                      </option>
                    ))}
                  </select>
                  {formmik.errors.agentName && formmik.touched.agentName ? (
                    <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                      {formmik.errors.agentName}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="basis-full p-4 flex justify-between items-center">
                <label
                  htmlFor="callType"
                  style={{ color: fontColor }}
                  className="font-medium w-28 text-left block"
                >
                  Call Type
                </label>
                <input
                  id="callType"
                  name="callType"
                  value={callType}
                  onChange={handleAgentChange}
                  disabled={true}
                  className="bg-gray-100 cursor-not-allowed flex-1 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="flex justify-end items-center w-full">
                <button
                  // onClick={handleContinue}
                  type="submit"
                  className="text-white inline-flex justify-end bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
        );
      case 2:
        return (
          <form onSubmit={formikStepTwo.handleSubmit}>
            <div className="flex flex-wrap justify-start items-center">
              <div className="flex w-full justify-between items-center"></div>
              <div className="basis-full p-4 flex justify-start items-center">
                <label
                  htmlFor="knowledgeBase"
                  style={{ color: fontColor }}
                  className="font-medium w-28 block text-left"
                >
                  Knowledge Base
                </label>
                <div className="flex-1">
                  <select
                    id="knowledgeBase"
                    name="knowledgeBase"
                    value={formikStepTwo.values.knowledgeBase}
                    onChange={formikStepTwo.handleChange}
                    onBlur={formikStepTwo.handleBlur}
                    // onChange={(e) => setSelectedKnowledgeBase(e.target.value)}
                    className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Select a knowledge base</option>
                    {knowledgeBase &&
                      knowledgeBase.length > 0 &&
                      knowledgeBase.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </select>
                  {formikStepTwo.errors.knowledgeBase &&
                  formikStepTwo.touched.knowledgeBase ? (
                    <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                      {formikStepTwo.errors.knowledgeBase}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="basis-full p-4 flex justify-between items-center">
                <label
                  htmlFor="twilio"
                  style={{ color: fontColor }}
                  className="font-medium w-28 text-left block"
                >
                  Twilio Number
                </label>
                <div className="flex-1">
                  {twilioList && twilioList.length > 0 && (
                    <select
                      id="twilio"
                      name="twilio"
                      value={formikStepTwo.values.twilio}
                      onChange={formikStepTwo.handleChange}
                      onBlur={formikStepTwo.handleBlur}
                      className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="">Select a Contact Number</option>
                      {twilioList.map((option) => (
                        <option key={option.id} value={option.contact_number}>
                          {option.contact_number}
                        </option>
                      ))}
                    </select>
                  )}
                  {formikStepTwo.errors.twilio &&
                  formikStepTwo.touched.twilio ? (
                    <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                      {formikStepTwo.errors.twilio}
                    </p>
                  ) : null}
                </div>
              </div>

              {callType && callType == "Inbound" ? (
                <div className="basis-full p-4 flex justify-center items-center">
                  <label
                    htmlFor="budget"
                    style={{ color: fontColor }}
                    className="font-medium w-28 block text-left"
                  >
                    Budget
                  </label>
                  <div className="flex-1">
                    <input
                      type="number"
                      id="budget"
                      min={0}
                      value={formikStepTwo.values.budget}
                      onChange={formikStepTwo.handleChange}
                      onBlur={formikStepTwo.handleBlur}
                      // onChange={(e) => setBudget(e.target.value)}
                      className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    {formikStepTwo.errors.budget &&
                    formikStepTwo.touched.budget ? (
                      <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                        {formikStepTwo.errors.budget}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="basis-full p-4 flex justify-start items-center">
                  <label
                    htmlFor="Contact List"
                    style={{ color: fontColor }}
                    className="font-medium w-28 block text-left"
                  >
                    Contact List
                  </label>
                  <div className="flex-1">
                    {/* {contactList && contactList.length > 0 && ( */}
                    <select
                      type="value"
                      id="contactList"
                      name="contactList"
                      value={formikStepTwo.values.contactList}
                      onChange={formikStepTwo.handleChange}
                      onBlur={formikStepTwo.handleBlur}
                      // onChange={(e) => setContactName(e.target.value)}
                      className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="">Select a Contact List</option>
                      {contactList &&
                        contactList.length > 0 &&
                        contactList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                    {/* )} */}
                    {formikStepTwo.errors.contactList &&
                    formikStepTwo.touched.contactList ? (
                      <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                        {formikStepTwo.errors.contactList}
                      </p>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="flex justify-end items-center w-full">
                <button
                  // onClick={handleContinue}
                  type="submit"
                  className="text-white inline-flex justify-end bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
        );
      case 3:
        return (
          <form
            onSubmit={formmikStepThree.handleSubmit}
            className="flex flex-wrap justify-start items-center"
          >
            <div className="basis-full p-4 flex justify-center items-center">
              <label
                htmlFor="startDate"
                style={{ color: fontColor }}
                className="font-medium w-28 block text-center basis-1/2 lg:pl-32"
              >
                Start Date
              </label>
              <div className="basis-1/2">
                <input
                  type="datetime-local"
                  id="startDate"
                  name="startDate"
                  value={formmikStepThree.values.startDate}
                  onChange={formmikStepThree.handleChange}
                  onBlur={formmikStepThree.handleBlur}
                  min={getMinimumDateTime()}
                  // onChange={(e) => setStartDate(e.target.value)}
                  className="bg-gray-50 lg:w-[40%] border  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                {formmikStepThree.errors.startDate &&
                formmikStepThree.touched.startDate ? (
                  <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                    {formmikStepThree.errors.startDate}
                  </p>
                ) : null}
              </div>
            </div>

            {/* <div className="basis-full p-4 flex justify-center items-center">
              <label
                htmlFor="name"
                style={{ color: fontColor }}
                className="font-medium w-28 text-center block basis-1/2 lg:pl-32"
              >
                Start Time
              </label>
              <div className="relative basis-1/2">
                <input
                  type="time"
                  id="startTime"
                  min={formattedCurrentTime}
                  value={formmikStepThree.values.startTime}
                  onChange={formmikStepThree.handleChange}
                  onBlur={formmikStepThree.handleBlur}
                  // onChange={(e) => setStartTime(e.target.value)}
                  className="bg-gray-50 border leading-none border-gray-300 lg:w-[40%] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                {formmikStepThree.errors.startTime &&
                formmikStepThree.touched.startTime ? (
                  <p className="w-full text-red-600 text-left m-[7px] mb-0 capitalize font-medium">
                    {formmikStepThree.errors.startTime}
                  </p>
                ) : null}
              </div>
            </div> */}
            <div className="basis-full p-4 flex justify-center items-center">
              <label
                htmlFor="endTime"
                style={{ color: fontColor }}
                className="font-medium w-28 text-center block basis-1/2 lg:pl-32"
              >
                End Time
              </label>
              <div className="relative basis-1/2">
                <input
                  type="time"
                  id="endTime"
                  value={formmikStepThree.values.endTime}
                  onChange={formmikStepThree.handleChange}
                  onBlur={formmikStepThree.handleBlur}
                  // onChange={(e) => setEndTime(e.target.value)}
                  className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block lg:w-[40%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                {formmikStepThree.errors.endTime &&
                formmikStepThree.touched.endTime ? (
                  <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                    {formmikStepThree.errors.endTime}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="basis-full p-4 flex justify-center items-center">
              <label
                htmlFor="budget"
                style={{ color: fontColor }}
                className="font-medium w-28 text-center block basis-1/2 lg:pl-32"
              >
                Budget
              </label>
              <div className="basis-1/2">
                <input
                  type="number"
                  id="budget"
                  min={0}
                  value={formmikStepThree.values.budget}
                  onChange={formmikStepThree.handleChange}
                  onBlur={formmikStepThree.handleBlur}
                  // onChange={(e) => setBudget(e.target.value)}
                  className="bg-gray-50 border leading-none border-gray-300 w-36 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                {formmikStepThree.errors.budget &&
                formmikStepThree.touched.budget ? (
                  <p className="w-full text-red-600 text-left m-[7px] mb-0 font-medium">
                    {formmikStepThree.errors.budget}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="basis-full p-4 flex justify-center items-center">
              <div className="flex justify-center items-center gap-6">
                <label style={{ color: fontColor }}>Also run on weekend</label>
                <div className="flex items-center justify-center gap-2">
                  <input
                    type="checkbox"
                    value="Saturday"
                    className="rounded-lg"
                    onChange={handleCheckboxChange}
                  />
                  <p style={{ color: fontColor }}>Saturday</p>
                  <input
                    type="checkbox"
                    value="Sunday"
                    className="rounded-lg"
                    onChange={handleCheckboxChange2}
                  />
                  <p style={{ color: fontColor }}>Sunday</p>
                </div>
              </div>
            </div>
            <div className="flex justify-evenly items-center w-full">
              <button
                // onClick={handleContinue}
                type="submit"
                className="text-white inline-flex justify-end bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Continue
              </button>
            </div>
          </form>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    formmik.setValues({
      campaignName: "",
      agentName: "",
    });

    formikStepTwo.setValues({
      knowledgeBase: "",
      twilio: "",
      budget: "",
      contactList: "",
    });

    formmikStepThree.setValues({
      startDate: new Date(),
      endTime: "",
      budget: "",
      startTime: "",
    });

    setCallType("");
  }, [modalOpen]);

  return (
    <>
      {modalOpen && (
        <div className="fixed inset-0 z-20 overflow-auto bg-black bg-opacity-40 flex justify-center items-center p-8">
          <div className="xl:w-[50%] w-full 2xl:max-h-[85%] bg-white custom-shadow flex flex-col 2xl:flex-row  p-1 rounded-xl relative">
            <div className="flex p-1 md:p-3">
              <div className="flex flex-wrap justify-start items-center">
                <div className="flex w-full justify-between items-center">
                  {currentStep > 1 && (
                    <button
                      onClick={handlePrevious}
                      type="button"
                      className="text-2xl mr-auto"
                    >
                      <MdOutlineArrowBackIos />
                    </button>
                  )}
                  <button
                    type="button"
                    className="text-3xl ml-auto"
                    onClick={closeModal}
                  >
                    <svg
                      className="w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                  </button>
                </div>
                {renderStepContent()}
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="w-full z-[999] h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
};

export default CreateCampaign;
