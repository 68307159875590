import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { importAgentSchema } from "../schemas/FormSchema";
import { useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { setRefresh } from "../features/Refresh/refresh";

function ImportAgent() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const company_name = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch()
  const refresh = useSelector(state => state.refresh.refresh);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const formMik = useFormik({
    initialValues: {
      companyName: "",
      agentID: "",
    },
    validationSchema: importAgentSchema,
    onSubmit: async (values,{resetForm}) => {
      try {
        const response = await fetch(`${baseUrl}/api/import_agent/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            agency_name:values.companyName.trim(),
            agent_id: values.agentID
          }),
        });
        if (response.ok) {
            toast.success("Agent imported successfully")
            toggleModal()
            resetForm()
            dispatch(setRefresh(!refresh))
        }
        else if(response.status === 400) {
            toast.error("Invalid Company Name or Agent ID")
        }
        else {
            toast.error("Something went wrong")
        }
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong")
      }
    },
  });

  return (
    <>
    <div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },

            // Default options for specific types
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
      </div>
      <button
        style={{ color: fontColor }}
        onClick={toggleModal}
        className="relative mr-3 inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
        type="button"
      >
        <span
          style={{ backgroundColor: backgroundColor }}
          className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
        >
          Import Agent
        </span>
      </button>

      <div
        className={`${
          isModalVisible ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          isModalVisible ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative px-2 py-4 bg-white rounded-lg shadow dark:bg-gray-700">
            <div
              className={`basis-full w-full px-2 py-2 flex justify-between items-center`}
            >
              <h1 className="font-bold">Import Agent</h1>
              <button type="button" className="text-3xl" onClick={toggleModal}>
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div>
              <form
                onSubmit={formMik.handleSubmit}
                className="flex flex-wrap justify-start items-center"
              >
                <div className="basis-full p-4 flex justify-start items-center">
                  <label
                    htmlFor="agentName"
                    style={{ color: fontColor }}
                    className="font-medium basis-[20%] block text-left"
                  >
                    Company Name:
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    placeholder="Company Name*"
                    value={formMik.values.companyName}
                    onChange={formMik.handleChange}
                    onBlur={formMik.handleBlur}
                    className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                {formMik.errors.companyName && formMik.touched.companyName ? (
                  <p className="w-full text-red-600 m-[-7px] font-medium text-left ml-4">
                    {formMik.errors.companyName}
                  </p>
                ) : null}
                <div className="basis-full p-4 flex justify-between items-center">
                  <label
                    style={{ color: fontColor }}
                    htmlFor="name"
                    className="font-medium basis-[20%] text-left block"
                  >
                    Agent ID:
                  </label>
                  <input
                    type="number"
                    min={0}
                    id="agentID"
                    name="agentID"
                    placeholder="Agent ID"
                    value={formMik.values.agentID}
                    onChange={formMik.handleChange}
                    onBlur={formMik.handleBlur}
                    className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                {formMik.errors.agentID && formMik.touched.agentID ? (
                  <p className="w-full text-red-600 m-[-7px] font-medium text-left ml-4">
                    {formMik.errors.agentID}
                  </p>
                ) : null}
                <div className="flex justify-center items-center w-full gap-4 mt-2">
                  <button
                    // onClick={handleContinue}
                    type="submit"
                    className="text-white inline-flex justify-center bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportAgent;
