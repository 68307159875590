import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import PageHeader from "../components/PageHeader";
import { useNotifications } from "../utils/NotificationsContext";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { firestore } from "../utils/firebase";

function SuperAdminNotification() {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [heading, setHeading] = useState("");
  const [message, setMessage] = useState("");
  const [pageHeaderData, setPageHeaderData] = useState([
    {
      tittle: "logs",
      link: "/superAdmin",
    },
    {
      tittle: "Users",
      link: "/superAdmins/user-view",
    },
    {
      tittle: "Notification",
      link: "/superAdmins/notification",
    },
  ]);
  const { notifications, unreadCount, markAsRead, userId } = useNotifications();

  const addNotification = async () => {
    if (heading.trim() === "" || message.trim() === "") return;

    const notificationsRef = collection(firestore, "notifications");

    await addDoc(notificationsRef, {
      heading,
      message,
      timestamp: serverTimestamp(),
      read: {}, // Initially no users have read the notification
    });

    setHeading("");
    setMessage("");
  };

  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full"
      >
        <Navbar />
        <div className="flex-1 p-4">
          <PageHeader buttons={pageHeaderData} />
          <div className="max-w-2xl mx-auto mt-4">
            <label htmlFor="heading" className="my-2">Enter notification heading</label>
            <input
              type="text"
              id="heading"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              placeholder="Enter notification heading"
              className="border p-2 w-full mb-2 rounded-md"
            />
            <label htmlFor="message" className="">Enter notification message</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter your notification message"
              className="border p-2 w-full mb-2 rounded-md"
            />
            <button
              className="bg-blue-500 text-white p-2 rounded mt-2"
              onClick={addNotification}
            >
              Send Notification
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuperAdminNotification;
