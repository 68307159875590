import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import "../App.css";
import { useSelector } from "react-redux";
const baseUrl = import.meta.env.VITE_GOOGLE_API_URL;

const SocialAuth = () => {
  // const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  let location = useLocation();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;

    if (code) {
      onGogglelogin();
    }
  }, []);

  const googleLoginHandler = async (code) => {
    return axios
      .get(`${baseUrl}/api/auth/google/${code}`, {
        tanet: "public",
      })
      .then((res) => {
        localStorage.setItem("accessToken", res.data.data.access_token);
        localStorage.setItem("refreshToken", res.data.data.refresh_token);
        localStorage.setItem("userData", JSON.stringify(res.data.data.user));
        if (res.data.success) {
          if (!res.data.data.user.last_name) {
            navigate("/onboarding");
          } else if (res.data.data.user.companies.length == 0) {
            navigate("/onboarding");
          } else if (!res.data.data.user.contact_num_verifed) {
            navigate("/create/agent");
          } else {
            navigate("/dashboard");
          }
        }
        return res.data;
      })
      .catch((err) => {
        setError(err);
        return err;
      });
  };

  const onGogglelogin = async () => {
    const response = await googleLoginHandler(location.search);
  };

  return (
    <div className="loading-icon-container">
      <div className="loading-icon">
        <div className="loading-icon__circle loading-icon__circle--first"></div>
        <div className="loading-icon__circle loading-icon__circle--second"></div>
        <div className="loading-icon__circle loading-icon__circle--third"></div>
        <div className="loading-icon__circle loading-icon__circle--fourth"></div>
      </div>
      <small className=" text-center mr-2">Just a moment</small>
    </div>
  );
};

export default SocialAuth;
