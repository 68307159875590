import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { FaMicrophone } from "react-icons/fa6";
import { useSelector } from "react-redux";

function RecordCloneVoice() {
  const [isRecording, setIsRecording] = useState(false);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [playAudio, setPlayAudio] = useState(false); // State to control audio playback
  const [audioEnded, setAudioEnded] = useState(false); // State to track if audio has ended
  const [fileName, setFileName] = useState("recorded_voice"); // State for file name
  const [fileNameError, setFileNameError] = useState(null); // State for file name error
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null); // Ref to store the media stream
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const audioRef = useRef(null); // Ref for the audio element

  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        streamRef.current = stream; // Store the media stream in ref
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (e) => {
          setRecordedBlob(e.data);
          setAudioUrl(URL.createObjectURL(e.data)); // Create URL for audio playback
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    } else {
      console.error("MediaDevices API not supported");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);

      // Stop all media tracks to release the microphone
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    }
  };

  const uploadVoice = async (blob) => {
    setUploading(true);
    setUploadError(null);

    const formData = new FormData();
    formData.append("file", blob, `${fileName}.wav`); // Use the fileName state for the file name
    formData.append("voice_name", fileName); 
    formData.append("type", "file"); 

    try {
      const response = await fetch(`${baseUrl}/api/clone/voice/`, {
        method: "POST",
        headers:{
            Accept: "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: formData,
      });

      const responseData = await response.json();
      if (!response.ok) {
        toast.error(responseData.message || "Something went wrong!!");
        return;
      }

      toast.success("Voice Cloned successfully");
      setFileName("");
      setRecordedBlob(null); // Reset recordedBlob
      setAudioUrl(null); // Reset audioUrl
      setIsRecording(false); // Reset isRecording
    } catch (error) {
      console.error("Upload error:", error);
      setUploadError(error.message);
    } finally {
      setUploading(false);
    }
  };

  const handleUpload = () => {
    if (!fileName.trim()) {
      setFileNameError("File name cannot be empty."); // Set error message if file name is empty
      return;
    }

    setFileNameError(null); // Reset error message
    if (recordedBlob) {
      uploadVoice(recordedBlob);
    }
  };

  const handlePlayAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
    setPlayAudio(true); // Update playAudio state
    setAudioEnded(false); // Reset audioEnded state when playing
  };

  const handlePauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    setPlayAudio(false); // Update playAudio state
  };

  const handleAudioEnded = () => {
    setPlayAudio(false); // Show play button when audio ends
    setAudioEnded(true); // Update audioEnded state
  };

  return (
    <>
    <div className="basis-1/3 flex flex-col justify-end">
      <h2>Clone voice by recording</h2>
      <div className="flex flex-col mt-4 gap-4 items-center">
        <button
          onClick={isRecording ? stopRecording : startRecording}
          className={`p-2 ${
            isRecording ? "bg-red-500" : "bg-green-500"
          } text-white rounded`}
        >
          {isRecording ? "Stop Recording" : "Start Recording"}
        </button>
        <FaMicrophone
          className={`text-7xl ${
            isRecording ? "text-blue-500" : "text-gray-500"
          }`}
        />
        {recordedBlob && !isRecording && (
          <div className="flex flex-col lg:flex-row w-full justify-center lg:items-start gap-4 mt-4">
            <div>
              <input
                type="text"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                className="p-2 w-full border rounded"
                placeholder="Enter file name"
              />
              {fileNameError && <p className="text-red-500">{fileNameError}</p>}{" "}
              {/* Display file name error */}
            </div>
            <button
              onClick={playAudio ? handlePauseAudio : handlePlayAudio}
              className={`p-2 ${
                playAudio ? "bg-yellow-500" : "bg-blue-500"
              } text-white rounded`}
            >
              {playAudio ? "Pause Recording" : "Play Recording"}
            </button>
            <button
              onClick={handleUpload}
              className="lg:ml-4 p-2 bg-blue-500 text-white rounded"
            >
              Upload Recorded Voice
            </button>
            <audio
              ref={audioRef}
              src={audioUrl}
              className="hidden" // Hide the audio element
              onEnded={handleAudioEnded} // Add onEnded event handler
            >
              Your browser does not support the <code>audio</code> element.
            </audio>
          </div>
        )}
        {uploading && <p>Uploading...</p>}
        {uploadError && <p className="text-red-500">Error: {uploadError}</p>}
      </div>
      </div>
    </>
  );
}

export default RecordCloneVoice;
