import React, { useState } from "react";
import { MdErrorOutline } from "react-icons/md";

function RecordingError({ data }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    <>
      <div className="flex items-center gap-2">
        <MdErrorOutline
          className="hover:cursor-pointer text-xl"
          onClick={() => openPopup()}
        />
      </div>
      {isPopupOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto overflow-x-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="relative w-full max-w-2xl mx-auto max-h-[660px] p-5 bg-white rounded-lg">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closePopup}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="min-h-32 mt-5 w-full text-left overflow-y-auto max-h-96">
              {data || "No Error"}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RecordingError;
