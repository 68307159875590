import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function ProtectedRoute({ Component, isSuperadminRoute }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [isSuperadmin, setIsSuperadmin] = useState(true);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  const companyName = userData?.companies?.[0]?.company_name;

  useEffect(() => {
    const handleGetUser = async () => {
      if (!accessToken) {
        setLoading(false);
        return;
      }

      const userID = userData?.id;
      try {
        const response = await fetch(`${baseUrl}/api/user/${userID}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 401) {
          await refreshAccessToken();
        }
      } catch (error) {
        console.error(error);
      }
    };

    const refreshAccessToken = async () => {
      if (!refreshToken) return;

      try {
        const response = await fetch(`${baseUrl}/api/token/refresh/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${refreshToken}`,
          },
          body: JSON.stringify({ refresh: refreshToken }),
        });

        if (response.ok) {
          const responseData = await response.json();
          const newAccessToken = responseData.access;
          localStorage.setItem("accessToken", newAccessToken);
        }
      } catch (error) {
        console.error("Error refreshing access token:", error.message);
      }
    };

    handleGetUser();
    const cookieSuperadmin = localStorage.getItem("is_admin"); // Correctly check for boolean
    setIsSuperadmin(cookieSuperadmin);

    setLoading(false);
  }, [accessToken, baseUrl, refreshToken, userData]);

  if (!accessToken || (location.pathname !== "/onboarding" && !companyName)) {
    return <Navigate to="/login" />;
  }

  if (isSuperadminRoute && !JSON.parse(Cookies.get("superAdmin"))) {
    console.log(isSuperadminRoute, !JSON.parse(localStorage.getItem("is_admin")));

    return <Navigate to="/dashboard" />;
  }

  return <Component />;
}

export default ProtectedRoute;
