import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  run: false,
  stepIndex: 0,
  steps: [
    {
      target: ".my-first-step",
      content: "Click on create tab",
      placement: "bottom",
      disableBeacon: true,
      disableOverlayClose: true,
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      //   target: menu.current!,
      title: "Menu",
    },
    {
      target: ".dummy",
      content: "Click on create tab",
      placement: "bottom",
      disableBeacon: true,
      disableOverlayClose: true,
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      //   target: menu.current!,
      title: "Menu",
    },
    {
      target: ".my-first-sub-step",
      content:
        "Here, you can build your custom agent. Choose a name, set the direction, build the script, and add functionalities like calendar integration, SMS, live transfer, notes, and goal tracking.",
      placement: "bottom",
    },
    {
      target: ".my-first-sub-step-two",
      content:
        "Attach your knowledge base in the form of a URL or PDF/doc file. This helps your agent handle objections, provide product summaries, or share any other relevant information.",
      placement: "bottom",
    },
    {
      target: ".my-first-sub-step-three",
      content:
        "Import your contacts to set up campaigns. Include fields like email, phone number, name, industry, or custom fields tailored to your needs.",
      placement: "bottom",
    },
    {
      target: ".my-second-step",
      content:
        "Build and set up your campaign here. Select your agent, attach the knowledge base, import lists, set timings, choose your Twilio number, and budget to get your campaign started.",
      placement: "bottom",
    },
    {
      target: ".my-third-step",
      content:
        "Get detailed analytics for each campaign and individual call. Track statuses, durations, outcomes, transcriptions, notes, and much more to stay informed.",
      placement: "bottom",
    },
    {
      target: ".my-fourth-step",
      content:
        "Check your current credit balance and view detailed financial history. Stay on top of your credits and spending.",
      placement: "bottom",
    },
    {
      target: ".my-fiveth-step",
      content: "Click on profile tab",
      placement: "bottom",
      spotlightClicks: true,
      disableBeacon: false,
    },
    {
      target: ".my-fiveth-substep",
      content:
        "Update your personal profile here. Keep your information current and accurate.",
      placement: "left",
      disableBeacon: false,
    },
    {
      target: ".my-fiveth-substep-two",
      content:
        "Invite team members and manage their authority levels. Collaborate easily with your team.",
      placement: "left",
      disableBeacon: false,
    },
    {
      target: ".my-fiveth-substep-three",
      content:
        "Manage all your integrations in this tab. Connect with the tools and services you use.",
      placement: "left",
      disableBeacon: false,
    },
    {
      target: ".my-fiveth-substep-four",
      content:
        "Check details of all bookings fixed here. Stay organized and on top of your schedule.",
      placement: "left",
      disableBeacon: false,
    },
    // Add more steps as needed
  ],
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    startTour: (state) => {
      state.run = true;
    },
    stopTour: (state) => {
      state.run = false;
    },
    nextStep: (state) => {
      state.stepIndex += 1;
    },
    prevStep: (state) => {
      state.stepIndex -= 1;
    },
    resetTour: (state) => {
      state.stepIndex = 0;
    },
    updateStepIndex: (state, action) => {
      state.stepIndex = action.payload;
    },
  },
});

export const {
  startTour,
  stopTour,
  nextStep,
  prevStep,
  resetTour,
  updateStepIndex,
} = tourSlice.actions;

export default tourSlice.reducer;
