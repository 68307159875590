import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function InviteTeam() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const { fontColor } = useSelector((state) => state.theme);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSendInvitation = () => {
    if (!role) {
      window.alert("Please select an option");
      return;
    }
    const company_name = JSON.parse(localStorage.getItem("userData"))
      ?.companies[0]?.company_name;

      
    fetch(`${baseUrl}/api/invite/member/`, {
      method: "POST",
      headers: {
        "Company-Name": company_name,
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({ email, user_type: role }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Invitations sent successfully!");
        } else if (response.status === 400) {
          // const responseData = await response.json();
          toast.error("Something went wrong!");
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((error) => {
        console.error("Error sending invitations:", error);
      });
  };

  return (
    <div style={{ color: fontColor }} className="rounded-xl p-5">
      <h1 className="font-bold text-xl">Invite team members</h1>
      <div className="w-full">
        <h3 className="font-semibold">Email addresses</h3>
        <p style={{ color: fontColor }} className="text-gray-600 py-2">
          Enter or paste email address.
        </p>
        <div className="border w-full p-2 flex justify-start items-start gap-2 min-h-20 border-[#6B7280] rounded-lg">
          <input
            type="text"
            value={email}
            onChange={handleEmailChange}
            className="flex-1 rounded-md focus:ring-black outline-none focus:border-black"
          />
        </div>
        <div className="mt-3">
          <label
            style={{ color: fontColor }}
            className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
            htmlFor="roles"
          >
            Role
          </label>
          <select
            id="roles"
            value={role}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={handleRoleChange}
          >
            <option value="">Select an option</option>
            <option value="member">Member</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <div className="mt-5 flex justify-center md:justify-end text-gray-800 gap-5 items-center">
          <button
            type="button"
            className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
            onClick={handleSendInvitation}
          >
            Send Invitations
          </button>
        </div>
      </div>
    </div>
  );
}

export default InviteTeam;
