// import { useFormik } from "formik";
// import React, { useEffect, useState } from "react";
// import { MdOutlineClose } from "react-icons/md";
// import { useSelector, useDispatch } from "react-redux";
// import { liveTransferSchema } from "../schemas/FormSchema";
// import toast, { Toaster } from "react-hot-toast";
// import { setRefresh } from "../features/Refresh/refresh";
// import { setSaveScript } from "../features/Agents/saveScript";

// function LiveTransfer({ data }) {
//   const refresh = useSelector((state) => state.refresh.refresh);
//   const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
//   const [showPopUp, setShowPopUp] = useState(false);
//   const { fontColor } = useSelector((state) => state.theme);
//   const agentID = localStorage.getItem("agentID");
//   const company_name = JSON.parse(localStorage.getItem("userData"))?.companies[0]
//     ?.company_name;
//   const retrievedString = localStorage.getItem("scriptData");
//   const retrievedArrayOfScript = JSON.parse(retrievedString);
//   const dispatch = useDispatch();
//   const [step, setStep] = useState(2);

//   const states = retrievedArrayOfScript?.map((item) => item.State);
//   const statesOption = states && states.slice(1);

//   const handleToggle = () => {
//     setShowPopUp(!showPopUp);
//   };

//   const formik = useFormik({
//     initialValues: {
//       number: "",
//       triggerState: "",
//       rejectionMessage: "",
//     },
//     validationSchema: liveTransferSchema,
//     onSubmit: async (values, { resetForm }) => {
//       try {
//         const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
//           method: "PATCH",
//           headers: {
//             "content-type": "application/json",
//             "Company-Name": company_name,
//             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//           },
//           body: JSON.stringify({
//             transfer_number: values.number,
//             transfer_trigger_state: values.triggerState,
//             transfer_failed_message: values.rejectionMessage,
//           }),
//         });
//         if (response.ok) {
//           const script = JSON.parse(localStorage.getItem("scriptData"));
//           const stateToUpdate = script.find(
//             (state) => state.State === values.triggerState
//           );

//           const value = `Trigger if user response to ${values.triggerState} is ${values.rejectionMessage}
// <function_calls>
// <invoke>
// <function_name>transfer_call</function_name>
// <parameters>
// <original_message>User's original message goes here</original_message>
// </parameters>
// </invoke>
// </function_calls>`;

//           if (stateToUpdate) {
//             stateToUpdate.important = value;
//           }

//           localStorage.setItem("scriptData", JSON.stringify(script));
//           handleSaveScript();
//           handleToggle();
//           resetForm();
//           toast.success("Live Transfer setup Successfully");
//         } else if (response.status === 400) {
//           const responseData = await response.json();
//           toast.error(responseData.message);
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     },
//   });

//   const handleSaveScript = async () => {
//     try {
//       const script = localStorage.getItem("scriptData");
//       const scriptData = JSON.parse(script);
//       const finalScript = scriptData.slice(1);

//       const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
//         method: "PATCH",
//         headers: {
//           "content-type": "application/json",
//           "Company-Name": company_name,
//           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//         },
//         body: JSON.stringify({
//           json_script: finalScript,
//         }),
//       });

//       if (response.ok) {
//         dispatch(setSaveScript(false));
//         dispatch(setRefresh(!refresh));
//         // toast.success("Script saved successfully");
//       } else {
//         toast.error("Something went wrong!");
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     if (showPopUp && data) {
//       formik.setValues({
//         number: data.transfer_number || "",
//         triggerState: data.transfer_trigger_state || "",
//         rejectionMessage: data.transfer_failed_message || "",
//       });
//     }
//   }, [showPopUp, data]);

// const handleDelete = async (state) => {
//   try {
//     const script = localStorage.getItem("scriptData");
//     const scriptData = JSON.parse(script);

//     scriptData.forEach((item) => {
//       if (item.State === state) {
//         delete item.important;
//       }
//     });

//     localStorage.setItem("scriptData", JSON.stringify(scriptData));

//     await handleSaveScript();

//     const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
//       method: "PATCH",
//       headers: {
//         "content-type": "application/json",
//         "Company-Name": company_name,
//         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//       },
//       body: JSON.stringify({
//         transfer_number: 0,
//         // transfer_trigger_state: "",
//         // transfer_failed_message: "",
//       }),
//     });
//     if (response.ok) {
//       handleToggle();
//       resetForm();
//       toast.success("Live Transfer setup Successfully");
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

//   return (
//     <>
//       <button
//         className="select-none rounded-lg 2xl:text-lg text-center align-middle text-xs font-semibold"
//         type="button"
//         style={{ color: fontColor }}
//         onClick={handleToggle}
//       >
//         Live Transfer
//       </button>
//       <div
//         style={{ color: fontColor }}
//         className={`${
//           showPopUp ? "" : "pointer-events-none"
//         } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
//           showPopUp ? "opacity-1" : "opacity-0"
//         } backdrop-blur-sm transition-opacity duration-300`}
//       >
//         <div
//           data-dialog="sign-in-dialog"
//           className="relative mx-auto flex w-full max-w-[24rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
//         >
//           <form onSubmit={formik.handleSubmit}>
//             <div className="flex flex-col gap-4 p-6">
//               <div className="flex justify-between items-center">
//                 <h4
//                   style={{ color: fontColor }}
//                   className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
//                 >
//                   Live Transfer
//                 </h4>
//                 <div onClick={handleToggle}>
//                   <MdOutlineClose className="text-2xl cursor-pointer" />
//                 </div>
//               </div>
//               <h6
//                 style={{ color: fontColor }}
//                 className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
//               >
//                 Number
//               </h6>
//               <div className="relative h-11 w-full min-w-[200px]">
//                 <input
//                   className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200 text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
//                   placeholder="+91 9999999999"
//                   name="number"
//                   id="number"
//                   value={formik.values.number}
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                 />
//               </div>
//               {formik.errors.number && formik.touched.number ? (
//                 <p className="w-full text-red-600 mx-[7px] font-medium">
//                   {formik.errors.number}
//                 </p>
//               ) : null}
//               <h6
//                 style={{ color: fontColor }}
//                 className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
//               >
//                 Trigger if user response to
//               </h6>
//               <div className="relative h-11 w-full min-w-[200px]">
//                 <select
//                   id="triggerState"
//                   name="triggerState"
//                   value={formik.values.triggerState}
//                   onChange={formik.handleChange}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
//                 >
//                   <option value="">Select Trigger State</option>
//                   {statesOption &&
//                     statesOption.map((state, index) => (
//                       <option key={index} value={state}>
//                         {state}
//                       </option>
//                     ))}
//                 </select>
//               </div>
//               {formik.errors.triggerState && formik.touched.triggerState ? (
//                 <p className="w-full text-red-600 mx-[7px] font-medium">
//                   {formik.errors.triggerState}
//                 </p>
//               ) : null}
//               <h6
//                 style={{ color: fontColor }}
//                 className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
//               >
//                 Is
//               </h6>
//               <div className="relative h-11 w-full min-w-[200px]">
//                 <input
//                   className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200 text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
//                   placeholder="Description"
//                   name="rejectionMessage"
//                   id="rejectionMessage"
//                   value={formik.values.rejectionMessage}
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                 />
//               </div>
//               {formik.errors.rejectionMessage &&
//               formik.touched.rejectionMessage ? (
//                 <p className="w-full text-red-600 mx-[7px] font-medium">
//                   {formik.errors.rejectionMessage}
//                 </p>
//               ) : null}
//             </div>
//             <div className="p-6 pt-0">
//               <button
//                 className="block w-full select-none rounded-lg bg-black py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md transition-all hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
//                 type="submit"
//               >
//                 Save
//               </button>
//             </div>
//             {data && data.transfer_trigger_state ? (
//               <div className="p-6 pt-0">
//                 <button
//                   onClick={() => handleDelete(data.transfer_trigger_state)}
//                   className="block w-full select-none rounded-lg bg-black py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md transition-all hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
//                   type="button"
//                 >
//                   reset
//                 </button>
//               </div>
//             ) : (
//               ""
//             )}
//           </form>
//         </div>
//       </div>
//     </>
//   );
// }

// export default LiveTransfer;

import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { liveTransferSchema } from "../schemas/FormSchema";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { setRefresh } from "../features/Refresh/refresh";
import { setSaveScript } from "../features/Agents/saveScript";

function LiveTransfer({ data }) {
  const refresh = useSelector((state) => state.refresh.refresh);
  const { fontColor } = useSelector((state) => state.theme);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [showPopUp, setShowPopUp] = useState(false);
  const [step, setStep] = useState(2);
  const [outcome, setOutcome] = useState([]);
  const [editIndex, setEditIndex] = useState(null); // Track the index of the outcome being edited
  const agentID = localStorage.getItem("agentID");
  const retrievedString = localStorage.getItem("scriptData");
  const retrievedArrayOfScript = JSON.parse(retrievedString);
  const dispatch = useDispatch();

  const states =
    retrievedArrayOfScript && retrievedArrayOfScript?.map((item) => item.State);
  const statesOption = states && states.slice(1);

  useEffect(() => {
    if (data && data.transfer_call_script) {
      try {
        const parsedData = JSON.parse(data.transfer_call_script);
        setOutcome(parsedData);
      } catch (error) {
        console.error("Error parsing end_call data:", error);
      }
    }
  }, [data]);

  const handleToggle = () => {
    setShowPopUp(!showPopUp);
  };

  const formik = useFormik({
    initialValues: {
      number: "",
      triggerState: "",
      rejectionMessage: "",
    },
    validationSchema: liveTransferSchema,
    onSubmit: async (values, { resetForm }) => {
      const newGoal = {
        // number: values.number,
        stateName: values.triggerState,
        description: values.rejectionMessage,
      };
      const updatedGoals = [...outcome];
      if (editIndex !== null) {
        updatedGoals[editIndex] = newGoal; // Update the outcome if editing
        setEditIndex(null); // Reset edit index after editing
      } else {
        updatedGoals.push(newGoal); // Add a new outcome if not editing
      }

      try {
        const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            transfer_number: values.number,
            transfer_call_script: JSON.stringify(updatedGoals),
          }),
        });

        if (response.ok) {
          const script = JSON.parse(localStorage.getItem("scriptData"));
          const stateToUpdate = script.find(
            (state) => state.State === values.triggerState
          );

          const value = `Trigger if user response to ${values.triggerState} is ${values.rejectionMessage}
<function_calls>
<invoke>
<function_name>transfer_call</function_name>
<parameters>
<original_message>User's original message goes here</original_message>
</parameters>
</invoke>
</function_calls>`;

          if (stateToUpdate) {
            stateToUpdate.important = value;
          }

          // Save the updated script back to localStorage
          localStorage.setItem("scriptData", JSON.stringify(script));

          handleSaveScript();
          handleToggle();
          setStep(2);
          resetForm();

          toast.success("End call updated successfully");
        } else {
          const responseData = await response.json();
          toast.error(responseData.message || "Something went wrong");
        }
      } catch (error) {
        console.error("Error updating end call:", error);
        toast.error("Something went wrong");
      }
    },
  });

  const handleSaveScript = async () => {
    try {
      const script = localStorage.getItem("scriptData");
      const scriptData = JSON.parse(script);
      const finalScript = scriptData.slice(1);

      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          json_script: finalScript,
        }),
      });

      if (response.ok) {
        dispatch(setSaveScript(false));
        dispatch(setRefresh(!refresh));
        // toast.success("Script saved successfully");
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditOutcome = (index) => {
    setEditIndex(index);
    setStep(1); // Move to the form step for editing
    formik.setValues({
      // Set form values to the values of the outcome being edited
      triggerState: outcome[index].stateName,
      rejectionMessage: outcome[index].description,
      number: data.transfer_number || "",
    });
  };

  const handleDeleteOutcome = async (index, state) => {
    const updatedOutcome = [...outcome];
    updatedOutcome.splice(index, 1);

    const script = localStorage.getItem("scriptData");
    const scriptData = JSON.parse(script);

    scriptData.forEach((item) => {
      if (item.State === state) {
        delete item.important;
      }
    });

    localStorage.setItem("scriptData", JSON.stringify(scriptData));

    try {
      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          transfer_call_script: JSON.stringify(updatedOutcome),
        }),
      });

      if (response.ok) {
        setOutcome(updatedOutcome);
        handleToggle();
        handleSaveScript();
        toast.success("End call updated successfully");
      } else {
        const responseData = await response.json();
        toast.error(responseData.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error updating end call:", error);
      toast.error("Something went wrong");
    }
  };

  const handleDelete = async (state) => {
    try {
      // const script = localStorage.getItem("scriptData");
      // const scriptData = JSON.parse(script);

      // scriptData.forEach((item) => {
      //   if (item.State === state) {
      //     delete item.important;
      //   }
      // });

      // localStorage.setItem("scriptData", JSON.stringify(scriptData));

      // await handleSaveScript();

      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          transfer_number: 0,
          // transfer_trigger_state: "",
          // transfer_failed_message: "",
        }),
      });
      if (response.ok) {
        handleToggle();
        resetForm();
        toast.success("Live Transfer setup Successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button
        style={{ color: fontColor }}
        className="select-none rounded-lg  2xl:text-lg text-center align-middle text-xs font-semibold"
        type="button"
        onClick={handleToggle}
      >
        Live Transfer
      </button>
      <div
        className={`${
          showPopUp ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          showPopUp ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div
          data-dialog="sign-in-dialog"
          className="relative mx-4 flex w-full max-w-xl flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
        >
          {step === 1 && (
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col gap-4 p-6">
                <div className="flex justify-between items-center">
                  <h4
                    style={{ color: fontColor }}
                    className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
                  >
                    Live Transfer
                  </h4>
                  <div onClick={handleToggle}>
                    <MdOutlineClose className="text-2xl cursor-pointer" />
                  </div>
                </div>
                <h6
                  style={{ color: fontColor }}
                  className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                >
                  Number
                </h6>
                <div className="relative h-11 w-full min-w-[200px]">
                  <input
                    className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200 text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder="+91 9999999999"
                    name="number"
                    id="number"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.errors.number && formik.touched.number ? (
                  <p className="w-full text-red-600 mx-[7px] font-medium">
                    {formik.errors.number}
                  </p>
                ) : null}
                <h6
                  style={{ color: fontColor }}
                  className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                >
                  Trigger if user response to
                </h6>
                <div className="relative h-11 w-full min-w-[200px]">
                  <select
                    id="triggerState"
                    name="triggerState"
                    value={formik.values.triggerState}
                    onChange={formik.handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Select Trigger State</option>
                    {statesOption &&
                      statesOption.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                  </select>
                </div>
                {formik.errors.triggerState && formik.touched.triggerState ? (
                  <p className="w-full text-red-600 mx-[7px] font-medium">
                    {formik.errors.triggerState}
                  </p>
                ) : null}
                <h6
                  style={{ color: fontColor }}
                  className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                >
                  Is
                </h6>
                <div className="relative h-11 w-full min-w-[200px]">
                  <input
                    className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200 text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder="Description"
                    name="rejectionMessage"
                    id="rejectionMessage"
                    value={formik.values.rejectionMessage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.errors.rejectionMessage &&
                formik.touched.rejectionMessage ? (
                  <p className="w-full text-red-600 mx-[7px] font-medium">
                    {formik.errors.rejectionMessage}
                  </p>
                ) : null}
              </div>
              <div className="p-6 pt-0">
                <button
                  className="block w-full select-none rounded-lg bg-black py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md transition-all hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="submit"
                >
                  Save
                </button>
              </div>
              {/* {data && data.transfer_trigger_state ? (
                <div className="p-6 pt-0">
                  <button
                    onClick={() => handleDelete(data.transfer_trigger_state)}
                    className="block w-full select-none rounded-lg bg-black py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md transition-all hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button"
                  >
                    reset
                  </button>
                </div>
              ) : (
                ""
              )} */}
            </form>
          )}
          {step === 2 && (
            <div className="flex flex-col gap-4 p-6">
              <div className="flex justify-between items-center">
                <h4
                  style={{ color: fontColor }}
                  className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
                >
                  Live Transfer
                </h4>
                <div onClick={handleToggle}>
                  <MdOutlineClose className="text-2xl cursor-pointer" />
                </div>
              </div>
              <div
                onClick={() => setStep(1)}
                className="w-full flex justify-center cursor-pointer border border-dashed p-4"
              >
                + setup another live transfer state
              </div>
              <div className="max-h-96 overflow-auto">
                {outcome &&
                  outcome.map((outcome, index) => (
                    <div
                      key={index}
                      className="w-full flex mb-2 justify-between border p-4"
                    >
                      <h3>{outcome.stateName}</h3>
                      <div className="flex justify-center gap-3 text-2xl">
                        <CiEdit
                          onClick={() => handleEditOutcome(index)}
                          className="hover:text-light-blue-800 cursor-pointer"
                        />
                        <MdDelete
                          onClick={() =>
                            handleDeleteOutcome(index, outcome.stateName)
                          }
                          className="hover:text-red-600 cursor-pointer"
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="p-6 pt-0">
                <button
                  onClick={() => handleDelete(data.transfer_trigger_state)}
                  className="block w-full select-none rounded-lg bg-black py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md transition-all hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  reset
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default LiveTransfer;