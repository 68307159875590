import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

const OnBoarding = () => {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const messageRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [err, setErr] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [welcomeDisplayed, setWelcomeDisplayed] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [inputValue, setInputValue] = useState(""); 
  const [buttonDisabled, setButtonDisabled] = useState(true); 
  const navigate = useNavigate();
  

  useEffect(() => {
    if (legalName) {
      const welcomeMessages = [
        `Welcome to ${legalName}! 👋`,
        `${legalName} revolutionizes data collection through powerful one-on-one video, audio, and text conversations... just like this one!`,
        "Now, let's get started. What is your first name?",
      ];

      if (!welcomeDisplayed) {
        setWelcomeDisplayed(true);
        welcomeMessages.forEach((message, index) => {
          setTimeout(() => {
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: message, user: false },
            ]);
            setDisabled(false);
          }, index * 1000);
        });
      }
    }
  }, [welcomeDisplayed, legalName]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageRef.current) {
        messageRef.current.scrollTop = messageRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    const userMessage = inputValue;

    if (currentStep === 0) {
      setFirstName(userMessage);
      setCurrentStep(1);

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: userMessage, user: true },
      ]);
      setIsLoading(true);
      setDisabled(true);
      setButtonDisabled(true)
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "Now, what is your last name?", user: false },
        ]);
        setIsLoading(false);
        setDisabled(false);
      }, 1000);
    } else if (currentStep === 1) {
      setLastName(userMessage);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: userMessage, user: true },
      ]);
      setIsLoading(true);
      setDisabled(true);
      setButtonDisabled(true)
      fetch(`${baseUrl}/api/update/full_name/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: userMessage,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            toast.error("Something went wrong");
            throw new Error("Failed to update full name");
          }
        })
        .then((data) => {
          const userData = JSON.parse(localStorage.getItem("userData"));
          userData.first_name = data.data.user.first_name;
          userData.last_name = data.data.user.last_name;
          localStorage.setItem("userData", JSON.stringify(userData));
          setCurrentStep(2);
          setTimeout(() => {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                text: `${data.data.message} Any ways, could you provide your company name?`,
                user: false,
              },
            ]);
            setIsLoading(false);
            setDisabled(false);
          }, 1000);
        })
        .catch((error) => {
          console.error("Error updating full name:", error);
          setIsLoading(false);
        });
    } else if (currentStep === 2) {
      const specialCharPattern = /[^a-zA-Z0-9\s]/;
      if (specialCharPattern.test(userMessage)) {
        toast.error("Company name has special character");
      } else {
        setCompanyName(userMessage);
        setIsLoading(true);
        // setDisabled(true);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: userMessage, user: true },
        ]);
        fetch(`${baseUrl}/api/update/company_name/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            company_name: userMessage.trim().replace(/\s+/g, "").toLowerCase(),
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return response.json().then((data) => {
                toast.error(data.message);
                throw new Error(data.message);
              });
            }
          })
          .then((responseData) => {
            const userData = responseData.data.user;
            localStorage.setItem("userData", JSON.stringify(userData));
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                text: "Thank you for providing your information. You are now onboarded!",
                user: false,
              },
            ]);
            setIsLoading(false);
            setShowConfetti(true);
            setTimeout(() => {
              setShowConfetti(false);
              navigate("/dashboard");
            }, 5000);
          })
          .catch((error) => {
            console.error("Error updating company name:", error);
            setErr(error.message);
            setIsLoading(false);
          });
      }
    }
    setInputValue(""); // Clear input value after submission
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex justify-center p-8 items-center flex-col h-screen w-[100%]"
      >
        <h1
          style={{ color: fontColor }}
          className="text-4xl font-medium my-8 capitalize"
        >
          {legalName}
        </h1>
        {err && (
          <p className="mb-3 text-red-500 capitalize font-semibold">{err}</p>
        )}
        <div className="bg-white rounded-xl custom-shadow w-full max-w-3xl">
          <div className="flex items-center justify-between rounded-tl-xl rounded-tr-xl bg-[#001d3d] px-5 py-4">
            <h1 className="font-semibold text-sm text-white capitalize">
              {legalName} Onboarding
            </h1>
            <div className="flex gap-2 items-center space-x-1">
              <p className="text-white">Connected</p>
              <span className="relative flex h-3 w-3 mb-[1px]">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-green-400"></span>
              </span>
            </div>
          </div>
          <hr className="border-t-2 border-gray-200" />
          <div
            ref={messageRef}
            className="h-[300px] 2xl:h-[500px] overflow-auto px-6 py-4 relative"
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`mb-4 flex ${
                  message.user ? "justify-end" : "justify-start"
                } items-center`}
              >
                <div className={`${message.user ? "hidden" : ""} basis-[10%]`}>
                  <img
                    src="https://cdn.dribbble.com/users/1740191/screenshots/6287147/eva.png"
                    alt=""
                    className="w-20"
                  />
                </div>
                <div className={`flex-1 `}>
                  <div
                    style={{ color: fontColor }}
                    className={`w-fit rounded-lg  p-3 inline-block ${
                      message.user ? "bg-[#d2edd4] float-end" : "bg-[#f5f5f5]"
                    }`}
                  >
                    <span>{message.text}</span>
                  </div>
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex space-x-2 justify-start items-center dark:invert pl-4">
                <div className="h-3 w-3 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="h-3 w-3 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="h-3 w-3 bg-gray-500 rounded-full animate-bounce"></div>
              </div>
            )}
          </div>
          <hr className="border-t-2 border-gray-200" />
          <form
            onSubmit={handleSendMessage}
            className="flex justify-between px-6 py-4 space-x-3"
          >
            <input
              type="text"
              name="message"
              value={inputValue} // Bind input value to state
              onChange={(e) => {
                setInputValue(e.target.value); // Update input value state
                setButtonDisabled(e.target.value.trim() === ""); // Enable/disable button based on input value
              }}
              placeholder={
                currentStep === 0
                  ? "Enter your first name..."
                  : currentStep === 1
                  ? "Enter your last name..."
                  : "Enter your company name..."
              }
              disabled={disabled}
              className={`${
                disabled ? " cursor-not-allowed" : ""
              } w-full h-12 rounded-xl hover:bg-gray-200 px-4 bg-gray-100`}
            />
            <button
              type="submit"
              disabled={buttonDisabled}
              className={`${
                buttonDisabled
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-gray-800 hover:bg-gray-900"
              } focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 flex items-center justify-center  ease-out  rounded-lg px-4 py-2 text-white`}
            >
              Send
            </button>
            {showConfetti && <Confetti />}
          </form>
        </div>
      </div>
    </>
  );
};

export default OnBoarding;
