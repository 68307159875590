import React, { useEffect, useState } from "react";
import { MdDelete, MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { addCalenderSchema } from "../schemas/FormSchema";
import toast from "react-hot-toast";
import { setSaveScript } from "../features/Agents/saveScript";
import { setRefresh } from "../features/Refresh/refresh";
import { CiEdit } from "react-icons/ci";

function CalenderPopUp({ data }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const refresh = useSelector((state) => state.refresh.refresh);
  const { fontColor } = useSelector((state) => state.theme);
  const [showPopUp, setShowPopUp] = useState(false);
  const [step, setStep] = useState(1);
  const [outcome, setOutcome] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const handleToggle = () => {
    setShowPopUp(!showPopUp);
  };
  const agentID = localStorage.getItem("agentID");
  const company_name = JSON.parse(localStorage.getItem("userData"))
    ?.companies[0]?.company_name;
  const retrievedString = localStorage.getItem("scriptData");
  const retrievedArrayOfScript = JSON.parse(retrievedString);
  const dispatch = useDispatch();

  const states = retrievedArrayOfScript?.map((item) => item.State);
  const statesOption = states && states.slice(1);

  useEffect(() => {
    if (data && data.calendar_script) {
      try {
        const parsedData = JSON.parse(data.calendar_script);
        setOutcome(parsedData);
      } catch (error) {
        console.error("Error parsing end_call data:", error);
      }
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      // url: "",
      triggerState: "",
      bookedMessage: "",
      rejectionMessage: "",
      calanderType: "",
      calcomApi: "",
      description: "",
    },
    validationSchema: addCalenderSchema,
    onSubmit: async (values, { resetForm }) => {
      const newGoal = {
        // calanderType: values.calanderType,
        // calcomApi: values.calcomApi,
        triggerState: values.triggerState,
        description: values.description,
      };
      const updatedGoals = [...outcome];
      if (editIndex !== null) {
        updatedGoals[editIndex] = newGoal; // Update the outcome if editing
        setEditIndex(null); // Reset edit index after editing
      } else {
        updatedGoals.push(newGoal); // Add a new outcome if not editing
      }

      // setOutcome(updatedGoals);
      try {
        const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            // calendar_url: values.url,
            // calendar_trigger_state: values.triggerState,
            // calendar_booked_message: values.bookedMessage,
            calendar_script: JSON.stringify(updatedGoals),
            calcom_api_key:
              values.calanderType == "CRM" ? "" : values.calcomApi,
            // cal_description: values.description,
            crm_calender: values.calanderType == "CRM" ? true : false,
          }),
        });
        if (response.ok) {
          handleToggle();
          toast.success("Calendar Updated Successfully");

          const script = JSON.parse(localStorage.getItem("scriptData"));
          // script.forEach((state) => {
          //   delete state.instructions; // Remove the instructions key
          // });
          // script.forEach((state) => {
          //   if (
          //     state.important &&
          //     state.important.includes("<conversation_flow_example>")
          //   ) {
          //     delete state.important;
          //   }
          // });
          const stateToUpdate = script.find(
            (state) => state.State === values.triggerState
          );

          const value = `Trigger if user response is ${values.description}
           <conversation_flow_example>
<sample_user_response>: [Any response satisfying trigger condition]
<sample_assistant_response>: When would you like to schedule our discussion? 
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
User: How about {user_provided_date_or_day} afternoon?
Assistant: <response>{user_provided_date_or_day} sounds good. Let me check our availability for that day.</response> <Think>Did the user provide any specific time (eg: 11am, 2pm, 3:30pm, etc) ? If yes, use "book_slot" else use "get_slots"</Think>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>get_slots</action>
<date>{user_provided_date_or_day}</date>
<time_range>afternoon</time_range>
</parameters>
</invoke>
</function_calls>
[Rule]Wait for user response to get available time slots. You can never generate time slots on your own. You will wait for user response after this[/Rule]
<user_response> User: Tell client information from the message. Don't repeat the message. <instruction>Do not include function invoke message in your response. You can only share slots from this Message, ignore slots provided in previous message, if any. Only User can tell available time slots</instruction> Message: Here are the available time slots for {user_provided_date_or_day}: {user_provided_date_or_day}T13:00:00-04:00, {user_provided_date_or_day}T14:00:00-04:00, {user_provided_date_or_day}T15:30:00-04:00, present these slots to the client as available time slots. You will receive time slots in this format: "2024-07-05T06:30:00+05:30" - but you will speak it as: [on 5th July, 6:30 am]
<assistant_response> Assistant: <response>Thank you for your patience. I've checked our availability for {user_provided_date_or_day}. Available time slots are: 1 pm, 2 pm and 3:30 pm. Which would you prefer?</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
<If no slots are available>
<assistant_response> Assistant: <response>I apologize, but it looks like we don't have any available slots for {user_provided_date_or_day}. Would you like to check another date?</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
<user_response> User: Yes, please check for {new_date} at 9am.
<assistant_response> Assistant: <response>Certainly. I'll try to book {new_date} at 9:00 AM for you.</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>book_slot</action>
<date>{new_date}</date>
<specific_time>09:00</specific_time>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to confirm booking[/instruction]
</assistant_response>
<user_response> User: Tell client information from the message. please tell date time and timezone. Don't repeat message, discussion of meeting is finished. Continue with the script. Message: Your booking is successfully booked for [booked dateTime] {new_date}T09:00:00-04:00. You will receive time slots in this format: "startTime": "2024-07-05T09:30:00+5:30" - but you will speak it as: "on 5th July, 9:30 am EST"
<assistant_response> Assistant: <response>Great! Your appointment is confirmed for {new_date} at 9:00 AM, EST.</response>
<next_state>Next State as per Script</next_state>
<wait_time>10</wait_time>
</conversation_flow_example>
<rule>You can never generate slots or provide success/unsuccessful status on your own. Only user will provide that information. You will always wait for user to provide that information to you. Your response will end after invoking the function</rule>
[IMPORTANT: After successful booking, transition to the next state in the script sequence. Never output state name]
`;

          const instruction = `<calendar_instructions>
Date Validation:
ALWAYS compare the requested date with the current date (use the date provided in the "Today is:" field towards the bottom of the prompt).
Calculate the minimum allowed date as [current date].
If the requested date is BEFORE or EQUAL TO the current date:
a. DO NOT invoke any calendar function.
b. Inform the user that only future dates can be scheduled.
c. Ask the user to provide a new date that is AFTER the current date.
<think>Always check in the history, whether the user provided any date/day or not. You can not self-assume any date/day to book/get slots, unless user has mentioned that date or day.</think>
Time Specification:
If the user provides ANY specific time (e.g., 11am, 9:00 AM, 1000 hours, 12 o'clock, evening 5, morning 10, afternoon 3, 5pm, 2pm, etc), use the "book_slot" action with 'specific_time' parameter.
If the user only provides a date or day, or a combination of date/day and general time range (e.g., tomorrow, next Monday, Tuesday evening), use the "get_slots" action with 'time_range' parameter (values: morning/afternoon/evening/full_day).
<definition>"specific_time" includes any mention of an exact hour, with or without minutes (e.g., 3pm, 11:30am, 14:00, etc.). These should always trigger the "book_slot" action with the "specific_time" parameter</definition>
Handling Past Dates:
If the requested date is in the past or equal to the current date:
a. Do not invoke any calendar function.
b. Inform the user that only future dates can be scheduled.
c. Ask the user to provide a new date that is after the current date.
Relative Date Handling:
For days of the week (e.g., "Monday" or "next Monday"), always calculate the next occurrence of that day, even if it's in the current week.
Ensure the calculated date is always in the future (after the current date).
Vague Timeframes:
If the user provides a vague timeframe (e.g., "next week"), ask them to specify a particular date or day.
Slot Generation:
Never generate time slots on your own. Only present slots that the user/client explicitly provides.
Always wait for the user to provide available time slots after a calendar function call.
Response Format:
Your response should never include <examples> or <calendar_instructions>.
Only use the syntax shown in the examples for invoking calendar functions.
Before invoking any calendar function, perform these checks:
1. Is the suggested date AFTER the current date?
2. For relative terms, have I calculated the correct future date?
3. Do I have a specific date or a clear time range to work with?
4. If a specific time is provided, am I using the book_slot action?
If any of these checks fail, do not invoke the calendar function. Instead, ask the user for clarification or a future date.
</calendar_instructions>
<reminder>
Always perform this date check BEFORE attempting to invoke any calendar function.
When in doubt, ask the user for a date that is on or after the current date.
</reminder>
<chain_of_thought>
Before responding, verify:
1. Is the requested date on or after the current date?
2. Am I using the correct function: book_slot (for specific time, eg: 9am, 11 am, afternoon 2, evening 5, 4PM, 12 noon, 10:00 am, 1:30 PM) or get_slots (for date only or general time range)?
3. Have I included all necessary parameters in the correct syntax?
4. Am I avoiding generating slots on my own?
5. Is my response free of <examples> or <calendar_instructions>?
6. Have I avoided generating a user response?
7. For days of the week, have I correctly calculated the next occurrence, ensuring it's always in the future?
8. If the date is not valid, am I asking the user for a new date instead of invoking a function?
9. Did the user provide a specific time? If yes, am I correctly using “book_slot” action with “specific_time” parameter?
Ensure the response follows this structure for valid current or future dates:
<response>I'll check our availability for [calculated date]</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>[get_slots or book_slot]</action>
<date>[calculated date in YYYY-MM-DD format]</date>
<time_range>[if applicable: morning/afternoon/evening/full_day]</time_range>
<specific_time>[if applicable: HH:MM in 24-hour format]</specific_time>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to get available slots[/instruction]
For invalid or past dates, use this structure:
I apologize, but we can only schedule appointments for current or future dates. Today is [current date]. Could you please provide a date on or after [current date]?
If the response doesn't match either structure, regenerate it.
</chain_of_thought>
<examples>
<example1>
User: Can we schedule for September 6th, 2024?
Assistant: <response>I apologize, but we can only schedule appointments for future dates. Today is September 7, 2024. Could you please provide a date after September 7, 2024?</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>get_slots</action>
<date>2024-09-08</date>
<time_range>full_day</time_range>
</parameters>
</invoke>
</function_calls>
</example1>
<example2>
User: How about September 10th, 2024 at 2 PM?
Assistant: <response>Certainly, I'll try to book September 10th, 2024 at 2:00 PM for you</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>book_slot</action>
<date>2024-09-10</date>
<specific_time>14:00</specific_time>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to confirm booking[/instruction]
</example2>
<example3>
User: Can we schedule for tomorrow?
Assistant: <response>Certainly, I'll check our availability for tomorrow, which would be September 8, 2024.</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>get_slots</action>
<date>2024-09-08</date>
<time_range>full_day</time_range>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to get available slots[/instruction]
</example3>
<example4>
User: How about next Friday afternoon?
Assistant: <response>Certainly, I'll check our availability for next Friday afternoon, which would be September 13, 2024.</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>get_slots</action>
<date>2024-09-13</date>
<time_range>afternoon</time_range>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to get available slots[/instruction]
</example4>
<example5>
User: Friday at 9 AM?
Assistant: <response>I'll try to book September 13th, 2024 at 9:00 AM for you.</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>book_slot</action>
<date>2024-09-13</date>
<specific_time>09:00</specific_time>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to confirm booking[/instruction]
</example5>
<example6>
User: How about last Monday?
Assistant: <response>I apologize, but we can't schedule appointments for past dates. Today is September 7, 2024. Could you please provide a date on or after September 7, 2024?</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
</example6>
<example7>
User: Can we book for December 1st, 2024 in the morning?
Assistant: <response>Certainly, I'll check our availability for December 1st, 2024 in the morning.</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>get_slots</action>
<date>2024-12-01</date>
<time_range>morning</time_range>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to get available slots[/instruction]
</example7>
<example8>
User: Is Thursday available for 2pm?
Assistant: <response>I'll check our availability for 12th September, 2024 for 2:00 PM.</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>book_slot</action>
<date>2024-09-12</date>
<specific_time>14:00</specific_time>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to get available slots[/instruction]
</example8>
<example9>
User: Can we schedule for tomorrow 7pm?
Assistant: <response>Certainly, I'll check our availability for today.</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>book_slot</action>
<date>2024-09-08</date>
<specific_time>19:00</specific_time>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to get available slots[/instruction]
</example9>
<example10>
User: How about next year, January 15th?
Assistant: <response>Certainly, I'll check our availability for January 15th, 2025.</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>get_slots</action>
<date>2025-01-15</date>
<time_range>full_day</time_range>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to get available slots[/instruction]
</example10>
<example11>
User: Can we schedule for Monday evening?
Assistant: <response>Certainly, I'll check our availability for next Monday, September 9, 2024.</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>get_slots</action>
<date>2024-09-09</date>
<time_range>evening</time_range>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to get available slots[/instruction]
</example11>
<example12>
User: How about August 15, 2024?
Assistant: <response>I apologize, but we can only schedule appointments for future dates. Today is September 7, 2024. Could you please provide a date after September 7, 2024?<response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>null</function_calls>
</example12>
<example13>
User: I am available on Wednesday 5pm
Assistant: <response>Certainly, let me confirm our availability for Wednesday, September 11, 2024 at 5pm.</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>book_slot</action>
<date>2024-09-11</date>
<specific_time>17:00</specific_time>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to get booking status[/instruction]
</example13>
<example14>
User: uh Friday 3pm?
Assistant: <response>Sure, let me book for 3:00 PM on September 13, 2024</response>
<next_state>Function Processing</next_state>
<wait_time>10</wait_time>
<function_calls>
<invoke>
<function_name>manage_calendar</function_name>
<parameters>
<action>book_slot</action>
<date>2024-09-13</date>
<specific_time>15:00</specific_time>
</parameters>
</invoke>
</function_calls>
[instruction]Wait for user response to get available slots[/instruction]
</example14>
</examples>
<critical>we have used 7th September as today's date for the examples. You will always refer to the date mentioned in the field "TODAY is:" at the bottom of this prompt to know Today's Actual Date</critical>
`;

          if (stateToUpdate) {
            stateToUpdate.instructions = instruction;

            stateToUpdate.important = value;
          }

          localStorage.setItem("scriptData", JSON.stringify(script));

          handleSaveScript();
          resetForm();
          setStep(1);
          // toast.success("Calendar setup Successfully");
        } else if (response.status === 400) {
          const responseData = await response.json();
          toast.error(responseData.message);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  // useEffect(() => {
  //   if (showPopUp && data) {
  //     formik.setValues({
  //       // url: data.calendar_url || "",
  //       triggerState: data.calendar_trigger_state || "",
  //       bookedMessage: data.calendar_booked_message || "",
  //       rejectionMessage: data.calendar_rejection_message || "",
  //       calcomApi: data.calcom_api_key || "",
  //       description: data.cal_description || "",
  //       calanderType: data.crm_calender ? "CRM" : "calendar" || "",
  //     });
  //   }
  // }, [showPopUp, data]);

  const handleSaveScript = async () => {
    try {
      const script = localStorage.getItem("scriptData");
      const scriptData = JSON.parse(script);
      const finalScript = scriptData.slice(1);

      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          json_script: finalScript,
        }),
      });

      if (response.ok) {
        dispatch(setSaveScript(false));
        dispatch(setRefresh(!refresh));
        // toast.success("Script saved successfully");
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (state) => {
    // const script = localStorage.getItem("scriptData");
    // const scriptData = JSON.parse(script);

    // scriptData.forEach((item) => {
    //   if (item.State === state) {
    //     delete item.important;
    //   }
    // });

    // localStorage.setItem("scriptData", JSON.stringify(scriptData));

    // handleSaveScript();

    try {
      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          // calendar_url: "",
          // calendar_trigger_state: "",
          // calendar_booked_message: "",
          // calendar_rejection_message: "",
          calendar_calcom_api: "",
          calcom_api_key: "",
          crm_calender: false,
          // cal_description: "",
        }),
      });
      if (response.ok) {
        handleToggle();
        toast.success("Calendar Updated Successfully");
        dispatch(setRefresh(!refresh));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditOutcome = (index) => {
    setEditIndex(index);
    setStep(2); // Move to the form step for editing
    formik.setValues({
      // Set form values to the values of the outcome being edited
      triggerState: outcome[index].triggerState,
      calcomApi: data.calcom_api_key || "",
      description: outcome[index].description,
      calanderType: data.crm_calender ? "CRM" : "calendar" || "",
    });
  };

  const handleDeleteOutcome = async (index, state) => {
    const updatedOutcome = [...outcome];
    updatedOutcome.splice(index, 1);
    console.log(state);

    // setOutcome(updatedOutcome)

    const script = localStorage.getItem("scriptData");
    const scriptData = JSON.parse(script);

    scriptData.forEach((item) => {
      if (item.State === state) {
        console.log(item);

        delete item.important;
        delete item.instructions;
      }
    });

    localStorage.setItem("scriptData", JSON.stringify(scriptData));

    try {
      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          calendar_script: JSON.stringify(updatedOutcome),
        }),
      });

      if (response.ok) {
        setOutcome(updatedOutcome);
        handleToggle();
        handleSaveScript();
        toast.success("End call updated successfully");
      } else {
        const responseData = await response.json();
        toast.error(responseData.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error updating end call:", error);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <button
        style={{ color: fontColor }}
        className="select-none rounded-lg  2xl:text-lg text-center align-middle text-xs font-semibold"
        type="button"
        onClick={handleToggle}
      >
        Add Calendar
      </button>
      <div
        className={`${
          showPopUp ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          showPopUp ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300 cursor-default`}
      >
        <div
          data-dialog="sign-in-dialog"
          className="relative p-6 gap-4 mx-auto flex w-full max-w-[40rem] h-[80%] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
        >
          <div className="flex justify-between items-center">
            <h4
              style={{ color: fontColor }}
              className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
            >
              Add Calendar
            </h4>
            <div onClick={handleToggle} className="cursor-pointer">
              <MdOutlineClose className="text-2xl" />
            </div>
          </div>
          <p className="block mb-3 w-full h-52 bg-blue-gray-400 font-sans text-base antialiased font-normal leading-relaxed text-gray-700"></p>
          {step === 2 && (
            <form
              className="h-full overflow-y-auto overflow-x-hidden"
              onSubmit={formik.handleSubmit}
            >
              <div className="flex flex-col gap-4">
                <div>
                  <select
                    id="calanderType"
                    name="calanderType"
                    value={formik.values.calanderType}
                    onChange={(e) =>
                      formik.setFieldValue("calanderType", e.target.value)
                    }
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="Select Calendar Type" />
                    <option value="calendar" label="Calendar" />
                    <option value="CRM" label="CRM Calendar" />
                  </select>
                </div>
                {formik.errors.calanderType && formik.touched.calanderType ? (
                  <p className="w-full text-red-600 m-[7px] font-medium">
                    {formik.errors.calanderType}
                  </p>
                ) : null}
                {formik.values.calanderType !== "CRM" && (
                  <div className="flex flex-col gap-3">
                    <h6
                      style={{ color: fontColor }}
                      className="flex justify-between items-center -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                    >
                      Calcom API Key
                    </h6>
                    <div className="relative flex items-stretch gap-2 h-11 w-full">
                      <input
                        className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200  text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        placeholder="Enter Your URL "
                        name="calcomApi"
                        id="calcomApi"
                        value={formik.values.calcomApi}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.errors.calcomApi && formik.touched.calcomApi ? (
                      <p className="w-full text-red-600 font-medium">
                        {formik.errors.calcomApi}
                      </p>
                    ) : null}
                  </div>
                )}

                <div className="flex items-center justify-between gap-3">
                  <div className="flex basis-1/2 flex-col gap-3">
                    <h6
                      style={{ color: fontColor }}
                      className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                    >
                      Trigger If User Response To
                    </h6>

                    <div className="relative h-11 w-full">
                      <select
                        id="triggerState"
                        name="triggerState"
                        value={formik.values.triggerState}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="bg-gray-50 border h-full py-0 px-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Select Trigger State</option>
                        {statesOption &&
                          statesOption.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                      </select>
                    </div>
                    {formik.errors.triggerState &&
                    formik.touched.triggerState ? (
                      <p className="w-full text-red-600 font-medium">
                        {formik.errors.triggerState}
                      </p>
                    ) : null}
                  </div>
                  <div className="flex flex-col basis-1/2 gap-3">
                    <h6
                      style={{ color: fontColor }}
                      className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                    >
                      Is
                    </h6>
                    <div className="relative h-11 w-full">
                      <input
                        className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200  text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        placeholder="Description"
                        name="description"
                        id="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.errors.description && formik.touched.description ? (
                      <p className="w-full text-red-600 font-medium">
                        {formik.errors.description}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="items-center hidden justify-between gap-3">
                  <div className="flex flex-col basis-full gap-3">
                    <h6
                      style={{ color: fontColor }}
                      className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                    >
                      booked message
                    </h6>
                    <div className="relative h-11 w-full">
                      <input
                        className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200  text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        placeholder="Booked Message"
                        name="bookedMessage"
                        id="bookedMessage"
                        value={formik.values.bookedMessage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.errors.bookedMessage &&
                    formik.touched.bookedMessage ? (
                      <p className="w-full text-red-600 font-medium">
                        {formik.errors.bookedMessage}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="hidden items-center justify-between gap-3">
                  <div className="flex basis-full flex-col gap-3">
                    <h6
                      style={{ color: fontColor }}
                      className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                    >
                      rejection message
                    </h6>
                    <div className="relative h-11 w-full">
                      <input
                        className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200  text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                        placeholder="Rejection Message"
                        name="rejectionMessage"
                        id="rejectionMessage"
                        value={formik.values.rejectionMessage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.errors.rejectionMessage &&
                    formik.touched.rejectionMessage ? (
                      <p className="w-full text-red-600 font-medium">
                        {formik.errors.rejectionMessage}
                      </p>
                    ) : null}
                  </div>
                  {/* <div className="flex basis-1/2 flex-col gap-3">
                  <h6
                    style={{ color: fontColor }}
                    className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                  >
                    Exit State
                  </h6>
                  <div className="relative h-11 w-full">
                    <input
                      className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200  text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      placeholder="Exit State"
                      name="exitState"
                      id="exitState"
                      value={formik.values.exitState}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div> */}
                </div>
              </div>
              <div className="pt-4 px-3 flex flex-col gap-4">
                <button
                  className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="submit"
                >
                  Save
                </button>
                {/* <button
                  onClick={() => setStep(1)}
                  className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  Back
                </button> */}
              </div>
              {/* {data && data.calendar_trigger_state ? (
                <div className="p-6 pt-0">
                  <button
                    onClick={() => handleDelete(data.calendar_trigger_state)}
                    className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button"
                  >
                    Delete
                  </button>
                </div>
              ) : (
                ""
              )} */}
            </form>
          )}
          {step === 1 && (
            <div className="flex flex-col gap-4">
              <div
                onClick={() => setStep(2)}
                className="w-full rounded-lg border-black flex justify-center cursor-pointer border border-dashed p-4"
              >
                + setup another Calendar State
              </div>
              <div className="max-h-96 overflow-auto">
                {outcome &&
                  outcome.map((outcome, index) => (
                    <div
                      key={index}
                      className="w-full flex mb-2 justify-between border p-4"
                    >
                      <h3>{outcome.triggerState}</h3>
                      <div className="flex justify-center gap-3 text-2xl">
                        <CiEdit
                          onClick={() => handleEditOutcome(index)}
                          className="hover:text-light-blue-800 cursor-pointer"
                        />
                        <MdDelete
                          onClick={() =>
                            handleDeleteOutcome(index, outcome.triggerState)
                          }
                          className="hover:text-red-600 cursor-pointer"
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="p-6 pt-0">
                <button
                  onClick={() => handleDelete(data.calendar_trigger_state)}
                  className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  Reset
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CalenderPopUp;
