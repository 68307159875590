import React, { useEffect, useState } from "react";
import { GHL } from "../utils/ghlHelper";
import { useDispatch, useSelector } from "react-redux";
import { setBaseUrl } from "../features/BaseURL/baseUrl";
import { setBackgroundColor, setFontColor, setLegalName, setLogo } from "../features/Theme/theme";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";

function GHLSsoLogin() {
  const [loading, setLoading] = useState(false); // Loading state
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    const ghl = new GHL(); // Create an instance of GHL

    window.ghl = ghl;

    const fetchUserData = async () => {
      try {
        const userData = await ghl.getUserData();
        console.log("hiii", userData); // Use the user data as needed

        if (userData) {
          setLoading(true);
          const response = await fetch(`${baseUrl}/api/ghl_user_login/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_data: userData,
            }),
          });
          const responseData = await response.json();
          if (!response.ok) {
            console.error(responseData);
          }
          const { access_token, refresh_token } = responseData.data;
          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);
          localStorage.setItem(
            "userData",
            JSON.stringify(responseData.data.user)
          );
          dispatch(setBaseUrl(`https://${responseData.data.domain.domain}`));
          dispatch(setFontColor(responseData.data.domain.font_color));
          dispatch(setBackgroundColor(responseData.data.domain.background_color));
          dispatch(setLegalName(responseData.data.domain.legal_name));
          dispatch(setLogo(responseData.data.domain.logo));
          setLoading(false);
          navigate("/dashboard")
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false); // Set loading to false after fetch completes
      }
    };
    fetchUserData();
  }, []); // Fetch user data when the component mounts

  return (
    <>
      {loading ? ( // Conditionally render loading spinner
        <div className="w-full z-[999] h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      ) : null}
    </>
  );
}

export default GHLSsoLogin;
