import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { IoArrowForward } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../features/user/userData";
import ChangePasswordPopUp from "./ChangePasswordPopUp";
import ProfileUpdate from "./ProfileUpdate";
import { PulseLoader } from "react-spinners";
import { Toaster } from "react-hot-toast";

function Profile() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [passwordHovered, setPasswordHovered] = useState(false);
  const [changePasswordHovered, setChangePasswordHovered] = useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [ChangePasswordVisible, setChangePasswordVisible] = useState(false);
  const [updateProfileVisible, setUpdateProfileVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [success, setSuccess] = useState("");
  const email = JSON.parse(localStorage.getItem("userData")).email;
  const [emailSend, setEmailSend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleGetUser = async () => {
      const userID = JSON.parse(localStorage.getItem("userData")).id;
      try {
        setLoading(true)
        const response = await fetch(`${baseUrl}/api/user/${userID}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          dispatch(setUserData(responseData.data.user));
        }
      } catch (error) {
        console.error(error);
      }
      finally {
        setLoading(false);
      }
    };
    handleGetUser();
  }, [refresh, setRefresh]);

  const handleChangePassword = () => {
    setChangePasswordVisible(!ChangePasswordVisible);
  };

  const handleSetPassword = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/forgot_password/${email}`, {
        method: "GET",
      });
      if (response.ok) {
        setEmailSend(!emailSend);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleProfileUpdate = () => {
    setUpdateProfileVisible(!updateProfileVisible);
    setSuccess("");
  };

  return (
    <>
    <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div style={{ backgroundColor: backgroundColor }}>
        <Navbar />
        <div className="w-full h-auto flex justify-center">
          <div className="lg:basis-[60%] w-full rounded-2xl py-11 px-9 my-4">
            <div className="flex gap-8">
              <div className="flex gap-8 flex-col w-full">
                <div className="flex flex-col gap-2">
                  <h1
                    style={{ color: fontColor }}
                    className="text-4xl text-gray-800 font-semibold"
                  >
                    Account
                  </h1>
                  <p
                    style={{ color: fontColor }}
                    className="text-md text-gray-500"
                  >
                    Manage your account information
                  </p>
                </div>
                <div className="flex flex-col bg-white w-full gap-2 custom-shadow rounded-lg p-5">
                  <h2
                    style={{ color: fontColor }}
                    className="text-xl text-gray-700 w-full font-semibold  tracking-wider"
                  >
                    Profile
                  </h2>
                  <p
                    style={{ color: fontColor }}
                    className="text-sm text-gray-500"
                  >
                    Manage profile settings
                  </p>
                  <div className="flex flex-col items-center gap-8 py-2 mt-5">
                    <div className="2xl:flex-row 2xl:gap-0 flex w-full flex-col gap-3">
                      <div className="basis-1/4 w-full ">
                        <p
                          style={{ color: fontColor }}
                          className="text-md font-semibold"
                        >
                          User Details
                        </p>
                      </div>
                      <div
                        onClick={handleProfileUpdate}
                        className="flex-1 flex cursor-pointer items-center justify-between"
                      >
                        <div className="flex items-center gap-2">
                          <div
                            style={{ color: fontColor }}
                            className="w-14 h-14 rounded-full flex justify-center items-center text-white text-xl capitalize"
                          >
                            {userData && userData?.profile_picture ? (
                              <img
                                src={`${userData.profile_picture}`}
                                alt=""
                                className="w-14 h-14 rounded-full"
                              />
                            ) : (
                              <>
                                <div className="w-14 h-14 flex justify-center items-center rounded-full bg-brown-600">{userData && userData.first_name[0]}</div>
                              </>
                            )}
                          </div>

                          <h1
                            style={{ color: fontColor }}
                            className="font-semibold capitalize"
                          >
                            {userData && userData.first_name}{" "}
                            {userData && userData.last_name}
                          </h1>
                        </div>
                        <IoArrowForward
                          style={{ color: fontColor }}
                          className="mr-5"
                        />
                      </div>
                    </div>
                    <div
                      style={{ color: fontColor }}
                      className="flex-col 2xl:flex-row 2xl:gap-0 gap-3 flex w-full"
                    >
                      <div className="basis-1/4 w-full ">
                        <p className="text-md font-semibold">Email Addresses</p>
                      </div>
                      <div
                        style={{ color: fontColor }}
                        className="flex-1 flex flex-col gap-2 border-b pb-4"
                      >
                        <div className="flex items-center justify-between pr-5 cursor-pointer">
                          <div
                            style={{ color: fontColor }}
                            className="flex gap-3 items-center"
                          >
                            <p className="text-sm md:text-lg">
                              {userData && userData.email}
                            </p>
                            {userData && userData.email_verifed ? (
                              <>
                                <span className="bg-[#E8EBFF] rounded-md p-2 font-semibold text-[#7168F3] text-xs">
                                  Primary
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="bg-[#FEE4E2] rounded-md p-2 font-semibold text-[#F04438] text-xs">
                                  Unverified
                                </span>
                              </>
                            )}
                          </div>
                          {/* <IoArrowForward /> */}
                        </div>
                        {/* <div
                        className="px-4 mt-1 py-2 flex justify-between items-center rounded-md text-blue-600 cursor-pointer hover:bg-[#C4D0FA]"
                        onMouseEnter={() => setEmailHovered(true)}
                        onMouseLeave={() => setEmailHovered(false)}
                      >
                        <p>
                          <span>+</span> Add an email address
                        </p>
                        <IoArrowForward className={emailHovered ? "" : "hidden"} />
                      </div> */}
                      </div>
                    </div>
                    <div
                      style={{ color: fontColor }}
                      className="flex-col gap-3 2xl:flex-row 2xl:gap-0 flex w-full"
                    >
                      <div className="basis-1/4 w-full ">
                        <p className="text-md font-semibold">Phone Numbers</p>
                      </div>
                      <div
                        style={{ color: fontColor }}
                        className="flex-1 flex flex-col gap-2 border-b pb-4"
                      >
                        <div className="flex items-center justify-between pr-5 cursor-pointer">
                          <div className="flex gap-3 items-center">
                            <p>{userData && userData.contact_number}</p>
                            {userData && userData.contact_number ? (
                              <>
                                <span className="bg-[#E8EBFF] rounded-md p-2 font-semibold text-[#7168F3] text-xs">
                                  Primary
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="bg-[#FEE4E2] rounded-md p-2 font-semibold text-[#F04438] text-xs">
                                  Unverified
                                </span>
                              </>
                            )}
                          </div>
                          {/* <IoArrowForward /> */}
                        </div>
                        {/* <div
                        className="px-4 mt-1 py-2 flex justify-between items-center rounded-md text-blue-600 cursor-pointer hover:bg-[#C4D0FA]"
                        onMouseEnter={() => setPhoneHovered(true)}
                        onMouseLeave={() => setPhoneHovered(false)}
                      >
                        <p>
                          <span>+</span> Add a phone number
                        </p>
                        <IoArrowForward className={phoneHovered ? "" : "hidden"} />
                      </div> */}
                      </div>
                    </div>
                    <div
                      style={{ color: fontColor }}
                      className="flex-col gap-3 2xl:flex-row 2xl:gap-0 flex w-full"
                    >
                      <div className="basis-1/4 w-full ">
                        <p className="text-md font-semibold">
                          Connected accounts
                        </p>
                      </div>
                      <div className="flex-1 flex flex-col gap-2 pb-4">
                        <div className="flex items-center justify-between pr-5 cursor-pointer">
                          <div className="flex gap-3 items-center">
                            {/* <FcGoogle /> */}
                            <p>{userData && userData.email}</p>
                          </div>
                          {/* <IoArrowForward /> */}
                        </div>
                        {/* <div
                        className="px-4 mt-1 py-2 flex justify-between items-center rounded-md text-blue-600 cursor-pointer hover:bg-[#C4D0FA]"
                        onMouseEnter={() => setSocialHovered(true)}
                        onMouseLeave={() => setSocialHovered(false)}
                      >
                        <p>
                          <span>+</span> Connect Account
                        </p>
                        <IoArrowForward className={socialHovered ? "" : "hidden"} />
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ color: fontColor }}
                  className="flex bg-white flex-col w-full gap-2 custom-shadow rounded-lg p-5"
                >
                  <h2
                    style={{ color: fontColor }}
                    className="text-xl text-gray-700 w-full font-semibold  tracking-wider"
                  >
                    Security
                  </h2>
                  <p
                    style={{ color: fontColor }}
                    className="text-sm text-gray-500"
                  >
                    Manage your security preferences
                  </p>
                  <div className="flex flex-col items-center gap-8 py-2 mt-5">
                    <div className="flex-col 2xl:flex-row 2xl:gap-0 flex gap-3 w-full">
                      <div className="basis-1/4 w-full ">
                        <p className="text-md font-semibold">Password</p>
                      </div>
                      <div className="flex-1 flex flex-col gap-2 pb-4">
                        {emailSend ? (
                          <p className="bg-green-500 w-fit px-3 text-white py-1 rounded-md">{`Set Password Request Send To ${email}`}</p>
                        ) : null}
                        <div
                          onClick={handleSetPassword}
                          className="px-4 mt-1 py-2 flex justify-between items-center rounded-md text-blue-600 cursor-pointer hover:bg-[#C4D0FA]"
                          onMouseEnter={() => setChangePasswordHovered(true)}
                          onMouseLeave={() => setChangePasswordHovered(false)}
                        >
                          <p style={{ color: fontColor }}>
                            <span>+</span> Set Password
                          </p>
                          <IoArrowForward
                            className={changePasswordHovered ? "" : "hidden"}
                          />
                        </div>
                        <div
                          onClick={handleChangePassword}
                          className="px-4 mt-1 py-2 flex justify-between items-center rounded-md text-blue-600 cursor-pointer hover:bg-[#C4D0FA]"
                          onMouseEnter={() => setPasswordHovered(true)}
                          onMouseLeave={() => setPasswordHovered(false)}
                        >
                          <p style={{ color: fontColor }}>
                            <span>+</span> Change Password
                          </p>
                          <IoArrowForward
                            className={passwordHovered ? "" : "hidden"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChangePasswordPopUp
          visible={ChangePasswordVisible}
          click={handleChangePassword}
        />
        <ProfileUpdate
          visible={updateProfileVisible}
          click={handleProfileUpdate}
          refresh={refresh}
          setRefresh={setRefresh}
          success={success}
          setSuccess={setSuccess}
          userData={userData}
        />
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default Profile;
