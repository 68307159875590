import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fileData: [],
};

export const fileDataSlice = createSlice({
  name: "fileData",
  initialState,
  reducers: {
    setFileData(state, action) {
      state.fileData = action.payload;
    },
  },
});

export const { setFileData } = fileDataSlice.actions;
export default fileDataSlice.reducer;
