import React, { useEffect, useRef, useState } from "react";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import { CiMicrophoneOn } from "react-icons/ci";
import { VscCallOutgoing } from "react-icons/vsc";
import { RxSpeakerLoud } from "react-icons/rx";
import { useSelector } from "react-redux";
import axios from "axios";

function TestAgent() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor } = useSelector((state) => state.theme);
  const [showPopUp, setShowPopUp] = useState(false);
  const messageRef = useRef(null);
  const [agentID, setAgentID] = useState(localStorage.getItem("agentID"));
  const company_name = JSON.parse(localStorage.getItem("userData")).companies[0]
    .company_name;
  const [messages, setMessages] = useState([]);
  const [listening, setListening] = useState(false);
  const [status, setStatus] = useState("Disconnected");
  const [transcript, setTranscript] = useState("");
  const [audioUrl, setAudioUrl] = useState(""); // State to store audio URL
  const [isAgentAudioPlaying, setIsAgentAudioPlaying] = useState(false);
  const audioRef = useRef(new Audio());
  const mediaRecorderRef = useRef(null);
  const socketRef = useRef(null);
  const streamRef = useRef(null);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageRef.current) {
        messageRef.current.scrollTop = messageRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [messages]);

  const handleToggle = () => {
    setAudioUrl("");
    setShowPopUp(!showPopUp);
    stopAudioStream();
    setListening(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    const startMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        streamRef.current = stream;
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        const socket = new WebSocket("wss://api.deepgram.com/v1/listen", [
          "token",
          "0367e776dd77515fa6f15a9c848795fbb872360e",
        ]);
        socketRef.current = socket;

        socket.onopen = () => {
          setStatus("Connected");
          mediaRecorder.addEventListener("dataavailable", (event) => {
            if (event.data.size > 0 && socket.readyState === 1) {
              socket.send(event.data);
            }
          });
          mediaRecorder.start(150);
        };

        socket.onmessage = async (message) => {
          const received = JSON.parse(message.data);
          const newTranscript = received.channel.alternatives[0]?.transcript;
          if (newTranscript && received.is_final) {
            setTranscript((prevTranscript) => {
              setMessages((prevMessages) => {
                const updatedMessages = [
                  ...prevMessages,
                  {
                    role: "user",
                    content: [{ type: "text", text: newTranscript }],
                  },
                ];

                // Send the updated message history to the API
                sendMessageToAPI(updatedMessages);

                return updatedMessages;
              });
              return prevTranscript + newTranscript + " ";
            });

            if (received.audio_url) {
              setAudioUrl(received.audio_url);
            }
          }
        };

        socket.onclose = () => {
          // console.log({ event: "onclose" });
          setStatus("Disconnected");
        };

        socket.onerror = (error) => {
          console.log({ event: "onerror", error });
        };
      } catch (error) {
        console.error("Error accessing media devices.", error);
      }
    };

    if (listening) {
      startMedia();
    } else {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
      if (socketRef.current) {
        socketRef.current.close();
      }
    }

    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      if (messages && messages.length > 0) {
        setMessages([]);
      }
      if (audioUrl) {
        setAudioUrl("");
      }
    };
  }, [listening, baseUrl, agentID, company_name, showPopUp]);

  const sendMessageToAPI = async (updatedMessages) => {
    const startMessage = {
      role: "user",
      content: [
        {
          type: "text",
          text: "Start",
        },
      ],
    };

    const messagesToSend = [startMessage, ...updatedMessages];

    setMessages(updatedMessages);
    try {
      const response = await axios.post(
        `${baseUrl}/api/test_agent/chat/${agentID}/`,
        {
          history: messagesToSend,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.status === 200) {
        // console.log(response.data.data[0].content[0]);
        const filteredMessages = response.data.data.filter(
          (msg, index) =>
            index !== 0 || msg.content[0].text.toLowerCase() !== "start"
        );

        setMessages(filteredMessages);
        if (response.data.audio) {
          const encodedAudio = response.data.audio;
          const byteCharacters = atob(encodedAudio);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Create a Blob from the byte array
          const blob = new Blob([byteArray], { type: "audio/mp3" });

          // Set the Blob as the audio source
          const url = URL.createObjectURL(blob);
          setAudioUrl(url);
        }
      }
    } catch (error) {
      console.error("Error sending message to API:", error);
    }
  };

  useEffect(() => {
    if (audioUrl) {
      audioRef.current.src = audioUrl;
      audioRef.current
        .play()
        .then(() => {
          setIsAgentAudioPlaying(true);
          audioRef.current.onended = () => {
            setIsAgentAudioPlaying(false);
          };
        })
        .catch((error) => {
          console.error("Failed to play audio:", error);
        });
    }
  }, [audioUrl]);

  const toggleListening = () => {
    setAudioUrl("");
    sendMessageToAPI([]);
    setListening(!listening);
  };

  useEffect(() => {
    stopAudioStream();
  }, [listening]);

  const stopAudioStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  return (
    <>
      <button
        onClick={handleToggle}
        style={{ color: fontColor }}
        type="button"
        className="text-gray-900 bg-white flex gap-3 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
      >
        <IoCallOutline className="text-xl" /> Test Agents
      </button>
      <div
        className={`${
          showPopUp ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          showPopUp ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300 cursor-default`}
      >
        <div
          // data-dialog="sign-in-dialog"
          className="relative mx-auto flex overflow-hidden justify-center items-center flex-col w-full min-h-[80%] h-[80%] max-h-[80%] max-w-[40rem] rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
        >
          <div className="bg-white rounded-xl w-full h-full flex flex-col justify-start items-center flex-1 gap-4 p-6">
            <div className="flex bg-white w-full justify-between items-center">
              <h4
                style={{ color: fontColor }}
                className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
              >
                Test Agent
              </h4>
              <div onClick={handleToggle} className="cursor-pointer">
                <MdOutlineClose className="text-2xl" />
              </div>
            </div>
            <div className="bg-white flex-1 max-h-[80%] rounded-xl custom-shadow w-full max-w-3xl">
              <div
                ref={messageRef}
                className=" h-full bg-white rounded-md overflow-auto px-6 py-4"
              >
                {messages.length > 0 &&
                  messages.map((message, index) => (
                    <div
                      key={index}
                      className={`mb-4 flex ${
                        message.role === "user"
                          ? "justify-end"
                          : "justify-start"
                      } items-center`}
                    >
                      <div
                        className={`${
                          message.role === "user" ? "hidden" : ""
                        } basis-[10%]`}
                      >
                        <img
                          src="https://cdn.dribbble.com/users/1740191/screenshots/6287147/eva.png"
                          alt=""
                          className="w-20"
                        />
                      </div>
                      <div className={`flex-1 `}>
                        <div
                          style={{ color: fontColor }}
                          className={`w-fit rounded-lg  p-3 inline-block ${
                            message.role === "user"
                              ? "bg-[#d2edd4] float-end"
                              : "bg-[#f5f5f5]"
                          }`}
                        >
                          <span>
                            {/* {message.role === "user"
                              ? message?.content.map((text, index) => (
                                  <div key={index}>{text.text}</div>
                                ))
                              : ""} */}
                            {message?.content.map((text, index) => (
                              <div key={index}>{text.text}</div>
                            ))}
                            {/* {message?.content?.map((text, index) => {
                              // Extract content between <response> tags
                              const responseMatch = text.text.match(
                                /<response>(.*?)<\/response>/
                              );
                              const responseText = responseMatch
                                ? responseMatch[1]
                                : "";

                              return <div key={index}>{responseText}</div>;
                            })} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <hr className="border-t-2 border-gray-200" />
              <div className="w-full bg-white flex justify-center items-center h-20">
                <div className="cursor-pointer" onClick={toggleListening}>
                  <div
                    className={`relative ${listening ? "animate-pulse" : ""}`}
                  >
                    <div className="w-14 flex justify-center items-center h-14 bg-gradient-to-b from-primary to-secondary rounded-full">
                      {isAgentAudioPlaying ? (
                        <div className="relative">
                          <RxSpeakerLoud className="text-3xl text-white animate-pulse-slow" />
                          <div className="absolute inset-0 bg-white opacity-75 rounded-full animate-ping"></div>
                        </div>
                      ) : listening ? (
                        <CiMicrophoneOn className="text-4xl text-white" />
                      ) : (
                        <div className="relative">
                          <VscCallOutgoing className="text-3xl text-white" />
                          <div className="absolute inset-0 ring-offset-8 bg-white opacity-75 rounded-full animate-ping"></div>
                          <div className="absolute inset-0 bg-transparent opacity-75 rounded-full animate-pulse"></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestAgent;
