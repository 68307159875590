import React, { useState } from "react";
import toast from "react-hot-toast";
import { GoOrganization } from "react-icons/go";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const { fontColor } = useSelector((state) => state.theme);
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [email, setEmail] = useState("");
  const full_name = `${
    JSON.parse(localStorage.getItem("userData"))?.first_name ?? ""
  } ${JSON.parse(localStorage.getItem("userData"))?.last_name ?? ""}`;
  const userEmail = JSON.parse(localStorage.getItem("userData")).email;
  const company_name = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;

  const navigate = useNavigate()

  const handleSave = () => {
    setEditMode(false);
  };

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setProfilePic(imageUrl);
  };

  const handleLeaveOrg = () => {
    setShowConfirmation(!showConfirmation);
    setEmail("");
  };

  const handleConfirmLeaveOrg = async () => {
    if (userEmail == email) {
      try {
        const response = await fetch(`${baseUrl}/api/leave_member/`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          setEmail("")
          navigate("/onboarding")
          toast.success("Organization Delete Successfully")
        }
        else if(response.status === 400){
          const responseData = await response.json();
          toast.error(responseData.message)
        }
        else {
          toast.error("Something went wrong")
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Invalid Email Address")
    }
  };

  return (
    <div className="space-y-5 p-5 flex flex-col">
      <div style={{ color: fontColor }} className="space-y-5">
        <h1 className="text-3xl">Settings</h1>
        <p className="text-xl">Manage organization settings</p>
      </div>
      <div className="space-y-4">
        <p style={{ color: fontColor }} className="text-xl">Organization Profile</p>
        <hr />
        <button
          className="flex flex-col items-start gap-3 pb-1 "
          onClick={() => setEditMode(true)}
        >
        <div className="flex gap-4">
          <div style={{ color: fontColor }} className="rounded-xl flex bg-brown-100 p-1 text-white items-center justify-center">
            {profilePic ? (
              <img
                src={profilePic}
                alt="Profile"
                className="w-10 h-10 rounded-lg"
              />
            ) : (
              <GoOrganization className="w-7 rounded-lg text-black" />
            )}
          </div>
          <div className="flex flex-row justify-start items-start">
              <p style={{ color: fontColor }} className="text-gray-500">{company_name}</p>
            </div>
            </div>
        </button>
      </div>
      <div className="space-y-3">
        <h1 style={{ color: fontColor }} className="text-xl">Caution</h1>
        <hr />
        <button
          className="pl-4 font-bold text-sm text-red-500 border lg:w-[40%] w-full border-red-400 rounded-lg h-8"
          onClick={handleLeaveOrg}
        >
          LEAVE ORGANIZATION
        </button>
        {showConfirmation && (
          <div className="flex flex-col">
            <div className="flex flex-col gap-2 p-2">
              <p className="text-sm font-semibold">
                This action is permanent and irreversible.
              </p>
              <p className="text-sm">Type your email below to continue:</p>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-3 mt-1 items-start ">
              <input
                type="email"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-400 rounded-xl h-9 "
              />
              <button
                type="button"
                className="bg-gray-900 hover:bg-black text-white p-1 rounded"
                onClick={handleConfirmLeaveOrg}
              >
                Confirm
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
