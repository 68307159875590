import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  saveScript: false,
};

export const saveScriptSlice = createSlice({
  name: "saveScript",
  initialState,
  reducers: {
    setSaveScript(state, action) {
      state.saveScript = action.payload;
    },
  },
});

export const { setSaveScript } = saveScriptSlice.actions;
export default saveScriptSlice.reducer;
