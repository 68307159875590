import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function GhlSuccess() {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const [clientId, setClientId] = useState("");
  const [state, setState] = useState("");

  // console.log(clientId, state);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setClientId(params.get("code"));
    setState(params.get("state"));

    const fetchData = async () => {
      // setLoading(true);
      try {
        const response = await fetch(`${baseUrl}/api/ghl/connect/user/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: params.get("code"),
            state: params.get("state")   
          })
        });
        if (response.ok) {
          // const jsonData = await response.json();
          // const fetchedData = jsonData?.data?.White_lable_Agency;
          // setData(fetchedData);
          // setLoading(false);
          // setResellPrice(fetchedData.reselling_price || "");
          // setLegalName(fetchedData.legal_name || "");
          // setStripeKey(fetchedData.stripe_key || "");
          // setEmail(fetchedData.email || "");
          // setContent(fetchedData.content || "");
          // setColor1(fetchedData.color1 || "");
          // setColor2(fetchedData.color2 || "");
          // setRecords(fetchedData.dns_record || "");
          // setContent(fetchedData.email_template || "");
          // setDomain(fetchedData.domain || "");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        //   setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="min-h-screen flex items-center justify-center bg-gray-100 p-4"
      >
        <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 mx-auto text-green-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
          <h2
            style={{ color: fontColor }}
            className="text-2xl font-semibold text-center mb-4"
          >
            Connection Successfull
          </h2>
          <p
            style={{ color: fontColor }}
            className="text-gray-600 text-center mb-4"
          >
            Thank you for choosing Closerx
          </p>
          <p
            style={{ color: fontColor }}
            className="text-gray-600 text-center mb-8"
          ></p>
        </div>
      </div>
    </>
  );
}

export default GhlSuccess;
