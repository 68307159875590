import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import WelcomeAgent from "../components/WelcomeAgent";
import MobVerify from "../components/MobVerify";
import AgentList from "../components/AgentList";
import { useDispatch, useSelector } from "react-redux";
import { setAgentData } from "../features/Agents/agentData";
import CreateAgent from "../components/CreateAgent";
import { PulseLoader } from "react-spinners";
import ImportAgent from "../components/ImportAgent";
import { Toaster } from "react-hot-toast";
import Intercom from "@intercom/messenger-js-sdk";

function CreatePage() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [loading, setLoading] = useState(false);
  const agentData = useSelector((state) => state.agentData.agentData);
  const [showMobVerify, setShowMobVerify] = useState(false);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [userData, setUserData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [resStatus, setResStatus] = useState("");
  const [userDetail, setUserDetail] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );
  const refresh2 = useSelector((state) => state.refresh.refresh);
  const [pageHeaderData, setPageHeaderData] = useState([
    {
      tittle: "Agent",
      link: "/create/agent",
    },
    {
      tittle: "Knowledge Bases",
      link: "/create/knowledge-bases",
    },
    {
      tittle: "Lists",
      link: "/create/list",
    },
  ]);
  const dispatch = useDispatch();

  const handleGetAgent = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}/api/agents/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        dispatch(setAgentData(responseData.data.agents));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
      if (!parsedUserData.contact_number) {
        setShowMobVerify(true);
      }
    }
    handleGetAgent();
  }, [refresh, setRefresh, refresh2]);

  if (baseUrl == "https://app.closerx.ai") {
    Intercom({
      app_id: "p6t03dhm",
      user_id: userDetail?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: userDetail?.first_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: userDetail?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
  }

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor }}
        className="relative w-full h-screen flex flex-col"
      >
        <Navbar />
        <div
          className={`flex ${
            resStatus ? "justify-between" : "justify-end"
          }  pt-5 flex-col md:flex-row items-center`}
        >
          {/* <PageHeader buttons={pageHeaderData} /> */}
          <h2 className="pl-4 text-red-500">{resStatus}</h2>
          {agentData && agentData.length != 0 ? (
            <div className="mr-4 ">
              <ImportAgent />
              <CreateAgent />
            </div>
          ) : (
            ""
          )}
        </div>
        {agentData && agentData.length != 0 ? (
          <AgentList
            agentData={agentData}
            refresh={refresh}
            setRefresh={setRefresh}
            setResStatus={setResStatus}
            resStatus={resStatus}
          />
        ) : (
          <WelcomeAgent />
        )}
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default CreatePage;
