import React from "react";
import Navbar from "./Navbar";
import Agreement from "./Agreement";
import { useSelector } from "react-redux";

const CampaignHome = ({data}) => {
  const { fontColor,backgroundColor } = useSelector((state) => state.theme);
  return (
    <>
        <div style={{ backgroundColor: backgroundColor }} className="w-full relative flex-1 flex flex-col justify-center ">
          <div className="text-center flex flex-col justify-center items-center">
            <div  className="text-4xl font-bold flex justify-center items-center ">
              <span style={{ color: fontColor }} className="bg-clip-text text-transparent p-1 bg-gradient-to-r from-gray-800 to-gray-700 lg:text-5xl">
                Create And Launch Your First Campaign
              </span>
            </div>
            <div className="my-6 flex justify-center">
              <p style={{ color: fontColor }} className="text-lg sm:basis-3/4 p-4 lg:basis-1/2 lg:text-3xl">
                Launch your AI agent and have it get on live calls by clicking
                the button below and creating your first campaign. Creating a
                campaign is easy and can be done in about 60 seconds.
              </p>
            </div>
            <Agreement activeTab={data} />
          </div>
        </div>
    </>
  );
};

export default CampaignHome;
