import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import {
  createAgentSchema,
  createAgentStepThreeSchema,
  createAgentStepTwoSchema,
} from "../schemas/FormSchema";
import { MdOutlineArrowBackIos, MdRebaseEdit } from "react-icons/md";
import { TbMessageChatbot } from "react-icons/tb";
import { LuImport } from "react-icons/lu";
import { BsFileEarmarkPlay, BsFileEarmarkText } from "react-icons/bs";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";

function CreateAgent() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const company_name = JSON.parse(localStorage.getItem("userData"))
    ?.companies[0]?.company_name;
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [file, setFile] = useState("");
  const audioInputRef = useRef(null);
  const navigate = useNavigate();
  const textInputRef = useRef(null);
  const [createAgentData, setCreateAgentData] = useState({
    agentName: "",
    projectName: "",
    callType: "",
    companyName: "",
    option: "",
  });

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
    setStep(1);
    formMik.values.agentName = "";
    formMik.values.projectName = "";
    formMikStep2.values.callType = "";
    formMikStep2.values.companyName = "";
    formMikStep3.values.option = "";
  };

  const formMik = useFormik({
    initialValues: {
      agentName: "",
      projectName: "",
    },
    validationSchema: createAgentSchema,
    onSubmit: async (values) => {
      if (step != 3) {
        setStep(step + 1);
      }
      localStorage.setItem("agentName", values.agentName);
      localStorage.setItem("projectName", values.projectName);
    },
  });

  const formMikStep2 = useFormik({
    initialValues: {
      callType: "",
      companyName: "",
    },
    validationSchema: createAgentStepTwoSchema,
    onSubmit: async (values, actions) => {
      if (step != 3) {
        setStep(step + 1);
      }
      localStorage.setItem("callType", values.callType);
      localStorage.setItem("companyName", values.companyName);
    },
  });

  const formMikStep3 = useFormik({
    initialValues: {
      option: "",
    },
    validationSchema: createAgentStepThreeSchema,
    onSubmit: async (values, actions) => {
      setCreateAgentData((prevValue) => ({
        ...prevValue,
        option: values.option,
      }));
      if (values.option == "scratch") {
        const formdata = {
          name: localStorage.getItem("agentName"),
          project_name: localStorage.getItem("projectName"),
          direction: localStorage.getItem("callType"),
          company_name: localStorage.getItem("companyName"),
        };
        setLoading(true);
        try {
          const response = await fetch(`${baseUrl}/api/agents/scrach/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Company-Name": company_name,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify(formdata),
          });
          if (response.ok) {
            const responseData = await response.json();
            localStorage.removeItem("agentName");
            localStorage.removeItem("projectName");
            localStorage.removeItem("callType");
            localStorage.removeItem("companyName");
            localStorage.setItem("agentID", responseData.data.agent.id);
            toast.success("Agent created successfully");
            setStep(step + 1);
          } else if (response.status === 400) {
            const responseData = await response.json();
            toast.error(responseData.message);
          } else {
            toast.error("Something went wrong");
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      } else {
        setStep(step + 1);
      }
    },
  });

  const handleAudioUploadClick = () => {
    if (audioInputRef.current) {
      audioInputRef.current.click();
    }
  };

  const handleTextUploadClick = () => {
    if (textInputRef.current) {
      textInputRef.current.click();
    }
  };

  const handleAudioSelected = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleTextSelected = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const uploadFile = async () => {
    const name = localStorage.getItem("agentName");
    const callType = localStorage.getItem("callType");
    const companyName = localStorage.getItem("companyName");
    const projectName = localStorage.getItem("projectName");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);
      formData.append("direction", callType);
      formData.append("project_name", projectName);
      formData.append("company_name", companyName);

      setLoading(true);

      const response = await fetch(
        `${baseUrl}/api/agents/script/upload_file/`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        localStorage.removeItem("agentName");
        localStorage.removeItem("projectName");
        localStorage.removeItem("callType");
        localStorage.removeItem("companyName");
        localStorage.setItem("agentID", responseData.data.agent.id);
        navigate("/flows");
        toast.success("Agent Create Successfully!");
      } else if (response.status === 400) {
        const responseData = await response.json();
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error("File upload error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <>
      <button
        style={{ color: fontColor }}
        onClick={toggleModal}
        className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
        type="button"
      >
        <span
          style={{ backgroundColor: backgroundColor }}
          className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
        >
          Create AI Agent
        </span>
      </button>

      <div
        className={`${
          isModalVisible ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          isModalVisible ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative px-2 py-4 bg-white rounded-lg shadow dark:bg-gray-700">
            <div
              className={`basis-full w-full px-2 py-2 flex ${
                step == 1 ? "justify-end" : "justify-between"
              } items-center`}
            >
              {step == 1 ? null : (
                <div onClick={handleBack}>
                  <MdOutlineArrowBackIos className="text-xl cursor-pointer" />
                </div>
              )}
              <button type="button" className="text-3xl" onClick={toggleModal}>
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {step == 1 && (
              <>
                <form
                  onSubmit={formMik.handleSubmit}
                  className="flex flex-wrap justify-start items-center"
                >
                  <div className="basis-full p-4 flex justify-start items-center">
                    <label
                      htmlFor="agentName"
                      style={{ color: fontColor }}
                      className="font-medium w-28 block text-left"
                    >
                      Agent Name:
                    </label>
                    <input
                      type="text"
                      id="agentName"
                      name="agentName"
                      placeholder="Agent Name*"
                      value={formMik.values.agentName}
                      onChange={formMik.handleChange}
                      onBlur={formMik.handleBlur}
                      className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  {formMik.errors.agentName && formMik.touched.agentName ? (
                    <p className="w-full text-red-600 m-[-7px] capitalize font-medium text-left ml-4">
                      {formMik.errors.agentName}
                    </p>
                  ) : null}
                  <div className="basis-full p-4 flex justify-between items-center">
                    <label
                      style={{ color: fontColor }}
                      htmlFor="name"
                      className="font-medium w-28 text-left block"
                    >
                      Project Name:
                    </label>
                    <input
                      type="text"
                      id="projectName"
                      name="projectName"
                      placeholder="Project Name"
                      value={formMik.values.projectName}
                      onChange={formMik.handleChange}
                      onBlur={formMik.handleBlur}
                      className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  {formMik.errors.projectName && formMik.touched.projectName ? (
                    <p className="w-full text-red-600 m-[-7px] capitalize font-medium text-left ml-4">
                      {formMik.errors.projectName}
                    </p>
                  ) : null}
                  <div className="flex justify-center items-center w-full gap-4 mt-2">
                    <button
                      // onClick={handleContinue}
                      type="submit"
                      className="text-white inline-flex justify-center bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </>
            )}

            {step == 2 && (
              <>
                <form
                  onSubmit={formMikStep2.handleSubmit}
                  className="flex flex-wrap justify-start items-center"
                >
                  <div className="basis-full p-4 flex justify-between items-center gap-3">
                    <label
                      style={{ color: fontColor }}
                      htmlFor="callType"
                      className="font-medium"
                    >
                      Inbound/Outbound:
                    </label>
                    <select
                      id="callType"
                      name="callType"
                      value={formMikStep2.values.callType}
                      onChange={formMikStep2.handleChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option default>Select Call Direction</option>
                      <option value="Inbound">Inbound</option>
                      <option value="Outbound">Outbound</option>
                    </select>
                  </div>
                  {formMikStep2.errors.callType ? (
                    <p className="w-full text-red-600 m-[-7px] capitalize font-medium text-left ml-4">
                      {formMikStep2.errors.callType}
                    </p>
                  ) : null}
                  <div className="basis-full p-4 w-full flex justify-between items-center">
                    <label
                      style={{ color: fontColor }}
                      htmlFor="name"
                      className="font-medium block text-left w-32"
                    >
                      Company Name:
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      placeholder="Company Name*"
                      value={formMikStep2.values.companyName}
                      onChange={formMikStep2.handleChange}
                      onBlur={formMikStep2.handleBlur}
                      className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  {formMikStep2.errors.companyName &&
                  formMikStep2.touched.companyName ? (
                    <p className="w-full text-red-600 m-[-7px] capitalize font-medium text-left ml-4">
                      {formMikStep2.errors.companyName}
                    </p>
                  ) : null}
                  <div className="flex justify-center items-center w-full gap-4 mt-2">
                    <button
                      // onClick={handleContinue}
                      type="submit"
                      className="text-white inline-flex justify-center bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </>
            )}

            {step == 3 && (
              <>
                <form
                  onSubmit={formMikStep3.handleSubmit}
                  className="flex flex-wrap justify-start items-center"
                >
                  <div className="basis-full overflow-y-scroll md:overflow-hidden h-[500px] md:h-full px-4 pt-4 flex justify-start flex-col items-start">
                    <ul className="mb-4 flex flex-col md:flex-row justify-between items-center w-full  rounded-lg">
                      <li className="basis-1/3">
                        <input
                          type="radio"
                          id="createConversationally"
                          name="option"
                          value="createConversationally"
                          checked={
                            formMikStep3.values.option ===
                            "createConversationally"
                          }
                          onChange={formMikStep3.handleChange}
                          className="hidden peer"
                        />
                        <label
                          htmlFor="createConversationally"
                          className="inline-flex h-48 items-center justify-between w-full p-5 text-gray-900 bg-white border md:border-r-0 border-gray-200  cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 md:rounded-l-lg  peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                        >
                          <div
                            style={{ color: fontColor }}
                            className="flex justify-center items-center flex-col"
                          >
                            <TbMessageChatbot className="text-4xl" />
                            <div
                              style={{ color: fontColor }}
                              className="w-full text-md text-center my-2 font-medium"
                            >
                              Create Conversationally
                            </div>
                            <p
                              style={{ color: fontColor }}
                              className="text-sm text-center text-[#111827]"
                            >
                              Effortlessly craft the content for voice bot
                              simply by a chat.
                            </p>
                          </div>
                        </label>
                      </li>
                      <li className="basis-1/3">
                        <input
                          type="radio"
                          id="import"
                          name="option"
                          value="import"
                          checked={formMikStep3.values.option === "import"}
                          onChange={formMikStep3.handleChange}
                          className="hidden peer"
                        />
                        <label
                          htmlFor="import"
                          className="inline-flex h-48 items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500  peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                        >
                          <div
                            style={{ color: fontColor }}
                            className="flex justify-center items-center flex-col"
                          >
                            <LuImport className="text-4xl" />
                            <div className="w-full text-md text-center my-2 font-medium">
                              Import
                            </div>
                            <p
                              style={{ color: fontColor }}
                              className="text-sm text-center text-[#111827]"
                            >
                              Automatically create your voice bot by uploading
                              knowledge bases, documents, or links.
                            </p>
                          </div>
                        </label>
                      </li>
                      <li className="basis-1/3">
                        <input
                          type="radio"
                          id="scratch"
                          name="option"
                          value="scratch"
                          checked={formMikStep3.values.option === "scratch"}
                          onChange={formMikStep3.handleChange}
                          className="hidden peer"
                        />
                        <label
                          htmlFor="scratch"
                          className="inline-flex h-48 items-center justify-between w-full p-5 text-gray-900 bg-white border md:border-l-0 border-gray-200 md:rounded-r-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500  peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                        >
                          <div
                            style={{ color: fontColor }}
                            className="flex justify-center items-center flex-col"
                          >
                            <MdRebaseEdit className="text-[33px]" />
                            <div className="w-full text-md text-center my-2 font-medium">
                              Create From Scratch
                            </div>
                            <p
                              style={{ color: fontColor }}
                              className="text-sm text-center text-[#262e40]"
                            >
                              Manually craft the voice bot from the ground up.
                            </p>
                          </div>
                        </label>
                      </li>
                    </ul>
                    {formMikStep3.errors.option ? (
                      <p className="w-full text-red-600 m-[-7px] capitalize font-medium text-left ml-4">
                        {formMikStep3.errors.option}
                      </p>
                    ) : null}
                    {loading && (
                      <div className="w-full h-[85%] flex justify-center items-center bg-white absolute top-10 left-0">
                        <PulseLoader color="#36d7b7" loading={loading} />
                      </div>
                    )}
                    <div className="flex justify-center items-center w-full gap-4 mt-2">
                      <button
                        // data-modal-hide="select-modal"
                        type="submit"
                        className="text-white inline-flex justify-center bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}

            {step == 4 && formMikStep3.values.option == "import" ? (
              <>
                <div className="basis-full px-4 pt-4 flex justify-start flex-col items-start">
                  <ul className="mb-4 flex justify-between items-center w-full  rounded-lg">
                    <li className="basis-1/2">
                      <div
                        onClick={handleAudioUploadClick}
                        className="inline-flex  items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500  peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                      >
                        <div
                          style={{ color: fontColor }}
                          className="flex justify-center items-center flex-col"
                        >
                          <BsFileEarmarkPlay className="text-4xl" />
                          <div className="w-full text-md text-center my-2 font-medium capitalize">
                            upload Recorded Script
                          </div>
                          <p
                            style={{ color: fontColor }}
                            className="text-sm text-center text-[#111827]"
                          >
                            Use your recorded script to generate the AI Calling
                            bot that follows your specified dialogue and
                            instructions automatically.
                          </p>
                        </div>
                      </div>
                      <input
                        type="file"
                        ref={audioInputRef}
                        style={{ display: "none" }}
                        accept=".mp3,audio/*"
                        onChange={handleAudioSelected}
                      />
                    </li>
                    <li className="basis-1/2">
                      <div
                        onClick={handleTextUploadClick}
                        className="inline-flex  items-center justify-between w-full p-5 text-gray-900 bg-white border border-l-0 border-gray-200 rounded-r-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500  peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
                      >
                        <div
                          style={{ color: fontColor }}
                          className="flex justify-center items-center flex-col"
                        >
                          <BsFileEarmarkText className="text-[33px]" />
                          <div className="w-full text-center text-md my-2 font-medium capitalize">
                            upload Script
                          </div>
                          <p
                            style={{ color: fontColor }}
                            className="text-sm text-center text-[#111827]"
                          >
                            Upload your script to create the AI Calling bot
                            automatically.
                            <br />
                            (Accepted file format: .txt extension only)
                          </p>
                        </div>
                      </div>
                      <input
                        type="file"
                        ref={textInputRef}
                        accept="text/plain, "
                        style={{ display: "none" }}
                        onChange={handleTextSelected}
                      />
                    </li>
                  </ul>
                  {loading && (
                    <div className="w-full h-[85%] flex justify-center items-center bg-white absolute top-10 left-0">
                      <PulseLoader color="#36d7b7" loading={loading} />
                    </div>
                  )}
                  {file && (
                    <div className="flex justify-center items-center w-full gap-4 mt-2">
                      Selected file : {file.name}
                    </div>
                  )}
                  <div className="flex justify-center items-center w-full gap-4 mt-2">
                    <button
                      onClick={uploadFile}
                      className="text-white inline-flex justify-center bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            {step == 4 && formMikStep3.values.option == "scratch" ? (
              <>
                <Navigate to="/flows" />
              </>
            ) : null}

            {step == 4 &&
            formMikStep3.values.option == "createConversationally" ? (
              <>
                <Navigate to="/conversationally/chatbot" />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateAgent;
