import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listName: "",
};

export const listNameSlice = createSlice({
  name: "listName",
  initialState,
  reducers: {
    setListName(state, action) {
      state.listName = action.payload;
    },
  },
});

export const { setListName } = listNameSlice.actions;
export default listNameSlice.reducer;
