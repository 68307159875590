import { Button } from "@material-tailwind/react";
import React from "react";
import { MdOutlineFileDownload } from "react-icons/md";

function RecordingsCSV({ data }) {
  const convertToCSV = (data) => {
    // Generate headers from the first object's keys
    const headers = Object.keys(data[0]).join(",");

    // Generate rows
    const rows = data.map((row) => {
      return Object.values(row)
        .map((value) => {
          if (typeof value === "object" && value !== null) {
            // Convert objects to JSON strings
            return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
          }
          // If the value is a string with commas or special characters, wrap it in quotes
          if (typeof value === "string" && (value.includes(",") || value.includes("\n"))) {
            return `"${value.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
          }
          return value;
        })
        .join(",");
    });

    // Join headers and rows with newline characters
    return `${headers}\n${rows.join("\n")}`;
  };

  const downloadCSV = (data) => {
    const csvData = convertToCSV(data);
    console.log(csvData);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Button
        onClick={() => downloadCSV(data)}
        className="flex items-center gap-2"
        size="sm"
      >
        <MdOutlineFileDownload className="text-xl" /> CSV
      </Button>
    </>
  );
}

export default RecordingsCSV;
