import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { voiceMailSchema } from "../schemas/FormSchema";
import toast, { Toaster } from "react-hot-toast";
import { setRefresh } from "../features/Refresh/refresh";

function VoiceMailPopUp({ data }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const refresh = useSelector((state) => state.refresh.refresh);
  const [showPopUp, setShowPopUp] = useState(false);
  const handleToggle = () => {
    setShowPopUp(!showPopUp);
  };
  const { fontColor } = useSelector((state) => state.theme);
  const agentID = localStorage.getItem("agentID");
  const company_name = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      voiceText: "",
    },
    validationSchema: voiceMailSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            voice_mail_text: values.voiceText,
          }),
        });
        if (response.ok) {
          handleToggle();
          toast.success("Voice mail setup successfully");
          dispatch(setRefresh(!refresh));
        } else if (response.status === 400) {
          const responseData = await response.json();
          toast.error(responseData.message);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleDelete = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          voice_mail_text: "",
        }),
      });
      if (response.ok) {
        handleToggle();
        dispatch(setRefresh(!refresh));
        toast.success("Voice mail Update successfully");
      } else if (response.status === 400) {
        const responseData = await response.json();
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data) {
      formik.setValues({ voiceText: data.voice_mail_text || "" });
    }
  }, [data]);

  return (
    <>
      {/* <div>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
            },

            // Default options for specific types
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
      </div> */}
      <button
        style={{ color: fontColor }}
        className="select-none rounded-lg  2xl:text-lg text-center align-middle text-xs font-semibold"
        type="button"
        onClick={handleToggle}
      >
        Voice Mail Message
      </button>
      <div
        className={`${
          showPopUp ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          showPopUp ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div className="relative mx-auto flex w-full max-w-[24rem] flex-col rounded-xl bg-white h-auto bg-clip-border text-gray-700 shadow-md">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-4 p-6">
              <div className="flex justify-between items-center">
                <h4
                  style={{ color: fontColor }}
                  className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
                >
                  Voice Mail Message
                </h4>
                <div onClick={handleToggle}>
                  <MdOutlineClose className="text-2xl cursor-pointer" />
                </div>
              </div>
              <h6
                style={{ color: fontColor }}
                className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
              >
                Message
              </h6>
              <div className="relative w-full min-w-[200px]">
                <textarea
                  className="w-full h-[100px] resize-none px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200  text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder="Type your message...."
                  name="voiceText"
                  id="voiceText"
                  value={formik.values.voiceText}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.errors.voiceText && formik.touched.voiceText ? (
                <p className="w-full text-red-600 mx-[7px] font-medium">
                  {formik.errors.voiceText}
                </p>
              ) : null}
            </div>
            <div className="p-6 pt-0">
              <button
                className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="submit"
              >
                Save
              </button>
            </div>
            {data && data.voice_mail_text ? (
              <div className="p-6 pt-0">
                <button
                  onClick={handleDelete}
                  className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  Reset
                </button>
              </div>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default VoiceMailPopUp;
