import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import PageHeader from "../components/PageHeader";
import SuperAdminUserView from "../components/SuperAdminUserView";

function SuperAdminUserPage() {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [pageHeaderData, setPageHeaderData] = useState([
    {
      tittle: "logs",
      link: "/superAdmin",
    },
    {
      tittle: "Users",
      link: "/superAdmins/user-view",
    },
    {
      tittle: "Notification",
      link: "/superAdmins/notification",
    },
  ]);

  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full"
      >
        <Navbar />
        <div className="flex-1 p-4">
          <PageHeader buttons={pageHeaderData} />
          <SuperAdminUserView />
        </div>
      </div>
    </>
  );
}

export default SuperAdminUserPage;
