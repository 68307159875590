import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import AgencyOnboarding from "./AgencyOnboarding";
import AgencyCustom from "./AgencyCustom";
import PageHeader from "./PageHeader";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import ManageSubsciption from "./ManageSubsciption";
import UpgardeSubs from "./UpgardeSubs";

function Agency() {
  const [data, setData] = useState([]);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const refresh = useSelector((state) => state.refresh.refresh);
  const [loading, setLoading] = useState(false);

  // console.log(data);
  const [pageHeaderData, setPageHeaderData] = useState([
    // {
    //   tittle: "Onboarding",
    //   link: "/agency/onboarding",
    // },
    {
      tittle: "Customize",
      link: "/agency",
    },
    {
      tittle: "Client Portal",
      link: "/agencys/analytics",
    },
  ]);

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}/api/white_label_domain_user/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const jsonData = await response.json();
        const fetchedData = jsonData?.data?.White_lable_Agency;
        setData(fetchedData);
        setLoading(false);
        // setResellPrice(fetchedData.reselling_price || "");
        // setLegalName(fetchedData.legal_name || "");
        // setStripeKey(fetchedData.stripe_key || "");
        // setEmail(fetchedData.email || "");
        // setContent(fetchedData.content || "");
        // setColor1(fetchedData.color1 || "");
        // setColor2(fetchedData.color2 || "");
        // setRecords(fetchedData.dns_record || "");
        // setContent(fetchedData.email_template || "");
        // setDomain(fetchedData.domain || "");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <>
        {loading && (
          <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
            <PulseLoader color="#36d7b7" loading={loading} />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div className="flex flex-col w-full min-h-screen">
        <Navbar />
        <div className="flex justify-between items-center">
          {data && data.activate ? <PageHeader buttons={pageHeaderData} /> : ""}
          <div className="pr-5 flex gap-3 items-center flex-col md:flex-row">
            {data && data.activate ? (
              <>
                <UpgardeSubs data={data && data.subsription_id} />
                <ManageSubsciption data={data && data.subsription_id} />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {data && data.activate ? (
          <AgencyCustom data={data && data} />
        ) : (
          <AgencyOnboarding />
        )}
      </div>
    </>
  );
}

export default Agency;
