import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEditing: false,
};

export const isEditingSlice = createSlice({
  name: "isEditing",
  initialState,
  reducers: {
    setIsEditing(state, action) {
      state.isEditing = action.payload;
    },
  },
});

export const { setIsEditing } = isEditingSlice.actions;
export default isEditingSlice.reducer;
