import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ForgetPasswordSchema } from "../schemas/FormSchema";
import { useSelector } from "react-redux";

function ForgetPassword() {
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const { fontColor,backgroundColor,legalName } = useSelector((state) => state.theme);

  const formmik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgetPasswordSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(
          `${baseUrl}/api/forgot_password/${values.email}`,
          {
            method: "GET",
          }
        );

        if (response.ok) {
          localStorage.setItem("email", values.email);
          navigate("/reset-password-request");
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const navigate = useNavigate();

  
  return (
    <>
      <div style={{ backgroundColor: backgroundColor }} className="w-screen h-screen p-8 flex justify-center items-center">
        <div className="md:basis-3/4 lg:basis-1/2 xl:basis-1/4 w-full bg-white rounded-md sm:basis-4/5 custom-shadow flex justify-center items-center gap-10 flex-col p-10">
          <div className="flex justify-center items-center flex-col gap-4">
            <h1 style={{ color: fontColor }} className="text-4xl font-medium text-center capitalize">{legalName}</h1>
            <p style={{ color: fontColor }} className="text-lg text-center">Forgot Your Password?</p>
            <p style={{ color: fontColor }} className="text-sm text-center">
              Enter your email address and we will send you instructions to
              reset your password.
            </p>
          </div>
          <div className="w-full flex justify-center items-center flex-col gap-4">
            <form
              className="w-full flex justify-center items-center gap-4 flex-col"
              onSubmit={formmik.handleSubmit}
            >
              <input
                type="email"
                id="Email"
                name="email"
                autoComplete="off"
                placeholder="Email address*"
                value={formmik.values.email}
                onChange={formmik.handleChange}
                onBlur={formmik.handleBlur}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formmik.errors.email && formmik.touched.email ? (
                <p className="w-full text-red-600 m-[-7px] font-medium">
                  {formmik.errors.email}
                </p>
              ) : null}
              <button
                type="submit"
                className="text-white w-full bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                Continue
              </button>
              <div className="flex text-sm gap-1">
                <Link to="/login">
                  <button style={{ color: fontColor }}  className="font-bold capitalize">Back to {legalName}</button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;