import { button } from "@material-tailwind/react";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function PageHeader({ buttons }) {
  
  return (
    <>
      <div className="px-5 py-3 w-full md:w-3/4 lg:w-[80%] xl:w-[47%]">
        <div className="bg-[#E8EBED] flex flex-col md:flex-row p-2 rounded-md justify-center gap-3 items-center w-full relative">
          {buttons.map((button, index) => (
            <NavLink
              key={index}
              to={button.link}
              className={({ isActive }) =>
                `${
                  isActive
                    ? "px-3 py-2 text-center w-full rounded-md md:basis-1/2 font-medium bg-white text-[#046451]"
                    : "px-3 py-1 text-center rounded-md basis-1/2 font-medium"
                }`
              }
            >
              {button.tittle}
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
}

export default PageHeader;
