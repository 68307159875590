import React, { useEffect, useRef, useState } from "react";
import WelcomeHand from "../assets/WelcomeHand.png";
import { TbMessageChatbot } from "react-icons/tb";
import { LuImport } from "react-icons/lu";
import { MdRebaseEdit } from "react-icons/md";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { BsFileEarmarkPlay } from "react-icons/bs";
import { BsFileEarmarkText } from "react-icons/bs";
import { useFormik } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import {
  createAgentSchema,
  createAgentStepThreeSchema,
  createAgentStepTwoSchema,
} from "../schemas/FormSchema";
import { Select, Option } from "@material-tailwind/react";
import CreateAgent from "./CreateAgent";
import { useSelector } from "react-redux";
import ImportAgent from "./ImportAgent";

var parsedClientData;

function WelcomeAgent() {
  const { fontColor,backgroundColor } = useSelector((state) => state.theme);
  const audioInputRef = useRef(null);
  const textInputRef = useRef(null);
  const closeModal = useRef(null);
  const [step, setStep] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const [createAgentData, setCreateAgentData] = useState({
    agentName: "",
    projectName: "",
    callType: "",
    companyName: "",
    option: "",
  });

  const formMik = useFormik({
    initialValues: {
      agentName: "",
      projectName: "",
    },
    validationSchema: createAgentSchema,
    onSubmit: async (values) => {
      if (step != 3) {
        setStep(step + 1);
      }
      setCreateAgentData((prevValue) => ({
        ...prevValue,
        agentName: values.agentName,
        projectName: values.projectName,
      }));
    },
  });

  const formMikStep2 = useFormik({
    initialValues: {
      callType: "",
      companyName: "",
    },
    validationSchema: createAgentStepTwoSchema,
    onSubmit: async (values, actions) => {
      if (step != 3) {
        setStep(step + 1);
      }
      setCreateAgentData((prevValue) => ({
        ...prevValue,
        callType: values.callType,
        companyName: values.companyName,
      }));
    },
  });

  const formMikStep3 = useFormik({
    initialValues: {
      option: "",
    },
    validationSchema: createAgentStepThreeSchema,
    onSubmit: async (values, actions) => {
      setCreateAgentData((prevValue) => ({
        ...prevValue,
        option: values.option,
      }));
      setStep(step + 1);
      // const handleRedierct = () => {
      //   if (values.option == "createConversationally") {
      //
      //   } else if (values.option == "import") {
      //     setStep(step + 1);
      //   } else if (values.option == "scratch") {
      //     setIsModalVisible(false);
      //     setStep(step + 1);
      //     navigate("/flows");
      //   }
      // };
      // handleRedierct();
    },
  });

  useEffect(() => {
    if (step > 3) {
      let serializedObject = JSON.stringify(createAgentData);
      localStorage.setItem("agentData", serializedObject);
    }
  }, [createAgentData, setCreateAgentData]);

  if (step == 4) {
    const retriveClientData = localStorage.getItem("agentData");
    parsedClientData = JSON.parse(retriveClientData);
  }

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleAudioUploadClick = () => {
    audioInputRef.current.click();
  };

  const handleTextUploadClick = () => {
    textInputRef.current.click();
  };

  const handleAudioSelected = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["audio/mp3", "audio/mp4", "audio/wav"];
    if (allowedFormats.includes(file.type)) {
    } else {
      console.error("Please select an audio file in mp3, mp4, or wav format.");
    }
  };

  const handleTextSelected = (event) => {
    const file = event.target.files[0];
    const allowedFormats = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (allowedFormats.includes(file.type)) {
    } else {
      console.error("Please select a file in PDF or Word format (docx).");
    }
  };

  const handleCloseModal = () => {
    closeModal.current.click();
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  
  return (
    <>
      <div className="w-full relative h-[80vh] flex flex-col justify-center items-center ">
        <div className="text-center w-fit px-5 flex flex-col gap-3 justify-center items-center">
          <div className="text-5xl font-bold flex justify-center items-center gap-3">
            <img src={WelcomeHand} className="w-10 lg:w-14" alt="" />
            <span style={{ color: fontColor }} className="bg-clip-text text-transparent bg-gradient-to-r from-gray-800 to-gray-700 lg:text-7xl">
              Welcome..
            </span>
          </div>
          <div className="my-6 flex justify-center">
            <p style={{ color: fontColor }} className="text-lg sm:basis-3/4 p-4 lg:basis-1/2 lg:text-2xl">
              To get started, create your first AI agent by clicking the button
              below. Once you create your agent, you can place it on live calls
              in minutes.
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center gap-2">
            <CreateAgent />
            <ImportAgent />
            <button className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800">
              <span style={{ color: fontColor,backgroundColor: backgroundColor }} className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                Watch Quickstart Tutorial Again
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default WelcomeAgent;
