import React from "react";
import Agent2 from "../assets/agent (2).png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useSelector } from "react-redux";

function AnalyticsCard({ data, handleNavigate }) {
  const { fontColor } = useSelector((state) => state.theme);
  const localTime = (utcTimeString) => {
    const utcDate = new Date(utcTimeString);
    const localDate = utcDate.toLocaleDateString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return localDate;
  };

  return (
    <div
      onClick={() => handleNavigate(data.id)}
      className="lg:basis-1/3 sm:basis-1/2 w-full pb-8 2xl:basis-1/4 sm:px-4 lg:px-5"
    >
      <div
        className={`border cursor-pointer bg-white relative rounded-lg h-auto shadow-lg flex justify-center items-center flex-col`}
      >
        <div
          className={`w-28 ${
            data.status === "active"
              ? "blink-bg-green"
              : data.status === "completed"
              ? "bg-red-500"
              : data.status === "pause"
              ? "bg-blue-500"
              : data.status === "running" ? "blink-bg-green" : ""
          } shadow h-28 my-3 mt-8 p-4 flex justify-center items-center rounded-full overflow-hidden border`}
        >
          <img src={Agent2} alt="" className="" />
        </div>
        <div className="flex justify-between w-full px-5 py-2">
          <h2 style={{ color: fontColor }} className="font-medium">
            Agent Name
          </h2>
          <h2 style={{ color: fontColor }} className="font-semibold">
            {data.agent_name}
          </h2>
        </div>
        <div className="flex justify-between w-full px-5 pb-2">
          <h2 style={{ color: fontColor }} className="font-medium">
            Campaign Name
          </h2>
          <h2 style={{ color: fontColor }} className="font-semibold">
            {data.campaign_name}
          </h2>
        </div>
        <div className="flex justify-between w-full border-b px-5 pb-2">
          <h2 style={{ color: fontColor }} className="font-medium">
            Direction
          </h2>
          <h2 style={{ color: fontColor }} className="font-semibold">
            {data.direction}
          </h2>
        </div>
        <div className="py-3 flex justify-between w-full px-2">
          <div className="border-r basis-1/3 text-center">
            <h2 style={{ color: fontColor }} className="text-[11px]">
              Credits Expended
            </h2>
            <p
              style={{ color: fontColor }}
              className="text-center font-semibold text-xl"
            >
              {data.credit_expended.toFixed(2)}
            </p>
          </div>
          <div className="border-r text-center basis-1/3">
            <h2 style={{ color: fontColor }} className="text-[11px]">
              Total Calls
            </h2>
            <p
              style={{ color: fontColor }}
              className="text-center font-semibold text-xl"
            >
              {data.total_calls}
            </p>
          </div>
          <div className="basis-1/3 text-center">
            <h2 style={{ color: fontColor }} className="text-[11px]">
              Seconds Consumed
            </h2>
            <p
              style={{ color: fontColor }}
              className="text-center font-semibold text-xl"
            >
              {data.min_consumed}
            </p>
          </div>
        </div>
        <div
          style={{ color: fontColor }}
          className="absolute top-3 left-3 font-semibold"
        >
          {localTime(data.created_at)}
        </div>
        <div className="absolute top-3 right-3 text-xl">
          {/* <BsThreeDotsVertical /> */}
        </div>
      </div>
    </div>
  );
}

export default AnalyticsCard;
