import React from "react";
import Navbar from "./Navbar";
import { useState } from "react";
import PageHeader from "./PageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setListName } from "../features/List/list";
import CreateListsPopUp from "./CreateListsPopUp";


const ContactListHome = () => {
  const fileName = useSelector((state) => state.listName.listName);
  const [showModal, setShowModal] = useState(false);
  const { fontColor } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // dispatch(setActiveStep(activeStep + 1))

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleFileName = () => {
    // dispatch()
  };

  const handleSave = () => {
    navigate("/import-contact");
  };
  return (
    <>
      <div className="w-full relative h-[80vh] flex flex-col justify-center ">
        <div className="text-center flex flex-col justify-center items-center">
          <div className="text-4xl font-bold flex justify-center items-center ">
            <span style={{ color: fontColor }} className="bg-clip-text text-transparent p-1 bg-gradient-to-r from-gray-800 to-gray-700 lg:text-5xl">
              Upload Contacts Before You Create a List
            </span>
          </div>
          <div className="my-6 flex justify-center">
            <p style={{ color: fontColor }} className="text-lg sm:basis-3/4 p-4 lg:basis-1/2 lg:text-3xl">
              You can use lists to organize your contacts into groups you want
              to call. To get started with lists, upload some contacts in the
              "contacts" tab and we'll automatically create first list for you
              :)
            </p>
          </div>
          <CreateListsPopUp />
        </div>
      </div>
    </>
  );
};

export default ContactListHome;
