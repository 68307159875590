import React from 'react'
import IntegrationPageNav from '../components/IntegrationPageNav'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/Navbar'
import { useSelector } from 'react-redux'


function IntigrationRoot() {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  return (
    <>
    <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full h-screen"
      >
        <Navbar />
        <div className="flex-1 mt-3 overflow-y-hidden z-0"> 
          <IntegrationPageNav />
          
        </div>
      </div>
    </>
  )
}

export default IntigrationRoot