import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditWebhookDialog from "./EditWebhookDilog";
import { PulseLoader } from "react-spinners";
import IntegrationPageNav from "./IntegrationPageNav";
import Navbar from "./Navbar";

function Webhooks() {
  const [webhooks, setWebhooks] = useState([]);
  console.log(webhooks);

  const [selectedWebhookId, setSelectedWebhookId] = useState(null);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const refresh = useSelector((state) => state.refresh.refresh);
  const [open, setOpen] = useState(false);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = (id) => {
    setSelectedWebhookId(id);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this webhook?"
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${baseUrl}/api/agency/webhook/${id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      setWebhooks(webhooks.filter((webhook) => webhook.id !== id));
    } catch (error) {
      console.error("Failed to delete webhook:", error);
    }
  };

  useEffect(() => {
    const fetchWebhooks = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/api/agency/webhook/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const result = await response.json();
        setWebhooks(result.data.agency_webhook);
      } catch (error) {
        console.error("Failed to fetch webhooks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWebhooks();
  }, [refresh]);

  return (
    <>
      <EditWebhookDialog
        open={open}
        setOpen={setOpen}
        webhookId={selectedWebhookId}
      />
      <div
        className=" overflow-x-auto px-[32px] mt-5 bg-white"
        style={{ backgroundColor: backgroundColor }}
      >
        <table className=" w-full border-collapse">
          <thead>
            <tr className="border-b text-black ">
              <th className="px-4 py-2 text-left">Action</th>
              <th className="px-4 py-2 text-left">Url</th>
              <th className="px-4 py-2 text-left">Token</th>
              <th className="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {webhooks?.map((webhook) => (
              <tr key={webhook.id} className="border-b ">
                <td className="px-4 py-2">
                  <p style={{ color: fontColor }}>{webhook.webhook_type}</p>
                </td>
                <td className="px-4 py-2">
                  <p style={{ color: fontColor }}>{webhook.url}</p>
                </td>
                <td className="px-4 py-2">
                  <p style={{ color: fontColor }}>{webhook.bearer_token}</p>
                </td>
                <td className="px-4 py-2 text-center flex gap-3">
                  <button
                    onClick={() => handleClickOpen(webhook.id)}
                    className="text-black"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => handleDelete(webhook.id)}
                    className="text-red-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default Webhooks;
