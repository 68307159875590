import { memo, useCallback, useState, useRef, useEffect } from "react";
import { GrSettingsOption } from "react-icons/gr";
import { Handle, useReactFlow, Position } from "reactflow";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setSaveScript } from "../features/Agents/saveScript";
import { setIsEditing } from "../features/NodeIsEditing/isEditing";
import toast from "react-hot-toast";
import { setDeleteNode } from "../features/deleteNode/deleteNode";

let validationTimeout;

const suggestions = [
  "contact_number}}",
  "email}}",
  "first_name}}",
  "last_name}}",
  "company_name}}",
  "industry}}",
  "custom1}}",
  "custom2}}",
  "custom3}}",
  "custom4}}",
];

const waitTimeOptions = [
  { label: "Short", value: 10 },
  { label: "Medium", value: 1000 },
  { label: "Long", value: 2000 },
  { label: "Custom", value: "custom" },
];

const CustomNodeChild = ({
  id,
  data,
  isConnectable,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
}) => {
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const { deleteElements } = useReactFlow();
  const [isEditing, setIsEditing] = useState(false);
  const [line, setLine] = useState(data.line);
  const para = localStorage.getItem("scriptData");
  const retrievedArrayOfScript = JSON.parse(para) || [];
  const [paratext, setParaText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef(null);
  const textareaRef = useRef(null);
  const dispatch = useDispatch();
  // Wait Time State
  const [waitTime, setWaitTime] = useState("Low");
  const [customWaitTime, setCustomWaitTime] = useState(null);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    setLine(retrievedArrayOfScript[id]?.State);
  }, [data]);

  useEffect(() => {
    setParaText(
      retrievedArrayOfScript[id]?.Prompt || retrievedArrayOfScript[id]?.Prompts
    );
  }, [data]);

  useEffect(() => {
    const nodeData =
      retrievedArrayOfScript.find((item) => item.State === data.line) || {};

    if (
      [1000, 2000, 10, "1000", "2000", "10"].includes(nodeData["Wait Time"])
    ) {
      setWaitTime(nodeData["Wait Time"]);
    } else {
      setWaitTime("custom");
      parseInt(nodeData["Wait Time"]);
      setCustomWaitTime(parseInt(nodeData["Wait Time"]));
    }
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setParaText(value);

    const regex = /{{\s+/g;
    const matches = value.match(regex);

    const anothermatch = value.endsWith("{{");

    if ((matches && matches.length > 0) || anothermatch) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleLineChange = (e) => {
    let newLine = e.target.value;
    const oldLine = line;
  
    // Check for duplicate state names
    const isDuplicate = retrievedArrayOfScript.some(
      (item) => item.State === newLine && item.State !== oldLine
    );
  
    if (isDuplicate) {
      
      let uniqueSuffix = 1;
      let newUniqueName = `${newLine}_${uniqueSuffix}`;
  
      // Find a unique name by appending a number
      while (retrievedArrayOfScript.some(
        (item) => item.State === newUniqueName && item.State !== oldLine
      )) {
        uniqueSuffix += 1;
        newUniqueName = `${newLine}_${uniqueSuffix}`;
      }
  
      newLine = newUniqueName; // Update newLine to the unique name
      toast(`State name already exists. Renamed to ${newLine}`);
    }
  
    setLine(newLine);
  
    const updatedArray = [...retrievedArrayOfScript];
  
    updatedArray.forEach((item) => {
      if (item.State === oldLine) {
        item.State = newLine;
      }
  
      if (item["Next State"] === oldLine) {
        item["Next State"] = newLine;
      }
      if (item["Previous State"] === oldLine) {
        item["Previous State"] = newLine;
      }
    });
  
    localStorage.setItem("scriptData", JSON.stringify(updatedArray));
    dispatch(setSaveScript(true));
  };
  
  

  const handleSuggestionClick = (suggestion) => {
    setParaText((prevValue) => {
      const startPos = textareaRef.current.selectionStart;
      const endPos = textareaRef.current.selectionEnd;

      const newValue =
        prevValue.substring(0, startPos) +
        suggestion +
        prevValue.substring(endPos, prevValue.length);

      updateLocalStorage(newValue);
      return newValue;
    });
    setShowSuggestions(false);
  };

  const updateLocalStorage = (newValue) => {
    const updatedArray = [...retrievedArrayOfScript];
    if (updatedArray[id]) {
      if (
        updatedArray[id].Prompt !== undefined ||
        updatedArray[id].Prompts !== undefined
      ) {
        if (updatedArray[id].Prompt !== undefined) {
          updatedArray[id].Prompt = newValue;
        } else if (updatedArray[id].Prompts !== undefined) {
          updatedArray[id].Prompts = newValue;
        }
      } else {
        updatedArray[id].Prompt = newValue;
      }
      localStorage.setItem("scriptData", JSON.stringify(updatedArray));
      dispatch(setSaveScript(true));
    }
  };

  const handleBlur = () => {
    updateLocalStorage(paratext);
  };

  const handleLineBlur = () => {
    const updatedArray = [...retrievedArrayOfScript];
    if (updatedArray[id]) {
      updatedArray[id].State = line;
      localStorage.setItem("scriptData", JSON.stringify(updatedArray));
      dispatch(setSaveScript(true));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      const { selectionStart, selectionEnd } = textareaRef.current;
      setParaText((prevText) => {
        const newText =
          prevText.substring(0, selectionStart) +
          "\n" +
          prevText.substring(selectionEnd);
        setTimeout(() => {
          textareaRef.current.selectionStart = selectionStart + 1;
          textareaRef.current.selectionEnd = selectionStart + 1;
        }, 0);
        return newText;
      });
    } else if (e.key === "Enter") {
      e.preventDefault();
      handleBlur();
      handleLineBlur();
      setIsEditing(false);
    }
  };

  const onClick = useCallback(() => {
    const updatedArray = [...retrievedArrayOfScript];

    // Find the index of the node to remove
    const indexToRemove = updatedArray.findIndex(
      (item) => item.State === data.line
    );

    if (indexToRemove !== -1) {
      // Remove the node
      const removedNodeState = updatedArray[indexToRemove].State;
      updatedArray.splice(indexToRemove, 1);

      // Update Next State and Previous State references
      updatedArray.forEach((item) => {
        if (item["Next State"] === removedNodeState) {
          item["Next State"] = "";
        }
        if (item["Previous State"] === removedNodeState) {
          item["Previous State"] = "";
        }
      });

      // Save updated array to localStorage
      localStorage.setItem("scriptData", JSON.stringify(updatedArray));

      // Dispatch the action to update the state
      dispatch(setDeleteNode(true));
    }

    // Remove the node from the UI
    deleteElements({ nodes: [{ id }] });
  }, [id, deleteElements, retrievedArrayOfScript, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const scrollable = suggestionsRef.current;
      if (scrollable) {
        if (event.key === "ArrowDown") {
          scrollable.scrollBy(0, 50); // Scroll down 50px
        } else if (event.key === "ArrowUp") {
          scrollable.scrollBy(0, -50); // Scroll up 50px
        }
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleWaitTimeChange = (e) => {
    const selectedValue = e.target.value;
    setWaitTime(selectedValue);

    if (selectedValue !== "custom") {
      // setCustomWaitTime("");
      updateWaitTimeInScript(selectedValue);
      // console.log(selectedValue);// Update the script with the selected value
    }
  };

  const handleCustomWaitTimeChange = (e) => {
    const value = e.target.value;
    setCustomWaitTime(value);

    // Clear any existing timeout to prevent multiple error messages
    clearTimeout(validationTimeout);

    // Delay the validation to give the user time to finish typing
    validationTimeout = setTimeout(() => {
      const customValue = parseInt(value, 10);

      if (!isNaN(customValue)) {
        if (customValue > 7000) {
          toast.error("Custom wait time cannot exceed 7000");
        } else if (customValue < 10) {
          toast.error("Custom wait time cannot be less than 10");
        } else {
          setCustomWaitTime(customValue);
          updateWaitTimeInScript(customValue);
        }
      } else {
        toast.error("Please enter a valid number");
      }
    }, 500); // 1000ms delay
  };

  const updateWaitTimeInScript = (waitTimeValue) => {
    const updatedArray = [...retrievedArrayOfScript];

    // const intValue = parseInt(waitTimeValue);
    // console.log(intValue);
    if (updatedArray[id]) {
      updatedArray[id]["Wait Time"] = waitTimeValue; // Assuming the field is named 'waitTime'
      localStorage.setItem("scriptData", JSON.stringify(updatedArray));
      dispatch(setSaveScript(true));
    }
  };

  return (
    <>
      <div
        onDoubleClick={handleDoubleClick}
        className="border border-gray-800 w-80 h-auto rounded-lg bg-white overflow-hidden"
      >
        <div className="bg-gray-800 custom-drag-handle text-white text-center py-2">
          Node {data.heading}
        </div>
        <div className="px-4 py-5">
          <div className="flex justify-between items-center">
            {isEditing ? (
              <input
                type="text"
                value={line}
                onChange={handleLineChange}
                onBlur={handleLineBlur}
                className="w-full h-10 outline-none border-b border-gray-300 focus:border-black"
              />
            ) : (
              <p style={{ color: fontColor }} className="font-bold text-md">
                {line}
              </p>
            )}
          </div>
          <div style={{ color: fontColor }} className="mt-2">
            {isEditing ? (
              <textarea
                ref={textareaRef}
                type="text"
                value={paratext}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoFocus
                className="w-full h-full overflow-y-auto outline-none focus:border-black focus:ring-0"
                rows="3"
              />
            ) : (
              <p>{paratext}</p>
            )}
          </div>

          {/* Wait Time Dropdown */}
          <div className="mt-4">
            <label
              htmlFor="wait-time"
              className="block text-sm font-medium text-gray-700"
            >
              Wait Time
            </label>
            <select
              id="wait-time"
              value={waitTime}
              onChange={handleWaitTimeChange}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="">Select Wait Time</option>
              {waitTimeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            {waitTime === "custom" && (
              <input
                type="number"
                value={customWaitTime}
                onChange={handleCustomWaitTimeChange}
                placeholder="Enter custom time in milliseconds"
                min={10}
                max={7000}
                className="mt-2 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            )}
          </div>

          {showSuggestions && (
            <div
              ref={suggestionsRef}
              className="mt-2 absolute top-0 -right-44 bg-white border border-gray-300 rounded-md shadow-lg max-h-full overflow-y-auto"
            >
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className="absolute top-2 right-2 p-1 text-xl cursor-pointer"
          onClick={onClick}
        >
          <MdDeleteOutline color="red" />
        </div>
        <Handle
          id="target-handle"
          type="target"
          position={targetPosition}
          isConnectable={isConnectable}
          className="w-2 h-2 bg-gray-800"
        />
        <Handle
          id="source-handle"
          type="source"
          position={sourcePosition}
          isConnectable={isConnectable}
          className="w-2 h-2 bg-gray-800"
        />
      </div>
    </>
  );
};

export default memo(CustomNodeChild);
