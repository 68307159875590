import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import CampaignHome from "../components/CampaignHome";
import CampaignList from "../components/CampaignList";
import Agreement from "../components/Agreement";
import { ref } from "yup";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import InboundUrl from "../components/InboundUrl";
import { Toaster } from "react-hot-toast";
import CampaignTabs from "./CampaignTabs";

function CampaignPage() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const refresh = useSelector((state) => state.refresh.refresh);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;

  useEffect(() => {
    const fetchCampaign = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseUrl}/api/campaign/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setResponseData(responseData.data["campaign lists"]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchCampaign();
  }, [refresh]);

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full h-screen"
      >
        <Navbar />
        <div className=" mt-3 z-0"> 
          <CampaignTabs />
          {/* <div
            className={`mt-6 px-5 flex items-center justify-end ${
              responseData && responseData.length != 0 ? "" : "opacity-0"
            } `}
          >
            <InboundUrl />
            <Agreement />
          </div> */}
        </div>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default CampaignPage;
