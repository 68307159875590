import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { setScriptData } from "../features/Agents/script";
import { PulseLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";

function ConversationallyChatBot() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const messageRef = useRef(null);
  const navigate = useNavigate();
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const [agentQues, setAgentQues] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });
  const [messages, setMessages] = useState([
    { text: "Which industry is this AI agent being built for?", user: false },
  ]);
  const [step, setStep] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const company_name = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const dispatch = useDispatch();
  const direction = localStorage.getItem("callType");

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageRef.current) {
        messageRef.current.scrollTop = messageRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (step == 1) {
      if (inputValue != "") {
        setIsLoading(true);
        setDisabled(true);
        localStorage.setItem("industry", inputValue);
        setMessages((prevValues) => [
          ...prevValues,
          { text: inputValue, user: true },
        ]);

        setTimeout(() => {
          setMessages((prevValues) => [
            ...prevValues,
            {
              text: "Please select your usecase.",
              user: false,
            },
          ]);
          setIsLoading(false);
          setDisabled(false);
        }, 2000);

        setTimeout(() => {
          setStep(2);
        }, 2500);
        setInputValue("");
      }
    } else if (step == 3) {
      if (inputValue != "") {
        setIsLoading(true);
        setDisabled(true);
        setMessages((prevValues) => [
          ...prevValues,
          { text: inputValue, user: true },
        ]);

        setTimeout(() => {
          setMessages((prevValues) => [
            ...prevValues,
            {
              text: `Please articulate the specific goal you have in mind for ${localStorage.getItem(
                "agentName"
              )}.`,
              user: false,
            },
          ]);
          setIsLoading(false);
          setDisabled(false);
        }, 2000);
        localStorage.setItem("role", inputValue);
        setAgentQues((prevValues) => ({
          ...prevValues,
          3: inputValue,
        }));
        setStep(4);
        setInputValue("");
      }
    } else if (step == 4) {
      if (inputValue != "") {
        setMessages((prevValues) => [
          ...prevValues,
          { text: inputValue, user: true },
        ]);
        localStorage.setItem("goal", inputValue);
        handleCreateAgent();
        setInputValue("");
      }
    }
  };

  const handleCreateAgent = async () => {
    setLoading(true);
    const formdata = {
      name: localStorage.getItem("agentName"),
      project_name: localStorage.getItem("projectName"),
      direction: localStorage.getItem("callType"),
      company_name: localStorage.getItem("companyName"),
      industry: localStorage.getItem("industry"),
      call_subtype: localStorage.getItem("callSubtype"),
      role: localStorage.getItem("role"),
      goal: localStorage.getItem("goal"),
    };
    try {
      const response = await fetch(`${baseUrl}/api/agents/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(formdata),
      });
      if (response.ok) {
        const responseData = await response.json();
        localStorage.removeItem('agentName');
        localStorage.removeItem('projectName');
        localStorage.removeItem('callType');
        localStorage.removeItem('companyName');
        localStorage.removeItem("industry");
        localStorage.removeItem("callSubtype");
        localStorage.removeItem("role");
        localStorage.removeItem("goal");
        localStorage.setItem("agentID", responseData.data.agent.id);
        setLoading(false);
        navigate("/flows");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (step == 5) {
      setTimeout(() => {
        navigate("/flows");
      }, 2000);
    }
  }, [step, setStep]);

  const handleColdCalling = () => {
    setIsLoading(true);
    setDisabled(true);
    setMessages((prevValues) => [
      ...prevValues,
      { text: "cold calling", user: true },
    ]);
    setTimeout(() => {
      setMessages((prevValues) => [
        ...prevValues,
        {
          text: "Please provide a concise job title and a detailed list of responsibilities.",
          user: false,
        },
      ]);
      setIsLoading(false);
      setDisabled(false);
    }, 2000);

    localStorage.setItem("callSubtype", "cold calling");
    setStep(3);
    setAgentQues((prevValues) => ({
      ...prevValues,
      2: "cold calling",
    }));
  };

  const handleProspect = () => {
    setIsLoading(true);
    setDisabled(true);
    setMessages((prevValues) => [
      ...prevValues,
      { text: "Prospect Follow up", user: true },
    ]);

    setTimeout(() => {
      setMessages((prevValues) => [
        ...prevValues,
        {
          text: "Please Provide a concise job tittle and detailed list of responsibilites",
          user: false,
        },
      ]);
      setIsLoading(false);
      setDisabled(false);
    }, 2000);
    localStorage.setItem("callSubtype", "Prospect Follow up");
    setStep(3);
    setAgentQues((prevValues) => ({
      ...prevValues,
      2: "Prospect Follow up",
    }));
  };

  const handleCustomerServices = () => {
    setIsLoading(true);
    setDisabled(true);
    setMessages((prevValues) => [
      ...prevValues,
      { text: "customer services", user: true },
    ]);
    setTimeout(() => {
      setMessages((prevValues) => [
        ...prevValues,
        {
          text: "Please Provide a concise job tittle and detailed list of responsibilites",
          user: false,
        },
      ]);
      setIsLoading(false);
      setDisabled(false);
    }, 2000);

    localStorage.setItem("callSubtype", "customer services");
    setStep(3);
    setAgentQues((prevValues) => ({
      ...prevValues,
      2: "cold calling",
    }));
  };

  const handleInboundSales = () => {
    setIsLoading(true);
    setDisabled(true);
    setMessages((prevValues) => [
      ...prevValues,
      { text: "inbound sales", user: true },
    ]);
    setTimeout(() => {
      setMessages((prevValues) => [
        ...prevValues,
        {
          text: "Please Provide a concise job tittle and detailed list of responsibilites",
          user: false,
        },
      ]);
      setIsLoading(false);
      setDisabled(false);
    }, 2000);

    localStorage.setItem("callSubtype", "inbound sales");
    setStep(3);
    setAgentQues((prevValues) => ({
      ...prevValues,
      2: "cold calling",
    }));
  };
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex justify-center p-4 items-center flex-col w-full h-screen"
      >
        <h1 className="text-4xl font-medium my-8 capitalize">{legalName}</h1>
        <div className="bg-white rounded-xl custom-shadow w-full max-w-3xl">
          <div className="flex items-center justify-between rounded-tl-xl rounded-tr-xl bg-[#001d3d] px-5 py-4">
            <h1 className="font-semibold text-lg text-white capitalize">
              {legalName} Onboarding
            </h1>
            <div className="flex items-center space-x-1">
              <p className="text-white">Connected</p>
              <span className="relative flex h-3 w-3 mb-[1px]">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-green-400"></span>
              </span>
            </div>
          </div>
          <hr className="border-t-2 border-gray-200" />
          <div
            ref={messageRef}
            className=" h-[300px] 2xl:h-[450px] overflow-auto px-6 py-4"
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`mb-4 flex ${
                  message.user ? "justify-end" : "justify-start"
                } items-center`}
              >
                <div className={`${message.user ? "hidden" : ""} basis-[10%]`}>
                  <img
                    src="https://cdn.dribbble.com/users/1740191/screenshots/6287147/eva.png"
                    alt=""
                    className="w-20"
                  />
                </div>
                <div className={`flex-1 `}>
                  <div
                    style={{ color: fontColor }}
                    className={`w-fit rounded-lg  p-3 inline-block ${
                      message.user ? "bg-[#d2edd4] float-end" : "bg-[#f5f5f5]"
                    }`}
                  >
                    <span>{message.text}</span>
                  </div>
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex space-x-2 justify-start items-center dark:invert pl-4">
                <div className="h-3 w-3 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="h-3 w-3 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="h-3 w-3 bg-gray-500 rounded-full animate-bounce"></div>
              </div>
            )}
            {step === 2 && direction == "Outbound" ? (
              <div className="flex flex-col md:flex-row justify-between gap-4 items-center w-full md:w-1/2 ml-4">
                <button
                  style={{ color: fontColor }}
                  type="button"
                  onClick={handleColdCalling}
                  className="text-gray-800 basis-1/2 bg-[#e5e5e5] hover:bg-[#edede9] focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                >
                  Cold Calling
                </button>
                <button
                  style={{ color: fontColor }}
                  type="button"
                  onClick={handleProspect}
                  className="text-gray-800 basis-1/2 bg-[#e5e5e5] hover:bg-[#edede9] focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                >
                  Prospect Follow up
                </button>
              </div>
            ) : (
              ""
            )}

            {step === 2 && direction == "Inbound" ? (
              <div className="flex flex-col md:flex-row justify-between gap-4 items-center w-full md:w-3/4 ml-4">
                <button
                  style={{ color: fontColor }}
                  type="button"
                  onClick={handleInboundSales}
                  className="text-gray-800 basis-1/2 bg-[#e5e5e5] hover:bg-[#edede9] focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                >
                  Inbound Sales
                </button>
                <button
                  style={{ color: fontColor }}
                  type="button"
                  onClick={handleCustomerServices}
                  className="text-gray-800 basis-1/2 bg-[#e5e5e5] hover:bg-[#edede9] focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                >
                  Customer Services
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          <hr className="border-t-2 border-gray-200" />
          <form
            onSubmit={handleSendMessage}
            className="flex justify-between px-6 py-4 space-x-3"
          >
            {step == 2 ? (
              <>
                <input
                  type="text"
                  name="message"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  disabled
                  className="bg-gray-50 cursor-not-allowed border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-800 focus:border-gray-800 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <button
                  type="submit"
                  className="bg-gray-500 cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-gray-300 font-medium text-sm dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 flex items-center justify-center  ease-out  rounded-lg px-4 py-2 text-white"
                  disabled
                >
                  Send
                </button>
              </>
            ) : (
              <>
                <input
                  type="text"
                  name="message"
                  value={inputValue}
                  disabled={disabled}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  className={`bg-gray-50 border ${
                    disabled ? " cursor-not-allowed" : ""
                  } border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-800 focus:border-gray-800 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                />
                <button
                  type="submit"
                  className="bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 flex items-center justify-center  ease-out  rounded-lg px-4 py-2 text-white"
                >
                  Send
                </button>
              </>
            )}
          </form>
        </div>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default ConversationallyChatBot;
