import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { SiGoogledocs } from "react-icons/si";
import { HiOutlineFilter } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md";
import { Typography, Button } from "@material-tailwind/react";
import { Link, json, useParams } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import ReactPlayer from "react-player";
import RecordingsCSV from "./RecordingsCSV";
import toast, { Toaster } from "react-hot-toast";
import RecordingError from "./RecordingError";

function QuickRecording() {
  const [loading, setLoading] = useState(false);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [popupContent, setPopupContent] = useState("");
  const [notesContent, setNotesContent] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Notes");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [outcomeQuery, setOutcomeQuery] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [durationFilter, setDurationFilter] = useState({
    operator: "",
    seconds: null,
  });
  const [selectedRecordings, setSelectedRecordings] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  //   console.log("link", selectedRecordings);
  const [dateValue, setDateValue] = useState({
    startDate: null,
    endDate: null,
  });

  const localTime = (utcTimeString) => {
    const utcDate = new Date(utcTimeString);
    const localDate = utcDate.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return localDate;
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}/api/quick_analytics/${id}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const jsonData = await response.json();
      setData(jsonData.data.analytics);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSelected = () => {
    const recordingsToDownload = selectAll ? filteredData : selectedRecordings;

    recordingsToDownload.forEach((recording) => {
      fetch(`${recording.recording_url}.wav`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `rec_${recording.id}.wav`; // Use a unique filename if possible
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Error downloading recording:", error));
    });
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRecordings([]);
    } else {
      setSelectedRecordings(filteredData);
    }
    setSelectAll(!selectAll);
  };

  const openPopup = (transcription, notes) => {
    setPopupContent(transcription);
    setNotesContent(notes);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleFilterChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleOutcomeChange = (event) => {
    setOutcomeQuery(event.target.value);
  };
  const handleOperatorChange = (event) => {
    setDurationFilter({
      ...durationFilter,
      operator: event.target.value,
    });
  };

  const handleSecondsChange = (event) => {
    setDurationFilter({
      ...durationFilter,
      seconds: parseInt(event.target.value),
    });
  };

  const isWithinDateRange = (date, startDate, endDate) => {
    if (!startDate && !endDate) return true;
    const targetDate = new Date(date);
    if (startDate && endDate) {
      const startOfDayStartDate = new Date(startDate);
      startOfDayStartDate.setHours(0, 0, 0, 0);
      const startOfDayEndDate = new Date(endDate);
      startOfDayEndDate.setHours(23, 59, 59, 999);
      return (
        targetDate >= startOfDayStartDate && targetDate <= startOfDayEndDate
      );
    }
    if (startDate) {
      const startOfDayStartDate = new Date(startDate);
      startOfDayStartDate.setHours(0, 0, 0, 0);
      return targetDate >= startOfDayStartDate;
    }
    if (endDate) {
      const endOfDayEndDate = new Date(endDate);
      endOfDayEndDate.setHours(23, 59, 59, 999);
      return targetDate <= endOfDayEndDate;
    }

    return true;
  };

  const handleValueChange = (dateValue) => {
    if (dateValue.startDate && dateValue.endDate) {
      if (dateValue.startDate <= dateValue.endDate) {
        setDateValue(dateValue);
      } else {
        setDateValue({
          startDate: dateValue.endDate,
          endDate: dateValue.startDate,
        });
      }
    } else {
      setDateValue(dateValue);
    }
  };

  const filteredData = data.filter((row) => {
    const statusMatch =
      selectedStatus === "All" ||
      row.status.toLowerCase() === selectedStatus.toLowerCase();

    const searchString = searchQuery.toLowerCase();
    const nameMatch = row.full_name.toLowerCase().includes(searchString);
    const outcomeString = outcomeQuery.toLowerCase();
    const outcomeMatch = row.outcome.toLowerCase().includes(outcomeString);
    let durationInSeconds = 0;
    if (typeof row.call_duration === "string") {
      const durationParts = row.call_duration.split(":");
      durationInSeconds =
        parseInt(durationParts[0]) * 3600 +
        parseInt(durationParts[1]) * 60 +
        parseInt(durationParts[2]);
    } else if (typeof row.call_duration === "number") {
      durationInSeconds = row.call_duration;
    }

    let durationCondition = true;
    if (
      durationFilter.operator === "smaller" &&
      durationFilter.seconds !== null
    ) {
      durationCondition = durationInSeconds < durationFilter.seconds;
    } else if (
      durationFilter.operator === "larger" &&
      durationFilter.seconds !== null
    ) {
      durationCondition = durationInSeconds > durationFilter.seconds;
    }

    const dateMatch = isWithinDateRange(
      row.created_at,
      dateValue.startDate,
      dateValue.endDate
    );

    return (
      statusMatch && nameMatch && outcomeMatch && durationCondition && dateMatch
    );
  });
  // const hii = filteredData[0].outcome
  // const parsed = JSON.parse(hii)
  //   console.log(parsed);

  const urlRec = filteredData[0]?.recording_url;
  // console.log(typeof urlRec);
  // console.log(urlRec);
  // const urlRec =

  const handleCheckboxChange = (recording) => {
    setSelectedRecordings((prevSelected) =>
      prevSelected.includes(recording)
        ? prevSelected.filter((r) => r !== recording)
        : [...prevSelected, recording]
    );
  };

  const tableRows = filteredData.map((row, index) => {
    let parsedOutcome = [];
    try {
      parsedOutcome = row.outcome ? JSON.parse(row.outcome) : [];
    } catch (e) {
      console.error("Error parsing outcome:", e);
    }

    const outcomeString = Array.isArray(parsedOutcome)
      ? parsedOutcome
          .filter((item) => item !== null && item !== "none")
          .join(", ")
      : "";

    return (
      <tr
        style={{ color: fontColor }}
        key={index}
        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      >
        <td className="px-5 py-4">
          <input
            type="checkbox"
            checked={selectedRecordings.includes(row)}
            onChange={() => handleCheckboxChange(row)}
          />
        </td>
        <td className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {row?.call_id}
        </td>
        <td className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {row.full_name}
        </td>
        <td className="px-9 py-4">{row.contact_number}</td>
        <td className="px-6 py-4 text-center">{localTime(row.created_at)}</td>
        <td className="px-6 py-4 text-center">{row.call_duration}</td>
        <td className="px-6 py-4 text-center capitalize">{row.status}</td>
        <td className="px-6 py-4 text-center">{row.outcome}</td>
        <td className="px-6 py-4 text-center flex items-center gap-2">
          {row.recording_url && (
            <audio controls className="w-[159px] p-1.5">
              <source src={row.recording_url} type="audio/wav" />
            </audio>
          )}
          {/* <Link to={urlRec}>Audio</Link> */}
        </td>
        <td className="px-12 py-4 text-center">
          <div className="flex items-center gap-2">
            <SiGoogledocs
              className="hover:cursor-pointer"
              onClick={() => openPopup(row.transcription, row.notes)}
            />
          </div>
        </td>
        <td className="px-12 py-4 text-center">
          <RecordingError data={row?.errors} />
        </td>
      </tr>
    );
  });

  const handleCopyTranscription = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(popupContent));

      toast.success("Transcription copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy transcription: ", error);
      toast.error("Failed to copy transcription.");
    }
  };

  const handleCopyNotes = async () => {
    try {
      await navigator.clipboard.writeText(notesContent);
      toast.success("Notes copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy notes: ", error);
      toast.error("Failed to copy notes.");
    }
  };

  const handleDownloadTranscription = (notes, transcription) => {
    const content = `${JSON.stringify(popupContent)}`;
    const blob = new Blob([content], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "transcription.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadNotes = (notes, transcription) => {
    const content = `${JSON.stringify(notesContent)}`;
    const blob = new Blob([content], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "notes.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor }}
        className="w-full h-screen"
      >
        <Navbar />
        <div className="mx-3 shadow-none my-10 p-5">
          <div floated={false} shadow={false} className="rounded-none">
            <div className="mb-8 flex items-center justify-between gap-8">
              <div>
                <Typography
                  style={{ color: fontColor }}
                  variant="h5"
                  color="blue-gray"
                >
                  Recordings
                </Typography>
                <Typography
                  style={{ color: fontColor }}
                  color="gray"
                  className="mt-1 font-normal"
                >
                  {data.length} recordings
                </Typography>
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                {selectedRecordings && selectedRecordings.length > 0 ? (
                  <Button
                    className="flex items-center gap-2"
                    size="sm"
                    onClick={handleDownloadSelected}
                  >
                    Recordings {`(${selectedRecordings.length})`}
                  </Button>
                ) : null}
                <Button
                  onClick={() => setShowFilter(!showFilter)}
                  variant="outlined"
                  className="flex items-center gap-2"
                  size="sm"
                >
                  <HiOutlineFilter className="text-lg" /> filter
                </Button>

                <RecordingsCSV data={filteredData} />
              </div>
            </div>
            <div
              className={`${
                showFilter ? "flex" : "hidden"
              } z-[999] flex-col items-center bg-white shadow-md p-3 rounded-md mb-4 justify-start md:flex-row`}
            >
              <div className="basis-1/4 pr-4">
                <select
                  id="status-filter"
                  className="bg-gray-50 w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={selectedStatus}
                  onChange={handleFilterChange}
                >
                  <option value="All">Status</option>
                  <option value="Busy">Busy</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Failed">Failed</option>
                  <option value="No-Answer">No-Answer</option>
                </select>
              </div>
              <div className="basis-1/4 pr-4">
                <input
                  type="text"
                  id="outcome"
                  placeholder="Search by outcome"
                  value={outcomeQuery}
                  onChange={handleOutcomeChange}
                  className="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="basis-1/4 pr-4">
                <Datepicker
                  placeholder={"Date"}
                  separator={"to"}
                  showShortcuts={true}
                  showFooter={true}
                  value={dateValue}
                  onChange={handleValueChange}
                  maxDate={new Date()}
                />
              </div>
              <div className="basis-1/4 flex pr-4 gap-3">
                <select
                  id="duration-operator"
                  className="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={durationFilter.operator}
                  onChange={handleOperatorChange}
                >
                  <option selected>Call Duration</option>
                  <option value="smaller">Call Duration Smaller Than</option>
                  <option value="larger">Call Duration Larger Than</option>
                </select>
                {durationFilter.operator != "" && (
                  <input
                    type="number"
                    placeholder="Sec"
                    min="0"
                    className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={durationFilter.seconds}
                    onChange={handleSecondsChange}
                  />
                )}
              </div>
              <div className="basis-1/4">
                <input
                  type="text"
                  id="search"
                  placeholder="Search by name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr style={{ color: fontColor }}>
                    <th scope="col" className="px-5 py-3">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                      />
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Call ID
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Contact Number
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Date and Time
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Call Duration
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Outcome
                    </th>
                    <th scope="col" className="px-14 py-3 ">
                      Recording
                    </th>
                    <th scope="col" className="py-3">
                      Transcription
                    </th>
                    <th scope="col" className="py-3">
                      Error
                    </th>
                  </tr>
                </thead>
                <tbody>{tableRows}</tbody>
              </table>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="fixed inset-0 z-50 overflow-y-auto overflow-x-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
            <div className="relative w-full max-w-2xl mx-auto max-h-[660px] p-5 bg-white rounded-lg">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closePopup}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div className="flex justify-around flex-col md:flex-row p-5 rounded-md gap-3 items-center w-full relative">
                <Typography
                  variant="h6"
                  className={`flex cursor-pointer justify-center px-3 py-2 text-center w-full rounded-md md:basis-1/2 font-medium bg-[#E8EBED] ${
                    selectedOption === "Notes" &&
                    "rounded-lg bg-gradient-to-tr from-gray-800 to-gray-600 py-3 px-6 font-sans text-xs font-bold text-white shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  }`}
                  onClick={() => setSelectedOption("Notes")}
                >
                  Notes
                </Typography>
                <Typography
                  variant="h6"
                  className={`flex cursor-pointer justify-center px-3 py-2 text-center w-full rounded-md md:basis-1/2 font-medium bg-[#E8EBED] ${
                    selectedOption === "Transcription" &&
                    "rounded-lg bg-gradient-to-tr from-gray-800 to-gray-600 py-3 px-6 font-sans text-xs font-bold text-white shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  }`}
                  onClick={() => setSelectedOption("Transcription")}
                >
                  Transcription
                </Typography>
              </div>
              <div className="">
                {selectedOption === "Notes" && (
                  <>
                    <p className="flex justify-center text-center w-full md:basis-1/2 font-medium">
                      Notes
                    </p>
                    <div className="m-5 h-96">
                      <textarea
                        className="w-full h-full p-2 border border-gray-300 focus-visible:border-gray-900 resize-none"
                        value={notesContent}
                        onChange={(e) => setNotesContent(e.target.value)}
                      />
                    </div>

                    <div className="">
                      <div className="flex flex-wrap items-center justify-center gap-5  shrink-0 text-blue-gray-500">
                        <button
                          onClick={handleCopyNotes}
                          className="w-[40%] rounded-lg bg-gradient-to-tr from-gray-800 to-gray-600 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        >
                          Copy
                        </button>
                        <button
                          onClick={handleDownloadNotes}
                          className="w-[40%] rounded-lg bg-gradient-to-tr from-gray-800 to-gray-600 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {selectedOption === "Transcription" && (
                  <>
                    <p className="flex justify-center text-center w-full md:basis-1/2 font-medium">
                      Transcription
                    </p>
                    <div className="m-5 h-96 overflow-y-scroll p-3 border rounded-xl">
                      {popupContent &&
                        popupContent.map((message, index) => (
                          <h1
                            key={index}
                            className={`flex p-2 items-center  ${
                              message.role === "user"
                                ? "justify-end"
                                : "justify-start"
                            }`}
                          >
                            <span
                              className={`w-fit p-2 rounded-xl ${
                                message.role === "user"
                                  ? "justify-end bg-[#d2edd4]"
                                  : "justify-start bg-[#f5f5f5]"
                              }`}
                            >
                              {message.role === "user"
                                ? message?.content.map((text, index) => (
                                    <div key={index}>{text.text}</div>
                                  ))
                                : ""}
                              {message?.content?.map((text, index) => {
                                // Extract content between <response> tags
                                const responseMatch = text.text.match(
                                  /<response>([\s\S]*?)<\/response>/
                                );
                                const responseText = responseMatch
                                  ? responseMatch[1]
                                  : "";

                                return <div key={index}>{responseText}</div>;
                              })}
                            </span>
                          </h1>
                        ))}
                    </div>

                    <div className="">
                      <div className="flex flex-wrap items-center justify-center gap-5  shrink-0 text-blue-gray-500">
                        <button
                          onClick={handleCopyTranscription}
                          className="w-[40%] rounded-lg bg-gradient-to-tr from-gray-800 to-gray-600 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        >
                          Copy
                        </button>
                        <button
                          onClick={handleDownloadTranscription}
                          className="w-[40%] rounded-lg bg-gradient-to-tr from-gray-800 to-gray-600 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default QuickRecording;
