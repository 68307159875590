import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const { fontColor,backgroundColor } = useSelector((state) => state.theme);
  const [redirectCountdown, setRedirectCountdown] = useState(5);
  const navigate = useNavigate();
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
  ?.company_name;

  useEffect(()=>{
    const sendStatusUpdate = async () => {
      const id = localStorage.getItem('sessionId')
      try {
        const response = await fetch(`${baseUrl}/api/add_user_credit/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            status: true,
            session_id: id,
          }),
        });

        if (response.ok) {
          // console.log("Status update successful");
        } else {
          const errorData = await response.json();
          console.error("Status update failed:", errorData);
        }
      } catch (error) {
        console.error("Error sending status update:", error);
      }
    };

    sendStatusUpdate();
  },[])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (redirectCountdown > 0) {
        setRedirectCountdown(redirectCountdown - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [redirectCountdown]);



  useEffect(() => {
    if (redirectCountdown === 0) {
      navigate('/create/agent');
    }
  }, [redirectCountdown]);

  return (
    <div style={{ backgroundColor: backgroundColor }} className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-16 w-16 mx-auto text-green-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
        <h2 style={{ color: fontColor }} className="text-2xl font-semibold text-center mb-4">Payment Successful!</h2>
        <p style={{ color: fontColor }} className="text-gray-600 text-center mb-4">Thank you for your payment.</p>
        <p style={{ color: fontColor }} className="text-gray-600 text-center mb-8">
          You will be redirected to your Dashboard in {redirectCountdown} seconds.
        </p>
      </div>
    </div>
  );
};

export default PaymentSuccess;
