import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { notesSchema } from "../schemas/FormSchema";
import toast from "react-hot-toast";


function NotesPopUp({data}) {
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const [showPopUp, setShowPopUp] = useState(false);
  const { fontColor } = useSelector((state) => state.theme);
  const [popupContent, setPopupContent] = useState("");
  const agentID = localStorage.getItem("agentID");
  const company_name = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const handleToggle = () => {
    setShowPopUp(!showPopUp);
  };

  useEffect(() => {
    if (data) {
      formik.setValues({ notes: data.notes || '' });
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      notes: ""
    },
    validationSchema: notesSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            notes: values.notes,
          }),
        });
        if (response.ok) {
          handleToggle()
          toast.success("Notes updated successfully")
        }
        else if (response.status === 400) {
          const responseData = await response.json()
          toast.error(responseData.message)
        }
        else{
          toast.error("Something went wrong")
        }
      } catch (error) {
        console.error(error);
      }
    },
  });
  return (
    <>
      <button
        style={{ color: fontColor }}
        className="select-none rounded-lg  2xl:text-lg text-center align-middle text-xs font-semibold"
        type="button"
        onClick={handleToggle}
      >
        Add Notes
      </button>
      <div
        className={`${
          showPopUp ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          showPopUp ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div className="fixed inset-0 z-50 overflow-y-auto overflow-x-auto bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="relative w-full max-w-xl mx-auto h-auto p-5 bg-white rounded-lg">
            <form onSubmit={formik.handleSubmit}>
              <div className="flex justify-between items-center">
                <h4
                  style={{ color: fontColor }}
                  className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
                >
                  Add Notes
                </h4>
                <div onClick={handleToggle}>
                  <MdOutlineClose className="text-2xl cursor-pointer" />
                </div>
              </div>
              <div className="my-5 h-96">
                <textarea
                  className="w-full h-full rounded-md p-2 border border-gray-300 focus-visible:border-gray-900 resize-none"
                  placeholder="Start Typing Notes..."
                  name="notes"
                  id="notes"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.errors.notes && formik.touched.notes ? (
                <p className="w-full text-red-600 mx-[7px] font-medium">
                  {formik.errors.notes}
                </p>
              ) : null}
              <button
                type="submit"
                className="w-full rounded-lg bg-gradient-to-tr from-gray-800 to-gray-600 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Add Notes
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotesPopUp;
