import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import AnalyticsCard from "./AnalyticsCard";
import { useNavigate } from "react-router-dom";
import CampaignHome from "./CampaignHome";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import AnalyticsTabs from "../pages/AnalyticsTabs";

function Analytics() {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="w-full h-screen flex flex-col"
      >
        <Navbar />
        <div className="mt-3 z-0">
          <AnalyticsTabs setLoading={setLoading} />
        </div>
      </div>
      {loading && (
        <div className="w-full h-full absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default Analytics;
