import React, { useState, useEffect } from "react";
import { RxChatBubble } from "react-icons/rx";
import { NavLink, useNavigate, Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa6";
import { IoWalletOutline } from "react-icons/io5";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import {
  startTour,
  stopTour,
  nextStep,
  prevStep,
  updateStepIndex,
} from "../features/Tour/Tour";
import { useDispatch, useSelector } from "react-redux";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import NavbarDrawer from "./NavbarDrawer";
import defaultLogo from "../assets/CloserX.ai (12).png";
import { useNotifications } from "../utils/NotificationsContext";

function Navbar() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { unreadCount } = useNotifications();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);
  const { legalName, backgroundColor, fontColor, logo } = useSelector(
    (state) => state.theme
  );
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const [openRight, setOpenRight] = React.useState(false);
  const [data, setData] = useState([]);
  const [openLeft, setOpenLeft] = React.useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const location = useLocation();
  const userIcon = localStorage.getItem("userIcon");
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const { run, steps, stepIndex } = useSelector((state) => state.tour);

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("isFirstVisit");
    if (!isFirstVisit) {
      localStorage.setItem("isFirstVisit", "false");
      dispatch(startTour());
    }
  }, [dispatch]);

  useEffect(() => {
    if (run && steps.length && steps[0].target === "body") {
      dispatch(nextStep());
    }
  }, [run, steps, dispatch]);

  const handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(stopTour());
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (action === ACTIONS.PREV) {
        dispatch(prevStep());
      } else {
        dispatch(nextStep());
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/agency/users/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData.data.agents[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openDrawerRight = () => setOpenRight(true);
  const closeDrawerRight = () => setOpenRight(false);
  const openDrawerLeft = () => setOpenLeft(true);
  const closeDrawerLeft = () => setOpenLeft(false);
  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const paths = ["/create/agent", "/create/knowledge-bases", "/create/list","/clone/voice"];
  const isActive = paths.includes(location.pathname);

  return (
    <>
      {run && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous={true}
          run={run}
          steps={steps}
          stepIndex={stepIndex}
          scrollToFirstStep
          showProgress={false}
          showSkipButton={true}
          disableOverlayClose={true}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      <nav
        style={{ backgroundColor: backgroundColor }}
        className="bg-white border-gray-200 lg:px-10 dark:bg-gray-900 dark:border-gray-700 shadow"
      >
        <div className="max-w-screen-2xl flex items-center justify-between md:justify-between gap-5 mx-auto p-4">
          <Link
            to="/dashboard"
            className="flex items-center rtl:space-x-reverse"
          >
            {logo ? (
              <img
                src={logo}
                alt=""
                className="min-w-[100px] min-h-[40px] max-w-[200px] max-h-[70px] object-contain"
              />
            ) : null}
            {/* <span
              style={{ color: fontColor }}
              className="self-center hidden xl:block capitalize text-lg sm:text-xl md:text-2xl font-mono font-bold whitespace-nowrap dark:text-white"
            >
              {legalName}
            </span> */}
          </Link>
          <div
            className="hidden w-full md:flex-1 xl:flex md:justify-center md:items-center gap-12 md:w-auto"
            id="navbar-multi-level"
          >
            <ul
              style={{ backgroundColor: backgroundColor, color: fontColor }}
              className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
            >
              <li>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? `md:bg-transparent  block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-lg border-b-2 border-[#046451] `
                        : "md:bg-transparent block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-lg hover:border-b-2 hover:border-[#046451]"
                    }`
                  }
                >
                  Home
                </NavLink>
              </li>
              <li className="relative my-first-step">
                <button
                  className={`text-lg hover:border-b-2 hover:border-[#046451] rounded ${
                    isActive ? "border-b-2 border-[#046451]" : ""
                  }`}
                  onClick={handleDropdownToggle}
                >
                  Create
                </button>
                <ul
                  className={`absolute z-10 ${
                    dropdownVisible ? "" : "hidden"
                  } top-9 right-0 left-[-55px] min-w-[180px] overflow-auto rounded-md border border-blue-gray-50 bg-white p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none`}
                >
                  <NavLink
                    to="/create/agent"
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? "md:bg-transparent my-first-sub-step text-[#046451] block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent border-b-2 border-[#046451]"
                          : "my-first-sub-step"
                      }`
                    }
                  >
                    <li className="block w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900">
                      Agent
                    </li>
                  </NavLink>

                  <NavLink
                    to="/create/knowledge-bases"
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? "md:bg-transparent my-first-sub-step-two block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent border-b-2 border-[#046451] "
                          : "my-first-sub-step-two"
                      }`
                    }
                  >
                    <li className="block w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900">
                      Knowledge Base
                    </li>
                  </NavLink>
                  <NavLink
                    to="/create/list"
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? "md:bg-transparent my-first-sub-step-three block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent border-b-2 border-[#046451] "
                          : "my-first-sub-step-three"
                      }`
                    }
                  >
                    <li className="block w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900">
                      Contacts
                    </li>
                  </NavLink>

                  <NavLink
                    to="/clone/voice"
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? "md:bg-transparent my-first-sub-step-three block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent border-b-2 border-[#046451] "
                          : "my-first-sub-step-three"
                      }`
                    }
                  >
                    <li className="block w-full cursor-pointer select-none rounded-md px-3 pt-[9px] pb-2 text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900">
                      Clone Voice
                    </li>
                  </NavLink>
                </ul>
              </li>

              <li className="my-second-step">
                <NavLink
                  to="/launch"
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? "md:bg-transparent block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-lg border-b-2 border-[#046451] "
                        : "md:bg-transparent block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-lg hover:border-b-2 hover:border-[#046451]"
                    }`
                  }
                >
                  Campaign Builder
                </NavLink>
              </li>
              <li className="my-third-step">
                <NavLink
                  to="/analytics"
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? "md:bg-transparent block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-lg border-b-2 border-[#046451] "
                        : "md:bg-transparent block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-lg hover:border-b-2 hover:border-[#046451]"
                    }`
                  }
                >
                  Analytics
                </NavLink>
              </li>

              {baseUrl == "https://app.closerx.ai" ? (
                <li>
                  <NavLink
                    to="/agency"
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? "md:bg-transparent text-[#046451] block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-lg border-b-2 border-[#046451] "
                          : "md:bg-transparent block py-2 px-3 rounded md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-lg hover:border-b-2 hover:border-[#046451]"
                      }`
                    }
                  >
                    Agency
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>

          <div className="flex items-center gap-2">
            <div className="md:mr-6 my-fourth-step">
              <Link
                style={{ backgroundColor: backgroundColor }}
                to="/transactions"
                className="py-1 md:py-3 px-1 md:px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-1 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex justify-center items-center gap-2"
              >
                <IoWalletOutline
                  style={{ color: fontColor }}
                  className="text-md md:text-xl"
                />
                <span
                  style={{ color: fontColor }}
                  className="font-bold mt-[3px] text-[9px] md:text-[15px]"
                >
                  {data.credits ? parseFloat(data.credits).toFixed(2) : 0}{" "}
                  Credits
                </span>
              </Link>
            </div>
            <div className="relative">
              <Link to={"/notifications"} className="relative">
                <span role="img" aria-label="bell">
                  🔔
                </span>
                {unreadCount > 0 && (
                  <span className="absolute -top-2 -right-1 bg-red-500 text-white rounded-full text-xs px-1">
                    {unreadCount}
                  </span>
                )}
              </Link>
            </div>
            <div className="flex items-center justify-center">
              <div
                onClick={openDrawerLeft}
                className="bg-transparent xl:hidden mr-3"
              >
                <FaBars style={{ color: fontColor }} className="text-2xl" />
              </div>
            </div>
            <Drawer
              placement="left"
              open={openLeft}
              onClose={closeDrawerLeft}
              className="p-4"
            >
              <div className="mb-6 flex items-center justify-between">
                <Typography variant="h5" color="blue-gray">
                  <Link style={{ color: fontColor }} to="/create/agent">
                    {legalName}
                  </Link>
                </Typography>
                <IconButton
                  variant="text"
                  color="black"
                  onClick={closeDrawerLeft}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="h-5 w-5"
                    style={{ color: fontColor }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </IconButton>
              </div>
              <ul
                style={{ color: fontColor }}
                className="space-y-2 font-medium"
              >
                <li>
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? "block py-2 px-3 rounded lg:p-0 md:dark:text-blue-500 dark:bg-blue-600 text-[#F6F5F5] hover:text-[#F6F5F5] bg-black"
                          : "md:bg-transparent block py-2 px-3 rounded md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent hover:bg-black hover:text-[#F6F5F5]"
                      }`
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <button
                    className={` w-full text-left ${
                      isActive
                        ? "block py-2 px-3 rounded lg:p-0 lg:dark:text-blue-500 dark:bg-blue-600 text-[#F6F5F5] hover:text-[#F6F5F5] bg-black"
                        : "block py-2 px-3 rounded md:dark:text-blue-500 hover:text-[#F6F5F5] dark:bg-blue-600 hover:bg-black"
                    }`}
                    onClick={toggleAccordion}
                  >
                    Create
                  </button>
                  {isAccordionOpen && (
                    <ul className="ml-4 mt-2">
                      <li>
                        <NavLink
                          to="/create/agent"
                          className="block py-1 px-3 rounded hover:bg-black hover:text-[#F6F5F5]"
                        >
                          Agent
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/create/knowledge-bases"
                          className="block py-1 px-3 rounded hover:bg-black hover:text-[#F6F5F5]"
                        >
                          Knowledge Base
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/create/list"
                          className="block py-1 px-3 rounded hover:bg-black hover:text-[#F6F5F5]"
                        >
                          Contacts
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/clone/voice"
                          className="block py-1 px-3 rounded hover:bg-black hover:text-[#F6F5F5]"
                        >
                          Clone Voice
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <NavLink
                    to="/launch"
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? "block py-2 px-3 rounded md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-[#F6F5F5] hover:text-[#F6F5F5] bg-black"
                          : "md:bg-transparent block py-2 px-3 rounded md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent hover:bg-black hover:text-[#F6F5F5]"
                      }`
                    }
                  >
                    Campaign Builder
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/analytics"
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? "block py-2 px-3 rounded md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-[#F6F5F5] hover:text-[#F6F5F5] bg-black"
                          : "md:bg-transparent block py-2 px-3 rounded md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent hover:bg-black hover:text-[#F6F5F5]"
                      }`
                    }
                  >
                    Analytics
                  </NavLink>
                </li>
                {baseUrl == "https://app.closerx.ai" ? (
                  <li>
                    <NavLink
                      to="/agency"
                      className={({ isActive }) =>
                        `${
                          isActive
                            ? "block py-2 px-3 rounded md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent text-[#F6F5F5] hover:bg-[#4F772D] hover:text-[#F6F5F5] bg-black"
                            : "md:bg-transparent block py-2 px-3 rounded md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent hover:bg-black hover:text-[#F6F5F5]"
                        }`
                      }
                    >
                      Agency
                    </NavLink>
                  </li>
                ) : null}
              </ul>
            </Drawer>
            {/* <RxChatBubble className="text-2xl" />
            <span>|</span> */}
            <div className="my-fiveth-step">
              <button
                onClick={openDrawerRight}
                className="flex justify-center items-center rounded-full w-10 h-10 font-semibold text-white cursor-pointer px-0 py-0 text-md"
              >
                {userData && userData.profile_picture ? (
                  <img
                    src={`${userData.profile_picture}`}
                    alt=""
                    className="w-10 h-10 rounded-full"
                  />
                ) : (
                  <>
                    <div className="w-10 h-10  rounded-full flex justify-center items-center capitalize text-xl bg-brown-600">
                      {userIcon
                        ? userIcon
                        : JSON.parse(localStorage.getItem("userData"))
                            ?.first_name[0]}
                    </div>
                  </>
                )}
              </button>
            </div>
            <NavbarDrawer openRight={openRight} setOpenRight={setOpenRight} />
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
