import * as Yup from "yup";

export const ForgetPasswordSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
});

export const signUpSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string()
    .min(6)
    .required("Password must be at least 6 characters"),
});

export const loginSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string()
    .min(6)
    .required("Password must be at least 6 characters"),
});

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .min(6)
    .required("Password must be at least 6 characters"),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

export const createAgentSchema = Yup.object({
  agentName: Yup.string()
    .max(25, "Agent name must be at most 25 characters long")
    .required("Agent name is required"),
  projectName: Yup.string()
    .max(25, "Project name must be at most 25 characters long")
    .required("Project name is required"),
});

export const createAgentStepTwoSchema = Yup.object({
  callType: Yup.string()
    .max(25, "Call Type must be at most 25 characters long")
    .required("Call type is required"),
  companyName: Yup.string()
    .max(25, "Company name must be at most 25 characters long")
    .required("Company name is required"),
});

export const createAgentStepThreeSchema = Yup.object({
  option: Yup.string().required("Please select an option"),
});

export const agencyOnboardingSchema = Yup.object({
  legalName: Yup.string().required("Legal name is required"),
  address: Yup.string().required("Address is required"),
  email: Yup.string().email().required("Please enter your email"),
  iSpaymentDone: Yup.boolean(),
  package: Yup.string().when("iSpaymentDone", {
    is: false,
    then: () => Yup.string().required("Package is required"),
  }),
  domain: Yup.string()
    .required("Domain Name is required")
    .matches(
      /^(?!www\.|http:\/\/|https:\/\/|[^\w])(?!.*[^a-zA-Z0-9-_.]).*?[a-zA-Z0-9-]+\.[a-zA-Z]{2,11}$/,
      "Please enter a valid domain name without 'http', 'https', 'www', or special characters at the start"
    ),
});

export const changePasswordSchema = Yup.object({
  currentPassword: Yup.string()
    .min(6)
    .required("Password must be at least 6 characters"),
  newPassword: Yup.string()
    .min(6)
    .required("Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .required("Confirm password must be at least 6 characters")
    .oneOf([Yup.ref("newPassword"), null], "Password must match"),
});

export const updateProfileSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
});

export const createCampaignStepOne = Yup.object({
  campaignName: Yup.string()
    .max(50, "Campaign name must be at most 50 characters long")
    .required("Campaign Name is required"),
  agentName: Yup.string().required("Agent Name is required"),
});

export const createCampaignStepTwo = Yup.object({
  // knowledgeBase: Yup.string().required("Knowledge Base is required"),
  contactList: Yup.string().required("Contact List is required"),
  twilio: Yup.string()
    .max(50, "Twilio number must be at most 50 characters long")
    .required("Twilio number is required"),
});

export const quickCampaignSchema = Yup.object({
  // knowledgeBase: Yup.string().required("Knowledge Base is required"),
  twilio: Yup.string()
    .max(50, "Twilio number must be at most 50 characters long")
    .required("Twilio number is required"),
});

export const createCampaignStepTwoInbound = Yup.object({
  // knowledgeBase: Yup.string().required("Knowledge Base is required"),
  // contactList: Yup.string().required("Contact List is required"),
  twilio: Yup.string().required("Twilio number is required"),
  budget: Yup.number()
    .required("Budget is required")
    .positive("Budget must be greater than 0"),
});

// Helper function to format the current time as HH:mm
const formatTime = (date) => {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const createCampaignStepThree = Yup.object().shape({
  // startDate: Yup.date()
  //   .required("Start Date is required")
  //   .min(
  //     new Date(),
  //     "Start date and time must be after or equal to the current date and time"
  //   ),
  // endTime: Yup.string()
  //   .required("End Time is required")
  //   .test(
  //     "is-after-start-time",
  //     "End Time must be after Start Time",
  //     function (value) {
  //       const { startTime } = this.parent;
  //       if (!value || !startTime) return false;

  //       const [startHours, startMinutes] = startTime.split(":").map(Number);
  //       const [endHours, endMinutes] = value.split(":").map(Number);
  //       const startDate = new Date();
  //       const endDate = new Date();

  //       startDate.setHours(startHours, startMinutes, 0, 0);
  //       endDate.setHours(endHours, endMinutes, 0, 0);

  //       console.log("Start Date:", startDate);
  //       console.log("End Date:", endDate);

  //       return endDate > startDate;
  //     }
  //   ),
  // startTime: Yup.string()
  //   .required("Start Time is required")
  //   .test(
  //     "is-valid-start-time",
  //     "Please select a valid start time",
  //     function (value) {
  //       if (!value) return false;

  //       const { startDate } = this.parent;
  //       const [selectedHours, selectedMinutes] = value.split(":").map(Number);
  //       const selectedDate = new Date();
  //       selectedDate.setHours(selectedHours, selectedMinutes, 0, 0);

  //       const currentDate = new Date(); // Get the current time dynamically

  //       console.log("Selected Date:", selectedDate.getTime());
  //       console.log("Live Date:", currentDate.getTime());

  //       // Check if start date is today
  //       if (startDate && startDate == currentDate) {
  //         // If start date is today, check if start time is equal to or later than current time
  //         console.log("evcd")
  //         return selectedDate >= currentDate;
  //       }

  //       return true; // Allow any time if start date is not today
  //     }
  //   ),
  budget: Yup.number()
    .required("Budget is required")
    .positive("Budget must be greater than 0"),
});

// export const addCalenderSchema = Yup.object({
//   // url: Yup.string().required("URL is required"),
//   triggerState: Yup.string().required("Trigger State is required"),
//   description: Yup.string().required("Description is required"),
//   // bookedMessage: Yup.string().required("Booked Message is required"),
//   // rejectionMessage: Yup.string().required("Rejection Message is required"),
//   // calcomApi: Yup.string().required("CalCom API is required"),
// });

export const addCalenderSchema = Yup.object().shape({
  calanderType: Yup.string().required("Calendar Type is required"),
  triggerState: Yup.string().required("Trigger State is required"),
  description: Yup.string().required("Description is required"),
  calcomApi: Yup.string().when("calanderType", {
    is: "calendar",
    then: () => Yup.string().required("CalCom API is required"), // schema when
  }),
});

export const liveTransferSchema = Yup.object({
  number: Yup.number()
    .required("Number is required")
    .typeError("Number must be a valid number"),
  triggerState: Yup.string().required("Trigger State is required"),
  rejectionMessage: Yup.string().required("Rejection Message is required"),
  // exitState: Yup.string().required("Exit State is required"),
});

export const notesSchema = Yup.object({
  notes: Yup.string().required("Notes is required"),
});

export const voiceMailSchema = Yup.object({
  voiceText: Yup.string().required("Voice Mail is required"),
});

export const goalSchema = Yup.object({
  outcomeName: Yup.string().required("Outcome Name is required"),
  outcomeDescription: Yup.string().required("Outcome Description is required"),
});

export const knowledgeSchema = Yup.object({
  knowledgeName: Yup.string().required("Knowledge Base Name is required"),
});

export const endCallSchema = Yup.object({
  stateName: Yup.string().required("State Name is required"),
  description: Yup.string().required("Description is required"),
});

export const ifConditionSchema = Yup.object({
  stateName: Yup.string().required("State Name is required"),
  description: Yup.string().required("Description is required"),
  ifCondition: Yup.string().required("If Condition State Name is required"),
  elseCondition: Yup.string().required("Else Condition State Name is required"),
});

export const addKnowledgeSchema = Yup.object({
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answer is required"),
});

export const importAgentSchema = Yup.object({
  companyName: Yup.string().required("Company Name is required"),
  agentID: Yup.number().required("Agent ID is required"),
});

export const advancedSettingSchema = Yup.object({
  basicPrompt: Yup.string()
    .matches(/^[^{}]*$/, "Basic Prompt cannot contain { or } characters")
    .required("This field is required"),
});

export const senderEmailSchema = Yup.object({
  senderEmail: Yup.string()
    .required("Please enter your email")
    .test(
      "no-at-symbol",
      "The email prefix cannot contain '@'",
      (value) => !/@/.test(value) // Test to ensure '@' is not present
    ),
});
