import React, { useEffect, useState, useTransition } from "react";
import { useSelector } from "react-redux";
import ConfirmationDialog from "./ConfirmationDialog";
import { useNavigate } from "react-router-dom";

function SuperAdminUserView() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor } = useSelector((state) => state.theme);
  const [refresh, setRefresh] = useState(false);
  const [userData, setUserData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserActivate, setSelectedUserActivate] = useState(false);
  const [isPending, startTransition] = useTransition(); // Initialize useTransition
  const navigate = useNavigate();
  const email = JSON.parse(localStorage.getItem("userData"))?.email;

  useEffect(() => {
    startTransition(async () => {
      try {
        const response = await fetch(`${baseUrl}/api/superadmin/whitlabel/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setUserData(responseData.data);
        }
      } catch (error) {
        console.error(error);
      }
    });
  }, [baseUrl, refresh, startTransition]);

  const handleToggleChange = (id, isActive) => {
    setSelectedUserId(id);
    setSelectedUserActivate(isActive);
    setDialogOpen(true);
  };

  const handleConfirmToggle = async () => {
    if (selectedUserId === null) return;

    const action = selectedUserActivate ? "deactivate" : "activate";

    try {
      const response = await fetch(
        `${baseUrl}/api/superadmin/whitlabel/agency/${selectedUserId}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({ id: selectedUserId }), // Use the correct payload
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setRefresh(!refresh);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setDialogOpen(false);
      setSelectedUserId(null);
    }
  };

  const handleAccess = async (id) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/superadmin/impersonate/sub/user/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({ email: email, company_name: id }), // Use the correct payload
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data.data.domain.domain);
      window.open(`https://${data.data.domain.domain}/access/${id}/${email}`, '_blank');
      
      // const { access_token, refresh_token } = data.data;
      // localStorage.setItem("accessToken", access_token);
      // localStorage.setItem("refreshToken", refresh_token);
      // localStorage.setItem("userData", JSON.stringify(data.data.user));
      // localStorage.setItem("guestUser", JSON.stringify(data.guest));
  
      // navigate("/dashboard")
      // setRefresh(!refresh);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNavigate = (id) => {
    navigate(`/superAdmins/whiteLabel/user/${id}`);
  };

  return (
    <>
      <div className="relative overflow-x-auto p-4">
        {isPending || !userData ? ( // Show skeleton loader if pending
          <div className="p-4">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr style={{ color: fontColor }}>
                  <th
                    scope="col"
                    className="px-6 py-3 bg-gray-300 animate-pulse"
                  ></th>
                  <th
                    scope="col"
                    className="px-6 py-3 bg-gray-300 animate-pulse"
                  ></th>
                  <th
                    scope="col"
                    className="px-6 py-3 bg-gray-300 animate-pulse"
                  ></th>
                  <th
                    scope="col"
                    className="px-6 py-3 bg-gray-300 animate-pulse"
                  ></th>
                  <th
                    scope="col"
                    className="px-6 py-3 bg-gray-300 animate-pulse"
                  ></th>
                  <th
                    scope="col"
                    className="px-6 py-3 bg-gray-300 animate-pulse"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {Array(5)
                  .fill(null)
                  .map((_, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                      <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                      <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                      <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                      <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                      <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <table className="w-full text-sm text-left rtl:text-right h-full text-gray-500 dark:text-gray-400 overflow-y-hidden">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr style={{ color: fontColor }}>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Company Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Legal Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Reselling Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Subscription Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Call Recordings
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
                <th scope="col" className="px-6 py-3">
                  Access
                </th>
              </tr>
            </thead>
            <tbody>
              {userData &&
                userData.map((user) => (
                  <tr
                    style={{ color: fontColor }}
                    key={user.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td className="px-6 py-4">
                      <span
                        onClick={() => handleNavigate(user?.id)}
                        className="cursor-pointer"
                      >
                        {user?.email}
                      </span>
                    </td>
                    <td className="px-6 capitalize py-4">
                      {user?.company_name}
                    </td>
                    <td className="px-6 capitalize py-4">{user?.legal_name}</td>
                    <td className="px-6 capitalize py-4">
                      {user?.reselling_price}
                    </td>
                    <td className="px-6 capitalize py-4">
                      {user?.subsription_type}
                    </td>
                    <td className="px-6 capitalize py-4">
                      <button
                        onClick={() =>
                          navigate(
                            `/superAdmins/callrecordings/${user?.company_name}`
                          )
                        }
                        className="border p-2 capitalize rounded-lg bg-blue-500 text-white"
                      >
                        Call Recordings
                      </button>
                    </td>
                    <td className="px-6 capitalize py-4">
                      <button
                        onClick={() =>
                          handleToggleChange(user.id, user.activate)
                        }
                        type="button"
                        className={`border p-2 capitalize rounded-lg ${
                          user?.activate
                            ? "bg-red-400 text-white"
                            : "bg-green-400 text-white"
                        }`}
                      >
                        {user?.activate ? "deactivate" : "activate"}
                      </button>
                    </td>
                    <td className="px-6 capitalize py-4">
                      <button
                        onClick={() => handleAccess(user.company_name)}
                        type="button"
                        className={`border p-2 capitalize rounded-lg`}
                      >
                        Access
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirmToggle}
        message={`Are you sure you want to ${
          selectedUserActivate ? "deactivate" : "activate"
        } this user?`}
      />
    </>
  );
}

export default SuperAdminUserView;
