import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyName: "",
};

export const companyNameSlice = createSlice({
  name: "companyName",
  initialState,
  reducers: {
    setCompanyName(state, action) {
      state.companyName = action.payload;
    },
  },
});

export const { setCompanyName } = companyNameSlice.actions;
export default companyNameSlice.reducer;
