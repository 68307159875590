import React from 'react';
import { useSelector } from 'react-redux';

const dataToCSV = (data) => {
  const csvRows = [];

  // Adding data rows
  data.forEach(row => {
    const values = row.map(value => {
      const escaped = (''+value).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  });

  // Join rows with newline characters
  return csvRows.join('\n');
};

const downloadCSV = (csvData, filename) => {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ConvertToCSVButton = ({ data, filename }) => {
  const { fontColor } = useSelector((state) => state.theme);
  const handleConvertToCSV = () => {
    const csvData = dataToCSV(data);
    downloadCSV(csvData, filename);
  };

  return (
    <button style={{ color: fontColor }} onClick={handleConvertToCSV}>Convert to CSV</button>
  );
};

export default ConvertToCSVButton;
