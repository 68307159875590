import { configureStore, createSlice } from "@reduxjs/toolkit";
import fileDataReducer from "../features/importContact/contactSlice";
import activeStepReducer from "../features/importContact/activeStep";
import contactDataReducer from "../features/getContact/contactData";
import companyNameReducer from "../features/companies/companyName";
import userDataReducer from "../features/user/userData";
import agentDataReducer from "../features/Agents/agentData"
import agentDetailReducer from "../features/Agents/agentDetail"
import listNameReducer from "../features/List/list"
import scriptDataReducer from "../features/Agents/script"
import refreshReducer from "../features/Refresh/refresh"
import tourReducer from '../features/Tour/Tour';
import themeReducer from "../features/Theme/theme"
import baseUrlReducer from "../features/BaseURL/baseUrl";
import saveScriptReducer from '../features/Agents/saveScript'
import isEditingReducer from "../features/NodeIsEditing/isEditing.js"
import deleteNodeReducer from "../features/deleteNode/deleteNode.js"

export const store = configureStore({
  reducer: {
    fileData: fileDataReducer,
    activeStep: activeStepReducer,
    companyName: companyNameReducer,
    contactData: contactDataReducer,
    userData: userDataReducer,
    agentData: agentDataReducer,
    agentDetail:agentDetailReducer,
    listName: listNameReducer,
    scriptData: scriptDataReducer,
    refresh:refreshReducer,
    tour: tourReducer,
    theme: themeReducer,
    baseUrl: baseUrlReducer,
    saveScript: saveScriptReducer,
    isEditing: isEditingReducer,
    deleteNode: deleteNodeReducer,
  },
});
