import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function UploadCloneVoice() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState(null);
  const [voiceName, setVoiceName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length > 0) {
      setFile(droppedFiles[0]);
      // Handle file upload or further processing here
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      // Handle file upload or further processing here
    }
  };

  const handleVoiceNameChange = (e) => {
    setVoiceName(e.target.value);
  };

  const uploadVoice = async () => {
    if (!file) {
      setUploadError("Please select a file to upload.");
      return;
    }

    setUploading(true);
    setUploadError(null);

    const formData = new FormData();
    formData.append("file", file);
    // Add the voice name to the form data
    formData.append("voice_name", voiceName);
    formData.append("type", "file");
    try {
      const response = await fetch(`${baseUrl}/api/clone/voice/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: formData,
      });

      const responseData = await response.json();
      if (!response.ok) {
        toast.error(responseData.message || "Something went wrong!!");
        return;
      }

      toast.success("Voice Cloned successfully");
      setVoiceName("")
      setFile(null)
      // Handle success (e.g., show a success message, update state, etc.)
    } catch (error) {
      console.error("Upload error:", error);
      setUploadError(error.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
    <div className="basis-1/2 flex flex-col">
      <h2>Clone voice by uploading a recording</h2>
      <div
        className={`flex-1 mt-2 min-h-[100px] border-2 border-dashed ${
          dragging ? "border-blue-500" : "border-gray-500"
        } flex items-center justify-center`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        <input
          type="file"
          accept="audio/*"
          onChange={handleFileSelect}
          className="absolute opacity-0"
        />
        <p className="text-center">
          {file
            ? `Selected File: ${file.name}`
            : "Drag and drop a voice file here, or click to select"}
        </p>
      </div>
      <input
        type="text"
        value={voiceName}
        onChange={handleVoiceNameChange}
        placeholder="Enter voice name"
        className="mt-2 p-2 border border-gray-300 rounded"
      />
      <button
        onClick={uploadVoice}
        className={`mt-2 p-2 bg-blue-500 text-white rounded ${
          uploading || !file || !voiceName
            ? "opacity-50 cursor-not-allowed"
            : ""
        }`}
        disabled={uploading || !file || !voiceName}
      >
        {uploading ? "Uploading..." : "Upload Voice"}
      </button>
      {uploadError && <p className="mt-2 text-red-500">{uploadError}</p>}
      </div>
    </>
  );
}

export default UploadCloneVoice;
