import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdCopyAll } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function AgentList({ agentData, setRefresh, refresh, setResStatus }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const agentDetail = useSelector((state) => state.agentDetail.agentDetail);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(null);

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/campaign/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setCampaigns(responseData.data["campaign lists"]);
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    };

    fetchCampaign();
  }, [baseUrl, companyName]);

  const handleAgentDetails = async (id) => {
    const agentCampaign = campaigns.find(
      (campaign) =>
        campaign.agent_id === id &&
        (campaign.status === "running" || campaign.status === "active")
    );

    if (!agentCampaign) {
      localStorage.setItem("agentID", id);
      navigate("/flows");
    } else {
      setResStatus(
        "Campaign is active or running. To edit the Agent, stop the campaign first."
      );
    }
  };

  const handleDelete = async () => {
    const agentCampaign = campaigns.find(
      (campaign) =>
        campaign.agent_id === deleteID && campaign.status === "active"
    );

    if (!agentCampaign) {
      try {
        const response = await fetch(`${baseUrl}/api/agents/${deleteID}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          setRefresh(!refresh);
          handleToggle()
          toast.success("Agent Delete Successfully");
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setResStatus("Campaign is active. To delete the Agent stop campaign");
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  const handleClone = async (id) => {
    try {
      const response = await fetch(`${baseUrl}/api/clone_agent/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          agent_id: id,
        }),
      });
      if (response.ok) {
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggle = () => setOpen(!open);

  const handleOpen = (id) => {
    setOpen(!open);
    setDeleteID(id);
  };

  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="relative overflow-x-auto shadow-md h-full sm:rounded-lg m-6"
      >
        <table className="w-full text-sm text-left rtl:text-right overflow-y-auto max-h-full text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Agent ID
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Agent Name
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Agent Direction
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Voice Type
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Last Updated
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {agentData.map((agent) => (
              <tr
                key={agent.id}
                className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
              >
                <td style={{ color: fontColor }} className="px-6 py-4">
                  {agent.id}
                </td>
                <th
                  scope="row"
                  style={{ color: fontColor }}
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <button onClick={() => handleAgentDetails(agent.id)}>
                    {agent.name}
                  </button>
                </th>
                <td style={{ color: fontColor }} className="px-6 py-4">
                  {agent.direction}
                </td>
                <td style={{ color: fontColor }} className="px-6 py-4">
                  {agent.voice ? agent.voice : "Default"}
                </td>
                <td style={{ color: fontColor }} className="px-6 py-4">
                  {formatDate(agent.updated_at)}
                </td>
                <td
                  style={{ color: fontColor }}
                  className="px-6 py-4 flex justify-start h-full items-center gap-2"
                >
                  <MdCopyAll
                    onClick={() => handleClone(agent.id)}
                    className="text-xl cursor-pointer hover hover:text-[#003285]"
                  />

                  <MdDeleteOutline
                    onClick={() => handleOpen(agent.id)}
                    className="text-2xl hover:text-red-600 cursor-pointer"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {open && (
        <div className="fixed z-[999] top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center">
          <div className="bg-white rounded-lg p-4 max-w-lg w-full">
            <div className="flex justify-center items-center flex-col gap-3">
              <p className="text-lg">Are you sure you want to delete agent?</p>
              <div className="flex items-center gap-3">
                <button
                  style={{ color: fontColor }}
                  onClick={handleToggle}
                  className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
                  type="button"
                >
                  <span
                    style={{ backgroundColor: backgroundColor }}
                    className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
                  >
                    Cancel
                  </span>
                </button>
                <button
                  style={{ color: fontColor }}
                  onClick={handleDelete}
                  className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
                  type="button"
                >
                  <span
                    style={{ backgroundColor: backgroundColor }}
                    className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
                  >
                    Delete
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AgentList;
