import React, { useEffect, useState } from "react";
import { TfiEmail } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function CheckEmail() {
  const { fontColor, backgroundColor, legalName } = useSelector(
    (state) => state.theme
  );
  const [email, setEmail] = useState("");
  useEffect(() => {
    const fetchEmail = localStorage.getItem("email");
    setEmail(fetchEmail);
  }, []);
  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="w-screen h-screen p-8 flex justify-center items-center"
      >
        <div className="md:basis-3/4 bg-white rounded-md lg:basis-1/2 xl:basis-1/4 w-full sm:basis-4/5 custom-shadow gap-10 p-10">
          <div style={{ color: fontColor, borderColor:fontColor }}  className="border-gray-800 mx-auto border-4 w-20 rounded-full h-20 flex justify-center items-center mb-5">
            <TfiEmail style={{ color: fontColor }}  className="text-4xl text-gray-800 font-extrabold" />
          </div>
          <div className="flex justify-center items-center flex-col gap-4">
            <h1 style={{ color: fontColor }}  className="text-2xl text-center">Check Your Email</h1>
            <p style={{ color: fontColor }}  className="text-sm text-center">
              {`Please check your email address ${email} instructions to reset
             your password.`}
            </p>
            <Link
            
              to="/forget-password"
              className="text-white w-full text-center bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
            >
              Resend email
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckEmail;
