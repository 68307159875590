import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/login");
    } else if (accessToken) {
      navigate("/dashboard");
    }
  }, []);
  return null;
};

export default NotFound;
