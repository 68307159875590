import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { goalSchema } from "../schemas/FormSchema";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
import { setRefresh } from "../features/Refresh/refresh";

function GoalPopUp({ data }) {
  const refresh = useSelector((state) => state.refresh.refresh);
  const { fontColor } = useSelector((state) => state.theme);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const companyName = userData?.companies[0]?.company_name || "";
  const [showPopUp, setShowPopUp] = useState(false);
  const [step, setStep] = useState(2);
  const [outcome, setOutcome] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const agentID = localStorage.getItem("agentID");
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.goal_tracking) {
      const parsedData = JSON.parse(data.goal_tracking);
      setOutcome(parsedData);
    }
  }, [data]);

  const handleToggle = () => {
    setShowPopUp(!showPopUp);
  };

  const handleEditOutcome = (index) => {
    setEditIndex(index);
    setStep(1);
    formik.setValues({
      outcomeName: outcome[index].goalName,
      outcomeDescription: outcome[index].goalDescription,
    });
  };

  const formik = useFormik({
    initialValues: {
      outcomeName: "",
      outcomeDescription: "",
    },
    validationSchema: goalSchema,
    onSubmit: async (values, { resetForm }) => {
      const newGoal = {
        goalName: values.outcomeName,
        goalDescription: values.outcomeDescription,
      };
      const updatedOutcome = [...outcome];
      if (editIndex !== null) {
        updatedOutcome[editIndex] = newGoal;
        setEditIndex(null);
      } else {
        updatedOutcome.push(newGoal);
      }
      try {
        const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            goal_tracking: JSON.stringify(updatedOutcome),
          }),
        });
        if (response.ok) {
          handleToggle();
          setStep(2);
          resetForm();
          dispatch(setRefresh(!refresh));
          toast.success("Goal tracking updated successfully");
        } else if (response.status === 404) {
          const responseData = await response.json();
          toast.error(responseData.message);
        } else {
          toast.error("Something went wrong")
        }
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong");
      }
    },
  });

  const handleDeleteOutcome = async (index) => {
    const updatedOutcome = [...outcome];
    updatedOutcome.splice(index, 1);
    try {
      const response = await fetch(`${baseUrl}/api/agents/${agentID}/`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          goal_tracking: JSON.stringify(updatedOutcome),
        }),
      });
      if (response.ok) {
        handleToggle();
        setStep(2);
        dispatch(setRefresh(!refresh));
        toast.success("Goal tracking updated successfully");
      } else if (response.status === 404) {
        const responseData = await response.json();
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong")
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <button
        style={{ color: fontColor }}
        className="select-none rounded-lg 2xl:text-lg text-center align-middle text-xs font-semibold"
        type="button"
        onClick={handleToggle}
      >
        Setup Goal Tracking
      </button>
      <div
        className={`${
          showPopUp ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          showPopUp ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div
          data-dialog="sign-in-dialog"
          className="relative mx-auto flex w-full max-w-xl flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
        >
          {step === 1 && (
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col gap-4 p-6">
                <div className="flex justify-between items-center">
                  <h4
                    style={{ color: fontColor }}
                    className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
                  >
                    Setup Goal Tracking
                  </h4>
                  <div onClick={handleToggle}>
                    <MdOutlineClose className="text-2xl cursor-pointer" />
                  </div>
                </div>
                <h6
                  style={{ color: fontColor }}
                  className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                >
                  Outcome Name
                </h6>
                <div className="relative h-11 w-full min-w-[200px]">
                  <input
                    className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200 text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder="Outcome Name"
                    id="outcomeName"
                    name="outcomeName"
                    value={formik.values.outcomeName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.outcomeName && formik.touched.outcomeName ? (
                    <p className="w-full text-red-600 m-[7px] font-medium">
                      {formik.errors.outcomeName}
                    </p>
                  ) : null}
                </div>
                <h6
                  style={{ color: fontColor }}
                  className="block -mb-2 font-sans mt-3 text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                >
                  Define Your Outcome
                </h6>
                <div
                  style={{ color: fontColor }}
                  className="relative w-full min-w-[200px]"
                >
                  <textarea
                    className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200 border-t-transparent text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder="Outcome Description "
                    id="outcomeDescription"
                    name="outcomeDescription"
                    value={formik.values.outcomeDescription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.outcomeDescription &&
                  formik.touched.outcomeDescription ? (
                    <p className="w-full text-red-600 my-[-7px] mx-[7px] font-medium">
                      {formik.errors.outcomeDescription}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="p-6 pt-0">
                <button
                  className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          )}

          {step === 2 && (
            <div className="flex flex-col gap-4 p-6">
              <div className="flex justify-between items-center">
                <h4
                  style={{ color: fontColor }}
                  className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
                >
                  Tracked Outcomes:
                </h4>
                <div onClick={handleToggle}>
                  <MdOutlineClose className="text-2xl cursor-pointer" />
                </div>
              </div>
              <div
                onClick={() => setStep(1)}
                className="w-full flex justify-center cursor-pointer border border-dashed p-4"
              >
                + setup another outcome
              </div>
              <div className="max-h-96 overflow-auto">
                {outcome &&
                  outcome.map((outcome, index) => (
                    <div
                      key={index}
                      className="w-full flex mb-2 justify-between border p-4"
                    >
                      <h3>{outcome.goalName}</h3>
                      <div className="flex justify-center gap-3 text-2xl">
                        <CiEdit
                          onClick={() => handleEditOutcome(index)}
                          className="hover:text-light-blue-800 cursor-pointer"
                        />
                        <MdDelete
                          onClick={() => handleDeleteOutcome(index)}
                          className="hover:text-red-600 cursor-pointer"
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default GoalPopUp;
