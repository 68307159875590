import { Button } from "@material-tailwind/react";
import React from "react";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ConvertToCSVButton from "./ConvertToCSVButton";
import { useDispatch, useSelector } from "react-redux";
import { setFileData } from "../features/importContact/contactSlice";
import { setActiveStep } from "../features/importContact/activeStep";
import { setListName } from "../features/List/list";

function ImportSuccess() {
  const navigate = useNavigate()
  const fileData = useSelector((state) => state.fileData.fileData);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const handleClose =()=>{
    navigate('/create/list')
    dispatch(setActiveStep(0))
    dispatch(setFileData([]));
    dispatch(setListName(""))
  }
  return (
    <>
      <div className="p-4">
        <div className="flex flex-col justify-center items-center h-[550px]">
          <div className="w-20 flex justify-center items-center h-20 rounded-full bg-[#38A169]">
            <FaCheck className="text-white text-4xl" />
          </div>
          <h2 className="mt-3 font-semibold" style={{ color: fontColor }}>Contacts were imported successfully</h2>
          <ConvertToCSVButton data={fileData} filename="data.csv" />
        </div>
      </div>
      <div className="px-4 flex justify-end">
        <Button onClick={handleClose}>Close</Button>
      </div>
    </>
  );
}

export default ImportSuccess;
