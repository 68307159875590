import React, { useEffect, useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Google from "./Google";
import { useFormik } from "formik";
import { loginSchema } from "../schemas/FormSchema";
import { useSelector } from "react-redux";
import Intercom from "@intercom/messenger-js-sdk";
import { GHL } from "../utils/ghlHelper";
import Cookies from "js-cookie";

const Login = () => {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const [showPassword, setShowPassword] = useState(false);
  const [loginErr, setLoginErr] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const navigate = useNavigate();

  useEffect(() => {
    const ghl = new GHL(); // Create an instance of GHL

    window.ghl = ghl;

    const fetchUserData = async () => {
      try {
        const userData = await ghl.getUserData();
        console.log("hiii", userData); // Use the user data as needed

        if (userData) {
          setLoading(true);
          const response = await fetch(`${baseUrl}/api/ghl_user_login/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_data: userData,
            }),
          });
          const responseData = await response.json();
          if (!response.ok) {
            console.error(responseData);
          }
          const { access_token, refresh_token } = responseData.data;
          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);
          localStorage.setItem(
            "userData",
            JSON.stringify(responseData.data.user)
          );
          navigate("/dashboard");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false); // Set loading to false after fetch completes
      }
    };
    fetchUserData();
  }, []); // Fetch user data when the component mounts

  const formMik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${baseUrl}/api/auth/login/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email,
            password: values.password,
          }),
        });

        if (response.ok) {
          const responseData = await response.json();
          const refreshToken = localStorage.setItem(
            "refreshToken",
            responseData.data.refresh_token
          );
          const accessToken = localStorage.setItem(
            "accessToken",
            responseData.data.access_token
          );
          const saveUserData = localStorage.setItem(
            "userData",
            JSON.stringify(responseData.data.user)
          );
          // Check admin status from response data
          const isAdmin = responseData.data.is_superadmin; // Adjust based on the actual response structure

          // Set the cookie if the user is an admin
          if (isAdmin) {
            Cookies.set("superAdmin", "true");
          }
          if (!responseData.data.user.last_name) {
            navigate("/onboarding");
          } else if (responseData.data.user.companies.length == 0) {
            navigate("/onboarding");
          } else {
            navigate("/dashboard");
          }
        } else {
          setLoginErr("Invalid Email or Password");
        }
      } catch (error) {
        console.error("Error logging in:", error.message);
      }
    },
  });

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
      try {
        const response = await fetch(`${baseUrl}/api/token/refresh/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
          },
          body: JSON.stringify({ refresh: refreshToken }),
        });

        if (response.ok) {
          const responseData = await response.json();
          const newAccessToken = responseData.access;
          localStorage.setItem("accessToken", newAccessToken);
        }
      } catch (error) {
        console.error("Error refreshing access token:", error.message);
      }
    }
  };

  const checkAccessTokenExpiration = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const decodedToken = parseJwt(accessToken);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        refreshAccessToken();
      }
    }
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  setInterval(checkAccessTokenExpiration, 5 * 60 * 1000);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  if (baseUrl === "https://app.closerx.ai") {
    Intercom({
      app_id: "p6t03dhm",
      // user_id: userDetail?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      // name: userDetail?.first_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      // email: userDetail?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
  }

  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="w-screen p-8 h-screen flex justify-center items-center"
    >
      {loading ? ( // Conditionally render loading spinner
        <div>Loading...</div>
      ) : (
        <div className="md:basis-3/4 lg:basis-1/2 xl:basis-1/4 w-full sm:basis-4/5 custom-shadow bg-white rounded-md flex justify-center items-center gap-10 flex-col p-10">
          <div className="flex justify-center items-center flex-col gap-4">
            <h1
              style={{ color: fontColor }}
              className="text-4xl font-medium text-center capitalize"
            >
              {legalName}
            </h1>
            <p style={{ color: fontColor }} className="text-lg text-center">
              Login
            </p>
            {loginErr ? (
              <p className="text-red-600 m-[-7px] capitalize font-medium">
                {loginErr}
              </p>
            ) : null}
          </div>
          <div className="w-full flex justify-center items-center flex-col gap-4">
            <form
              className="w-full flex justify-center items-center gap-4 flex-col"
              onSubmit={formMik.handleSubmit}
            >
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email address*"
                value={formMik.values.email}
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {formMik.errors.email && formMik.touched.email ? (
                <p className="w-full text-red-600 m-[-7px] font-medium">
                  {formMik.errors.email}
                </p>
              ) : null}
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password*"
                  value={formMik.values.password}
                  onChange={formMik.handleChange}
                  onBlur={formMik.handleBlur}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center mr-3 focus:outline-none"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? (
                    <IoEyeOutline className="text-black h-6 w-6" />
                  ) : (
                    <IoEyeOffOutline className="text-black h-6 w-6" />
                  )}
                </div>
              </div>
              {formMik.errors.password && formMik.touched.password ? (
                <p className="w-full text-red-600 m-[-7px] font-medium">
                  {formMik.errors.password}
                </p>
              ) : null}
              <div className="flex justify-between w-full items-start mb-3">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-0 focus:ring-black dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  />
                  <label
                    style={{ color: fontColor }}
                    htmlFor="remember"
                    className="ms-2 text-sm text-gray-900 dark:text-gray-300"
                  >
                    Remember me
                  </label>
                </div>
                <Link
                  style={{ color: fontColor }}
                  to="/forget-password"
                  className="text-sm font-medium"
                >
                  Forget Password?
                </Link>
              </div>
              <button
                type="submit"
                className="text-white w-full bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                Continue
              </button>
              <div className="w-full flex text-sm gap-1">
                <p style={{ color: fontColor }}>Don't have an account?</p>
                {baseUrl === "https://app.closerx.ai" ? (
                  <a href="https://start.closerx.ai/" rel="noopener noreferrer">
                    <button
                      type="button"
                      style={{ color: fontColor }}
                      className="font-bold"
                    >
                      Create an Account
                    </button>
                  </a>
                ) : (
                  <Link
                    style={{ color: fontColor }}
                    to="/register"
                    className="text-sm font-medium"
                  >
                    Create an Account
                  </Link>
                )}
              </div>
            </form>
            {baseUrl === "https://app.closerx.ai" && (
              <>
                <div className="relative w-full">
                  <span className="absolute bg-gray-400 w-[45%] h-[1px] top-[59%] transform -translate-y-1/2 left-0"></span>
                  <p className="text-center">or</p>
                  <span className="absolute bg-gray-400 w-[45%] h-[1px] top-[59%] transform -translate-y-1/2 right-0"></span>
                </div>
                <Google />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
