import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setListName } from "../features/List/list";

function CreateListsPopUp() {
  const fileName = useSelector((state) => state.listName.listName);
  const { fontColor,backgroundColor } = useSelector((state) => state.theme);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    dispatch(setListName(""))
  },[])

  const handleSave = () => {
    navigate("/import-contact");
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      <div>
        <button
          onClick={toggleModal}
          className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
          type="button"
        >
          <span
            style={{ color: fontColor,backgroundColor:backgroundColor }}
            className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
          >
            Creat a List
          </span>
        </button>
      </div>
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-[70%] md:w-[35%] px-8 py-5 rounded-lg">
            <div className="flex w-full justify-between py-5">
              <h1
                style={{ color: fontColor }}
                className="text-[15px] md:text-[21px] justify-center flex items-center"
              >
                New List
              </h1>
              <button
                onClick={toggleModal}
                className="text-gray-700 hover:text-gray-900 focus:outline-none pb-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mb-4">
              <div className="flex justify-between items-center">
                <p style={{ color: fontColor }}>List Name</p>
                <input
                  type="text"
                  value={fileName}
                  onChange={(e) => dispatch(setListName(e.target.value))}
                  placeholder=""
                  className="w-[80%] border-gray-300 rounded-md focus:border-gray-500 focus:ring focus:ring-gray-500 focus:ring-opacity-50"
                />
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 absolute right-3 top-3 text-gray-500 pointer-events-none"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 19l-6-6M5 19l6-6"
                />
              </svg>
            </div>
            <div className="w-full flex justify-center items-center">
              <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800">
                <span
                  style={{ color: fontColor }}
                  className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
                  onClick={handleSave}
                >
                  Save
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateListsPopUp;
