import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import SignUp from "./components/SignUp";
import Login from "./components/Login";
import OnBoarding from "./components/OnBoarding";
import NotFound from "./components/NotFound";
import ForgetPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ResetPassword";
import CheckEmail from "./components/CheckEmail";
import CreatePage from "./pages/CreatePage";
import Activate from "./components/Activate";
import Knowledge from "./pages/Knowledge";
import SocialAuth from "./components/SocialAuth";
import ConversationallyChatBot from "./components/ConversationallyChatBot";
import ActivateMember from "./components/ActivateMember";
import Editor from "./components/Editor";
import ContactList from "./components/ContactList";
import ImportContact from "./components/ImportContact";
import TeamSettings from "./components/TeamSettings";
import Profile from "./components/Profile";
import Twilio from "./components/Twilio";
import Analytics from "./components/Analytics";
import CreateCampaign from "./components/CreateCampaign";
import Agency from "./components/Agency";
import PaymentSuccess from "./components/PaymentSuccess";
import AgencyCustom from "./components/AgencyCustom";
import Recordings from "./components/Recordings";
import PaymentCancel from "./components/PaymentCancel";
import SinglePaymentCancel from "./components/SinglePaymentCancel";
import ProtectedRoute from "./components/ProtectedRoute";
import Transaction from "./components/Transaction";
import BasicFlow from "./components/BasicFlow";
import ListsPage from "./pages/ListsPage";
import CampaignPage from "./pages/CampaignPage";
import BookingPage from "./pages/BookingPage";
import DashboardPage from "./pages/DashboardPage";
import SuperAdminPage from "./pages/SuperAdminPage";
import KnowlwdgeBaseEdit from "./components/KnowlwdgeBaseEdit";
import { useEffect, useState } from "react";
import { setBaseUrl } from "./features/BaseURL/baseUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  setBackgroundColor,
  setFontColor,
  setLegalName,
  setLogo,
} from "./features/Theme/theme";
import Test from "./components/Test";
import NotFoundLanding from "./components/NotFoundLanding";
import AgencyAnalytics from "./components/AgencyAnalytics";
import AgencyAnalyticsCampaign from "./components/AgencyAnalyticsCampaign";
import GhlSuccess from "./components/GhlSuccess";
import SuperAdminUserPage from "./pages/SuperAdminUserPage";
import SuperAdminWhiteUser from "./components/SuperAdminWhiteUser";
import QuickRecording from "./components/QuickRecording";
import GHLSsoLogin from "./pages/GHLSsoLogin";
import IntigrationRoot from "./layout/IntigrationRoot";
import Webhooks from "./components/Webhooks";
import SuperAdminNotification from "./pages/SuperAdminNotification";
import ShowNotification from "./pages/ShowNotification";
import CloneVoice from "./pages/CloneVoice";
import SuperCallRecords from "./pages/SuperCallRecords";
import AgencyCallRecords from "./pages/AgencyCallRecords";
import ClientApi from "./components/ClientApi";
import ZapierIntigration from "./components/ZapierIntigration";
import Access from "./pages/Access";

function App() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const refresh = useSelector((state) => state.refresh.refresh);
  const [isAccess, setIsAccess] = useState(true);
  const dispatch = useDispatch();
  const [faviconLogo, setfaviconLogo] = useState("");
  const [webTitle, setWebTitle] = useState("");
  // const navigate = useNavigate()

  useEffect(() => {
    document.title = webTitle;
  }, [webTitle]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    if (faviconLogo) {
      // console.log(faviconLogo);
      link.href = faviconLogo;
    } else {
      // console.log("error");
      // link.href = favicon;
    }
  }, [baseUrl, refresh, faviconLogo]);

  useEffect(() => {
    let baseURL = "";
    if (import.meta.env.MODE === "production") {
      const url = window.location.origin;
      // baseUrl = url
      baseURL = url;
      setIsAccess(url.startsWith("https://app."));
    } else {
      // In development mode, use the base URL from environment variables
      baseURL = import.meta.env.VITE_BACKEND_API_URL;
    }
    dispatch(setBaseUrl(baseURL));
  }, [dispatch]);

  useEffect(() => {
    if (baseUrl) {
      fetchTheme();
    }
  }, [baseUrl, refresh]);

  const fetchTheme = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/domain_data`, {
        method: "GET",
      });
      if (response.ok) {
        const responseData = await response.json();
        // if (responseData.data.domain == "localhost") {
        dispatch(setFontColor(responseData.data.font_color));
        dispatch(setBackgroundColor(responseData.data.background_color));
        dispatch(setLegalName(responseData.data.legal_name));
        dispatch(setLogo(responseData.data.logo));
        // console.log(responseData.data.fav_icon);
        setfaviconLogo(responseData.data.fav_icon);
        setWebTitle(responseData.data.legal_name);
        // }
        // else {
        //   // dispatch(setLegalName(responseData.data.domain))
        // }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Router>
      <Routes>
        {isAccess && (
          <>
            <Route path="/register" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password-request" element={<CheckEmail />} />
            <Route path="/reset-password/:uid" element={<ResetPassword />} />
            <Route path="/activate/:uid" element={<Activate />} />
            <Route path="/google" element={<SocialAuth />} />
            <Route path="/access/:id/:email" element={<Access />} />
            <Route path="/activate-member/:uuid" element={<ActivateMember />} />
            <Route
              path="/onboarding"
              element={<ProtectedRoute Component={OnBoarding} />}
            />
            <Route
              path="/org"
              element={<ProtectedRoute Component={TeamSettings} />}
            />
            <Route
              path="/dashboard"
              element={<ProtectedRoute Component={DashboardPage} />}
            />
            <Route
              path="/create/agent"
              element={<ProtectedRoute Component={CreatePage} />}
            />
            <Route
              path="/create/knowledge-bases"
              element={<ProtectedRoute Component={Knowledge} />}
            />
            <Route
              path="/create/list"
              element={<ProtectedRoute Component={ListsPage} />}
            />
            <Route
              path="/clone/voice"
              element={<ProtectedRoute Component={CloneVoice} />}
            />
            <Route
              path="/contacts/list"
              element={<ProtectedRoute Component={ContactList} />}
            />
            <Route
              path="/launch"
              element={<ProtectedRoute Component={CampaignPage} />}
            />
            <Route
              path="/conversationally/chatbot"
              element={<ProtectedRoute Component={ConversationallyChatBot} />}
            />
            <Route
              path="/editor"
              element={<ProtectedRoute Component={Editor} />}
            />
            <Route
              path="/flows"
              element={<ProtectedRoute Component={BasicFlow} />}
            />
            <Route
              path="/import-contact"
              element={<ProtectedRoute Component={ImportContact} />}
            />
            <Route
              path="/create/knowledge-bases"
              element={<ProtectedRoute Component={Knowledge} />}
            />
            <Route
              path="/knowledge-bases/:id"
              element={<ProtectedRoute Component={KnowlwdgeBaseEdit} />}
            />
            <Route
              path="/user"
              element={<ProtectedRoute Component={Profile} />}
            />

             <Route
              path="/integration"
              element={<ProtectedRoute Component={IntigrationRoot} />}
            />
            {/* <Route
              path="/integration/webhook"
              element={<ProtectedRoute Component={Webhooks} />}
            />
            <Route
              path="/integration/clientapi"
              element={<ProtectedRoute Component={ClientApi} />}
            />
            <Route
              path="/integration/zapiar"
              element={<ProtectedRoute Component={ZapierIntigration} />}
            /> * */}

            <Route
              path="/analytics"
              element={<ProtectedRoute Component={Analytics} />}
            />
            <Route
              path="/agency"
              element={<ProtectedRoute Component={Agency} />}
            />
            {/* <Route
              path="/agency/customize"
              element={<ProtectedRoute Component={AgencyCustom} />}
            /> */}
            <Route
              path="/agencys/analytics"
              element={<ProtectedRoute Component={AgencyAnalytics} />}
            />
            <Route
              path="/agency/analytics/campaigns/:id"
              element={<ProtectedRoute Component={AgencyAnalyticsCampaign} />}
            />
            <Route
              path="/agency/callrecords/:id"
              element={<ProtectedRoute Component={AgencyCallRecords} />}
            />
            <Route
              path="/recordings/:id"
              element={<ProtectedRoute Component={Recordings} />}
            />
            <Route
              path="/quick/recordings/:id"
              element={<ProtectedRoute Component={QuickRecording} />}
            />
            <Route
              path="/transactions"
              element={<ProtectedRoute Component={Transaction} />}
            />
            <Route
              path="/success"
              element={<ProtectedRoute Component={PaymentSuccess} />}
            />
            <Route
              path="/cancel"
              element={<ProtectedRoute Component={PaymentCancel} />}
            />
            <Route
              path="/failed"
              element={<ProtectedRoute Component={SinglePaymentCancel} />}
            />
            <Route
              path="/bookings"
              element={<ProtectedRoute Component={BookingPage} />}
            />
            <Route
              path="/superAdmin"
              element={
                <ProtectedRoute
                  Component={SuperAdminPage}
                  isSuperadminRoute={true}
                />
              }
            />
            <Route
              path="/superAdmins/user-view"
              element={
                <ProtectedRoute
                  Component={SuperAdminUserPage}
                  isSuperadminRoute={true}
                />
              }
            />
            <Route
              path="/superAdmins/notification"
              element={
                <ProtectedRoute
                  Component={SuperAdminNotification}
                  isSuperadminRoute={true}
                />
              }
            />
            <Route
              path="/superAdmins/whiteLabel/user/:id"
              element={
                <ProtectedRoute
                  Component={SuperAdminWhiteUser}
                  isSuperadminRoute={true}
                />
              }
            />
            <Route
              path="/superAdmins/callrecordings/:id"
              element={
                <ProtectedRoute
                  Component={SuperCallRecords}
                  isSuperadminRoute={true}
                />
              }
            />
            <Route
              path="/notifications"
              element={<ProtectedRoute Component={ShowNotification} />}
            />
            <Route path="/g-h-l/success" element={<GhlSuccess />} />
            <Route path="/g-h-l-sso" element={<GHLSsoLogin />} />
            <Route path="/test" element={<Test />} />
            <Route path="*" element={<NotFound />} />
          </>
        )}
        {isAccess == false && (
          <>
            <Route path="/" element={<SignUp />} />
            <Route path="*" element={<NotFoundLanding />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
