import { useFormik } from "formik";
import React, { useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { changePasswordSchema } from "../schemas/FormSchema";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";


function ChangePasswordPopUp({ visible, click }) {
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const { fontColor } = useSelector((state) => state.theme);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePasswordTwo = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleTogglePasswordThree = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formMik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${baseUrl}/api/reset_password/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            old_password: values.currentPassword,
            new_password: values.newPassword,
            confirm_new_password: values.confirmPassword,
          }),
        });

        if (response.ok) {
          const responseData = await response.json();
          values.currentPassword = "";
          values.newPassword = "";
          values.confirmPassword = "";
          setSuccess(responseData);
          toast.success("Password updated successfully!")
        } else if(response.status === 400) {
          const responseData = await response.json();
          setMessage(responseData);
          toast.error(responseData.message)
        }
        else {
          toast.error("Something went wrong!")
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <div
        className={`fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          visible ? "opacity-1" : "opacity-0 pointer-events-none"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <form className="w-[30rem] relative" onSubmit={formMik.handleSubmit}>
          <div
            data-dialog="sign-in-dialog"
            className="relative mx-auto flex w-full  flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
          >
            <div className="flex flex-col gap-4 p-6">
              <h4 style={{ color: fontColor }} className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                Change Password
              </h4>
              <p style={{ color: fontColor }} className="block mb-3 font-sans text-sm antialiased font-normal leading-relaxed text-gray-700">
                Update password for enhanced account security.
              </p>
              {message && (
                <p className=" w-full text-center text-red-500">
                  {message && message.message}
                </p>
              )}
              <p className=" w-full text-center text-green-500">
                {success && success.message}
              </p>
              <h6 style={{ color: fontColor }} className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">
                Current Password
              </h6>
              <div className="relative h-11 mb-3 w-full min-w-[200px]">
                <div className="relative w-full">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="currentPassword"
                    placeholder="Current Password*"
                    value={formMik.values.currentPassword}
                    onChange={formMik.handleChange}
                    onBlur={formMik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <div
                    className="absolute inset-y-0 right-0 flex items-center mr-3 focus:outline-none"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? (
                      <IoEyeOutline className="text-black h-6 w-6" />
                    ) : (
                      <IoEyeOffOutline className="text-black h-6 w-6" />
                    )}
                  </div>
                </div>
              </div>
              {formMik.errors.currentPassword &&
              formMik.touched.currentPassword ? (
                <p className="w-full text-red-600 mx-[5px] font-medium">
                  {formMik.errors.currentPassword}
                </p>
              ) : null}
              <h6 style={{ color: fontColor }} className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">
                New Password
              </h6>
              <div className="relative mb-3 h-11 w-full min-w-[200px]">
                <div className="relative w-full">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    id="newPassword"
                    name="newPassword"
                    placeholder="New Password*"
                    value={formMik.values.newPassword}
                    onChange={formMik.handleChange}
                    onBlur={formMik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <div
                    className="absolute inset-y-0 right-0 flex items-center mr-3 focus:outline-none"
                    onClick={handleTogglePasswordTwo}
                  >
                    {showNewPassword ? (
                      <IoEyeOutline className="text-black h-6 w-6" />
                    ) : (
                      <IoEyeOffOutline className="text-black h-6 w-6" />
                    )}
                  </div>
                </div>
              </div>
              {formMik.errors.newPassword && formMik.touched.newPassword ? (
                <p className="w-full text-red-600 mx-[5px] font-medium">
                  {formMik.errors.newPassword}
                </p>
              ) : null}
              <h6 style={{ color: fontColor }} className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">
                Confirm New Password
              </h6>
              <div className="relative h-11 mb-3 w-full min-w-[200px]">
                <div className="relative w-full">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm New Password*"
                    value={formMik.values.confirmPassword}
                    onChange={formMik.handleChange}
                    onBlur={formMik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <div
                    className="absolute inset-y-0 right-0 flex items-center mr-3 focus:outline-none"
                    onClick={handleTogglePasswordThree}
                  >
                    {showConfirmPassword ? (
                      <IoEyeOutline className="text-black h-6 w-6" />
                    ) : (
                      <IoEyeOffOutline className="text-black h-6 w-6" />
                    )}
                  </div>
                </div>
              </div>
              {formMik.errors.confirmPassword &&
              formMik.touched.confirmPassword ? (
                <p className="w-full text-red-600 mx-[5px] font-medium">
                  {formMik.errors.confirmPassword}
                </p>
              ) : null}
            </div>
            <div className="p-6 pt-0">
              <button
                type="submit"
                className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Change Password
              </button>
            </div>
          </div>
          <button
            type="button"
            onClick={click}
            className="absolute top-4 right-4 text-2xl"
          >
            <IoClose />
          </button>
        </form>
      </div>
    </>
  );
}

export default ChangePasswordPopUp;
