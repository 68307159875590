import React, { useState } from "react";
import { MdAttachFile, MdOutlineClose } from "react-icons/md";
import { FaRegFile } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { addKnowledgeSchema } from "../schemas/FormSchema";
import { json } from "react-router-dom";
import { setRefresh } from "../features/Refresh/refresh";
import toast, { Toaster } from "react-hot-toast";

function UploadKnowledgeBasePopUp({id}) {
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const refresh = useSelector(state => state.refresh.refresh);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [showPopUp, setShowPopUp] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: addKnowledgeSchema,
    onSubmit: async (values,{resetForm}) => {
      try {
        const response = await fetch(
          `${baseUrl}/api/knowledge_base/${id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              "Company-Name": companyName,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE4MjI5Nzg0LCJpYXQiOjE3MTgwMTM3ODQsImp0aSI6IjQyYWY5MjY2NGM0ZjQ2NzJiYTBjODQ4MjkzMDIxM2QyIiwidXNlcl9pZCI6NH0.CFG6kc2KskSny2Ve6uw4Ln95Ae_fnIWsj4VPXjKolZQ`,
            },
            body: JSON.stringify({
              question_text: values.question,
              answer_text: values.answer,
            }),
          }
        );
        if (response.ok) {
          dispatch(setRefresh(!refresh))
          resetForm();
          handleToggle()
          toast.success("Knowledge base updated successfully")
        }
        else if (response.status === 400) {
          const responseData = await response.json()
          toast.error(responseData.message)
        }
        else {
          toast.error("Something went wrong")
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleToggle = () => {
    setShowPopUp(!showPopUp);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = Array.from(e.dataTransfer.files);
    setFiles(files);
    // handleFiles(files);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setFiles(files);
    // handleFiles(files);
  };
  return (
    <>
    <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <button
        onClick={handleToggle}
        className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
        type="button"
      >
        <span
          style={{ color: fontColor, backgroundColor: backgroundColor }}
          className="relative px-9 py-3.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
        >
          Add
        </span>
      </button>
      <div
        className={`${
          showPopUp ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          showPopUp ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300 px-3`}
      >
        <div
          data-dialog="sign-in-dialog"
          className="relative mx-auto flex w-full max-w-[40rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
        >
          <div className="flex flex-col gap-4 p-6">
            <div className="flex justify-between items-center">
              <h4
                style={{ color: fontColor }}
                className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
              >
                Add to Knowledge Base
              </h4>
              <div onClick={handleToggle}>
                <MdOutlineClose className="text-2xl cursor-pointer" />
              </div>
            </div>
            <p style={{ color: fontColor }}>
              Do you want AI to magically turn documents into FAQ flashcards or
              add them manually?
            </p>
            <h6
              style={{ color: fontColor }}
              className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
            >
              Add FAQ
            </h6>
            <form onSubmit={formik.handleSubmit}>
              <div class="mb-5">
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Question
                </label>
                <input
                  type="text"
                  id="question"
                  name="question"
                  value={formik.values.question}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Question"
                />
                {formik.errors.question && formik.touched.question ? (
                  <p className="w-full text-red-600 m-[7px] font-medium">
                    {formik.errors.question}
                  </p>
                ) : null}
              </div>

              <div class="mb-5">
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Answer
                </label>
                <input
                  type="text"
                  id="answer"
                  name="answer"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Answer"
                  value={formik.values.answer}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.answer && formik.touched.answer ? (
                  <p className="w-full text-red-600 m-[7px] font-medium">
                    {formik.errors.answer}
                  </p>
                ) : null}
              </div>
              <div className=" pt-0">
                <button
                  className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="Submit"
                >
                  Save
                </button>
              </div>
            </form>
            {/* <div className="relative w-full min-w-[200px]">
              <input
                type="radio"
                id="hosting-small"
                name="hosting"
                value="hosting-small"
                className="hidden peer"
                required
              />
              <label
                htmlFor="hosting-small"
                className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
              >
                <div className="block">
                  <div style={{ color: fontColor }} className="w-full text-lg font-semibold">
                    Manually Add FAQ Flashcard
                  </div>
                </div>
                <svg
                style={{ color: fontColor }}
                  className="w-5 h-5 ms-3 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </label>
            </div> */}
            {/* <h6 style={{ color: fontColor }} className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">
              Upload Document
            </h6>
            <div className="relative w-full min-w-[200px]">
              <div
                className={`border-2 border-dashed flex justify-center items-center p-8  rounded-lg cursor-pointer relative ${
                  isDragOver ? "bg-gray-200" : ""
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={handleFileSelect}
                  accept=".csv, .xls, .xlsx, .xlsb"
                />
                <div className="flex items-center gap-2">
                  <MdAttachFile style={{ color: fontColor }} className="text-4xl" />
                  <div>
                    <p style={{ color: fontColor }} className="text-md text-gray-800 font-semibold">
                      Click to choose a file or drag and drop here
                    </p>
                    <p style={{ color: fontColor }} className="text-sm text-gray-600 font-medium">
                      Please provide a file in any of the following formats
                    </p>
                  </div>
                </div>
                <label htmlFor="fileInput" className="absolute inset-0"></label>
              </div>
            </div>
            {files ? (
              <div style={{ color: fontColor }} className="relative w-full min-w-[200px] flex items-center gap-2">
                <FaRegFile /> {files && files[0].name}
              </div>
            ) : (
              ""
            )} */}
          </div>
          {/* <div className="p-6 pt-0">
            <button
              className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              type="button"
            >
              Select
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default UploadKnowledgeBasePopUp;
