import React, { useEffect, useState, useTransition } from "react";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import PageHeader from "../components/PageHeader";
import { useParams } from "react-router-dom";

function AgencyCallRecords() {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const [isPending, startTransition] = useTransition();
  const [userData, setUserData] = useState(null);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const { id } = useParams();

  useEffect(() => {
    startTransition(async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/agency/all_calls/?company_name=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Company-Name": companyName,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (response.ok) {
          const responseData = await response.json();
          const arr = [
            ...responseData.campaign_data,
            ...responseData.quick_campaign_data,
          ];
          setUserData(arr);
        }
      } catch (error) {
        console.error(error);
      }
    });
  }, [baseUrl, startTransition]);

  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full"
      >
        <Navbar />
        <div className="flex-1 p-4">
          <div className="relative overflow-x-auto p-4">
            {isPending || !userData ? ( // Show skeleton loader if pending
              <div className="p-4">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr style={{ color: fontColor }}>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array(5)
                      .fill(null)
                      .map((_, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <table className="w-full text-sm text-left rtl:text-right h-full text-gray-500 dark:text-gray-400 overflow-y-hidden">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr style={{ color: fontColor }}>
                    <th scope="col" className="px-6 py-3">
                      Campaign Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      sid
                    </th>
                    <th scope="col" className="px-6 py-3">
                      call duration
                    </th>
                    <th scope="col" className="px-6 py-3">
                      credits consumed
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userData &&
                    userData.map((user, index) => (
                      <tr
                        style={{ color: fontColor }}
                        key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      >
                        <td className="px-6 py-4">
                          <span className="cursor-pointer">
                            {user?.campaign}
                          </span>
                        </td>
                        <td className="px-6 capitalize py-4">{user?.sid}</td>
                        <td className="px-6 capitalize py-4">
                          {user?.call_duration}
                        </td>
                        <td className="px-6 capitalize py-4">
                          {user?.credits_consumed}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AgencyCallRecords;
