import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agentData: [],
};

export const agentDataSlice = createSlice({
  name: "agentData",
  initialState,
  reducers: {
    setAgentData(state, action) {
      state.agentData = action.payload;
    },
  },
});

export const { setAgentData } = agentDataSlice.actions;
export default agentDataSlice.reducer;
