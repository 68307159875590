import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agentDetail: null,
};

export const agentDetailSlice = createSlice({
  name: "agentDetail",
  initialState,
  reducers: {
    setAgentDetail(state, action) {
      state.agentDetail = action.payload;
    },
  },
});

export const { setAgentDetail } = agentDetailSlice.actions;
export default agentDetailSlice.reducer;
