import React, { useEffect, useState } from "react";
import dashboardIMG from "../assets/dashboardIMG.png";
import { FcClock } from "react-icons/fc";
import Chart from "react-apexcharts";
import { SiCampaignmonitor } from "react-icons/si";
import Agreement from "./Agreement";
import { useSelector } from "react-redux";
import MobVerify from "./MobVerify";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";

const Dashboard = () => {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const [greeting, setGreeting] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMobVerify, setShowMobVerify] = useState(false);
  const [campaigns, setCampaigns] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const companyID = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_id;
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);

  const handleNavigateToGhl = () => {
    window.location.href = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://app.closerx.ai/g-h-l/success&state=${companyID}&client_id=669fa047e5605344754496bc-lyyex339&scope=contacts.write contacts.readonly calendars.readonly calendars.write calendars/events.readonly calendars/events.write locations/customValues.readonly locations/customValues.write locations/tags.write locations.readonly`;
  };

  useEffect(() => {
    const currentTime = new Date().getHours();
    let timeOfDay;
    if (currentTime > 5 && currentTime <= 12) {
      timeOfDay = "morning";
    } else if (currentTime > 12 && currentTime < 18) {
      timeOfDay = "afternoon";
    } else {
      timeOfDay = "evening";
    }
    const storedUserData = localStorage.getItem("userData");
    const storedUsername = storedUserData
      ? JSON.parse(storedUserData)
      : { first_name: "" };
    setGreeting(`Good ${timeOfDay}, ${storedUsername.first_name || ""}!`);
  }, []);

  useEffect(() => {
    const fetchCampaign = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseUrl}/api/campaign/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          const activeCampaigns = responseData.data["campaign lists"].filter(
            (campaign) => campaign.status === "active"
          );
          setCampaigns(activeCampaigns);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchCampaign();

    const fetchDashboard = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseUrl}/api/dashboard_data/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setDashboardData(responseData);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchDashboard();

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseUrl}/api/agency/users/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData.data.agents[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const displayTodayDateAndDay = () => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const today = new Date();
    const dayOfWeek = days[today.getDay()];
    const month = months[today.getMonth()];
    const dayOfMonth = today.getDate();
    const year = today.getFullYear();

    return `${dayOfWeek}, ${month} ${dayOfMonth}, ${year}`;
  };

  const options = {
    chart: {
      type: "donut",
    },
    labels: ["Other Calls", "Conected Calls"],
    legend: {
      show: false,
    },
    markers: {
      size: 0,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = dashboardData
    ? [
        dashboardData.other_call_percentage,
        dashboardData.completed_calls_percentage,
      ]
    : [50, 50];

  const lineOptions = {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        tools: {
          download: false, // Hide the download button completely
        },
        export: {
          csv: {
            filename: "chart-data",
            columnDelimiter: ",",
            headerCategory: "Category",
            headerValue: "Value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            show: false, // Hide SVG download option
          },
          png: {
            show: false, // Hide PNG download option
          },
        },
      },
    },
    // dataLabels: {
    //   enabled: false,
    // },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Monthly Calls",
      align: "left",
      style: {
        color: fontColor, // Change title color here
      },
    },
    dataLabels: {
      style: {
        colors: ["#F44336", "#E91E63", "#9C27B0"],
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: fontColor,
          fontSize: "13px",
          cssClass: "apexcharts-yaxis-label",
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          colors: fontColor,
          fontSize: "12px",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };

  const lineSeries = [
    {
      name:
        (dashboardData && dashboardData.monthly_data[0].name) ||
        "Monthly Calls",
      data: (dashboardData && dashboardData.monthly_data[0].data) || [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
    },
  ];

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      if (!parsedUserData.contact_number) {
        setShowMobVerify(true);
      }
    }
  }, [refresh, setRefresh]);

  const handleMobVerifySuccess = () => {
    setShowMobVerify(false);
  };

  const handleNavigate = () => {
    navigate("/transactions");
  };

  return (
    <>
      {showMobVerify && <MobVerify onSuccess={handleMobVerifySuccess} />}
      <div
        style={{ backgroundColor: backgroundColor }}
        className=" pt-4 w-full h-screen max-h-screen flex flex-col gap-4"
      >
        <div
          className="bg-white p-4 flex flex-col gap-3 md:flex-row md:items-center justify-between"
          style={{
            backgroundColor: backgroundColor,
            borderColor: "white",
            borderWidth: "1px",
          }}
        >
          <div className="flex gap-3 flex-col lg:flex-row">
            <img src={dashboardIMG} alt="Dashboard" className="w-32" />
            <div className="flex flex-col gap-2">
              <p style={{ color: fontColor }}>{displayTodayDateAndDay()}</p>
              <h1
                className="text-2xl font-extrabold capitalize"
                style={{ color: fontColor }}
              >
                {greeting}
              </h1>
              <div
                className="flex flex-col lg:flex-row items-start"
                style={{ color: fontColor }}
              >
                <div className="flex items-center">
                  Breaking the boundaries of the 9-5{" "}
                  <span className="text-xl mx-1">
                    <FcClock />
                  </span>
                </div>
                We are with you every step of the way.
              </div>
            </div>
          </div>
          {baseUrl == "https://app.closerx.ai" && (
            <button
              style={{ color: fontColor }}
              onClick={handleNavigateToGhl}
              className="relative w-fit inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium h-fit text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
              type="button"
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                Connect CRM
              </span>
            </button>
          )}
        </div>
        <div
          className="bg-white  p-8 flex-1 w-full"
          style={{ backgroundColor: backgroundColor }}
        >
          <div className="p-4 border-2 h-full rounded-xl flex flex-col">
            <div className="flex flex-wrap lg:flex-nowrap">
              <div className="border-2 rounded-lg lg:basis-1/4 basis-full lg:mx-4 my-4 py-4 flex px-14 lg:px-4 gap-2 flex-col justify-center items-center">
                <p
                  className="text-xl font-semibold"
                  style={{ color: fontColor }}
                >
                  Total Balance
                </p>
                <h6 className="text-3xl font-bold" style={{ color: fontColor }}>
                  {data ? data.credits.toFixed(2) : "0"}
                </h6>
                <button
                  type="button"
                  style={{ backgroundColor: backgroundColor, color: fontColor }}
                  onClick={handleNavigate}
                  className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  Buy Credits
                </button>
              </div>
              <div className="border-2 z-3 rounded-lg lg:basis-3/4 basis-full py-4 lg:mx-4 my-4 flex flex-col lg:flex-row gap-12 lg:gap-0 lg:pr-0 justify-center items-center">
                <Chart
                  options={options}
                  series={series}
                  type="donut"
                  width="200"
                />
                <div className="flex flex-col lg:flex-row gap-12 lg:gap-4 xl:gap-14">
                  <div className="flex flex-col gap-4 lg:pr-0 text-center">
                    <h6
                      className="text-xl lg:text-md xl:text-xl font-semibold"
                      style={{ color: fontColor }}
                    >
                      Total Call Cost
                    </h6>
                    <h2
                      className="text-4xl lg:text-lg xl:text-4xl font-bold"
                      style={{ color: fontColor }}
                    >
                      {dashboardData && dashboardData.total_cost.toFixed(2)}
                    </h2>
                  </div>
                  <div className="flex flex-col gap-4 lg:pr-0 text-center">
                    <h6
                      className="text-xl lg:text-md xl:text-xl font-semibold"
                      style={{ color: fontColor }}
                    >
                      Completed Calls
                    </h6>
                    <h2
                      className="text-4xl lg:text-lg  xl:text-4xl font-bold"
                      style={{ color: fontColor }}
                    >
                      {dashboardData &&
                        dashboardData.completed_calls_percentage}
                      %
                    </h2>
                  </div>
                  <div className="flex flex-col gap-4 text-center">
                    <h6
                      className="text-xl xl:text-xl lg:text-md font-semibold"
                      style={{ color: fontColor }}
                    >
                      Other Calls
                    </h6>
                    <h2
                      className="text-4xl xl:text-4xl lg:text-lg font-bold"
                      style={{ color: fontColor }}
                    >
                      {dashboardData && dashboardData.other_call_percentage}%
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 lg:flex-nowrap flex-wrap">
              <div className="border-2 rounded-lg lg:basis-1/4 lg:mx-4 p-4 flex basis-full gap-2 flex-col justify-start items-start">
                <p className="text-xl font-bold" style={{ color: fontColor }}>
                  Active Campaign
                </p>
                <div className="flex-1 w-full p-2 flex flex-col gap-3 overflow-y-auto">
                  {campaigns && campaigns.length > 0 ? (
                    campaigns.map((campaign, index) => (
                      <div key={index} className="flex gap-2">
                        <div
                          className="w-12 h-12 rounded-xl bg-[#58FFC5] flex justify-center items-center text-xl"
                          style={{ color: fontColor }}
                        >
                          <SiCampaignmonitor />
                        </div>
                        <div className="flex-1">
                          <h6
                            className="font-bold"
                            style={{ color: fontColor }}
                          >
                            {campaign.name}
                          </h6>
                          <p
                            className="text-sm font-semibold text-[#898b8c]"
                            style={{ color: fontColor }}
                          >
                            {campaign.agent}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="h-full w-full flex justify-center items-center">
                      <Agreement />
                    </div>
                  )}
                </div>
              </div>
              <div className="border-2 w-full h-auto my-4 lg:my-0 rounded-lg lg:basis-3/4 basis-full py-4 lg:mx-4 flex justify-center items-center">
                <div className="w-full h-full z-3">
                  <Chart
                    options={lineOptions}
                    series={lineSeries}
                    type="line"
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="w-full z-[999] h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
};

export default Dashboard;
