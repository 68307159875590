import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";

function Booking() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);

  const localDate = (utcTimeString) => {
    const utcDate = new Date(utcTimeString);
    const localDate = utcDate.toLocaleDateString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return localDate;
  };

  const localTime = (utcTimeString) => {
    const utcDate = new Date(utcTimeString);
    const localDate = utcDate.toLocaleTimeString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour: "numeric",
      minute: "numeric",
    });
    return localDate;
  };

  function convertToTimeZone(utcTime, timeZone) {
    try {
      // Validate input
      if (typeof utcTime !== "string" || typeof timeZone !== "string") {
        throw new Error("Invalid input: utcTime and timeZone must be strings.");
      }

      // Parse the UTC time string into a Date object
      const date = new Date(utcTime);

      // Check if the date parsing was successful
      if (isNaN(date.getTime())) {
        throw new Error("Invalid UTC time format.");
      }

      // Convert the UTC time to the specified timezone
      const options = {
        timeZone: timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };

      // Format the date object into a readable string in the specified timezone
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const formattedDate = formatter.format(date);

      return formattedDate.replace(",", "");
    } catch (error) {
      console.error("Error:", error.message);
      return null;
    }
  }

  // Example usage:
  const utcTime = "2024-08-29T10:00:00.000Z";
  const timeZone = "Asia/Calcutta";
  const convertedTime = convertToTimeZone(utcTime, timeZone);
  console.log(convertedTime); // Output: 08/29/2024, 15:30:00

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${baseUrl}/api/booking_details/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const jsonData = await response.json();
      setData(jsonData.data.booking_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="relative overflow-x-auto"
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Contact name
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Contact Number
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Time Zone
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Meeting Slot
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Meeting Date
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Campaign Details
              </th>
              <th
                style={{ color: fontColor }}
                scope="col"
                className="px-6 py-3"
              >
                Meeting Link
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((booking, index) => (
              <tr
                key={index}
                className="bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700"
              >
                <td style={{ color: fontColor }} className="px-6 py-4">
                  {booking.contact_name}
                </td>
                <td style={{ color: fontColor }} className="px-6 py-4">
                  {booking.contact_number}
                </td>
                <td style={{ color: fontColor }} className="px-6 py-4">
                  {booking.timezone}
                </td>
                <td style={{ color: fontColor }} className="px-6 py-4">
                  <span>
                    {convertToTimeZone(
                      booking.start_date_time,
                      booking.timezone
                    )}
                  </span>{" "}
                  {/* - <span>{localTime(booking.end_date_time)}</span> */}
                </td>
                <td style={{ color: fontColor }} className="px-6 py-4">
                  {localDate(booking.scheduled_date)}
                </td>
                <td style={{ color: fontColor }} className="px-6 py-4">
                  {booking.campaign_details}
                </td>{" "}
                {/*Later this will be update by Agent name and Direction , these data will come via backend*/}
                <td style={{ color: fontColor }} className="px-6 py-4">
                  <a
                    href={booking.meeting_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {booking.meeting_link}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default Booking;
