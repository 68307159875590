import React, { useEffect, useState } from "react";
import PageHeader from "./PageHeader";
import { useSelector } from "react-redux";
import Navbar from "./Navbar";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import SuperAddCredit from "./SuperAddCredit";
import SuperRemoveCredits from "./SuperRemoveCredits";

function AgencyAnalytics() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const navigate = useNavigate();
  const [selectedUserCompany, setSelectedUserCompany] = useState(null);
  const [isCreditAddOpen, setIsCreditAddOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isCreditRemoveOpen, setIsCreditRemoveOpen] = useState(false);
  const company_name = JSON.parse(localStorage.getItem("userData"))
    ?.companies[0]?.company_name;

  useEffect(() => {
    const fetchAnayticsData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${baseUrl}/api/whitelable_analytics`,
          {
            headers: {
              "Content-Type": "application/json",
              "Company-Name": company_name,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (response.status === 200) {
          setAnalyticsData(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchAnayticsData();
  }, [refresh]);

  const [pageHeaderData, setPageHeaderData] = useState([
    // {
    //   tittle: "Onboarding",
    //   link: "/agency/onboarding",
    // },
    {
      tittle: "Customize",
      link: "/agency",
    },
    {
      tittle: "Client Portal",
      link: "/agencys/analytics",
    },
  ]);

  const handleGetDetails = (id) => {
    navigate(`/agency/analytics/campaigns/${id}`);
  };

  const handlePriceChange = async (company, price) => {
    try {
      const response = await fetch(`${baseUrl}/api/change/credit_price/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ credit_price: price, company_name: company }),
      });
      if (response.ok) {
        toast.success(`Price Updated for ${company} client`);
      } else if (response.status === 400) {
        const responseData = await response.json();
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyPress = (e, comapny) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const newValue = e.target.value;
      handlePriceChange(comapny, newValue);
    }
  };

  const handleNavigate = (id) => {
    window.location.href = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://app.closerx.ai/g-h-l/success&state=${id}&client_id=669fa047e5605344754496bc-lyyex339&scope=contacts.write contacts.readonly calendars.readonly calendars.write calendars/events.readonly calendars/events.write locations/customValues.readonly locations/customValues.write locations/tags.write locations.readonly`;
  };

  const handleCreditToggle = (companyName) => {
    setSelectedUserCompany(companyName);
    setIsCreditAddOpen(true);
  };

  const handleRemoveCreditToggle = (companyName) => {
    setSelectedUserCompany(companyName);
    setIsCreditRemoveOpen(true);
  };

  const handleAddCreditToggle = async (amount) => {
    try {
      const response = await fetch(`${baseUrl}/api/add_credits/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Company-Name": company_name,
        },
        body: JSON.stringify({
          company_name: selectedUserCompany,
          credits: amount, // Pass the credit amount
        }),
      });
      if (!response.ok) {
        toast.error("failed to add credits");
      }
      const data = await response.json();
      setRefresh(!refresh); // Refresh the data
      toast.success("credits added successfully");
      setIsCreditAddOpen(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Close the dialog
      // setSelectedUserId(null); // Reset the selected user
    }
  };

  const handleRemoveCredit = async (amount) => {
    try {
      const response = await fetch(`${baseUrl}/api/remove_credits/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Company-Name": company_name,
        },
        body: JSON.stringify({
          company_name: selectedUserCompany,
          credits: amount, // Pass the credit amount
        }),
      });
      if (!response.ok) {
        toast.error("failed to Remove credits");
      }
      const data = await response.json();
      setRefresh(!refresh); // Refresh the data
      toast.success("credits Remove successfully");
      setIsCreditRemoveOpen(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Close the dialog
      // setSelectedUserId(null); // Reset the selected user
    }
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full min-h-screen"
      >
        <Navbar />
        <PageHeader buttons={pageHeaderData} />
        <div className="flex-1 flex overflow-x-auto justify-center w-full items-start p-4">
          <table className="w-full text-sm text-left rtl:text-right overflow-x-scroll text-gray-500 dark:text-gray-400">
            <thead className="text-xs w-full text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="w-full">
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  Client Name
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  Total Credits Consumed
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  Credit Balance
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  Total Minutes Consumed
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  Active Campaigns
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  Price
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  Location ID
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  GHL Name
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  GHL unique links
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  Call Records
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  Add Credits
                </th>
                <th
                  style={{ color: fontColor }}
                  scope="col"
                  className="px-4 py-3"
                >
                  Remove Credits
                </th>
              </tr>
            </thead>
            <tbody>
              {analyticsData &&
                analyticsData.length > 0 &&
                analyticsData?.map((data, index) => (
                  <tr key={index} className="bg-white">
                    <td
                      style={{ color: fontColor }}
                      scope="col"
                      onClick={() => handleGetDetails(data?.agency_id)}
                      className="px-6 py-3 capitalize cursor-pointer"
                    >
                      {data?.client_name}
                    </td>
                    <td
                      style={{ color: fontColor }}
                      scope="col"
                      className="px-6 py-3 capitalize"
                    >
                      {data?.total_credits_consumed}
                    </td>
                    <td
                      style={{ color: fontColor }}
                      scope="col"
                      className="px-6 py-3"
                    >
                      {data?.credit_balance}
                    </td>
                    <td
                      style={{ color: fontColor }}
                      scope="col"
                      className="px-7 py-3"
                    >
                      {data?.total_minutes_consumed}
                    </td>
                    <td
                      style={{ color: fontColor }}
                      scope="col"
                      className="px-6 py-3"
                    >
                      {data?.total_active_campaign}
                    </td>
                    <td
                      className="px-6 py-3"
                      onBlur={() =>
                        handlePriceChange(data?.client_name, data?.credit_price)
                      }
                    >
                      <input
                        type="number"
                        value={data?.credit_price}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          const updatedData = [...analyticsData];
                          updatedData[index].credit_price = newValue;
                          setAnalyticsData(updatedData);
                        }}
                        onKeyPress={(e) => handleKeyPress(e, data?.client_name)}
                        min={0}
                        style={{
                          width: "100px",
                          border: "none",
                          // textAlign: "center",
                        }}
                      />
                    </td>
                    <td
                      style={{ color: fontColor }}
                      scope="col"
                      className="px-6 py-3"
                    >
                      {data?.ghl_location_id}
                    </td>
                    <td
                      style={{ color: fontColor }}
                      scope="col"
                      className="px-6 py-3"
                    >
                      {data?.ghl_name}
                    </td>
                    <td>
                      <button
                        style={{ color: fontColor }}
                        onClick={() => handleNavigate(data.agency_id)}
                        className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
                        type="button"
                      >
                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                          Connect GHL
                        </span>
                      </button>
                    </td>
                    <td>
                      <button
                        style={{ color: fontColor }}
                        onClick={() => navigate(`/agency/callrecords/${data?.client_name}`)}
                        className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
                        type="button"
                      >
                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                          Call records
                        </span>
                      </button>
                    </td>
                    <td className="px-6 capitalize py-4">
                      <button
                        onClick={() => handleCreditToggle(data.client_name)}
                        type="button"
                        className={`border p-2 capitalize rounded-lg bg-green-400 text-white`}
                      >
                        Add Credits
                      </button>
                    </td>
                    <td className="px-6 capitalize py-4">
                      <button
                        onClick={() =>
                          handleRemoveCreditToggle(data.client_name)
                        }
                        type="button"
                        className={`border p-2 capitalize rounded-lg bg-blue-gray-800 text-white`}
                      >
                        Remove Credits
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}

      <SuperAddCredit
        isOpen={isCreditAddOpen}
        onClose={() => setIsCreditAddOpen(false)}
        onConfirm={handleAddCreditToggle}
      />
      <SuperRemoveCredits
        isOpen={isCreditRemoveOpen}
        onClose={() => setIsCreditRemoveOpen(false)}
        onConfirm={handleRemoveCredit}
      />
    </>
  );
}

export default AgencyAnalytics;
