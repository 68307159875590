import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";

const TABLE_HEAD = [
  "S.No",
  "Date & Time",
  "Transaction Amount",
  "Credits Purchased",
  "Transaction Type",
  "Balance",
  "Credit Deducted",
  "Card Details",
];

function Transaction() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [paymentData, setPaymentData] = useState(null);
  const [creditPrice, setCreditPrice] = useState(null);

  const localTime = (utcTimeString) => {
    const utcDate = new Date(utcTimeString);
    const localDate = utcDate.toLocaleDateString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return localDate;
  };

  const handleHistory = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${baseUrl}/api/payment_history/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setData(data.data.history);
      } else {
        const errorData = await response.json();
        console.error("Erro fetching History:", errorData);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCreditPrice = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${baseUrl}/api/credit_price/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCreditPrice(data.data);
      } else {
        const errorData = await response.json();
        console.error("Erro fetching History:", errorData);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleHistory();
    getCreditPrice();
  }, []);

  const handlePayment = async () => {
    if (!amount.trim() || isNaN(parseFloat(amount))) {
      setAmountError("Please enter a valid number.");
      return;
    }

    if (parseFloat(amount) < 99) {
      setAmountError("Enter a minimum amount of $99.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}/api/user_credit_payment/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          product_data: {
            name: "Credit",
            currency: "usd",
            unit_amount: parseInt(amount),
          },
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setPaymentData(data);
        localStorage.setItem("sessionId", data.data.id);
        window.open(data.data.url, "_blank");
      } else {
        // const errorData = await response.json();
        setAmountError("Contact Administrator");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (paymentData && paymentData.data && paymentData.data.url) {
  //     const id = paymentData.data.id;
  //     const redirectUrl = paymentData.data.url;
  //     const sendStatusUpdate = async () => {
  //       try {
  //         const response = await fetch(`${baseUrl}/api/add_user_credit/`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             "Company-Name": companyName,
  //             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //           },
  //           body: JSON.stringify({
  //             status: true,
  //             session_id: id,
  //           }),
  //         });

  //         if (response.ok) {
  //           // console.log("Status update successful");
  //           window.open(redirectUrl,"_blank")
  //         } else {
  //           const errorData = await response.json();
  //           console.error("Status update failed:", errorData);
  //         }
  //       } catch (error) {
  //         console.error("Error sending status update:", error);
  //       }
  //     };

  //     sendStatusUpdate();
  //   }
  // }, [paymentData]);
  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full min-h-screen"
      >
        <Navbar />
        <div className="flex-1 p-6">
          <div className="p-4 custom-shadow rounded-xl">
            <Card className="h-full w-full shadow-none">
              <CardHeader
                floated={false}
                shadow={false}
                className="rounded-none"
              >
                <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                  <div style={{ color: fontColor }}>
                    <Typography
                      style={{ color: fontColor }}
                      variant="h5"
                      color="blue-gray"
                    >
                      Recent Transactions
                    </Typography>
                    <Typography
                      style={{ color: fontColor }}
                      color="gray"
                      className="mt-1 font-normal"
                    >
                      These are details about the last transactions
                    </Typography>
                  </div>
                  <div className="flex w-full shrink-0 gap-2 md:w-max">
                    <div className="flex flex-col ">
                      <div className="w-full flex justify-center items-center">
                        <input
                          type="number"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          className="flex items-center w-[90%] gap-3 placeholder:text-[11px] md:placeholder:text-[15px] rounded-lg"
                          placeholder="Enter top up amount"
                        />
                      </div>
                      <p
                        style={{ color: fontColor }}
                        className="text-gray-500 text-sm text-center"
                      >
                        Enter a minimum amount of $99
                      </p>
                      <p
                        style={{ color: fontColor }}
                        className="text-gray-500 text-sm text-center"
                      >
                        One credit is equal to USD {creditPrice}
                      </p>
                    </div>
                    <Button
                      className="flex items-center gap-3 w-fit h-11"
                      size="sm"
                      onClick={handlePayment}
                    >
                      Add Credits
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <div className="w-full text-right text-red-600 my-[-3px]">
                {amountError}
              </div>
              <CardBody className="overflow-auto px-0">
                <table className="w-full overflow-x-scroll min-w-max table-auto text-center">
                  <thead>
                    <tr style={{ color: fontColor }}>
                      {TABLE_HEAD.map((head) => (
                        <th
                          key={head}
                          className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                        >
                          <Typography
                            style={{ color: fontColor }}
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => {
                      const isLast = index === data.length - 1;
                      const classes = isLast
                        ? "p-4"
                        : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr style={{ color: fontColor }} key={index}>
                          <td className={classes}>
                            <Typography
                              style={{ color: fontColor }}
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {index + 1}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              style={{ color: fontColor }}
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {localTime(row.created_at)}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              style={{ color: fontColor }}
                              variant="small"
                              color="blue-gray"
                              className="font-normal uppercase"
                            >
                              {row.currency}{" "}
                              {Number(row.payment_amount).toFixed(0)}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              style={{ color: fontColor }}
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {row.credits_recieved}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              style={{ color: fontColor }}
                              variant="small"
                              color="blue-gray"
                              className="font-normal capitalize"
                            >
                              {row.payment_type}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              style={{ color: fontColor }}
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {/* <span className="uppercase">{row.currency}</span>{" "} */}
                              {Number(row.current_credit).toFixed(0)}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              style={{ color: fontColor }}
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {/* <span className="uppercase">{row.currency}</span>{" "} */}
                              {Number(row.credits_deducted).toFixed(0)}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3 justify-center">
                              <div className="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
                                <Avatar
                                  src={
                                    row.card_brand === "visa"
                                      ? "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/logos/visa.png"
                                      : "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/logos/mastercard.png"
                                  }
                                  size="sm"
                                  variant="square"
                                  className="h-full w-full object-contain p-1"
                                />
                              </div>
                              <div className="flex flex-col">
                                <Typography
                                  style={{ color: fontColor }}
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal capitalize"
                                >
                                  <span className="capitalize">
                                    {row.card_brand}
                                  </span>{" "}
                                  {row.card_last_4_digit}
                                </Typography>
                                <Typography
                                  style={{ color: fontColor }}
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal opacity-70"
                                >
                                  <span>{row.card_exp_month}</span>/
                                  {row.card_exp_year}
                                </Typography>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default Transaction;
