import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import verifyImg from "../assets/verifyImg.jpg";
import verifyImgMobile from "../assets/verifyImgMobile.jpg";
import toast, { Toaster } from "react-hot-toast";

function Activate() {
  const { uid } = useParams();
  const navigate = useNavigate();
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);

  const activateAccount = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/email_verification/${uid}`, {
        method: "GET",
      });
      if (response.ok) {
        navigate("/create/agent");
      }
      else{
        toast.error("Something went wrong! Please try again.")
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   activateAccount();
  // }, []);

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div className="w-full h-screen flex gap-6 p-4 justify-center flex-col items-center">
        <img src={verifyImg} alt="" className="w-[500px] hidden 2xl:block" />
        <img src={verifyImgMobile} alt="" className="w-[500px] 2xl:hidden" />
        <h2 className="font-bold text-3xl w-full text-center">
          Verify your email address
        </h2>
        <p className="font-semibold text-xl w-full text-center">
          Thank you for signup, we need to verify your email address.
        </p>
        <button
          className="text-white flex gap-3 hover:text-gray-800 bg-gray-900 border border-gray-800 hover:bg-white focus:ring-2 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          type="button"
          onClick={activateAccount}
        >
          Verify Email
        </button>
      </div>
    </>
  );
}

export default Activate;
