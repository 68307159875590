import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { setRefresh } from "../features/Refresh/refresh";

export default function EditWebhookDialog({ open, setOpen, webhookId }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresh.refresh);

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      webhookUrl: "",
      bearer_token: "",
    },
    validationSchema: Yup.object({
      
      // webhookUrl: Yup.string().url("Invalid URL format").required("Required"),
    }),
    onSubmit: async (values ,{ resetForm }) => {
      try {
        const response = await fetch(
          `${baseUrl}/api/agency/webhook/${webhookId}/`,
          {
            
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              "Company-Name": companyName,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify({
              url: values.webhookUrl,
              bearer_token: values.bearer_token,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Webhook updated successfully:", data);
        dispatch(setRefresh(!refresh));
        resetForm(); 

        handleClose(); 
      } catch (error) {
        console.error("Failed to update webhook:", error);
      }
    },
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Webhook</DialogTitle>
      <DialogContent>
        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
          <div>
            <label htmlFor="webhookUrl">Webhook URL</label>
            <input
              type="text"
              id="webhookUrl"
              name="webhookUrl"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.webhookUrl}
              className={`mt-2 p-1 w-full border ${
                formik.touched.webhookUrl && formik.errors.webhookUrl
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
            />
            {formik.touched.webhookUrl && formik.errors.webhookUrl ? (
              <div className="text-red-500 text-sm">
                {formik.errors.webhookUrl}
              </div>
            ) : null}
          </div>
          <div>
            <label htmlFor="bearer_token">Bearer Token</label>
            <input
              type="text"
              id="bearer_token"
              name="bearer_token"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bearer_token}
              className={`mt-2 p-1 w-full border ${
                formik.touched.bearer_token && formik.errors.bearer_token
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
            />
            {formik.touched.bearer_token && formik.errors.bearer_token ? (
              <div className="text-red-500 text-sm">
                {formik.errors.bearer_token}
              </div>
            ) : null}
          </div>
          <button
            type="submit"
            className="mt-4 bg-blue-500 text-white p-2 rounded"
          >
            Save Changes
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
