import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, writeBatch } from 'firebase/firestore';
import { firestore } from './firebase';

const NotificationsContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationsContext);
};

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [userId, setUserId] = useState(null); // Assuming you'll get userId from auth or context

  useEffect(() => {
    const storedUserId = JSON.parse(localStorage.getItem('userData'))?.id;
    
    setUserId(storedUserId);
    // Create a reference to the notifications collection
    const notificationsRef = collection(firestore, 'notifications');

    // Create a query to order notifications by timestamp in descending order
    const q = query(notificationsRef, orderBy('timestamp', 'desc'));

    // Subscribe to snapshot updates
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notificationsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData);

      // Calculate unread count for the current user
      const unread = notificationsData.filter((n) => !n.read?.[userId]).length;
      setUnreadCount(unread);
    });

    return () => unsubscribe();
  }, [userId]);

  const markAsRead = async () => {
    
    if (!userId) return;

    const batch = writeBatch(firestore); // Create a new batch instance
    notifications.forEach((notification) => {
      const docRef = doc(firestore, 'notifications', notification.id);
      const readStatus = { ...notification.read, [userId]: true };
      batch.update(docRef, { read: readStatus });
    });

    await batch.commit();
  };

  return (
    <NotificationsContext.Provider value={{ notifications, unreadCount, markAsRead, setUserId }}>
      {children}
    </NotificationsContext.Provider>
  );
};
