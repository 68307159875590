import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { setFileData } from "../features/importContact/contactSlice";
import { setActiveStep } from "../features/importContact/activeStep";
import toast, { Toaster } from 'react-hot-toast';

function MatchContact() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const fileData = useSelector((state) => state.fileData.fileData);
  const { fontColor } = useSelector((state) => state.theme);
  const activeStep = useSelector((state) => state.activeStep.activeStep);
  const fileName = useSelector((state) => state.listName.listName);
  const [fieldMappings, setFieldMappings] = useState({});
  const [validationResults, setValidationResults] = useState({});
  const [showInvalidRowsOnly, setShowInvalidRowsOnly] = useState(false);
  const [editableCell, setEditableCell] = useState(null);
  const [activeInput, setActiveInput] = useState(null);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const dispatch = useDispatch();
  const company_name = JSON.parse(localStorage.getItem("userData"))?.companies[0]?.company_name;

  let updatedDataFile = null;

  const pushToUndoStack = (state) => {
    setUndoStack((prevStack) => [...prevStack, state]);
    setRedoStack([]); // Clear redo stack on new action
  };

  const handleMatchField = (columnName, fieldName) => {
    pushToUndoStack({
      fileData,
      fieldMappings,
      editableCell,
      activeInput,
    });
    setFieldMappings((prevMappings) => ({
      ...prevMappings,
      [columnName]: fieldName === "contact_number" ? "contact_number" : fieldName,
    }));
  };

  const validateContactNumber = (contactNumber) => {
    const contactNumberRegex = /^\d{8,15}$/;
    return contactNumberRegex.test(contactNumber);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCellEdit = (value, columnName, rowIndex) => {
    pushToUndoStack({
      fileData,
      fieldMappings,
      editableCell,
      activeInput,
    });

    const updatedFileData = [...fileData];
    const updatedRow = [...updatedFileData[rowIndex + 1]];
    updatedRow[fileData[0].indexOf(columnName)] = value;
    updatedFileData[rowIndex + 1] = updatedRow;
    dispatch(setFileData(updatedFileData));
  };

  const handleCellDoubleClick = (columnName, rowIndex) => {
    setEditableCell({ columnName, rowIndex });
    setActiveInput({ columnName, rowIndex });
  };

  const handleInputChange = (e, columnName, rowIndex) => {
    const { value } = e.target;
    handleCellEdit(value, columnName, rowIndex);
  };

  const handleInputKeyDown = (e, columnName, rowIndex) => {
    if (e.key === "Enter") {
      handleCellEdit(e.target.value, columnName, rowIndex);
      setEditableCell(null);
      setActiveInput(null);
    }
  };

  const handlePrev = () => dispatch(setActiveStep(activeStep - 1));

  const handleNext = async () => {
    try {
      updateFileDataHeaders(); // Wait for this function to finish
      const selectedColumnsData = updatedDataFile.map((row) =>
        fileData[0].reduce((acc, header, index) => {
          const mappedField = fieldMappings[header];
          if (mappedField) {
            acc.push(row[index]);
          }
          return acc;
        }, [])
      );

      const response = await fetch(`${baseUrl}/api/contact_list_upload/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": company_name,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          contact_list: fileName,
          contacts_data: selectedColumnsData,
        }),
      });

      if (response.ok) {
        dispatch(setActiveStep(activeStep + 1));
      } else {
        toast.error("Failed to upload contact");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isPhoneColumnSelected = useMemo(() => {
    return Object.values(fieldMappings).includes("contact_number");
  }, [fieldMappings]);

  const hasInvalidPhoneCells = useMemo(() => {
    if (!isPhoneColumnSelected) return false;

    return fileData.slice(1).some((row, rowIndex) => {
      const contactNumberIndex = fileData[0].findIndex(
        (header) => fieldMappings[header] === "contact_number"
      );
      const contactNumber = row[contactNumberIndex];
      return !validateContactNumber(contactNumber);
    });
  }, [fileData, fieldMappings, isPhoneColumnSelected]);

  const isEmailColumnSelected = useMemo(() => {
    return Object.values(fieldMappings).includes("email");
  }, [fieldMappings]);

  const hasInvalidEmailCells = useMemo(() => {
    if (!isEmailColumnSelected) return false;

    return fileData.slice(1).some((row, rowIndex) => {
      const emailIndex = fileData[0].findIndex(
        (header) => fieldMappings[header] === "email"
      );
      const email = row[emailIndex];
      return !validateEmail(email);
    });
  }, [fileData, fieldMappings, isEmailColumnSelected]);

  const updateFileDataHeaders = () => {
    const updatedFileData = fileData.map((row, index) => {
      if (index === 0) {
        return row.map((header) => fieldMappings[header] || header);
      } else {
        return row;
      }
    });
    dispatch(setFileData(updatedFileData));
    updatedDataFile = updatedFileData;
  };

  const handleUndo = () => {
    if (undoStack.length > 0) {
      const lastState = undoStack[undoStack.length - 1];
      setRedoStack((prevStack) => [...prevStack, {
        fileData,
        fieldMappings,
        editableCell,
        activeInput,
      }]);
      setUndoStack((prevStack) => prevStack.slice(0, -1));

      dispatch(setFileData(lastState.fileData));
      setFieldMappings(lastState.fieldMappings);
      setEditableCell(lastState.editableCell);
      setActiveInput(lastState.activeInput);
    }
  };

  const handleRedo = () => {
    if (redoStack.length > 0) {
      const nextState = redoStack[redoStack.length - 1];
      setUndoStack((prevStack) => [...prevStack, {
        fileData,
        fieldMappings,
        editableCell,
        activeInput,
      }]);
      setRedoStack((prevStack) => prevStack.slice(0, -1));

      dispatch(setFileData(nextState.fileData));
      setFieldMappings(nextState.fieldMappings);
      setEditableCell(nextState.editableCell);
      setActiveInput(nextState.activeInput);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 'z') {
        e.preventDefault();
        handleUndo();
      } else if (e.ctrlKey && e.key === 'r') {
        e.preventDefault();
        handleRedo();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndo, handleRedo]);

  return (
    <>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="p-4">
        <div className="flex items-center mb-4">
          <label style={{ color: fontColor }} className="mr-2">
            Show Invalid Rows Only:
          </label>
          <input
            type="checkbox"
            checked={showInvalidRowsOnly}
            onChange={() => setShowInvalidRowsOnly(!showInvalidRowsOnly)}
          />
        </div>
        {fileData.length > 0 ? (
          <div className="relative overflow-x-auto h-[500px] my-5 overflow-y-scroll">
            <table className="w-full text-sm text-left rtl:text-right border text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr style={{ color: fontColor }}>
                  {fileData.length > 0 &&
                    fileData[0].map((header, index) => (
                      <th key={index} scope="col" className="px-6 py-3">
                        {header}
                        <div className="mt-1">
                          <select
                            className="border rounded-md p-1"
                            onChange={(e) =>
                              handleMatchField(header, e.target.value)
                            }
                          >
                            <option value="">Select Field</option>
                            <option value="contact_number">Contact Number</option>
                            <option value="email">Email</option>
                            <option value="first_name">First Name</option>
                            <option value="last_name">Last Name</option>
                            <option value="company_name">Company Name</option>
                            <option value="industry">Industry</option>
                            <option value="custom1">Custom 1</option>
                            <option value="custom2">Custom 2</option>
                            <option value="custom3">Custom 3</option>
                            <option value="custom4">Custom 4</option>
                          </select>
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {fileData.slice(1).map((row, rowIndex) => {
                  const isInvalidRow = row.some((cell, cellIndex) => {
                    const columnName = fileData[0][cellIndex];
                    if (
                      activeInput &&
                      activeInput.columnName === columnName &&
                      activeInput.rowIndex === rowIndex
                    ) {
                      return false;
                    }
                    if (fieldMappings[columnName] === "contact_number") {
                      return !validateContactNumber(cell);
                    } else if (fieldMappings[columnName] === "email") {
                      return !validateEmail(cell);
                    }
                    return false;
                  });

                  if (
                    !showInvalidRowsOnly ||
                    (showInvalidRowsOnly && isInvalidRow) ||
                    (editableCell && editableCell.rowIndex === rowIndex)
                  ) {
                    return (
                      <tr
                        style={{ color: fontColor }}
                        key={rowIndex}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      >
                        {row.map((cell, cellIndex) => {
                          const columnName = fileData[0][cellIndex];
                          const isPhoneColumn =
                            fieldMappings[columnName] === "contact_number";
                          const isInvalidPhone =
                            isPhoneColumn && !validateContactNumber(cell);
                          const isEmailColumn =
                            fieldMappings[columnName] === "email";
                          const isInvalidEmail =
                            isEmailColumn && !validateEmail(cell);
                          const cellClass =
                            isInvalidPhone || isInvalidEmail
                              ? "bg-red-200"
                              : "";

                          return (
                            <td
                              key={cellIndex}
                              className={`px-6 py-4 ${cellClass} cursor-pointer`}
                              onDoubleClick={() =>
                                handleCellDoubleClick(columnName, rowIndex)
                              }
                            >
                              {editableCell &&
                              editableCell.columnName === columnName &&
                              editableCell.rowIndex === rowIndex ? (
                                <input
                                  type="text"
                                  className="border-0 bg-transparent outline-none w-full"
                                  value={cell}
                                  onChange={(e) =>
                                    handleInputChange(e, columnName, rowIndex)
                                  }
                                  onKeyDown={(e) =>
                                    handleInputKeyDown(e, columnName, rowIndex)
                                  }
                                  onBlur={() => {
                                    setEditableCell(null);
                                    setActiveInput(null);
                                  }}
                                />
                              ) : (
                                cell
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
      <div className="px-4 flex justify-between">
        <Button onClick={handlePrev}>Previous</Button>
        {/* <Button onClick={handleUndo} disabled={undoStack.length === 0}>Undo</Button>
        <Button onClick={handleRedo} disabled={redoStack.length === 0}>Redo</Button> */}
        {isPhoneColumnSelected &&
        !hasInvalidPhoneCells &&
        !hasInvalidEmailCells ? (
          <Button onClick={() => handleNext()}>
            Next
          </Button>
        ) : null}
      </div>
    </>
  );
}

export default MatchContact;
