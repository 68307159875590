import React from "react";
import Booking from "../components/Booking";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";

function BookingPage() {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);

  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full h-screen"
      >
        <Navbar />
        <div className="flex-1 p-8">
          <Booking />
        </div>
      </div>
    </>
  );
}

export default BookingPage;
