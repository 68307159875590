import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import UploadKnowledgeBasePopUp from "./UploadKnowledgeBasePopUp";
import KnowledgeBaseCard from "./KnowledgeBaseCard";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";

function KnowlwdgeBaseEdit() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const [searchQuery, setSearchQuery] = useState("");
  const company_name = JSON.parse(localStorage.getItem("userData"))
    ?.companies[0]?.company_name;
  const [loading, setLoading] = useState(false);
  const refresh = useSelector((state) => state.refresh.refresh);
  const { id } = useParams();
  const [knowledgeBaseItems, setKnowledgeBaseItems] = useState(null);

  useEffect(() => {
    const handleGetKnowledgeBase = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${baseUrl}/api/knowledge_base/${id}/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": company_name,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE4MjI5Nzg0LCJpYXQiOjE3MTgwMTM3ODQsImp0aSI6IjQyYWY5MjY2NGM0ZjQ2NzJiYTBjODQ4MjkzMDIxM2QyIiwidXNlcl9pZCI6NH0.CFG6kc2KskSny2Ve6uw4Ln95Ae_fnIWsj4VPXjKolZQ`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();
          setKnowledgeBaseItems(responseData.data["knowledge base"]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    handleGetKnowledgeBase();
  }, [refresh]);

  const filteredKnowledgeBaseItems = knowledgeBaseItems
    ? knowledgeBaseItems.knowledgebase_datas.filter((item) =>
        item.question_text.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full h-screen"
      >
        <Navbar />
        <div className="flex-1 p-8 flex flex-col gap-4">
          <div className="flex flex-col md:flex-row justify-between items-center w-full">
            <div
              style={{ color: fontColor }}
              className="text-4xl p-1 font-bold"
            >
              {knowledgeBaseItems && knowledgeBaseItems.name}
            </div>
            <div className="flex items-stretch gap-4">
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-800 focus:border-gray-800 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <UploadKnowledgeBasePopUp id={id} />
            </div>
          </div>
          <div className="flex justify-between 2xl:justify-start flex-wrap items-stratch">
            {knowledgeBaseItems &&
              filteredKnowledgeBaseItems.map((knowledge, index) => (
                <KnowledgeBaseCard key={index} heading={knowledge} />
              ))}
          </div>
        </div>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default KnowlwdgeBaseEdit;
