import React, { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import AnalyticsCard from "./AnalyticsCard";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "./Navbar";

function AgencyAnalyticsCampaign() {
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const {id} = useParams()
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await fetch(`${baseUrl}/api/agency/analytics/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const jsonData = await response.json();
      setData(jsonData.data.analytics_list);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigate = (id) => {
    navigate(`/recordings/${id}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data?.filter((item) =>
    item.campaign_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="w-full h-screen flex flex-col"
      >
        <Navbar />
        <div className="w-full flex justify-center items-center flex-1 pt-8 px-4 lg:px-8 2xl:px-16">
          <div className="w-full h-full">
            <div className="flex items-center gap-3">
              <form className="w-full mx-auto">
                <div className="flex">
                  <div className="relative w-full">
                    <input
                      type="search"
                      id="search-dropdown"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-2 border-gray-300 focus:ring-gray-300 focus:border-gray-300 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                      placeholder="Search"
                    />
                    <button
                      type="submit"
                      className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full rounded-e-lg border focus:ring-3 focus:outline-none focus:ring-gray-800 dark:bg-gray-800"
                    >
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      <span className="sr-only">Search</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="pt-8 flex flex-wrap justify-start">
              {filteredData?.map((dataItem) => (
                <AnalyticsCard
                  key={dataItem.id}
                  data={dataItem}
                  handleNavigate={handleNavigate}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default AgencyAnalyticsCampaign;
