import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { updateProfileSchema } from "../schemas/FormSchema";
import { useSelector } from "react-redux";
import userData from "../features/user/userData";
import toast from "react-hot-toast";

function ProfileUpdate({
  visible,
  click,
  refresh,
  setRefresh,
  success,
  setSuccess,
  userData,
}) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { fontColor } = useSelector((state) => state.theme);
  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null);

  const formMik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: updateProfileSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${baseUrl}/api/update/full_name/`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            first_name: values.firstName,
            last_name: values.lastName,
          }),
        });
        if (response.ok) {
          const responseData = await response.json();
          localStorage.setItem("userIcon",responseData.data.user.first_name[0])
          values.firstName = "";
          values.lastName = "";
          setSuccess(responseData);
          setRefresh(!refresh);
          toast.success("Name updated successfully");
        } else if (response.status === 400) {
          const responseData = await response.json();
          setMessage(responseData);
          toast.error(responseData.message);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleUpdateClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    try {
      const formData = new FormData();
      formData.append("profile_picture", file);

      const response = await fetch(`${baseUrl}/api/update_profile_picture/`, {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: formData,
      });
      if (response.ok) {
        const responseData = await response.json();
        setSuccess(responseData);
        setRefresh(!refresh);
        toast.success("Profile ipdated successfully")
      } else if (response.status === 404){
        const responseData = await response.json();
        setMessage(responseData.message);
        toast.error(responseData.message)
      }
      else {
        toast.error("Something went wrong")
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div
        className={`fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          visible ? "opacity-1" : "opacity-0 pointer-events-none"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div className="md:w-[30rem] relative">
          <div
            data-dialog="sign-in-dialog"
            className="relative mx-auto flex w-full  flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
          >
            <div className="flex flex-col gap-4 p-6">
              <h4
                style={{ color: fontColor }}
                className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
              >
                Edit Profile
              </h4>
              <p
                style={{ color: fontColor }}
                className="block mb-3 font-sans text-sm antialiased font-normal leading-relaxed text-gray-700"
              >
                Provide details about yourself.
              </p>
              {message && (
                <p className=" w-full text-center text-red-500">
                  {message && message.message}
                </p>
              )}
              <p className=" w-full text-center text-green-500">
                {success && success.message}
              </p>
              <div
                style={{ color: fontColor }}
                className="flex justify-between items-center"
              >
                <div className="flex flex-col gap-4">
                  <h6
                    style={{ color: fontColor }}
                    className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                  >
                    Profile Photo
                  </h6>
                  <div className="flex gap-3">
                    <button
                      style={{ color: fontColor }}
                      onClick={handleUpdateClick}
                      className="border-2 px-4 py-2 rounded-xl font-semibold"
                    >
                      Update
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                    {/* <button className="border-2 px-4 py-2 rounded-xl font-semibold">
                      Remove
                    </button> */}
                  </div>
                </div>
                <div className="w-20 h-20 rounded-full">
                  {userData && userData.profile_picture ? (
                    <img
                      src={`${userData.profile_picture}`}
                      alt=""
                      className="w-20 h-20 rounded-full"
                    />
                  ) : (
                    <>
                      <div className="w-20 h-20 flex justify-center items-center capitalize text-white font-bold text-4xl rounded-full bg-brown-600">
                        {userData && userData.first_name[0]}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <form
                onSubmit={formMik.handleSubmit}
                className="flex flex-col gap-4"
              >
                <h6
                  style={{ color: fontColor }}
                  className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                >
                  First Name
                </h6>
                <div className="relative h-11 mb-3 w-full min-w-[200px]">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name*"
                      value={formMik.values.firstName}
                      onChange={formMik.handleChange}
                      onBlur={formMik.handleBlur}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>
                {formMik.errors.firstName && formMik.touched.firstName ? (
                  <p className="w-full text-red-600 mx-[5px] font-medium">
                    {formMik.errors.firstName}
                  </p>
                ) : null}
                <h6
                  style={{ color: fontColor }}
                  className="block -mb-2 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit"
                >
                  Last Name
                </h6>
                <div className="relative mb-3 h-11 w-full min-w-[200px]">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name*"
                      value={formMik.values.lastName}
                      onChange={formMik.handleChange}
                      onBlur={formMik.handleBlur}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>
                {formMik.errors.lastName && formMik.touched.lastName ? (
                  <p className="w-full text-red-600 mx-[5px] font-medium">
                    {formMik.errors.lastName}
                  </p>
                ) : null}
                <div className="pt-0">
                  <button
                    type="submit"
                    className="block w-full select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  >
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
          <button
            type="button"
            onClick={click}
            className="absolute top-4 right-4 text-2xl"
          >
            <IoClose />
          </button>
        </div>
      </div>
    </>
  );
}

export default ProfileUpdate;
