import React, { useState } from "react";
import { Stepper, Step, Button, Typography } from "@material-tailwind/react";
import ImportFormatStepOne from "./ImportFormatStepOne";
import ContactUpload from "./ContactUpload";
import { useDispatch, useSelector } from "react-redux";
import MatchContact from "./MatchContact";
import ImportSuccess from "./ImportSuccess";
import { setActiveStep } from "../features/importContact/activeStep";
import { MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function ImportContact() {
  const { fontColor } = useSelector((state) => state.theme);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const fileData = useSelector((state) => state.fileData.fileData);
  const activeStep = useSelector((state) => state.activeStep.activeStep);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleNext = () =>
    !isLastStep && dispatch(setActiveStep(activeStep + 1));
  const handlePrev = () =>
    !isFirstStep && dispatch(setActiveStep(activeStep + 1));

  const isFileUploaded = fileData && fileData.length > 0;

  const handleClose = () =>{
    dispatch(setActiveStep(0))
    navigate("/create/list")
}

  return (
    <>
      <div className="p-8 relative rounded-md w-full">
        <div className="w-full py-4  border h-full flex flex-col justify-between">
          <div className="flex-1 flex flex-col">
            <div className="w-full  border-b">
              <div className="w-[86%] mx-auto h-24">
                <Stepper
                  activeStep={activeStep}
                  isLastStep={(value) => setIsLastStep(value)}
                  isFirstStep={(value) => setIsFirstStep(value)}
                >
                  <Step>
                    1
                    <div className="absolute -bottom-[1.5rem] w-max text-center">
                      <h2 
                      style={{ color: fontColor }}
                        className="text-gray-600 text-[10px]"
                      >
                        Getting Started
                      </h2>
                    </div>
                  </Step>
                  <Step>
                    2
                    <div className="absolute -bottom-[1.5rem] w-max text-center">
                      <h2
                      style={{ color: fontColor }}
                        className="text-gray-600 text-[10px]"
                      >
                        Upload File
                      </h2>
                    </div>
                  </Step>
                  <Step>
                    3
                    <div className="absolute -bottom-[1.5rem] w-max text-center">
                      <h2
                      style={{ color: fontColor }}
                        className="text-gray-600 text-[10px]"
                      >
                        Match Columns
                      </h2>
                    </div>
                  </Step>
                  <Step>
                    4
                    <div className="absolute -bottom-[1.5rem] w-max text-center">
                      <h2
                      style={{ color: fontColor }}
                        className="text-gray-600 text-[10px]"
                      >
                        Import Data
                      </h2>
                    </div>
                  </Step>
                </Stepper>
              </div>
            </div>
            <div className="flex-1 flex flex-col justify-between">
              {activeStep == 0 ? <ImportFormatStepOne /> : null}
              {activeStep == 1 ? <ContactUpload /> : null}
              {activeStep == 2 ? <MatchContact /> : null}
              {activeStep == 3 ? <ImportSuccess /> : null}
            </div>
          </div>
        </div>
        <div onClick={handleClose} className="absolute right-10 top-10 cursor-pointer text-3xl">
          <MdOutlineClose />
          </div>
      </div>
    </>
  );
}

export default ImportContact;
