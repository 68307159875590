import React, { useEffect, useState, useTransition } from "react";
import { useSelector } from "react-redux";

function SuperAdmin() {
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);

  const [userData, setUserData] = useState(null);
  const [isPending, startTransition] = useTransition(); // Initialize useTransition

  useEffect(() => {
    startTransition(() => {
      const handleGetAdminData = async () => {
        try {
          const response = await fetch(`${baseUrl}/api/user/logs/`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          });
          if (response.ok) {
            const responseData = await response.json();
            setUserData(responseData.data); // Update state
          }
        } catch (error) {
          console.error(error);
        }
      };

      handleGetAdminData();
    });
  }, [baseUrl, startTransition]); // Added startTransition to dependency array

  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <>
      <div className="relative overflow-x-auto p-4">
        {isPending || !userData ? ( // Use isPending to control skeleton loader
          <div className="p-4">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr style={{ color: fontColor }}>
                  <th scope="col" className="px-6 py-3 bg-gray-300 animate-pulse"></th>
                  <th scope="col" className="px-6 py-3 bg-gray-300 animate-pulse"></th>
                  <th scope="col" className="px-6 py-3 bg-gray-300 animate-pulse"></th>
                  <th scope="col" className="px-6 py-3 bg-gray-300 animate-pulse"></th>
                  <th scope="col" className="px-6 py-3 bg-gray-300 animate-pulse"></th>
                  <th scope="col" className="px-6 py-3 bg-gray-300 animate-pulse"></th>
                  <th scope="col" className="px-6 py-3 bg-gray-300 animate-pulse"></th>
                  <th scope="col" className="px-6 py-3 bg-gray-300 animate-pulse"></th>
                </tr>
              </thead>
              <tbody>
                {Array(5).fill(null).map((_, index) => (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                    <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                    <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                    <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                    <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                    <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                    <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                    <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <table className="w-full text-sm text-left rtl:text-right h-full text-gray-500 dark:text-gray-400 overflow-y-hidden">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr style={{ color: fontColor }}>
                <th scope="col" className="px-6 py-3">Email</th>
                <th scope="col" className="px-6 py-3">Label</th>
                <th scope="col" className="px-6 py-3">Ip Address</th>
                <th scope="col" className="px-6 py-3">Device Type</th>
                <th scope="col" className="px-6 py-3">Browser Type & version</th>
                <th scope="col" className="px-6 py-3">Status</th>
                <th scope="col" className="px-6 py-3">Last Logged In</th>
                <th scope="col" className="px-6 py-3">Os Type & OS Version</th>
                {/* <th scope="col" className="px-6 py-3">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {userData &&
                userData.map((user) => (
                  <tr
                    style={{ color: fontColor }}
                    key={user.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td className="px-6 py-4">{user.email}</td>
                    <td className="px-6 capitalize py-4">{user.label}</td>
                    <td className="px-6 capitalize py-4">{user.ip_address}</td>
                    <td className="px-6 capitalize py-4">{user.device_type}</td>
                    <td className="px-6 capitalize py-4">
                      {user.browser_type} ({user.browser_version})
                    </td>
                    <td className="px-6 capitalize py-4">{user.action}</td>
                    <td className="px-6 capitalize py-4">
                      {convertTimestamp(user.created_at)}
                    </td>
                    <td className="px-6 capitalize py-4">
                      {user.os_type} ({user.os_version})
                    </td>
                    {/* <td className="px-6 capitalize py-4">
                      <FaBan
                        onClick={() => handleGetCompanyName(user.id)}
                        className="hover:text-red-500 text-xl"
                      />
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

export default SuperAdmin;
