import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { SiKnowledgebase } from "react-icons/si";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import EditKnowledgeCard from "./EditKnowledgeCard";
import { setRefresh } from "../features/Refresh/refresh";
import toast from "react-hot-toast";

function KnowledgeBaseCard({ heading }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const { fontColor, legalName, backgroundColor } = useSelector(
    (state) => state.theme
  );
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresh.refresh);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/knowledge_base_data/${id}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE4MjI5Nzg0LCJpYXQiOjE3MTgwMTM3ODQsImp0aSI6IjQyYWY5MjY2NGM0ZjQ2NzJiYTBjODQ4MjkzMDIxM2QyIiwidXNlcl9pZCI6NH0.CFG6kc2KskSny2Ve6uw4Ln95Ae_fnIWsj4VPXjKolZQ`,
          },
        }
      );
      if (response.ok) {
        dispatch(setRefresh(!refresh));
        toast.success("Knowledge Base card deleted successfully");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="basis-1/3 p-4">
        <Card className="mt-6 h-full flex flex-col ">
          <CardBody className="flex-1">
            <SiKnowledgebase
              style={{ color: fontColor }}
              className="text-5xl mb-4 h-12 w-12 text-gray-900"
            />
            <Typography variant="h5" color="blue-gray" className="mb-2">
              {heading.question_text}
            </Typography>
            <Typography style={{ color: fontColor }} className="max-h-16 h-auto">
              {heading.answer_text}
            </Typography>
          </CardBody>
          <CardFooter className="pt-0 flex justify-end items-center">
            <EditKnowledgeCard
              id={heading.id}
              question={heading.question_text}
              answer={heading.answer_text}
            />
            <Button
              size="sm"
              variant="text"
              onClick={() => handleDelete(heading.id)}
              className="flex items-center gap-2 hover:text-red-500"
            >
              <MdDelete className="text-xl " />
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}

export default KnowledgeBaseCard;
