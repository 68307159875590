import React, { useState } from "react";
import Navbar from "./Navbar";
import { GoOrganization } from "react-icons/go";
import { BiMessageSquare } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { LuSettings } from "react-icons/lu";
import InviteTeam from "./InviteTeam";
import Settings from "./Settings";
import Members from "./Members";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { Toaster } from "react-hot-toast";
import Intercom from "@intercom/messenger-js-sdk";

const TeamSettings = () => {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);

  const [loading, setLoading] = useState(false);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );
  const companies = userData.companies;
  const full_name = `${userData?.first_name ?? ""} ${
    userData?.last_name ?? ""
  }`;
  const [activeSection, setActiveSection] = useState(null);

  const handleCompanyChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const updatedCompanies = [...userData.companies];
    const selectedCompany = updatedCompanies.splice(selectedIndex, 1)[0];
    updatedCompanies.unshift(selectedCompany);
    const updatedUserData = { ...userData, companies: updatedCompanies };
    setUserData(updatedUserData);
    localStorage.setItem("userData", JSON.stringify(updatedUserData));
  };

  const renderSection = () => {
    switch (activeSection) {
      case "members":
        return <Members loading={loading} setLoading={setLoading} />;
      case "invitations":
        return <InviteTeam />;
      case "settings":
        return <Settings />;
      default:
        return <Members loading={loading} setLoading={setLoading} />;
    }
  };

  if (baseUrl == "https://app.closerx.ai") {
    Intercom({
      app_id: "p6t03dhm",
      user_id: userData?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: userData?.first_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: userData?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
  }

  return (
    <>
    <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full h-screen"
      >
        <Navbar />
        <div className="w-full h-screen flex justify-center items-start p-8">
          <div className="xl:w-[60%] w-full bg-white custom-shadow flex flex-col 2xl:flex-row gap-5 p-1 rounded-xl relative">
            <div className="xl:w-[30%] w-full p-3 space-y-5 2xl:border-r border-b 2xl:border-b-0">
              <div className="flex space-x-2 items-center pb-1">
                <div className="p-2 w-7 rounded-lg bg-[#77aaff] text-white">
                  <GoOrganization />
                </div>
                <div className="flex flex-col">
                  <h1 style={{ color: fontColor }}>{full_name}</h1>
                  <select
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    onChange={handleCompanyChange}
                  >
                    {companies.map((company, index) => (
                      <option key={index} value={company.company_name}>
                        {company.company_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                style={{ color: fontColor }}
                className="flex space-x-2 items-center justify-center"
                onClick={() => setActiveSection("members")}
              >
                <div className="p-2 w-7 rounded-lg bg-brown-100 text-black">
                  <FaRegUser />
                </div>
                <h1>Members</h1>
              </button>
              <button
                style={{ color: fontColor }}
                className="flex space-x-2 items-center justify-center"
                onClick={() => setActiveSection("invitations")}
              >
                <div className="p-2 w-7 rounded-lg bg-brown-100 text-black">
                  <BiMessageSquare />
                </div>
                <h1>Invitations</h1>
              </button>
              <button
                style={{ color: fontColor }}
                className="flex space-x-2 items-center justify-center"
                onClick={() => setActiveSection("settings")}
              >
                <div className="p-2 w-7 rounded-lg bg-brown-100 text-black">
                  <LuSettings />
                </div>
                <h1>Settings</h1>
              </button>
            </div>
            <div className="w-full">{renderSection()}</div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
};

export default TeamSettings;
