import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setRefresh } from "../features/Refresh/refresh";
import Navbar from "./Navbar";
import IntegrationPageNav from "./IntegrationPageNav";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";

function ClientApi() {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [showKey, setShowKey] = useState(false); // Toggle visibility of the API key
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const refresh = useSelector((state) => state.refresh.refresh);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);

  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  const companyName = storedUserData?.companies[0]?.company_name;
  const accessToken = localStorage.getItem("accessToken");

  const fetchApiData = async (method, body = null) => {
    try {
      const response = await fetch(`${baseUrl}/api/agency/api_key/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${accessToken}`,
        },
        body: body ? JSON.stringify(body) : null,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };

  useEffect(() => {
    fetchApiData("GET");
  }, [refresh]);

  const fetchNewApiKey = async () => {
    try {
      if (data?.data?.agency_key) {
        const response = await fetch(`${baseUrl}/api/agency/api_key/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ old_api_key: data.data.agency_key }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const newData = await response.json();
        setData(newData);
        dispatch(setRefresh(!refresh)); // Trigger refresh
        toast("New api key generated ");
      } else {
        console.error("No API key available to send as old_api_key");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data?.data?.agency_key || "");
    toast("API Key copied to clipboard!");
  };

  const toggleShowKey = () => {
    setShowKey((prevShowKey) => !prevShowKey);
  };

  return (
    <>
    <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        className=" w-full flex flex-col items-center mt-5 bg-white"
        style={{ backgroundColor: backgroundColor }}
      >
        <h1 className="text-center " style={{ color: fontColor }}>
          Client API Keys
        </h1>
        <div className="text-center mt-4" style={{ color: fontColor }}>
          <span>
            {showKey ? data?.data.agency_key : "••••••••••••••••"} {/* Hide API Key */}
          </span>
          {/* <button
            onClick={toggleShowKey}
            className="ml-2 text-sm underline"
            style={{ color: fontColor }}
          >
            {showKey ? "Hide" : "Show"} API Key
          </button> */}
        </div>

        <button
          onClick={copyToClipboard}
          className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800 mt-4"
          type="button"
        >
          <span
            style={{ backgroundColor: backgroundColor, color: fontColor }}
            className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
          >
            Copy API Key
          </span>
        </button>

        <button
          onClick={fetchNewApiKey}
          className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800 mt-4"
          type="button"
        >
          <span
            style={{ backgroundColor: backgroundColor, color: fontColor }}
            className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
          >
            Generate New API Key
          </span>
        </button>
      </div>
    </>
  );
}

export default ClientApi;
