import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import PageHeader from "../components/PageHeader";
import ContactListHome from "../components/ContactListHome";
import ContactList from "../components/ContactList";
import CreateListsPopUp from "../components/CreateListsPopUp";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { Toaster } from "react-hot-toast";

function ListsPage() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const [listData, setListData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);

  const handleGetList = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}/api/contact/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        setListData(responseData.data["contact_lists"]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetList();
  }, [refresh]);

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor }}
        className="w-full h-screen"
      >
        <Navbar />
        <div className="flex justify-end pt-5 flex-col md:flex-row items-center">
          {listData && listData.length !== 0 ? (
            <div className="mr-4">
              <CreateListsPopUp />
            </div>
          ) : (
            ""
          )}
        </div>
        {listData && listData.length !== 0 ? (
          <ContactList refresh={refresh} setRefresh={setRefresh} />
        ) : (
          <ContactListHome />
        )}
      </div>
      {loading && (
        <div className="w-full h-screen absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default ListsPage;
