import React, { useEffect, useState, useTransition } from "react";
import { useSelector } from "react-redux";
import Navbar from "./Navbar";
import ConfirmationDialog from "./ConfirmationDialog";
import { useParams } from "react-router-dom";
import SuperAddCredit from "./SuperAddCredit";
import toast, { Toaster } from "react-hot-toast";
import SuperRemoveCredits from "./SuperRemoveCredits";

function SuperAdminWhiteUser() {
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [userData, setUserData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isCreditAddOpen, setIsCreditAddOpen] = useState(false);
  const [isCreditRemoveOpen, setIsCreditRemoveOpen] = useState(false);
  const [selectedUserActivate, setSelectedUserActivate] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserCompany, setSelectedUserCompany] = useState(null);
  const [isPending, startTransition] = useTransition(); // Initialize useTransition
  const email = JSON.parse(localStorage.getItem("userData"))?.email;

  useEffect(() => {
    startTransition(async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/superadmin/whitlabel/agency/${id}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (response.ok) {
          const responseData = await response.json();
          setUserData(responseData.data);
        }
      } catch (error) {
        console.error(error);
      }
    });
  }, [baseUrl, id, refresh, startTransition]);

  const handleToggleChange = (id, isActive) => {
    setSelectedUserId(id);
    setSelectedUserActivate(isActive);
    setDialogOpen(true);
  };

  const handleCreditToggle = (companyName) => {
    setSelectedUserCompany(companyName);
    setIsCreditAddOpen(true);
  };

  const handleRemoveCreditToggle = (companyName) => {
    setSelectedUserCompany(companyName);
    setIsCreditRemoveOpen(true);
  };

  const handleConfirmToggle = async () => {
    if (selectedUserId === null) return;

    try {
      const response = await fetch(`${baseUrl}/api/superadmin/whitlabel/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ agency_id: selectedUserId }), // Use the correct payload
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setRefresh(!refresh);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setDialogOpen(false);
      setSelectedUserId(null);
    }
  };

  const handleAccess = async (id) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/superadmin/impersonate/sub/user/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({ email: email, company_name: id }), // Use the correct payload
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data.data.domain.domain);
      window.open(`https://${data.data.domain.domain}/access/${id}/${email}`, '_blank');
      console.log(`${data.data.domain.domain}/access/${id}/${email}`);
      
      // const { access_token, refresh_token } = data.data;
      // localStorage.setItem("accessToken", access_token);
      // localStorage.setItem("refreshToken", refresh_token);
      // localStorage.setItem("userData", JSON.stringify(data.data.user));
      // localStorage.setItem("guestUser", JSON.stringify(data.guest));
  
      // navigate("/dashboard")
      // setRefresh(!refresh);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddCreditToggle = async (amount) => {
    try {
      const response = await fetch(`${baseUrl}/api/superadmin/add_credits/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          company_name: selectedUserCompany,
          credits: amount, // Pass the credit amount
        }),
      });
      if (!response.ok) {
        toast.error("failed to add credits");
      }
      const data = await response.json();
      setRefresh(!refresh); // Refresh the data
      toast.success("credits added successfully");
      setIsCreditAddOpen(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Close the dialog
      // setSelectedUserId(null); // Reset the selected user
    }
  };

  const handleRemoveCredit = async (amount) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/superadmin/remove_credits/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            company_name: selectedUserCompany,
            credits: amount, // Pass the credit amount
          }),
        }
      );
      if (!response.ok) {
        toast.error("failed to Remove credits");
      }
      const data = await response.json();
      setRefresh(!refresh); // Refresh the data
      toast.success("credits Remove successfully");
      setIsCreditRemoveOpen(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Close the dialog
      // setSelectedUserId(null); // Reset the selected user
    }
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex flex-col w-full"
      >
        <Navbar />
        <div className="flex-1 p-4">
          <div className="relative overflow-x-auto p-4">
            {isPending || !userData ? ( // Show skeleton loader if pending
              <div className="p-4">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr style={{ color: fontColor }}>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-300 animate-pulse"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array(5)
                      .fill(null)
                      .map((_, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                          <td className="px-6 py-4 bg-gray-200 animate-pulse h-6"></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <table className="w-full text-sm text-left rtl:text-right h-full text-gray-500 dark:text-gray-400 overflow-y-hidden">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr style={{ color: fontColor }}>
                    <th scope="col" className="px-6 py-3">
                      Company Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Twilio SID
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Twilio Token
                    </th>
                    <th scope="col" className="px-6 py-3">
                      GHL Location ID
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Credits
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Credits Price
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Credits Consumed
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Add credits
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Remove Credits
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Action
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Access
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userData &&
                    userData.map((user) => (
                      <React.Fragment key={user.id}>
                        <tr
                          style={{ color: fontColor }}
                          className="bg-blue-200 border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td className="px-6 capitalize py-4">
                            {user?.company_name || "-"}
                          </td>
                          <td className="px-6 capitalize py-4">
                            {user?.twilio_sid || "-"}
                          </td>
                          <td className="px-6 capitalize py-4">
                            {user?.twilio_token || "-"}
                          </td>
                          <td className="px-6 capitalize py-4">
                            {user?.ghl_location_id || "-"}
                          </td>
                          <td className="px-6 capitalize py-4">
                            {parseInt(user?.credit).toFixed(2) || "-"}
                          </td>
                          <td className="px-6 capitalize py-4">
                            {parseInt(user?.credit_price).toFixed(2) || "-"}
                          </td>
                          <td className="px-6 capitalize py-4">
                            {user?.total_credits_consumed.toFixed(2) || "-"}
                          </td>
                          <td className="px-6 capitalize py-4">
                            <button
                              onClick={() =>
                                handleCreditToggle(user.company_name)
                              }
                              type="button"
                              className={`border p-2 capitalize rounded-lg bg-green-400 text-white`}
                            >
                              Add Credits
                            </button>
                          </td>
                          <td className="px-6 capitalize py-4">
                            <button
                              onClick={() =>
                                handleRemoveCreditToggle(user.company_name)
                              }
                              type="button"
                              className={`border p-2 capitalize rounded-lg bg-blue-gray-800 text-white`}
                            >
                              Remove Credits
                            </button>
                          </td>
                          <td className="px-6 capitalize py-4">
                            <button
                              onClick={() =>
                                handleToggleChange(user.id, user.is_suspended)
                              }
                              type="button"
                              className={`border p-2 capitalize rounded-lg ${
                                user?.is_suspended
                                  ? "bg-green-400 text-white"
                                  : "bg-red-400 text-white"
                              }`}
                            >
                              {user?.is_suspended ? "Active" : "Suspend"}
                            </button>
                          </td>
                          <td className="px-6 capitalize py-4">
                            <button
                              onClick={() =>
                                handleAccess(user?.company_name)
                              }
                              type="button"
                              className={`border p-2 capitalize rounded-lg`}
                            >
                              Access
                            </button>
                          </td>
                        </tr>
                        {/* Add a row for users associated with the company */}
                        {user.users.length > 0 && (
                          <tr className="bg-gray-50 dark:bg-gray-800">
                            <td colSpan="8" className="px-6 py-4">
                              <div className="overflow-x-auto">
                                <table className="w-full text-sm text-left rtl:text-right h-full text-gray-500 dark:text-gray-400">
                                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                      <th scope="col" className="px-6 py-3">
                                        Email
                                      </th>
                                      <th scope="col" className="px-6 py-3">
                                        First Name
                                      </th>
                                      <th scope="col" className="px-6 py-3">
                                        Last Name
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {user.users.map((user) => (
                                      <tr
                                        key={user.id}
                                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                      >
                                        <td className="px-6 py-4">
                                          {user.email}
                                        </td>
                                        <td className="px-6 py-4">
                                          {user.first_name || "-"}
                                        </td>
                                        <td className="px-6 py-4">
                                          {user.last_name || "-"}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirmToggle}
        message={`Are you sure you want to ${
          selectedUserActivate ? "active" : "suspend"
        } this user?`}
      />
      <SuperAddCredit
        isOpen={isCreditAddOpen}
        onClose={() => setIsCreditAddOpen(false)}
        onConfirm={handleAddCreditToggle}
      />
      <SuperRemoveCredits
        isOpen={isCreditRemoveOpen}
        onClose={() => setIsCreditRemoveOpen(false)}
        onConfirm={handleRemoveCredit}
      />
    </>
  );
}

export default SuperAdminWhiteUser;
