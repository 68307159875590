import React from 'react'
import Navbar from './Navbar'
import IntegrationPageNav from './IntegrationPageNav'
function ZapierIntigration() {
  return (
   
    <div>Zapiar</div>
   
    
  )
}

export default ZapierIntigration