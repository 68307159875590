import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../features/user/userData";
import { setAgentData } from "../features/Agents/agentData";
import CreateCampaign from "./CreateCampaign";
import { useNavigate } from "react-router-dom";
import QuickCampaign from "./QuickCampaign";

function Agreement({ activeTab }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const userData = useSelector((state) => state.userData.userData);
  const [modalOpen, setModalOpen] = useState(false);
  const agentData = useSelector((state) => state.agentData.agentData);
  const [isConfirmButtonActive, setIsConfirmButtonActive] = useState(false);
  const containerRef = useRef(null);
  const [knowledgeBase, setKnowledgeBase] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [twilioList, setTwilioList] = useState(null);
  const dispatch = useDispatch();
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [showModal, setShowModal] = useState(false);
  const [quickModal, setQuickModal] = useState(false);
  const { fontColor, backgroundColor, legalName } = useSelector(
    (state) => state.theme
  );
  const navigate = useNavigate();

  const handleToggle = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const handleScroll = () => {
        // Check if the user has scrolled to the bottom
        if (
          container.scrollTop + (container.clientHeight + 100) >=
          container.scrollHeight
        ) {
          setIsConfirmButtonActive(true);
        } else {
          setIsConfirmButtonActive(false);
        }
      };

      container.addEventListener("scroll", handleScroll);
    }
  }, [showModal, setShowModal]);

  const handleGetUser = async () => {
    const userID = JSON.parse(localStorage.getItem("userData"))?.id;
    try {
      const response = await fetch(`${baseUrl}/api/user/${userID}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        dispatch(setUserData(responseData.data.user));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetAgent = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/agents/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        dispatch(setAgentData(responseData.data.agents));
      }
    } catch (error) {}
  };

  const fetchKnowledgeBase = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/knowledge_base/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        setKnowledgeBase(responseData.data["knowledge base"]);
      }
    } catch (error) {
      console.error("Error fetching knowledge base options:", error);
    }
  };

  const fetchContactList = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/contact/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        setContactList(responseData.data["contact_lists"]);
      }
    } catch (error) {
      console.error("Error fetching knowledge base options:", error);
    }
  };

  const fetchTwilio = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/twilio/number/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        setTwilioList(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  useEffect(() => {
    handleGetUser();
    handleGetAgent();
    fetchKnowledgeBase();
    fetchContactList();
    fetchTwilio();
  }, []);

  const handleModalClose = () => {
    setShowModal(false);
    if (activeTab === "Campaign") {
      setModalOpen(true);
    }
    else if (activeTab === "quickCampaign") {
      setQuickModal(true)
    }
  };

  return (
    <>
      <button
        onClick={handleToggle}
        className="relative inline-flex items-center justify-center p-0.5  overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
        type="button"
      >
        <span
          style={{ backgroundColor: backgroundColor, color: fontColor }}
          className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
        >
          Launch a Campaign
        </span>
      </button>
      <div
        className={`${
          showModal ? "" : "pointer-events-none"
        } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
          showModal ? "opacity-1" : "opacity-0"
        } backdrop-blur-sm transition-opacity duration-300`}
      >
        <div
          data-dialog="dialog"
          className="relative m-4 w-[80%] lg:w-2/5 lg:min-w-[40%] lg:max-w-[40%] rounded-lg bg-white font-sans text-base font-light leading-relaxed text-blue-gray-500 antialiased shadow-2xl"
        >
          {true ? (
            <>
              {agentData && agentData.length > 0 ? (
                <>
                  {true ? (
                    <>
                      {true ? (
                        <>
                          <div className="agreement">
                            <div
                              style={{ color: fontColor }}
                              className="m-5 h-96 overflow-y-scroll"
                              ref={containerRef}
                            >
                              Terms of Use for{" "}
                              {legalName ? legalName : "{Brand Name}"} AI
                              Calling Services <br /> <br /> 1. Introduction{" "}
                              <br /> These Terms of Use ("Agreement") govern
                              your use of the AI calling services ("Services")
                              provided by {legalName} ("we," "us," or "our"). By
                              accessing or using our Services, you ("Subscriber"
                              or "you") agree to be bound by this Agreement. If
                              you do not agree to these terms, you are
                              prohibited from using or accessing our Services.{" "}
                              <br /> <br /> 2. Subscriber's Obligations and
                              Compliance Requirements <br /> 2.1 Responsibility
                              for Proper Use: {legalName} provides advanced AI
                              calling tools, but you are solely responsible for
                              how you use them. Your continued access to our
                              Services depends on your adherence to these Terms
                              of Use and all applicable laws in your
                              jurisdiction. <br /> 2.2 Monitoring and
                              Responsibility: While {legalName} may monitor the
                              use of our Services, we are not obligated to do
                              so. We bear no responsibility for the content of
                              information, databases, or audio messages you
                              upload or use with our Services. <br /> 2.3
                              Compliance with Telemarketing and Communication
                              Laws: You must comply with all relevant laws and
                              regulations in your jurisdiction governing
                              automated or AI-assisted calls and text messages.
                              This includes, but is not limited to: - Anti-spam
                              legislation (e.g., CAN-SPAM Act in the US, CASL in
                              Canada, GDPR in EU) - Telemarketing regulations
                              (e.g., Telemarketing Sales Rule in the US) -
                              Do-not-call list requirements - Telemarketer
                              registration obligations - Data protection and
                              privacy laws (e.g., CCPA, GDPR) - Any current or
                              future laws or regulations that may apply to
                              AI-assisted communications in your region <br />{" "}
                              2.4 Accurate Identification and Required
                              Disclosures: You agree to provide truthful and
                              accurate Caller ID information for all
                              communications made using our Services, as
                              required by the laws in your jurisdiction. You are
                              also responsible for including all legally
                              required disclosures in your communications, such
                              as: - The identity of the caller - The purpose of
                              the call - Opt-out instructions - Any other
                              disclosures required by applicable laws Compliance
                              with these requirements is your sole
                              responsibility. <br /> 2.5 Consent Requirements:
                              You understand that different types of
                              communications may require different levels of
                              recipient consent, which may vary by jurisdiction.
                              You agree not to initiate any communication unless
                              you have: - Obtained the necessary consent from
                              the recipient, as required by local laws - An
                              existing business relationship that permits the
                              communication, where applicable - A valid legal
                              exemption or exception that allows the
                              communication in your jurisdiction You acknowledge
                              that consent requirements may include, but are not
                              limited to: - Express written consent for certain
                              types of calls or messages - Clear and conspicuous
                              disclosure of the terms of consent - Maintenance
                              of records of consent <br /> 2.6 Maintenance and
                              Provision of Consent Records: You are required to
                              maintain accurate records of consent, business
                              relationships, or applicable legal exemptions for
                              all communications made using our Services, in
                              accordance with the laws in your jurisdiction.
                              These records must be kept either by you directly
                              or through a reputable third-party service. Upon
                              {legalName}'s request via email, you agree to
                              promptly provide certified copies of these records
                              within 5 business days. <br /> 2.7 International
                              Use: You acknowledge that laws and regulations
                              regarding AI-assisted communications,
                              telemarketing, and data privacy vary significantly
                              between countries. It is your responsibility to
                              ensure that your use of our Services complies with
                              all applicable local, state, and national laws in
                              both your jurisdiction and the jurisdictions of
                              your call recipients. <br /> 2.8 Indemnification
                              for International Use: You agree to indemnify and
                              hold {legalName}
                              harmless from any claims, losses, or damages
                              arising from your use of the Services in violation
                              of any international, national, or local laws or
                              regulations. <br /> <br /> 3. Service Description{" "}
                              {legalName}
                              provides AI-powered calling tools for business
                              communication. Our Services include automated
                              voice calls, conversational AI, and related
                              features designed to enhance customer engagement
                              and streamline communication processes. <br />{" "}
                              <br /> 4. Subscriber Responsibilities <br /> 4.1
                              Proper Use of Services: You agree to use our
                              Services responsibly and ethically. Prohibited
                              activities include: - Harassment or abusive
                              behavior - Fraudulent or deceptive practices -
                              Violation of intellectual property rights - Any
                              use that may damage our reputation or Services{" "}
                              <br /> 4.2 Call Recording Compliance: You
                              acknowledge that all calls are recorded on the
                              platform. You agree to comply with all applicable
                              recording and wiretapping laws in your
                              jurisdiction and the jurisdictions of your call
                              recipients, including obtaining necessary consents
                              for call recording.
                              <br /> <br />
                              5. Data and Privacy <br /> 5.1 Subscriber Data:
                              You grant us a non-exclusive, royalty-free,
                              worldwide, perpetual, irrevocable, transferrable,
                              sublicensable license to use, copy, perform,
                              reproduce, display, and distribute, including to
                              create derivative works or incorporate into other
                              works, all Subscriber-owned data provided by
                              Subscriber or which Subscriber makes available to
                              {legalName} in connection with Subscriber's use of
                              the Services ("Subscriber Data") for the limited
                              purpose of fulfilling {legalName}'s obligations
                              under this Agreement, including without limitation
                              conducting research, development, usage
                              monitoring, and other day-to-day business
                              activities. <br /> 5.2 Usage Data: We collect and
                              own usage, technical, and statistical data related
                              to your use of our Services. This data may be used
                              for research, development, business activities,
                              improving our services, and any other lawful
                              purpose. {legalName} may use and disclose usage,
                              technical, and statistical data without
                              restriction. <br /> 5.3 Data Protection: We
                              implement reasonable security measures to protect
                              your data. However, you are responsible for
                              maintaining the security of your account
                              credentials and complying with data protection
                              laws in your jurisdiction.
                              <br /> 5.4 Data Rights: You may request access to,
                              correction of, or deletion of your personal data
                              by contacting us at support@{legalName}, subject
                              to applicable laws in your jurisdiction. <br />{" "}
                              5.5 International Data Processing: You are
                              responsible for ensuring that your use of the
                              Services complies with data protection laws in
                              your jurisdiction and the jurisdictions of your
                              call recipients, particularly regarding
                              international data transfers. You are not
                              permitted to use the Services in connection with
                              the processing of personal data of data subjects
                              in jurisdictions where such processing would
                              violate local data protection laws without our
                              prior written consent.
                              <br /> <br />
                              6. Intellectual Property <br /> 6.1 Our IP: All
                              intellectual property related to our Services,
                              including software, algorithms, and content,
                              remains our exclusive property. <br /> 6.2 Your
                              Content: You retain ownership of content you
                              create using our Services, but grant us a license
                              to use it for Service-related purposes. <br /> 6.3
                              Social Media Content: You grant us an irrevocable
                              perpetual license to use, reproduce, edit, create
                              derivative works from, distribute, display, copy,
                              transmit or otherwise use in any way, commercially
                              or otherwise, any material that you post to any
                              social networking site or other Third Party
                              Service in connection with {legalName} or the
                              Services. <br /> <br /> 7. Confidentiality <br />{" "}
                              7.1 Definition of Confidential Information:
                              "Confidential Information" means any information
                              disclosed by one party ("Disclosing Party") to the
                              other party ("Receiving Party"), either directly
                              or indirectly, in writing, orally or by inspection
                              of tangible objects, which is designated as
                              "Confidential," "Proprietary" or some similar
                              designation, or information which, by its nature,
                              would reasonably be considered confidential.{" "}
                              <br /> 7.2 Obligations: The Receiving Party shall:
                              a) Hold the Confidential Information in strict
                              confidence; b) Not disclose such Confidential
                              Information to any third parties; c) Use the
                              Confidential Information only for the purpose of
                              performing its obligations or exercising its
                              rights under this Agreement; d) Take all
                              reasonable precautions to protect the
                              confidentiality of such Confidential Information
                              with at least the same degree of care that it uses
                              to protect its own confidential information, but
                              in no case less than reasonable care; e) Not
                              reverse engineer, disassemble, or decompile any
                              prototypes, software, or other tangible objects
                              which embody the Confidential Information. <br />{" "}
                              7.3 Exceptions: The obligations of confidentiality
                              shall not apply to any information which: a) Was
                              publicly known and made generally available prior
                              to the time of disclosure; b) Becomes publicly
                              known and made generally available after
                              disclosure through no action or inaction of the
                              Receiving Party; c) Is already in the possession
                              of the Receiving Party at the time of disclosure;
                              d) Is obtained by the Receiving Party from a third
                              party without a breach of such third party's
                              obligations of confidentiality; e) Is
                              independently developed by the Receiving Party
                              without use of or reference to the Confidential
                              Information. <br /> 7.4 Compelled Disclosure: If
                              the Receiving Party is required by law to disclose
                              Confidential Information, it shall give prompt
                              written notice to the Disclosing Party and provide
                              reasonable assistance in obtaining an order
                              protecting the information from public disclosure.
                              <br /> 7.5 Return of Materials: Upon termination
                              of this Agreement or upon request of the
                              Disclosing Party, the Receiving Party will
                              promptly return or destroy all copies of
                              Confidential Information. <br /> <br /> 8.
                              Limitation of Liability To the fullest extent
                              permitted by applicable law, in no event will
                              {legalName}, its affiliates, directors, officers,
                              employees, agents, or licensors be liable for any
                              indirect, incidental, special, consequential,
                              punitive, or exemplary damages, including but not
                              limited to damages for loss of profits, goodwill,
                              use, data, or other intangible losses. Our total
                              liability for any claims arising under these Terms
                              or our relationship with you will not exceed the
                              amount paid by you to {legalName} for the Services
                              in the 12 months preceding the claim. <br />{" "}
                              <br /> 9. Modifications to Services and Terms We
                              may modify our Services or this Agreement at any
                              time by posting the revised terms on our website
                              or by notifying you via email. Continued use of
                              our Services after changes constitutes acceptance
                              of the modified terms. <br /> <br /> 10.
                              Termination <br /> 10.1 By Subscriber: You may
                              terminate this Agreement by ceasing use of our
                              Services and closing your account. <br /> 10.2 By{" "}
                              {legalName}: We may suspend or terminate your
                              access to our Services for violations of this
                              Agreement or for any other reason at our
                              discretion, with or without notice.
                              <br />
                              10.3 Effects of Termination: Upon termination, you
                              must cease all use of the Services and delete any
                              downloaded materials in your possession or
                              control. Any provisions that by their nature
                              should survive termination shall survive
                              termination. <br /> <br /> 11. Dispute Resolution{" "}
                              <br /> 11.1 Informal Resolution: Before initiating
                              any formal dispute resolution procedure, the
                              parties agree to attempt to resolve any dispute
                              informally by contacting each other. <br /> 11.2
                              Arbitration: Any dispute, controversy, or claim
                              arising out of or relating to this Agreement shall
                              be settled by binding arbitration in accordance
                              with the commercial arbitration rules of the
                              American Arbitration Association. The arbitration
                              will be conducted in the English language and
                              shall take place in a mutually agreed location or
                              via online means. For disputes under $10,000, the
                              arbitration may be conducted on the basis of
                              documents only. <br /> 11.3 Exception to
                              Arbitration: Notwithstanding the foregoing,
                              nothing in this Agreement will preclude either
                              party from seeking injunctive relief in any court
                              of competent jurisdiction. <br /> <br /> 12.
                              Governing Law This Agreement is governed by and
                              construed in accordance with the laws of Canada,
                              without regard to its conflict of law provisions.
                              The parties expressly exclude the application of
                              the United Nations Convention on Contracts for the
                              International Sale of Goods (CISG) to this
                              Agreement. <br /> <br /> 13. Third Party Content
                              and Services Our Service may interact with or
                              include links to third-party platforms, services,
                              or content. These Third Party Services are not
                              under {legalName}'s control, and we are not
                              responsible for their content, functionality, or
                              practices. Your use of Third Party Services is at
                              your own risk and subject to the terms and
                              policies of those third parties. <br /> <br /> 14.
                              Indemnification You agree to defend, indemnify,
                              and hold harmless {legalName}, its affiliates,
                              licensors, and service providers, and its and
                              their respective officers, directors, employees,
                              contractors, agents, licensors, suppliers,
                              successors, and assigns from and against any
                              claims, liabilities, damages, judgments, awards,
                              losses, costs, expenses, or fees (including
                              reasonable attorneys' fees) arising out of or
                              relating to: <br /> a) Your violation of these
                              Terms of Use; <br /> b) Your use of the Services;{" "}
                              <br /> c) Any content or information you provide
                              through the Services; <br /> d) Your violation of
                              any law, regulation, or third-party right,
                              including but not limited to any intellectual
                              property right or privacy right; <br /> e) Any
                              claim that your use of the Services caused damage
                              to a third party. <br /> <br /> 15. Warranty
                              Disclaimer THE SERVICES ARE PROVIDED ON AN "AS IS"
                              AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES
                              OF ANY KIND, EITHER EXPRESS OR IMPLIED.{" "}
                              {legalName} EXPRESSLY DISCLAIMS ALL WARRANTIES,
                              WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
                              INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                              MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
                              PARTICULAR PURPOSE. {legalName} DOES NOT WARRANT
                              THAT THE SERVICES WILL BE UNINTERRUPTED,
                              ERROR-FREE, OR COMPLETELY SECURE.
                              {legalName} DOES NOT GUARANTEE THE ACCURACY,
                              COMPLETENESS, OR USEFULNESS OF THE SERVICES. YOU
                              ACKNOWLEDGE THAT YOU ACCESS AND USE THE SERVICES
                              AT YOUR OWN DISCRETION AND RISK, AND THAT YOU WILL
                              BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                              COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM
                              SUCH ACCESS OR USE. <br /> <br /> 16. Export
                              Compliance You agree to comply with all applicable
                              export and re-export control laws and regulations,
                              including those maintained by Global Affairs
                              Canada, the Export Administration Regulations
                              maintained by the U.S. Department of Commerce,
                              trade and economic sanctions maintained by the
                              Treasury Department's Office of Foreign Assets
                              Control, and the International Traffic in Arms
                              Regulations maintained by the Department of State.{" "}
                              <br /> <br /> 17. Severability and Survivability
                              If any provision of this Agreement is found to be
                              unenforceable or invalid, that provision will be
                              limited or eliminated to the minimum extent
                              necessary so that this Agreement will otherwise
                              remain in full force and effect and enforceable.
                              The sections titled "Intellectual Property,"
                              "Limitation of Liability," "Warranty Disclaimer,"
                              "Indemnification," and "Dispute Resolution" shall
                              survive termination of this Agreement. <br />{" "}
                              <br /> 18. Miscellaneous <br /> 18.1 Entire
                              Agreement: This Agreement constitutes the entire
                              agreement between you and {legalName} regarding
                              the Services and supersedes all prior agreements
                              and understandings, whether written or oral.{" "}
                              <br /> 18.2 Assignment: {legalName} may assign or
                              transfer these Terms of Use, in whole or in part,
                              without restriction. You may not assign or
                              transfer any rights or obligations under these
                              Terms without {legalName}'s prior written consent.{" "}
                              <br /> 18.3 Force Majeure: Neither party will be
                              responsible for any failure or delay in
                              performance due to circumstances beyond its
                              reasonable control, including but not limited to
                              acts of labor disputes, industrial
                              disturbances, systemic electrical,
                              telecommunications, or other utility failures,
                              earthquake, storms, or other elements of nature,
                              pandemics, blockages, embargoes, riots, acts or
                              orders of government, terrorism, or war.
                              <br />
                              18.4 No Waiver: No failure or delay by {legalName}
                              in exercising any right under this Agreement will
                              constitute a waiver of that right. <br />
                              <br /> 19. Contact Information For support:
                              support@{legalName} For legal inquiries: info@
                              {legalName} By using our Services, you acknowledge
                              that you have read, understood, and agree to be
                              bound by this Agreement.
                            </div>
                            <div className="flex flex-wrap items-center justify-end p-4 shrink-0 text-blue-gray-500">
                              <button
                                onClick={handleToggle}
                                className="px-6 py-3 mr-1 font-sans text-xs font-bold text-red-500 uppercase transition-all rounded-lg middle none center hover:bg-red-500/10 active:bg-red-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={handleModalClose}
                                disabled={!isConfirmButtonActive}
                                className="middle none center rounded-lg bg-gradient-to-tr from-gray-800 to-gray-600 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:shadow-gray-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div style={{ color: fontColor }} className="mt-3">
                            Twilio Number is required
                          </div>
                          <div className="flex flex-wrap items-center justify-end p-4 shrink-0 text-blue-gray-500">
                            <button
                              onClick={handleToggle}
                              className="px-6 py-3 mr-1 font-sans text-xs font-bold text-red-500 uppercase transition-all rounded-lg middle none center hover:bg-red-500/10 active:bg-red-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => navigate("/integration")}
                              className="middle none center rounded-lg bg-gradient-to-tr from-green-600 to-green-400 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            >
                              Confirm
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div style={{ color: fontColor }} className="mt-3">
                        {/* Contact List is required */} Twilio Number is
                        required
                      </div>
                      <div className="flex flex-wrap items-center justify-end p-4 shrink-0 text-blue-gray-500">
                        <button
                          onClick={handleToggle}
                          className="px-6 py-3 mr-1 font-sans text-xs font-bold text-red-500 uppercase transition-all rounded-lg middle none center hover:bg-red-500/10 active:bg-red-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        >
                          Cancel
                        </button>
                        <button
                          // onClick={() => navigate("/create/list")}
                          onClick={() => navigate("/integration")}
                          className="middle none center rounded-lg bg-gradient-to-tr from-green-600 to-green-400 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        >
                          Confirm
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div style={{ color: fontColor }} className="mt-3">
                    Agent is required
                  </div>
                  <div className="flex flex-wrap items-center justify-end p-4 shrink-0 text-blue-gray-500">
                    <button
                      onClick={handleToggle}
                      className="px-6 py-3 mr-1 font-sans text-xs font-bold text-red-500 uppercase transition-all rounded-lg middle none center hover:bg-red-500/10 active:bg-red-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => navigate("/create/agent")}
                      className="middle none center rounded-lg bg-gradient-to-tr from-green-600 to-green-400 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    >
                      Confirm
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div style={{ color: fontColor }} className="mt-3">
                Email is not verified
              </div>
              <div className="flex flex-wrap items-center justify-end p-4 shrink-0 text-blue-gray-500">
                <button
                  onClick={handleToggle}
                  className="px-6 py-3 mr-1 font-sans text-xs font-bold text-red-500 uppercase transition-all rounded-lg middle none center hover:bg-red-500/10 active:bg-red-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                >
                  Cancel
                </button>
                <button
                  onClick={handleToggle}
                  className="middle none center rounded-lg bg-gradient-to-tr from-green-600 to-green-400 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                >
                  Confirm
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <CreateCampaign modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <QuickCampaign modalOpen={quickModal} setModalOpen={setQuickModal} />
    </>
  );
}

export default Agreement;
