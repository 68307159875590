import React, { useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../schemas/FormSchema";
import { useSelector } from "react-redux";

function ResetPassword() {
  const baseUrl = useSelector(state => state.baseUrl.baseUrl);
  const { uid } = useParams();
  const { fontColor,legalName,backgroundColor } = useSelector((state) => state.theme);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReEnterPassword, setShowReEnterPassword] = useState(false);
  const [response, setResponse] = useState("");

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleToggleReEnterPassword = () => {
    setShowReEnterPassword(!showReEnterPassword);
  };

  const formMik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, action) => {
      try {
        const response = await fetch(
          `${baseUrl}/api/new_password/${uid}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password: values.password,
              password2: values.confirm_password,
            }),
          }
        );
        if (response.ok) {
          action.resetForm();
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <>
      <div style={{ backgroundColor: backgroundColor }} className="w-screen h-screen p-8 flex justify-center items-center">
        <div className="md:basis-3/4 bg-white rounded-md lg:basis-1/2 xl:basis-1/4 w-full sm:basis-4/5 custom-shadow flex justify-center items-center gap-10 flex-col p-10">
          <div className="flex justify-center items-center flex-col gap-4">
            <h1 style={{ color: fontColor }} className="text-4xl font-medium text-center capitalize">{legalName}</h1>
            <p style={{ color: fontColor }} className="text-lg text-center">Change Your Password</p>
            <p style={{ color: fontColor }} className="text-sm text-center">
              Enter a new password below to change your password
            </p>
            {response ? (
              <p className="text-red-600 m-[-7px] capitalize font-medium">
                {response}
              </p>
            ) : null}
          </div>
          <div className="w-full flex justify-center items-center flex-col gap-4">
            <form
              className="w-full flex justify-center items-center gap-4 flex-col"
              onSubmit={formMik.handleSubmit}
            >
              <div className="relative w-full">
                <input
                  type={showNewPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password*"
                  value={formMik.values.password}
                  onChange={formMik.handleChange}
                  onBlur={formMik.handleBlur}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center mr-3 focus:outline-none"
                  onClick={handleToggleNewPassword}
                >
                  {showNewPassword ? (
                    <IoEyeOutline className="text-black h-6 w-6" />
                  ) : (
                    <IoEyeOffOutline className="text-black h-6 w-6" />
                  )}
                </div>
              </div>
              {formMik.errors.password && formMik.touched.password ? (
                <p className="w-full text-red-600 m-[-7px] font-medium">
                  {formMik.errors.password}
                </p>
              ) : null}
              <div className="relative w-full">
                <input
                  type={showReEnterPassword ? "text" : "password"}
                  id="confirm_password"
                  name="confirm_password"
                  placeholder="Password*"
                  value={formMik.values.confirm_password}
                  onChange={formMik.handleChange}
                  onBlur={formMik.handleBlur}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center mr-3 focus:outline-none"
                  onClick={handleToggleReEnterPassword}
                >
                  {showReEnterPassword ? (
                    <IoEyeOutline className="text-black h-6 w-6" />
                  ) : (
                    <IoEyeOffOutline className="text-black h-6 w-6" />
                  )}
                </div>
              </div>
              {formMik.errors.confirm_password &&
              formMik.touched.confirm_password ? (
                <p className="w-full text-red-600 m-[-7px] font-medium">
                  {formMik.errors.confirm_password}
                </p>
              ) : null}
              <button
                type="submit"
                className="text-white w-full bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;