import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { ImCreditCard } from "react-icons/im";
import { agencyOnboardingSchema } from "../schemas/FormSchema";
import { useDispatch, useSelector } from "react-redux";
import DNSExample from "../assets/dns.jpeg";
import { MdOutlineCopyAll } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import { setRefresh } from "../features/Refresh/refresh";

function AgencyOnboarding() {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const refresh = useSelector((state) => state.refresh.refresh);
  const dispatch = useDispatch();
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [showDNS, setShowDNS] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  const formMik = useFormik({
    initialValues: {
      legalName: "",
      address: "",
      email: "",
      domain: "",
      package: "",
      iSpaymentDone: false,
    },
    validationSchema: agencyOnboardingSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(
          `${baseUrl}/api/user_subscription_payment/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Company-Name": companyName,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify({
              legal_name: values.legalName,
              address: values.address,
              email: values.email,
              domain: `app.${values.domain.toLowerCase()}`,
              price_id: values.package,
              payment_status: values.iSpaymentDone,
              subscription_id: paymentData.subscription_id,
              subsription_type: paymentData.subsription_type,
            }),
          }
        );
        const jsonData = await response.json();
        if (response.ok) {
          if (jsonData.data) {
            window.open(jsonData.data.url, '_blank');
          }
          setShowDNS(true);
          dispatch(setRefresh(!refresh));
          toast.success("Agency onboarded successfully");
        } else if (response.status === 400) {
          toast.error(jsonData.message);
        } else {
          toast.error("Something went wrong!");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  const handleEmailBlur = async (e) => {
    formMik.handleBlur(e);

    if (!formMik.errors.email) {
      const apiCallPromise = async () => {
        const response = await fetch(
          `${baseUrl}/api/check_subcription_email/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Company-Name": companyName,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify({
              email: formMik.values.email,
            }),
          }
        );

        const jsonData = await response.json();
        if (response.ok) {
          formMik.setFieldValue("iSpaymentDone", jsonData.payment_status);
          setPaymentData(jsonData);
          return jsonData;
        } else {
          throw new Error(jsonData.message || "Invalid email");
        }
      };

      try {
        await toast.promise(
          apiCallPromise(),
          {
            loading: "Validating email...",
            success: "Email is valid",
            error: (err) => err.message,
          },
          {
            success: {
              duration: 4000,
            },
            error: {
              duration: 4000,
            },
          }
        );
      } catch (error) {
        // toast.error("Something went wrong while validating email");
      }
    }
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/user_subscription_payment/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Company-Name": companyName,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            // Define default options
            className: "",
            duration: 2000,
            style: {
              background: "#363636",
              color: "#fff",
            },

            // Default options for specific types
            success: {
              duration: 2000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
      </div>
      <div
        style={{ backgroundColor: backgroundColor }}
        className="flex-1 flex justify-center w-full items-center p-4"
      >
        <form
          onSubmit={formMik.handleSubmit}
          className="w-full bg-white custom-shadow p-5 rounded-lg max-w-2xl"
        >
          <div className="mb-5">
            <label
              htmlFor="legalName"
              style={{ color: fontColor }}
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Legal Name
            </label>
            <input
              type="text"
              id="legalName"
              name="legalName"
              value={formMik.values.legalName}
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Legal Name"
            />
            {formMik.errors.legalName && formMik.touched.legalName ? (
              <p className="w-full text-red-600 m-[7px] font-medium">
                {formMik.errors.legalName}
              </p>
            ) : null}
          </div>
          <div className="mb-5">
            <label
              htmlFor="address"
              style={{ color: fontColor }}
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={formMik.values.address}
              onChange={formMik.handleChange}
              onBlur={formMik.handleBlur}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Address"
            />
            {formMik.errors.address && formMik.touched.address ? (
              <p className="w-full text-red-600 m-[7px] font-medium">
                {formMik.errors.address}
              </p>
            ) : null}
          </div>
          <div className="mb-5">
            <label
              htmlFor="email"
              style={{ color: fontColor }}
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Email
            </label>

            <input
              type="text"
              id="email"
              name="email"
              value={formMik.values.email}
              onChange={formMik.handleChange}
              onBlur={handleEmailBlur}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="example@gmail.com"
            />
            {formMik.errors.email && formMik.touched.email ? (
              <p className="w-full text-red-600 m-[7px] font-medium">
                {formMik.errors.email}
              </p>
            ) : null}
          </div>
          <div className="mb-5">
            <label
              htmlFor="domain"
              style={{ color: fontColor }}
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Domain Name
            </label>
            <div className="flex justify-start items-stretch">
              <div className="border bg-[#F9FAFB] pb-1 px-2 rounded-s-lg flex justify-center items-center">
                app.
              </div>
              <input
                type="text"
                id="domain"
                name="domain"
                value={formMik.values.domain}
                onChange={formMik.handleChange}
                onBlur={formMik.handleBlur}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-e-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="example.com"
              />
            </div>
            {formMik.errors.domain && formMik.touched.domain ? (
              <p className="w-full text-red-600 m-[7px] font-medium">
                {formMik.errors.domain}
              </p>
            ) : null}
          </div>
          {formMik.values.iSpaymentDone ? null : (
            <div className=" mb-5">
              <label
                htmlFor="bordered-radio-1"
                style={{ color: fontColor }}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Select Package
              </label>
              <div className="flex flex-col md:flex-row justify-start gap-3">
                <div className="flex cursor-pointer basis-1/2 rounded dark:border-gray-700">
                  <input
                    type="radio"
                    id="bordered-radio-1"
                    name="package"
                    value={
                      data && data?.data?.length > 0
                        ? data?.data[0]?.price_id
                        : ""
                    }
                    className="hidden peer"
                    onChange={formMik.handleChange}
                  />
                  <label
                    htmlFor="bordered-radio-1"
                    // style={{ color: fontColor }}
                    className="peer-checked:text-blue-600 rounded-md border-gray-200 peer-checked:shadow-md peer-checked:border-blue-600 border"
                  >
                    <div className="w-full p-4  rounded-lg">
                      <div className="flex justify-between gap-3">
                        <h1 className="text-sm font-semibold">
                          {data && data?.data?.length > 0
                            ? data?.data[0]?.plan_name
                            : ""}
                        </h1>
                        <p className="text-sm font-semibold">
                          <span className="uppercase">
                            {data && data?.data?.length > 0
                              ? data?.data[0]?.currency
                              : ""}
                          </span>
                          $
                          {data && data?.data?.length > 0
                            ? data?.data[0]?.unit_amount_decimal / 100
                            : ""}
                          /month
                        </p>
                      </div>
                      <ul className="mt-2 ml-6 list-disc">
                        <li className="text-sm">
                          User will get{" "}
                          {data && data?.data?.length > 0
                            ? data?.data[0]?.description
                            : ""}
                        </li>
                      </ul>
                    </div>
                  </label>
                </div>
                <div className="flex cursor-pointer basis-1/2  rounded dark:border-gray-700">
                  <input
                    type="radio"
                    id="bordered-radio-2"
                    name="package"
                    value={
                      data && data?.data?.length > 0
                        ? data?.data[1]?.price_id
                        : ""
                    }
                    className="hidden peer"
                    onChange={formMik.handleChange}
                  />
                  <label
                    htmlFor="bordered-radio-2"
                    // style={{ color: fontColor }}
                    className="peer-checked:text-blue-600 rounded-md peer-checked:shadow-md border-gray-200 peer-checked:border-blue-600 border"
                  >
                    <div className="w-full p-4 rounded-lg">
                      <div className="flex justify-between gap-3">
                        <h1 className="text-sm font-semibold text-start">
                          {data && data?.data?.length > 0
                            ? data?.data[1]?.plan_name
                            : ""}
                        </h1>
                        <p className="text-sm font-semibold">
                          <span className="uppercase">
                            {data && data?.data?.length > 0
                              ? data?.data[1]?.currency
                              : ""}
                          </span>
                          $
                          {data && data?.data?.length > 0
                            ? data?.data[1]?.unit_amount_decimal / 100
                            : ""}
                          /month
                        </p>
                      </div>
                      <ul className="mt-2 ml-6 list-disc">
                        <li className="text-sm">
                          User will get{" "}
                          {data && data?.data?.length > 0
                            ? data?.data[1]?.description
                            : ""}
                        </li>
                      </ul>
                    </div>
                  </label>
                </div>
              </div>

              {formMik.errors.package && formMik.touched.package ? (
                <p className="w-full text-red-600 m-[7px]  font-medium">
                  {formMik.errors.package}
                </p>
              ) : null}
            </div>
          )}

          <button
            type="submit"
            className="text-white bg-gray-800 hover:bg-gray-700 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default AgencyOnboarding;
