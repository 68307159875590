import React, { useState } from "react";
import { TbWorldUpload } from "react-icons/tb";
import { RiFolderUploadLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { MdAttachFile } from "react-icons/md";
import { useFormik } from "formik";
import { knowledgeSchema } from "../schemas/FormSchema";
import { MdArrowBackIos } from "react-icons/md";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";

function CreateKnowledgeBase({ refresh, setRefresh }) {
  const baseUrl = useSelector((state) => state.baseUrl.baseUrl);
  const companyName = JSON.parse(localStorage.getItem("userData"))?.companies[0]
    ?.company_name;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { fontColor, backgroundColor } = useSelector((state) => state.theme);
  const [step, setStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [url, setUrl] = useState("");
  const [isDragOver, setIsDragOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleFiles = async (file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("upload_file", file[0]);
      formData.append("name", formMik.values.knowledgeName);

      const response = await fetch(`${baseUrl}/api/knowledge_base/`, {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE4MjI5Nzg0LCJpYXQiOjE3MTgwMTM3ODQsImp0aSI6IjQyYWY5MjY2NGM0ZjQ2NzJiYTBjODQ4MjkzMDIxM2QyIiwidXNlcl9pZCI6NH0.CFG6kc2KskSny2Ve6uw4Ln95Ae_fnIWsj4VPXjKolZQ`,
        },
        body: formData,
      });
      if (response.ok) {
        const responseData = await response.json();
        // setSuccess(responseData);
        toggleModal();
        formMik.values.knowledgeName = "";
        setRefresh(!refresh);
        toast.success("Knowledge Base Created Successfully");
      } else if (response.status === 400) {
        const responseData = await response.json();
        // setMessage(responseData.message);
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = Array.from(e.dataTransfer.files);
    setFiles(files);
    handleFiles(files);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setFiles(files);
    // console.log(files);
    handleFiles(files);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
    setStep(0);
    formMik.values.knowledgeName = "";
    setSelectedOption("");
  };

  const handleWebUrl = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("website_url", url);
      formData.append("name", formMik.values.knowledgeName);
      const response = await fetch(`${baseUrl}/api/knowledge_base/`, {
        method: "POST",
        headers: {
          // "content-type": "application/json",
          Accept: "application/json",
          "Company-Name": companyName,
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE4MjI5Nzg0LCJpYXQiOjE3MTgwMTM3ODQsImp0aSI6IjQyYWY5MjY2NGM0ZjQ2NzJiYTBjODQ4MjkzMDIxM2QyIiwidXNlcl9pZCI6NH0.CFG6kc2KskSny2Ve6uw4Ln95Ae_fnIWsj4VPXjKolZQ`,
        },
        body: formData,
      });
      if (response.ok) {
        setRefresh(!refresh);
        toggleModal();
        setUrl("");
        formMik.values.knowledgeName = "";
        toast.success("Knowledge Base Created Successfully");
      } else if (response.status === 400) {
        const responseData = await response.json();
        toast.error(responseData.message);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formMik = useFormik({
    initialValues: {
      knowledgeName: "",
    },
    validationSchema: knowledgeSchema,
    onSubmit: async (values) => {
      setStep(step + 1);
    },
  });

  const handleOptionContinue = () => {
    if (selectedOption) {
      setStep(2);
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-center items-center gap-2">
        <button
          onClick={toggleModal}
          className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-gray-800 to-gray-700 group-hover:from-[#52796f]  group-hover:via-[#4f772d] group-hover:to-[#90a955] hover:text-white dark:text-white dark:hover:text-gray-900 focus:ring-1 focus:outline-none focus:ring-lime-200 dark:focus:ring-lime-800"
        >
          <span
            style={{ color: fontColor, backgroundColor: backgroundColor }}
            className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0"
          >
            New Knowledge Base
          </span>
        </button>

        <div
          className={`${
            isModalVisible ? "" : "pointer-events-none"
          } fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black bg-opacity-60 ${
            isModalVisible ? "opacity-1" : "opacity-0"
          } backdrop-blur-sm transition-opacity duration-300`}
        >
          <div className="relative p-4 w-full max-w-3xl max-h-full">
            <div className="relative px-2 py-4 bg-white rounded-lg shadow dark:bg-gray-700">
              <div
                className={`basis-full w-full px-2 py-2 flex ${
                  step == 0 ? "justify-end" : "justify-between"
                } items-center`}
              >
                {step == 0 ? null : (
                  <button className="text-xl" onClick={() => setStep(step - 1)}>
                    <MdArrowBackIos />
                  </button>
                )}
                <button
                  type="button"
                  className="text-5xl"
                  onClick={toggleModal}
                >
                  <svg
                    className="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {step === 0 && (
                <>
                  <div>
                    <form onSubmit={formMik.handleSubmit}>
                      <div className="basis-full p-4 flex justify-start items-center">
                        <label
                          htmlFor="agentName"
                          style={{ color: fontColor }}
                          className="font-medium w-52 block text-left"
                        >
                          Knowledge Base Name:
                        </label>
                        <input
                          type="text"
                          id="knowledgeName"
                          name="knowledgeName"
                          placeholder="Knowledge Base Name*"
                          value={formMik.values.knowledgeName}
                          onChange={formMik.handleChange}
                          onBlur={formMik.handleBlur}
                          className="bg-gray-50 border flex-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      {formMik.errors.knowledgeName &&
                      formMik.touched.knowledgeName ? (
                        <p className="w-full text-red-600 m-[-7px] font-medium text-left ml-4">
                          {formMik.errors.knowledgeName}
                        </p>
                      ) : null}
                      <div className="flex justify-center items-center w-full gap-4 mt-2">
                        <button
                          // onClick={handleContinue}
                          type="submit"
                          className="text-white inline-flex justify-center bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              )}
              {step === 1 && (
                <div className="basis-full px-4 pt-4 flex justify-start flex-col items-start">
                  <ul className="flex items-stretch w-full gap-6 flex-col md:flex-row">
                    <li className="basis-1/2">
                      <input
                        type="radio"
                        id="webUrl"
                        name="knowledge"
                        value="webUrl"
                        onChange={handleOptionChange}
                        className="hidden peer"
                        required
                      />
                      <label
                        htmlFor="webUrl"
                        className="inline-flex items-center justify-between w-full text-gray-500 bg-white border border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                      >
                        <div className="inline-flex  items-center justify-between w-full h-full p-5 text-gray-900 bg-white border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500  peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                          <div
                            style={{ color: fontColor }}
                            className="flex justify-center items-center flex-col"
                          >
                            <TbWorldUpload className="text-4xl" />
                            <div
                              style={{ color: fontColor }}
                              className="w-full text-md my-2 font-medium capitalize text-center"
                            >
                              web url
                            </div>
                            <p
                              style={{ color: fontColor }}
                              className="text-sm text-[#111827] text-center"
                            >
                              Provide the website URL or any other webpage link
                              to enrich the bot's knowledge base and enable it
                              to extract valuable data for you.
                            </p>
                          </div>
                        </div>
                      </label>
                    </li>
                    <li className="basis-1/2">
                      <input
                        type="radio"
                        id="upload"
                        name="knowledge"
                        value="upload"
                        onChange={handleOptionChange}
                        className="hidden peer"
                      />
                      <label
                        htmlFor="upload"
                        className="inline-flex h-full items-center justify-between border w-full text-gray-500 bg-white  border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                      >
                        <div className="inline-flex  items-center justify-between w-full p-5 text-gray-900 bg-white   border-gray-200 rounded-r-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500  peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500">
                          <div
                            style={{ color: fontColor }}
                            className="flex justify-center items-center flex-col"
                          >
                            <RiFolderUploadLine className="text-4xl" />
                            <div
                              style={{ color: fontColor }}
                              className="w-full text-md my-2 font-medium capitalize text-center"
                            >
                              upload
                            </div>
                            <p
                              style={{ color: fontColor }}
                              className="text-sm text-[#111827] text-center"
                            >
                              Upload additional resources in PDF or CSV format, allowing bot to access a broader range of information.
                            </p>
                          </div>
                        </div>
                      </label>
                    </li>
                  </ul>
                  <div className="flex justify-center items-center w-full gap-4 mt-2">
                    <button
                      onClick={handleOptionContinue}
                      className="text-white inline-flex justify-center bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              )}
              {step === 2 && selectedOption == "webUrl" ? (
                <div className="px-4">
                  <form onSubmit={handleWebUrl}>
                    <label
                      style={{ color: fontColor }}
                      htmlFor="helper-text"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Enter Your URL
                    </label>
                    <input
                      type="text"
                      id="helper-text"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      aria-describedby="helper-text-explanation"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-800 focus:border-gray-800 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="https://www.example.com"
                    />
                    <button
                      type="submit"
                      className="text-white mt-3 inline-flex justify-center bg-gray-800 hover:bg-gray-900 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Continue
                    </button>
                  </form>
                </div>
              ) : (
                ""
              )}
              {step === 2 && selectedOption == "upload" ? (
                <div className="relative w-full min-w-[200px] p-4">
                  <div
                    className={`border-2 border-dashed h-[200px] flex justify-center items-center p-8  rounded-lg cursor-pointer relative ${
                      isDragOver ? "bg-gray-200" : ""
                    }`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <input
                      type="file"
                      id="fileInput"
                      className="hidden"
                      onChange={handleFileSelect}
                      accept="application/pdf, application/msword ,.doc, .docx, "
                    />
                    <div className="flex items-center gap-2">
                      <MdAttachFile
                        style={{ color: fontColor }}
                        className="text-4xl"
                      />
                      <div>
                        <p
                          style={{ color: fontColor }}
                          className="text-md text-gray-800 font-semibold"
                        >
                          Click to choose a file or drag and drop here
                        </p>
                        <p
                          style={{ color: fontColor }}
                          className="text-sm text-gray-600 font-medium"
                        >
                          Please provide a file in any of the following formats
                          word
                        </p>
                      </div>
                    </div>
                    <label
                      htmlFor="fileInput"
                      className="absolute inset-0"
                    ></label>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="w-full h-screen z-[999] absolute top-0 flex justify-center items-center overflow-hidden bg-white">
          <PulseLoader color="#36d7b7" loading={loading} />
        </div>
      )}
    </>
  );
}

export default CreateKnowledgeBase;
