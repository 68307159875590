import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contactData: [],
};

export const contactDataSlice = createSlice({
  name: "contactData",
  initialState,
  reducers: {
    setContactData(state, action) {
      state.contactData = action.payload;
    },
  },
});

export const { setContactData } = contactDataSlice.actions;
export default contactDataSlice.reducer;
