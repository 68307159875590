import React, { useState } from "react";
import CreateKnowledgeBase from "./CreateKnowledgeBase";
import { useSelector } from "react-redux";

function WelcomeKnowledge({ refresh, setRefresh }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { fontColor } = useSelector((state) => state.theme);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <>
      <div className="w-full h-[80vh] flex flex-col justify-center items-center ">
        <div className=" w-fit text-center px-5 flex flex-col gap-3 justify-center items-center">
          <div className="text-xl font-bold flex justify-center items-center gap-3">
            <span
              style={{ color: fontColor }}
              className="bg-clip-text text-transparent bg-gradient-to-r text-4xl from-gray-800 to-gray-700 lg:text-7xl"
            >
              Create Your First Knowledge Base
            </span>
          </div>
          <div className="my-6 flex justify-center">
            <p
              style={{ color: fontColor }}
              className="text-lg sm:basis-3/4 lg:basis-1/2 lg:text-2xl"
            >
              Create a knowledge base to give your agents important context and
              information about your company. Once your agent is connected to a
              knowledge base, it will have the ability to handle literally
              thousands of FAQs, overcome any objection and more... with perfect
              recall and infinite memory.
            </p>
          </div>
          <CreateKnowledgeBase refresh={refresh} setRefresh={setRefresh} />
        </div>
      </div>
    </>
  );
}

export default WelcomeKnowledge;
