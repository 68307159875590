import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refresh: false,
};

export const refreshSlice = createSlice({
  name: "refresh",
  initialState,
  reducers: {
    setRefresh(state, action) {
      state.refresh = action.payload;
    },
  },
});

export const { setRefresh } = refreshSlice.actions;
export default refreshSlice.reducer;
